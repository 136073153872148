import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { IStore } from '../../../../App/AppStore'
import { Project } from '../../models/Project'
import './ProjectListItem.css'


interface IProjectListItemProps extends IStore {
    project: Project
}

// @ts-ignore
@withRouter
@inject('store')
@observer
class ProjectListItem extends React.Component<IProjectListItemProps> {
    constructor(props: Readonly<IProjectListItemProps>) {
        super(props)
        this.onError = this.onError.bind(this)
    }

    public componentWillMount() {
        this.props.project.getPreviewImage()
    }

    public onError() {
        this.props.project.onErrorPreviewImage()
    }

    public render() {
        return (
            <Link className='project-list-item' to={`/project/${this.props.project.id}`}>
                <div className='first-section'>
                    <img src={this.props.project.previewImage} onError={this.onError} alt="" style={{ width: "100%" }} />
                </div>
                <div className='second-section'>
                    <div>
                        <h4><strong>{this.props.project.upperCaseName}</strong></h4>
                        <p className={'project-list-item-date'}>{this.props.store!.formatMessage('created')} {this.props.project.formatedCreatedDate}</p>
                    </div>
                    <p><i><FontAwesomeIcon icon={['fal', 'home']} fixedWidth={true} /></i> Paradigm Builder</p>
                    <p><i><FontAwesomeIcon icon={['fal', 'map-marker']} fixedWidth={true} /></i> {this.props.project.addressLine1}</p>
                </div>
            </Link>
        )
    }
}

export default ProjectListItem
