import { action, computed, observable, runInAction } from 'mobx'
import { Store } from '../../App/AppStore'
import { BaseModel } from '../common/stores/BaseModel'
import { projectService } from '../project/ProjectService'
import { builderService, designService, assetBundleLabelsService } from './BuilderProjectService'
import { Builder } from './models/Builder'
import { Design } from './models/Design'
import { Editor } from './models/Editor'
import { Section } from './models/Section'
import { TemplateProjectInput } from './models/TemplateProjectInput'
import { Client } from './models/Client'
import { AssetBundleLabel } from './models/AssetBundleLabel'
import { JsogService } from 'jsog-typescript'

const PRODUCTION_SECTION = 'Product Selection'
const MYBUILD_PARAMETERS = 'MyBuild_Parameters'

export default class BuilderProjectStore extends BaseModel {
    @observable public builders: Builder[] = []
    @observable public design: Design
    @observable public editor: Editor
    @observable public sections: Section[] = []
    @observable public isLoading: boolean = false
    @observable public selectedBuilder: Builder = new Builder()
    @observable public currentTemplateProjectInput: TemplateProjectInput = new TemplateProjectInput()
    @observable public clients: Client[] = []
    @observable public clientId: string
    @observable public assetbundleLabels: AssetBundleLabel[] = []

    constructor(store: Store) {
        super(store)
    }

    @computed get hasBuilders(): boolean {
        return this.builders.length > 0
    }

    @action public setCurrentTemplateProjectInput(templateProjectInput: TemplateProjectInput) {
        this.currentTemplateProjectInput = templateProjectInput
    }

    @action public setName(name: string | null) {
        name = name ? name : ''
        this.currentTemplateProjectInput.name = name
    }

    @action public setInternalName(internalName: string | null) {
        internalName = internalName ? internalName : ''
        this.currentTemplateProjectInput.internalName = internalName
    }

    @action public setSelectedBuilder(selectedBuilder: Builder) {
        this.selectedBuilder = selectedBuilder
        this.currentTemplateProjectInput.builderId = this.selectedBuilder.id
    }

    @action public setSelectedClientId(clientId: string | null) {
        this.currentTemplateProjectInput.clientId = clientId
    }

    @action public setEmptyBuilderAsSelected() {
        this.setSelectedBuilder(new Builder())
    }

    @action public setBuilders(builders: Builder[]) {
        this.builders = builders
    }

    @action public setEditor(editor: Editor) {
        this.editor = editor
    }

    @action public setDesign(design: Design) {
        this.design = design
    }

    @action public setSections(sections: Section[]) {        
        this.sections = sections
    }

    @computed get filteredSection() {
        return this.sections.filter((x: any) => x.name !== PRODUCTION_SECTION && x.name !== MYBUILD_PARAMETERS)
    }

    @action public setLoading(loading: boolean) {
        this.isLoading = loading
    }

    @action public clearTemplate() {
        this.currentTemplateProjectInput = new TemplateProjectInput()
        this.selectedBuilder = new Builder()
    }

    @action public async publishProject(projectId: string) {
        try {
            await projectService.publish(projectId, this.currentTemplateProjectInput)
            this.store.notifyUser(this.store.formatMessage('successPublished'), 'success')
            this.clearTemplate()
        } catch (error) {
            this.store.notifyUser(this.store.formatMessage('somethingWentWrong'), 'error')
        }
    }

    @action public async fetchBuilders(): Promise<Builder[]> {
        let builders: Builder[] = []
        try {
            this.setLoading(true)
            const response = await builderService.findAll()
            builders = response.data
            this.setBuilders(builders)
        } catch (error) {
            console.error(error)
        } finally {
            this.setLoading(false)
        }

        return builders
    }

    @action public async fetchEditor(editorName: string): Promise<Editor> {
        let editor: Editor = new Editor()

        try {
            this.setLoading(true)
            const token = this.design.authToken.access_token
            const response = await designService.createDesignEditor(
                this.selectedBuilder.id, 
                this.design.design.id, 
                editorName, 
                token, 
                this.store.getToken()
            )
            editor = response.data as Editor
            this.setEditor(editor)
        } catch (error) {
            console.error(error)
        } finally {
            this.setLoading(false)
        }

        return editor
    }

    @action public async fetchSections(designName: string, buildingModelId: string, editorName: string, scene?: string): Promise<Section[]> {
        let sections: Section[] = []
        try {
            this.setLoading(true)
            
            const design = await designService.createDesignFromName(designName, this.clientId, this.selectedBuilder.id, buildingModelId)
            this.setDesign(design.data)
            
            const token = this.design.authToken.access_token

            await this.fetchEditor(editorName)
            const response = await designService.fetchSections(this.editor.id, this.design.design.id, token, this.store.getToken())
            sections = response.data

            this.setSections(sections)
        } catch (error) {
            console.error(error)
        } finally {
            this.setLoading(false)
        }

        return sections
    }

    @action public selectScene = async (assetBundleLabel: string) => {
        const sectionId = this.findSectionIdByName(this.sections, "MyBuild_Parameters")

        const { access_token } = this.design.authToken

        if (sectionId) {
            const sectionResponse = await designService.fetchSection(
                this.design.design.id, 
                this.editor.id, 
                sectionId, 
                access_token, 
                this.store.getToken()
            )
            let section = sectionResponse.data as Section
            if (section) {
                const sceneQuestion = section.questions.find(q => q.text === 'Scene')
                if (sceneQuestion) {
                    const selectedLabelAnswer = sceneQuestion.answers.find(a => a.text === assetBundleLabel)
                    
                    if (selectedLabelAnswer && selectedLabelAnswer.id !== sceneQuestion.selectedAnswerId) {
                        sceneQuestion.selectedAnswerId = selectedLabelAnswer.id
                        const answerResponse = await designService.answerQuestion(
                            this.design.design.id, 
                            this.editor.id, 
                            sectionId, 
                            sceneQuestion, 
                            access_token, 
                            this.store.getToken()
                        )
                        section = answerResponse.data as Section

                        const response = await designService.fetchSections(
                            this.editor.id, 
                            this.design.design.id, 
                            access_token, 
                            this.store.getToken()
                        )
                        this.setSections(response.data as Section[])
                    }
                }
            }
        }
    }

    @action public async fetchClients(): Promise<Client[]> {
        let clients: Client[] = []
        try {
            this.setLoading(true)
            const response = await designService.fetchClients()
            clients = response.data
            runInAction(() => this.clients = clients)
        } catch (error) {
            console.error(error)
        } finally {
            this.setLoading(false)
        }

        return clients
    }

    public async findUrlFromPublishingData(templateName: string, builderName: string, clientId: string): Promise<string | null>
    {
        try {
            const response = await builderService.findUrl(templateName, builderName, clientId)
            return response.data
        } catch (error) {
            console.error(error)
        }

        return null
    }

    public findSectionIdByName(sections: Section[], sectionName: string): string | undefined {
        const foundSection = sections.find((section) => section.name === sectionName)
        let sectionId

        if (foundSection !== undefined) {
            sectionId = foundSection.id
        }

        return sectionId
    }

    @action public async fetchAssetBundlelabels(): Promise<AssetBundleLabel[]> {
        let assetbundleLabels: AssetBundleLabel[] = []
        try {
            const response = await assetBundleLabelsService.findAll()
            const jsog = new JsogService()
            const labels = jsog.deserialize(response.data, AssetBundleLabel) as AssetBundleLabel[]
            assetbundleLabels = labels
            runInAction(() => this.assetbundleLabels = labels)
            return assetbundleLabels
        } catch (error) {
            console.error(error)
        }
        return assetbundleLabels
    }
}
