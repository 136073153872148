import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { ControlLabel, FormGroup } from 'react-bootstrap'
import { IStore } from '../../../App/AppStore'
import { IUploadFieldState } from './interfaces'
import { styled } from '@paradigm/blueprints-common-frontend'

const FileInput = styled.input`
    text-align: center;
    padding: 1rem;
    margin: 0 auto;
    border: 1px dashed ${props => props.theme.colors.primary};
`

@inject('store')
@observer
export class UploadField extends React.Component<IStore, IUploadFieldState> {
    constructor(props: Readonly<IStore>) {
        super(props)
        this.onChange = this.onChange.bind(this)

        this.state = {
            fileName: ''
        }
    }

    public onChange(event: any) {
        if (event.target.files.length > 0) {
            const file: File = event.target.files[0]
            this.setState({ fileName: file.name })
            this.props.store!.projectStore.handleFileUpload(file)
        }
    }

    public render() {
        return (
            <FormGroup controlId='file'>
                <ControlLabel>{this.props.store!.formatMessage('uploadField.btn.label')}</ControlLabel>
                <FileInput style={{ margin: 'auto', border: '1px dashed ' }} type='file' multiple={false} onChange={this.onChange} />
                <span className='help-block'>
                    {this.props.store!.formatMessage('uploadField.help')}
                </span>
            </FormGroup>
        )
    }
}
