import { AxiosPromise } from 'axios'
import * as queryString from 'query-string'
import * as transport from '../common/service/transport'
import { IGeometryDTO } from './interfaces'
import { IMappingData } from './interfaces'

const BUILDING_MODEL_API = '/buildingmodel/api'
const TRANSLATION_END_POINT = 'translation'

export function getModel(id: string) {
    return transport.get(`${BUILDING_MODEL_API}/model/${id}`)
}

export function getGeometries(modelNodes: IGeometryDTO[], modelId: string, includeMetadata: boolean) {
    const queryParameters = queryString.stringify({ includeMetadata })
    return transport.post(`${BUILDING_MODEL_API}/model/${modelId}/elements?${queryParameters}`, modelNodes)
}

export function fetch2DDataForElements(elementIds: string[], modelId: string): AxiosPromise<Map<string, object>> {
    return transport.post(`${BUILDING_MODEL_API}/model/${modelId}/elements2d`, elementIds)
}

export function getMetadata(modelId: string, metadataId: string) {
    return transport.get(`${BUILDING_MODEL_API}/model/${modelId}/metadata/${metadataId}`)
}

export function updateMappingData(modelId: string, mappingdata: Map<string, IMappingData>) {
    return transport.post(`${BUILDING_MODEL_API}/model/${modelId}/mapping`, mappingdata)
}

export function getGeometryPerimeter(checksum: string) {
    return transport.get(`${BUILDING_MODEL_API}/geometry/${checksum}/perimeter`)
}

export function fetchMappingData(id: string) {
    return transport.get(`${BUILDING_MODEL_API}/model/${id}/mapping`)
}

export const translationService = {
    fetchTranslationByExternalId: (externalId: string) => {
        return transport.get(`${BUILDING_MODEL_API}/${TRANSLATION_END_POINT}/externalId/${externalId}`)
    }
}