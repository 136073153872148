import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { withRouter } from 'react-router-dom'
import { IStore } from '../../../../App/AppStore'
import Banner from '../../../common/components/Banner'
import ProjectListHeader from '../../components/ProjectListHeader'
import ProjectListItem from '../../components/ProjectListItem'
import ProjectListToolbar from '../../components/ProjectListToolbar'
import createProjectBackground from '../assets/mybuild-background-smaller.jpg'
import './ProjectList.css'

@inject('store')
@observer
class ProjectList extends React.Component<IStore> {
    constructor(props: Readonly<IStore>) {
        super(props)
        this.refreshData = this.refreshData.bind(this)
        this.fetchMoreData = this.fetchMoreData.bind(this)
    }

    public componentWillMount() {
        this.refreshData()
    }

    public fetchMoreData = () => {
        this.props.store!.projectStore.loadProjects()
    }

    public refreshData = () => {
        this.props.store!.projectStore.refreshData()
    }

    public renderItems() {
        if (!this.props.store!.projectStore.isLoading && this.props.store!.projectStore.projects.length === 0) {
            return <p className={'empty-projects'}>{this.props.store!.formatMessage('noProjects')}</p>
        }

        return this.props.store!.projectStore.projects.map(project => (
            <div className='project-list-item-container' key={project.id}>
                <div className='project-list-item-card' key={project.id}>
                    <ProjectListItem project={project} />
                </div>
            </div>
        ))
    }

    public render() {
        const loader = (
            <Col xs={12} style={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={faSpinner} size={'2x'} spin={true} />
            </Col>
        )

        return (
            <div className='project-list-wrapper'>
                <ProjectListHeader />
                <Grid style={{ padding: 64, minHeight: 'calc(100vh - 278px)' }}>
                    <ProjectListToolbar />
                    <Row>
                        <Col xs={12} style={{ padding: 0 }}>
                            <InfiniteScroll
                                dataLength={this.props.store!.projectStore.projects.length}
                                next={this.fetchMoreData}
                                hasMore={this.props.store!.projectStore.hasMore}
                                loader={loader}
                                endMessage={''}
                            >
                                {this.renderItems()}
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </Grid>
                <Banner
                    buttonContent={this.props.store!.formatMessage('createProject')}
                    content={this.props.store!.formatMessage('createProjectPanelContent')}
                    image={createProjectBackground}
                    imageAlt='mybuild-background-smaller.jpg'
                    isDisabled={false}
                    buttonRef='/project'
                />
            </div>
        )
    }
}

export default withRouter(ProjectList as React.ComponentType<any>)
