import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { IStore } from '../../../App/AppStore'

@inject('store')
@observer
export class TotalFinishArea extends React.Component<IStore> {
    public render() {
        const { isLoadingModel } = this.props.store!.buildingModel

        if (isLoadingModel) {
            return null
        }

        const totalFinishArea = this.props.store!.buildingModel.calculateTotalFinishArea()

        return (
            <React.Fragment>
                {totalFinishArea} {this.props.store!.formatMessage('squareFeet')}
            </React.Fragment>
        )
    }
}
