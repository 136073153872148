
import {
    ActionEvent, ActionManager, ArcRotateCamera, Camera,
    Color3, CombineAction, DynamicTexture, ExecuteCodeAction,
    Mesh, MeshBuilder, Nullable, Path2, PointLight,
    Scene, SetValueAction, StandardMaterial, TargetCamera, Tools, Vector2, Vector3, VertexData
} from "babylonjs"
import * as fileStorageApi from '../../common/service/fileStorageApi'
import { IOpeningMeasurementLabel } from "../interfaces"
import { Edge } from "../models/Edge"
import * as VectorUtils from "./vectorUtils"

const ELEVATION_2D = 0
export const LIGHTER_GREY_COLOR = new Color3(255 / 255, 245 / 255, 228 / 255)
export const DARKER_GREY_COLOR = new Color3(192 / 255, 183 / 255, 176 / 255)
export const DEFAULT_ALPHA_OPAQUE: number = 1
export const DEFAULT_ALPHA_TRANSPARENT: number = 0
export const DEFAULT_ALPHA_WINDOWS: number = 0.5

export const COLOR_ACTIVE_HEX_STRING: string = '#1C66D1'
export const SELECTED_MESH_COLOR_IN_2D = Color3.FromHexString(COLOR_ACTIVE_HEX_STRING)
export const SELECTED_MESH_COLOR_ALPHA_IN_2D = 0.25

const FONT_SIZE = 300
const FONT = "bold " + FONT_SIZE + "px Arial, Helvetica, Sans-serif"
const DISC_TESSELATION = 60
const DISC_RADIUS = 1
const SQUARE_WIDTH = 2
const TEXTURE_WIDTH = 64
const TEXTURE_HEIGHT = 1.5 * FONT_SIZE
const TEXTURE_PLANE_HEIGHT = 1
const TEXTE_BASELINE_MIDDLE = 'middle'
const LABEL_SQUARE_NAME: string = 'square'
const LABEL_DISC_NAME: string = 'disc'
const LABEL_TEXT_NAME: string = 'plane'

export const MATERIAL_PROPERTY: string = 'material'

export const AXIX_NEGATIVE_Z: Vector3 = new Vector3(0, 0, -1)

export function getGroundPosition(scene: Scene, ground: Mesh, is2D: boolean = true): Vector3 | null {
    let position: Vector3 | null = null
    const pickinfo = scene.pick(scene.pointerX, scene.pointerY, (mesh) => mesh === ground)

    if (pickinfo && pickinfo.hit && pickinfo.pickedPoint) {
        if (is2D) {
            pickinfo.pickedPoint.y = ELEVATION_2D
        }
        position = pickinfo.pickedPoint
    }

    return position
}

export function detachCamera(scene: Scene): void {
    const camera = scene.activeCamera
    const canvas = findSceneCanvas(scene)

    if (camera && canvas) {
        setTimeout(() => { camera.detachControl(canvas) }, 0)
    }
}

export function attachCamera(scene: Scene): void {
    const camera = scene.activeCamera
    const canvas = findSceneCanvas(scene)

    if (camera && canvas) {
        if (camera instanceof ArcRotateCamera) {
            (camera as ArcRotateCamera).attachControl(canvas, false, true)
        }
        camera.attachControl(canvas, false)
    }
}

export function findSceneCanvas(scene: Scene): Nullable<HTMLCanvasElement> {
    let canvas = null

    if (scene.getEngine() !== null) {
        canvas = scene.getEngine().getRenderingCanvas()
    }

    return canvas
}

export function createStandardMaterial(color: Color3, scene: Scene): StandardMaterial {
    const material = new StandardMaterial('material', scene)
    material.diffuseColor = color
    material.specularColor = Color3.Black()
    return material
}

export function createCustomMesh(vertices: number[], indices: number[], scene: Scene, colors?: number[]): Mesh {
    const vertexData = new VertexData()
    vertexData.positions = vertices
    vertexData.indices = indices

    if (colors) {
        vertexData.colors = colors
    }

    return createCustomMeshFormVertexData(vertexData, scene)
}


export function createCustomMeshFormVertexData(vertexData: VertexData, scene: Scene): Mesh {
    vertexData.normals = []
    VertexData.ComputeNormals(vertexData.positions, vertexData.indices, vertexData.normals)
    const mesh = new Mesh('mesh', scene)
    vertexData.applyToMesh(mesh, true)
    return mesh
}

export function createVertexDataToShowTranparency(vertices: number[], indices: number[], materialIndices: number[], materials: number[]): VertexData {
    const vertexData = new VertexData()
    const newVertices: number[] = []
    const newIndices: number[] = []
    const colors: number[] = []

    let trianglesIndex = 0
    let triangleAlpha = DEFAULT_ALPHA_OPAQUE

    indices.forEach((indice, index) => {
        const vertex = new Vector3(vertices[indice * 3], vertices[indice * 3 + 1], vertices[indice * 3 + 2])
        newVertices.push(vertex.x, vertex.y, vertex.z)

        if (newIndices.length % 3 === 0) {
            if (newIndices.length !== 0) {
                trianglesIndex++
            }

            const triangleMaterialIndex = materialIndices[trianglesIndex] * 4
            const triangleAlphaIndex = triangleMaterialIndex + 3
            triangleAlpha = materials[triangleAlphaIndex]
        }

        newIndices.push(index)
        let color

        if (triangleAlpha < DEFAULT_ALPHA_OPAQUE) {
            triangleAlpha = DEFAULT_ALPHA_WINDOWS
            color = Color3.White()
        } else {
            color = DARKER_GREY_COLOR
        }

        colors.push(color.r, color.g, color.b, triangleAlpha)
    })

    vertexData.positions = newVertices
    vertexData.indices = newIndices
    vertexData.colors = colors
    const normals:number[] = []
    VertexData.ComputeNormals(newVertices, indices, normals)
    vertexData.normals = normals

    return vertexData
}


export function materialsContainTransparency(materials: number[]): boolean {
    for (let index = 0; index < materials.length; index += 4) {
        if (materials[index + 3] < 1) {
            return true
        }
    }

    return false
}

/**
 * This method uses the camera to zoom the a mesh using its bounding box
 *
 * @param mesh Mesh for which we need to zoom on
 * @param camera Arcrotate camera
 * @param distanceFactor distance factor from the camera to the mesh position
 */
export function zoomAll(mesh: Mesh, camera: ArcRotateCamera, distanceFactor: number = 2) {
    const boundingBox = mesh.getBoundingInfo().boundingBox

    const lengthX = (boundingBox.maximum.x - boundingBox.minimum.x) / 2
    const lengthY = (boundingBox.maximum.y - boundingBox.minimum.y) / 2
    const lengthZ = (boundingBox.maximum.z - boundingBox.minimum.z) / 2

    const zDist = Math.max(lengthX, lengthY) / Math.tan(camera.fov / 2)


    const centerX = boundingBox.minimum.x + lengthX
    const centerY = boundingBox.minimum.y + lengthY
    const centerZ = boundingBox.minimum.z + lengthZ

    camera.setTarget(new Vector3(centerX, centerY, centerZ))
    camera.radius = zDist + lengthZ * distanceFactor

    camera.beta = Math.PI / 2
    camera.alpha = -Math.PI / 180 * 90
}

export function createLine(edge: Edge, color: Color3, name: string, scene: Scene): Mesh {
    const line = Mesh.CreateLines(name, [new Vector3(edge.p1.x, edge.p1.y, edge.p1.z),
    new Vector3(edge.p2.x, edge.p2.y, edge.p2.z)], scene)
    line.color = color

    return line
}

export function createPoinLight(name: string, position: Vector3, direction: Vector3, intensity: number, scene: Scene): PointLight {
    const light = new PointLight(name, position, scene)
    light.setDirectionToTarget(direction)
    light.intensity = intensity
    return light
}

export function createMeshActionManager(mesh: Mesh, material: StandardMaterial, codeActionFunc: (evt: ActionEvent) => void, scene: Scene) {
    mesh.actionManager = new ActionManager(scene)

    mesh.actionManager.registerAction (
        new CombineAction( ActionManager.OnLeftPickTrigger, [
                new SetValueAction (ActionManager.OnPointerOverTrigger, mesh, MATERIAL_PROPERTY, material),
                new ExecuteCodeAction ({ trigger: ActionManager.OnLeftPickTrigger, parameter: '' }, codeActionFunc)
            ]
        )
    )
}

/**
 * If needed, this method will perform a counter clockwise reorganization of points following a path
 *
 * @param pLine
 */
export function makeCounterClockWise(pLine: Path2): Path2 {
    if (isCounterClockwise(pLine.getPoints())) {
        return pLine
    }
    let counterClockWisePline: Path2
    const points: Vector2[] = pLine.getPoints()
    const firstPoint: Vector2 = points[points.length - 1]
    counterClockWisePline = new Path2(firstPoint.x, firstPoint.y)
    for (let i = points.length - 2; i >= 0; i--) {
        const currentPoint = points[i]
        counterClockWisePline.addLineTo(currentPoint.x, currentPoint.y)
    }
    counterClockWisePline.close()
    return counterClockWisePline
}

function isCounterClockwise(vector2List: Vector2[]) {
    const lines: Array<[Vector2, Vector2]> = []

    for (let index = 0; index < vector2List.length; index++) {
        const point1 = vector2List[index]
        const point2 = vector2List[(index + 1) % vector2List.length]

        if (VectorUtils.pointCompare(point1, point2) === false) {
            lines.push([point1, point2])
        }
    }

    let kSum: number = 0

    for (let index = 0; index < lines.length; index++) {
        const currentLine: [Vector2, Vector2] = lines[index]
        const nextLine: [Vector2, Vector2] = lines[(index + 1) % lines.length]

        const a: Vector2 = currentLine[0].subtract(currentLine[1])
        const b: Vector2 = nextLine[0].subtract(nextLine[1])

        const km: number = a.x * b.y - a.y * b.x

        const am: number = a.length()
        const bm: number = b.length()

        const k: number = km / (am * bm)

        kSum += k
    }

    // Positive means counterclockwise, negative means clockwise
    return kSum > 0
}

export function createDiscLabel(text: string, backgroungColorInHexString: string, textColorInHexString: string, scene: Scene): IOpeningMeasurementLabel {
    const textPlane = createPlaneWithText(text, backgroungColorInHexString, textColorInHexString, scene)
    const disc = MeshBuilder.CreateDisc(LABEL_DISC_NAME, {tessellation: DISC_TESSELATION, radius: DISC_RADIUS}, scene)

    return {
        plane: textPlane,
        mesh: disc
    }
}

export function createSquareLabel(text: string, backgroungColorInHexString: string, textColorInHexString: string, scene: Scene): IOpeningMeasurementLabel {
    const textPlane = createPlaneWithText(text, backgroungColorInHexString, textColorInHexString, scene)
    const square = MeshBuilder.CreatePlane(LABEL_SQUARE_NAME, { width: SQUARE_WIDTH, height: SQUARE_WIDTH }, scene)

    return {
        plane: textPlane,
        mesh: square
    }
}

export function createPlaneWithText(text: string, backgroungColorInHexString: string, textColorInHexString: string, scene: Scene): Mesh {
    const ratio = TEXTURE_PLANE_HEIGHT / TEXTURE_HEIGHT

    const textureUsedToMeasureTextWidth = new DynamicTexture("DynamicTexture", TEXTURE_WIDTH, scene, false)
    const context = textureUsedToMeasureTextWidth.getContext()
    context.font = FONT
    const textureWidth = context.measureText(text).width
    const planeWidth = textureWidth * ratio
    textureUsedToMeasureTextWidth.dispose()

    const dynamicTexture = new DynamicTexture("DynamicTexture", { width: textureWidth, height: TEXTURE_HEIGHT }, scene, false)
    dynamicTexture.getContext().textBaseline = TEXTE_BASELINE_MIDDLE
    const material = new StandardMaterial("mat", scene)
    material.diffuseTexture = dynamicTexture
    material.opacityTexture = dynamicTexture
    dynamicTexture.drawText(text, 0, (TEXTURE_HEIGHT / 2) , FONT, textColorInHexString, backgroungColorInHexString, true)

    const textPlane = MeshBuilder.CreatePlane(LABEL_TEXT_NAME, { width: planeWidth, height: TEXTURE_PLANE_HEIGHT }, scene)
    textPlane.material = material

    return textPlane
}

 /**
  * This method creates an image based on a screenshot of a specified mesh of the scene
  * The image is save on the file storage using API.
  * @param scene - the scene in which the screenshot is taken
  * @param fileName - the name to give to the screenshot image file on storage server
  * @param storageFileContainer - the file storage container name (folder)
  * @param meshToScreenShot - the mesh to screenshot
  * @param maxHeightOrWidthResolution - the maximum resolution for either image height or width
  * @returns true if the sceenshot was successfully added
  */
export async function screenshotMeshAndUploadToFileStorage(scene: Scene, fileName: string, storageFileContainer: string, meshToScreenShot: Mesh, maxHeightOrWidthResolution: number): Promise<boolean> {
    const MAPPING_SCREENSHOT_FILE_TYPE: string = 'image/png'

    // get the mesh dimensions
    const maximumBoundingBox: Vector3 = meshToScreenShot.getBoundingInfo().boundingBox.maximum
    const meshWidth = Math.abs(maximumBoundingBox.x * 2)
    // existing mesh has not the same y/z axes ... detect if the value of Y is near 0 ... Use Z in that case
    const meshHeight = Math.abs((Math.round(maximumBoundingBox.y) === 0 ? maximumBoundingBox.z : maximumBoundingBox.y) * 2)

    // if the mesh has invalid dimensions (0), return undefined
    if (!(meshWidth > 0 && meshHeight > 0)) {
        return false
    }

    // create a new camera for the screenshot and add it to the scene
    const meshCamera = addAndZoomCameraOnMesh(scene, meshToScreenShot, "meshCamera")

    if (!meshCamera) {
        return false
    }

    // calculate the dimensions (pixels) of the screenshot that fit the mesh
    const widthHeightProportion: number = meshWidth/meshHeight
    let screenshotWidth: number = maxHeightOrWidthResolution
    let screenshotHeight: number = maxHeightOrWidthResolution
    if (widthHeightProportion > 1) {
        screenshotHeight = Math.round(screenshotHeight / widthHeightProportion)
    } else {
        screenshotWidth = Math.round(screenshotWidth * widthHeightProportion)
    }
    // add camera on the scene
    scene.addCamera(meshCamera)

    // create screenshot
    await generateScreenshotAndAddToFileStorage(meshCamera, screenshotWidth, screenshotHeight, storageFileContainer, fileName, MAPPING_SCREENSHOT_FILE_TYPE)

    // remove the snapshot camera from the scene
    scene.removeCamera(meshCamera)

    return true
}

/**
 * Creates a camera by cloning the scene's active camera, place it in front of a specified mesh and zoom on it
 *
 * @param scene - the scene on work on
 * @param meshToScreenShot - the mesh on which to place and zoom the camera
 * @param cameraName - the name to give to the new added camera
 *
 * @returns the newly created camera or undefined if there was an issue
 */
export function addAndZoomCameraOnMesh(scene: Scene, meshToScreenShot: Mesh, cameraName: string): Camera | undefined {
    let meshCamera: Camera | undefined

    if (meshToScreenShot && scene.activeCamera) {
        const maximumBoundingBox: Vector3 = meshToScreenShot.getBoundingInfo().boundingBox.maximum
        const meshWidth: number = Math.abs(maximumBoundingBox.x * 2)
        // existing mesh has not the same y/z axes ... detect if the value of Y is near 0 ... Use Z in that case
        const meshHeight: number = Math.abs((Math.round(maximumBoundingBox.y) === 0 ? maximumBoundingBox.z : maximumBoundingBox.y) * 2)

        // clone the active camera so it inherit of all the positionning settings
        meshCamera = scene.activeCamera.clone(cameraName)

        // if the camera is an instance of TargetCamera, we can set a target on it, we then set the mesh to screenshot as the target
        if (meshCamera instanceof TargetCamera) {
            meshCamera.setTarget(meshToScreenShot.position)
        }

        // crop camera angles to fit with the mesh
        meshCamera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA
        meshCamera.orthoTop = meshHeight / 2
        meshCamera.orthoBottom = -(meshHeight / 2)
        meshCamera.orthoLeft = -(meshWidth / 2)
        meshCamera.orthoRight = meshWidth / 2
    }
    return meshCamera
}

/**
 * Creates a screenshot using a already placed camera using specific image resolution
 * The screenshot image is saved on the file storage
 *
 * @param screenshotCamera - the camera to use for the image screenshot
 * @param screenshotWidth - the image width dimension (pixels)
 * @param screenshotHeight - the image height dimension (pixels)
 * @param storageFileContainer - the file storage container name (folder)
 * @param imageName - the name to give to the screenshot image file on storage server
 * @param mimeType - the mime type of the image
 */
export function generateScreenshotAndAddToFileStorage(screenshotCamera: Camera, screenshotWidth:number, screenshotHeight:number, storageFileContainer:string, imageName:string, mimeType:string) {
    Tools.CreateScreenshotUsingRenderTarget(screenshotCamera.getScene().getEngine(), screenshotCamera, {width: screenshotWidth, height: screenshotHeight},
    data => {
        dataToFile(data, imageName, mimeType).then(file => {
            fileStorageApi.files.storeFile(storageFileContainer, imageName, file)
        })
    }, mimeType, 1, true, imageName)
}

/**
 * This method generate a file based on a data (bite stream)
 * @param data - the bite stream to convert in file
 * @param filename - the file name
 * @param mimeType - the mime type
 * @returns the file created
 */
export function dataToFile(data: string, filename: string, mimeType: string): Promise<File>{
    mimeType = mimeType || (data.match(/^data:([^;]+);/) || '')[1]
    return (fetch(data)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }))
    )
}
