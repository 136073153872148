import { computed, observable } from 'mobx'
import { User } from '../../auth/models/User'
import { QualityAssuranceDetail } from './QualityAssuranceDetail'
import { Status } from './Status'

export class QualityAssurance {
  @observable public id: string
  @observable public createdDate: number
  @observable public modifiedDate: number
  @observable public createdBy: string
  @observable public comment: string = ''
  @observable public status: Status = new Status()
  @observable public identifier: number
  @observable public qualityAssuranceDetails: QualityAssuranceDetail[] = []
  @observable public reviewer: User = new User()

  @computed get formatedCreatedDate(): string {
      if (this.createdDate !== undefined) {
          return new Date(this.createdDate).toLocaleDateString()
      }
      return ''
  }

  @computed get formatedModifiedDate(): string {
      if (this.modifiedDate !== undefined) {
          return new Date(this.modifiedDate).toLocaleDateString()
      }
      return ''
  }
}
