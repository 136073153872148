import { inject, observer } from 'mobx-react'
import * as React from 'react'
import Notifications from 'react-notify-toast'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import Profile from '../modules/auth/containers/Profile'
import Footer from '../modules/common/components/Footer'
import Header from '../modules/common/components/Header'
import { CreateProject } from '../modules/project/components/CreateProject'
import { QualityAssurance } from '../modules/project/components/QualityAssurance'
import CameraTool from '../modules/project/containers/CameraTool'
import DotTool from '../modules/project/containers/DotTool'
import MappingToolContainer from '../modules/project/containers/MappingToolContainer'
import ProjectDetails from '../modules/project/containers/ProjectDetails'
import { ProjectHome } from '../modules/project/containers/ProjectHome'
import ProjectList from '../modules/project/containers/ProjectList'
import { IStore } from './AppStore'
import logo from './MyBuild-Logo-white.png'
import * as routes from './routes'
import ErrorBoundary from '../modules/common/components/ErrorBoundary'
import Preview from './../modules/buildingModel/components/Preview/index'

// @ts-ignore
@withRouter
@inject('store')
@observer
export class Main extends React.Component<IStore> {
    public render() {
        if (this.props.store!.user.id === undefined) {
            return <Redirect to={routes.LOGIN} />
        }
        return (
            <div className='main-wrapper'>
                <Header logoAlt={this.props.store!.formatMessage('myBuildLogo')} logo={logo} />
                <main>
                    <Notifications />
                    <Switch>
                        <Route path={routes.ROOT} exact={true} component={ProjectList} />

                        <Route path={routes.PROJECT_ADD} exact={true} component={CreateProject} />
                        <Route path={routes.PROJECT_HOME} exact={true} component={ProjectHome} />
                        <Route path={routes.PROJECT_PAGE} exact={true} component={ProjectDetails} />

                        <Route path={routes.QA} exact={true} component={QualityAssurance} />
                        <Route path={routes.MAPPING_TOOL} exact={true} component={MappingToolContainer} />
                        <Route path={routes.CAMERA_TOOL} exact={true} component={CameraTool} />
                        <Route path={routes.PREVIEW} exact={true} component={Preview} />
                        <Route path={routes.DOT_TOOL} exact={true} render={() => (
                                <ErrorBoundary>
                                    <DotTool />
                                </ErrorBoundary>
                            )}
                        />

                        <Route path={routes.PROFILE} exact={true} component={Profile} />
                    </Switch>
                </main>
                <Footer logoAlt={this.props.store!.formatMessage('myBuildLogo')} logo={logo} />
            </div>
        )
    }
}
