import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Col, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap'
import { IStore } from '../../../../App/AppStore'
import './ProjectListToolbar.css'

@inject('store')
@observer
export default class ProjectListToolbar extends React.Component<IStore> {
    constructor(props: Readonly<IStore>) {
        super(props)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.onSortChange = this.onSortChange.bind(this)
        this.refreshData = this.refreshData.bind(this)
        this.onFilterByAssigneeChange = this.onFilterByAssigneeChange.bind(this)
    }

    public onSearchChange(event: any) {
        this.props.store!.projectStore.criteriaQuery.setSearchQuery(event.target.value)
        this.refreshData()
    }

    public onSortChange(event: any) {
        this.props.store!.projectStore.criteriaQuery.setSortQuery(event.target.value)
        this.refreshData()
    }

    public onFilterByAssigneeChange(event: any) {
        this.props.store!.projectStore.criteriaQuery.setFilterByAssigneeQuery(event.target.value)
        this.refreshData()
    }

    public refreshData = () => {
        this.props.store!.projectStore.refreshData()
    }

    public render() {
        return (
            <Row className={'ProjectListToolbar'}>
                <Col xs={12} sm={6} md={8} lg={9}>
                    <FormGroup>
                        <div className="form-control-wrapper search">
                            <FormControl
                                onChange={this.onSearchChange}
                                value={this.props.store!.projectStore.criteriaQuery.search}
                                name='search'
                                placeholder={this.props.store!.formatMessage('searchProducts')}
                                type={'text'} />
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Addon className='transparent'><span>{this.props.store!.formatMessage('sortBy')}:</span></InputGroup.Addon>
                            <FormControl
                                componentClass='select'
                                className='left-border-none'
                                onChange={this.onSortChange}>
                                {
                                    this.props.store!.projectStore.criteriaQuery.sortOptions.map(option =>
                                        <option key={option.key} value={option.key}>{this.props.store!.formatMessage(option.value)}</option>
                                    )
                                }
                            </FormControl>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}
