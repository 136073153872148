import { Dropdown, Icon, ICONS, styled } from '@paradigm/blueprints-common-frontend'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { v1 as uuidv1 } from 'uuid'
import { Store } from '../../../../App/AppStore'
import { SPINNER_DEFAULT_SIZE } from '../../../common/components/Spinner'
import PageSpinner from '../../../common/components/Spinner/PageSpinner'
import { ModelStore } from '../../../stores/modelStore'
import { PannellumStore } from '../../../stores/PannellumStore'
import { IDotToolData } from './models/IDotToolData'

interface ICameraListProps {
    pannellumStore?: PannellumStore
    modelStore?: ModelStore
    store?: Store
}

@inject('store', 'pannellumStore', 'modelStore')
@observer
export class CameraList extends React.Component<ICameraListProps> {

    public render() {
        const { builderDotToolData, isAddCameraScene, cameraSize, isInterior } = this.props.pannellumStore!

        if (!builderDotToolData) {
            return <PageSpinner size={SPINNER_DEFAULT_SIZE} />
        }

        const cameraKeys = Object.keys(builderDotToolData).filter((key: string) => {
            const dotToolData = builderDotToolData[key]
            return dotToolData !== undefined && dotToolData.cameraName !== null
        })

        return (
            <CameraListContainer>
                {!isInterior &&
                    <div style={{ display: 'block', margin: '10px' }}>
                        <button onClick={this.props.pannellumStore!.placeCameras}>
                            Place Cameras
                        </button>
                    </div>
                }
                <HeaderContainer>
                    <Header>Resolution</Header>
                    <select id='resolutions' onChange={this.onResolutionChange} value={cameraSize}>
                        <option value='2048'>High (2048)</option>
                        <option value='1024'>Medium (1024)</option>
                        <option value='512'>Low (512)</option>
                    </select>
                </HeaderContainer>
                <CameraDropDownContainer>
                    <CameraDropDownLabel>1st Camera:</CameraDropDownLabel>
                    <DropdownContainer className='firstCameraDD'>
                        <Dropdown resetThenSet={this.onFirstCameraDropDownChange.bind(this)} list={this.getCameraList()} />
                    </DropdownContainer>
                </CameraDropDownContainer>
                <HeaderContainer>
                    <Header>Camera List</Header>
                    <AddCameraLink onClick={this.onAddCameraClick.bind(this)}>
                        <Icon icon={ICONS.ADD} size={20} /><div>Add Camera</div>
                    </AddCameraLink>
                </HeaderContainer>
                {Object.keys(builderDotToolData).length === 0 && <p>No Camera saved</p>}
                {cameraKeys.map((key) =>
                    <ListContainer key={`camera_${builderDotToolData[key].cameraName}`}>

                        <ListItem>
                            <CameraName title={`Load ${builderDotToolData[key].cameraName} camera`} onClick={() => this.onLoadCameraClick(builderDotToolData[key])}>{builderDotToolData[key].cameraName}</CameraName>
                            <ButtonContainer>
                                {
                                    (this.props.pannellumStore!.pannellumConfig && builderDotToolData[key].cameraName === this.props.pannellumStore!.pannellumConfig!.default.firstScene) &&
                                        <div title='1st Camera'><FirstCameraIcon size={20} icon={ICONS.CHECKMARK} /></div>
                                }
                                <RemoveIcon title={`Remove ${builderDotToolData[key].cameraName} camera`} onClick={() => this.onRemoveCameraClick(builderDotToolData[key])}><Icon size={20} icon={ICONS.DELETE} /></RemoveIcon>
                            </ButtonContainer>
                        </ListItem>

                        {(this.props.pannellumStore!.currentCamera && this.props.pannellumStore!.currentCamera!.cameraId === builderDotToolData[key].cameraId) &&
                            <Scenes>
                                <ScenesHeader>Scenes</ScenesHeader>
                                {this.getSceneLink()}

                                {isAddCameraScene &&
                                    <AddSceneDropDownContainer>
                                        <AddSceneHeading>Select a scene and click on image.</AddSceneHeading>
                                        <DropdownContainer>
                                            <Dropdown resetThenSet={this.onSceneDropDownChange.bind(this)} list={this.getCameraListForScene(builderDotToolData[key].cameraName)} />
                                        </DropdownContainer>
                                    </AddSceneDropDownContainer>
                                }
                                <SceneList>
                                    {builderDotToolData[key].cameraData && Object.keys(builderDotToolData[key].cameraData).map((scene) =>
                                        <SceneContainer key={`scene_${builderDotToolData[key].cameraData[scene].cameraId}`}>
                                            <Scene>{builderDotToolData[key].cameraData[scene].cameraId}</Scene>
                                            <ButtonContainer>
                                                <RemoveIcon title={`Remove ${builderDotToolData[key].cameraData[scene].cameraId} scene`} onClick={() => this.onRemoveSceneClick(builderDotToolData[key], builderDotToolData[key].cameraData[scene])}><Icon size={16} icon={ICONS.DELETE} /></RemoveIcon>
                                            </ButtonContainer>
                                        </SceneContainer>
                                    )}
                                </SceneList>
                            </Scenes>
                        }
                    </ListContainer>
                )}
            </CameraListContainer>
        )
    }

    @action
    private onLoadCameraClick = (camera: IDotToolData) => {
        this.props.pannellumStore!.currentCamera = camera

        const { viewer } = this.props.pannellumStore!

        if (viewer) {
            viewer.loadScene(camera.cameraName)
        }
    }

    @action
    private onRemoveCameraClick = async (camera: IDotToolData) => {
        const { builderDotToolData, modelId, builderId } = this.props.pannellumStore!

        if (builderDotToolData) {
            Object.keys(builderDotToolData).forEach(key => {
                const sceneData = builderDotToolData[key].cameraData.filter(scene => scene.cameraId !== camera.cameraName)
                builderDotToolData[key].cameraData = sceneData
            })

            delete builderDotToolData[camera.cameraName]

            if (modelId && builderId) {
                await this.saveDotToolData(builderDotToolData)
            }
        }
    }

    @action
    private onAddCameraClick = async () => {
        const { builderDotToolData, modelId, builderId } = this.props.pannellumStore!

        // Create a new camera initial name
        const newCameraName = Object.keys(builderDotToolData).length + 1

        // create a new camera with the previous camera's coords
        const newCamera: IDotToolData = {
            cameraId: uuidv1(),
            cameraName: `CAMERA_${newCameraName}`,
            cameraCoordinates: {
                x: -13.8,
                y: 7.7,
                z: 21.7
            },
            yawAngle: -220,
            pitchAngle: -4,
            dotInfo: [],
            cameraData: [],
            fov: 80,
            minPitch: 0,
            maxPitch: 0,
            minHFov: 80,
            maxHFov: 80
        }

        if (builderDotToolData) {
            builderDotToolData[newCamera.cameraName] = newCamera

            if (modelId && builderId) {
                await this.saveDotToolData(builderDotToolData)
            }
        }
    }

    @action
    private addSceneClick = () => {
        this.props.pannellumStore!.isAddCameraScene = true
        this.props.pannellumStore!.isMove = false
    }

    @action
    private cancelAddSceneClick = () => {
        this.props.pannellumStore!.isAddCameraScene = false
        this.props.pannellumStore!.isMove = true
    }

    private getSceneLink = () => {
        if (!this.props.pannellumStore!.isAddCameraScene) {
            return <AddSceneLink onClick={this.addSceneClick.bind(this)}><Icon icon={ICONS.ADD} size={20} /><div>Add Scene</div></AddSceneLink>
        } else {
            return <AddSceneLink onClick={this.cancelAddSceneClick.bind(this)}><Icon icon={ICONS.CLOSE} size={20} /><div>Cancel</div></AddSceneLink>
        }
    }

    @action
    private onFirstCameraDropDownChange = async (id: string | number, key: string) => {
        this.props.pannellumStore!.pannellumConfig!.default.firstScene = key
        await this.props.pannellumStore!.savePannellumConfig()
        this.props.store!.notifyUser(this.props.store!.formatMessage('succesSavedFirstCamera'), 'success')
    }

    @action
    private onSceneDropDownChange = (id: string | number, key: string) => {
        this.props.pannellumStore!.cameraSceneId = key
    }

    private getCameraListForScene = (currentCamera: string) => {
        const cameras = this.props.pannellumStore!.builderDotToolData
        const sceneList: any = []
        Object.keys(cameras)
            .filter((key) => key !== currentCamera)
            .reduce((obj, key) => {
                obj[key] = cameras[key]

                const option = {
                    id: cameras[key].cameraId,
                    key: cameras[key].cameraName,
                    selected: false,
                    title: cameras[key].cameraName
                }

                sceneList.push(option)
                return obj
            }, {})

        return sceneList
    }

    private getCameraList = () => {
        const cameras = this.props.pannellumStore!.builderDotToolData
        const sceneList: any = []
        Object.keys(cameras)
            .filter((key: string) => {
                const dotToolData = cameras[key]
                return dotToolData !== undefined && dotToolData.cameraName !== null
            })
            .reduce((obj, key) => {
                obj[key] = cameras[key]

                const option = {
                    id: cameras[key].cameraId,
                    key: cameras[key].cameraName,
                    selected: this.props.pannellumStore!.pannellumConfig ? cameras[key].cameraName === this.props.pannellumStore!.pannellumConfig.default.firstScene : false,
                    title: cameras[key].cameraName
                }

                sceneList.push(option)
                return obj
            }, {})

        return sceneList
    }

    @action
    private onRemoveSceneClick = async (camera: IDotToolData, scene: any) => {
        const { builderDotToolData, modelId, builderId } = this.props.pannellumStore!

        if (builderDotToolData) {
            const sceneData = builderDotToolData[camera.cameraName].cameraData.filter((x) => x.cameraId !== scene.cameraId)
            builderDotToolData[camera.cameraName].cameraData = sceneData

            if (modelId && builderId) {
                await this.saveDotToolData(builderDotToolData)
            }
        }
    }

    private async saveDotToolData(builderDotToolData: {[key: string]: IDotToolData}) {
        await this.props.pannellumStore!.saveDotToolData(builderDotToolData)
    }

    private onResolutionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.pannellumStore!.setCameraSize(+event.target.value)
        this.props.pannellumStore!.applyCameraUpdates()
    }
}

const CameraListContainer = styled.div`
    min-width: 300px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-right: 10px;
`

const Header = styled.div`
    flex: 2 auto;
    font-size: 16px;
    margin: 5px 10px;
    font-weight: 500;
`

const ListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 10px;
    background-color: #F7F7F7;
    margin: 5px 10px;
`

const CameraName = styled.div`
    font-size: 14px;
    color: grey;
    flex: 2 auto;
    align-self: center;
    cursor: pointer;
`

const ListItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;

    &:hover {
        ${CameraName} {
            color: #353535;
            font-weight: 500;
        }
    }
`

const ButtonContainer = styled.div`
    display: flex;

    button {
        padding: .5rem 2rem;
        font-size: 14px;
    }
`

const RemoveIcon = styled.div`
    cursor: pointer;
    svg {
        fill: ${(props) => props.theme.colors.error} !important;
    }
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const AddCameraLink = styled.div`
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    align-self: center;
    cursor: pointer;

    div {
        font-size: 14px;
        padding-left: 5px;
    }
    svg {
        fill: ${(props) => props.theme.colors.primary};
    }
`

const Scenes = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid lightgrey;
    padding-left: 5px;

    svg {
        fill: ${(props) => props.theme.colors.primary};
    }
`

const ScenesHeader = styled.div`
    font-size: 12px;
    color: grey;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const AddSceneLink = styled.div`
     color: ${(props) => props.theme.colors.primary};
     display: flex;
     align-self: flex-end;
     cursor: pointer;
`

const AddSceneDropDownContainer = styled.div`
    font-size: 12px;
    color: grey;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
`

const AddSceneHeading = styled.div`
    font-size: 12px;
    color: #9c9c9c;
`

const DropdownContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px;
    border: 1px solid ${(props) => props.theme.colors.neutrals[3]};
    background-color: ${(props) => props.theme.colors.common[0]};
    font-family: ${(props) => props.theme.font.family};
    font-size: 12px;
    color: ${(props) => props.theme.colors.neutrals[5]};
    padding-left: 1px;

    div {
        height: 100%;
    }

    input {
        font-size: 14px;
        height: 25px;
        padding-left: 5px;
    }

    ul {
        position: absolute;
        margin-top: 25px;
        width: 100%;
        padding: 0px;
        border: 1px solid ${(props) => props.theme.colors.neutrals[3]};

        > li {
            width: 100%;
            font-size: 12px;
            padding: 5px;
            vertical-align: middle;

            &:hover {
                background-color: ${(props) => props.theme.colors.primary};
                color: ${(props) => props.theme.colors.common[0]};
            }
        }
    }
`

const SceneList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 10px;
`

const Scene = styled.div`
    font-size: 10px;
    flex: 2 auto;
`

const SceneContainer = styled.div`
    display: flex;
    align-items: center;
`
const CameraDropDownContainer = styled.div`
    height: 38px;
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    margin-top: 10px;
    align-items: center;
    margin-bottom: 20px;

    input {
        font-size: 12px;
        margin-left: 5px;
    }

    .firstCameraDD {
        width: auto;
    }
`

const CameraDropDownLabel = styled.div`
    font-size: 16px;
    padding-left: 10px;
    font-weight: 500;
`

const FirstCameraIcon = styled(Icon)`
    padding-right: 5px;
`
