import { IMetadata, IMetadataProperty } from '../interfaces'

export const METADATA_PROPERTY_SET_MYBUILD: string = 'MyBuild'
export const METADATA_PROPERTY_SET_ELEMENT_SPECIFIC: string = 'ElementSpecific'
export const METADATA_PROPERTY_SET_GEOMETRY: string = 'Geometry'

export const METADATA_PROPERTY_GEOMETRY_CHECKSUM: string = 'GeometryChecksum'
export const METADATA_PROPERTY_GEOMETRY_MATRIX: string = 'GeometryMatrix'
export const METADATA_PROPERTY_ELEVATION: string = 'Elevation'
export const METADATA_PROPERTY_LONGNAME: string = 'LongName'
export const METADATA_PROPERTY_STOREY_AREA: string = 'StoreyArea'
export const METADATA_PROPERTY_MY_BUILD_STOREY_NAME: string = 'MyBuildStoreyName'

export function getMetadataProperty(metadata: IMetadata | undefined, propertySetName: string, propertyName: string): IMetadataProperty | undefined {
    let result: IMetadataProperty | undefined

    if (metadata && metadata.propertySets[propertySetName]) {
        result = metadata.propertySets[propertySetName][propertyName]
    }

    return result
}
