import { ThemeProvider } from '@paradigm/blueprints-common-frontend'
import { inject, observer } from 'mobx-react'
import DevTools from 'mobx-react-devtools'
import * as React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import Login from '../modules/auth/containers/Login'
import defaultTheme from '../modules/common/theme/takeoff'
import './App.css'
import { IStore } from './AppStore'
import { Main } from './Main'
import * as routes from './routes'

// @ts-ignore
@withRouter
@inject('store')
@observer
export default class App extends React.Component<IStore> {
    public render() {
        return (
            <ThemeProvider theme={defaultTheme}>
                <div>
                    {process.env.NODE_ENV === 'development' && <DevTools />}
                    <Switch>
                        <Route path={routes.LOGIN} component={Login} />
                        <Route path={routes.ROOT} component={Main} />
                    </Switch>
                </div>
            </ThemeProvider>
        )
    }
}
