import CardPanel from './CardPanel'
import Panel from './Panel'
import PanelActions from './PanelActions'
import PanelContent from './PanelContent'
import PanelHeader from './PanelHeader'
import PanelTitle from './PanelTitle'

export { PanelContent, PanelHeader, PanelActions, PanelTitle, CardPanel }

export default Panel
