import { Animation, ArcRotateCamera, PointerInfo, Scene } from "babylonjs"
import { IOpeningMeasurementLabel } from "../interfaces"
import { PointerObserverManager } from "./PointerObserverManager"

export class OpeningLabelManager {
    private readonly LABEL_VISIBILITY_BRIGHT: number = 1
    private readonly LABEL_MESH_VISIBILITY_DIM: number = 0.4
    private readonly LABEL_PLANE_VISIBILITY_DIM: number = 0.7
    private readonly LABEL_VISIBILITY_DIMING_POINT: number = 45
    private readonly LABEL_ANIMATION_START_FRAME: number = 0
    private readonly LABEL_ANIMATION_END_FRAME: number = 10
    private readonly LABEL_ANIMATION_PROPERTY_VISIBILITY: string = 'visibility'
    private readonly LABEL_ANIMATION_NAME: string = 'visibilityAnimation'
    private readonly LABEL_ANIMATION_FRAME_PER_SECONDE: number = 30

    private pointerObserverManager: PointerObserverManager
    private scene: Scene
    private camera: ArcRotateCamera
    private labelList: IOpeningMeasurementLabel[] = []
    private labelsDimmed: boolean = false

    public constructor(scene: Scene, camera: ArcRotateCamera) {
        this.scene = scene
        this.camera = camera
        this.init()
    }

    public addLabelVisibilityAnimation(label: IOpeningMeasurementLabel) {
        label.mesh.animations.push(this.createVisibilityAnimation(this.LABEL_VISIBILITY_BRIGHT, this.LABEL_MESH_VISIBILITY_DIM))
        label.plane.animations.push(this.createVisibilityAnimation(this.LABEL_VISIBILITY_BRIGHT, this.LABEL_PLANE_VISIBILITY_DIM))
        this.labelList.push(label)
    }

    private init() {
        this.pointerObserverManager = new PointerObserverManager(this.scene)
        this.pointerObserverManager.registerToMouseWheelObserver(this.iconsZoomVisibility.bind(this))
    }

    private createVisibilityAnimation(visibilityBright: number, visibilityDim: number): Animation {
        const keys = []
        const animation: Animation = new Animation(this.LABEL_ANIMATION_NAME, this.LABEL_ANIMATION_PROPERTY_VISIBILITY,
            this.LABEL_ANIMATION_FRAME_PER_SECONDE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE
        )

        keys.push({ frame: this.LABEL_ANIMATION_START_FRAME, value: visibilityBright })
        keys.push({ frame: this.LABEL_ANIMATION_END_FRAME, value: visibilityDim })
        animation.setKeys(keys)
        return animation
    }

    private iconsZoomVisibility(pointerInfo: PointerInfo) {
        const delta: number = this.pointerObserverManager.calculateMouseWheelDelta(pointerInfo, this.camera)
        const inertiaOffset = 1 - this.camera.inertia
        const projectedCameraRadius = this.camera.radius - (this.camera.inertialRadiusOffset + delta) / inertiaOffset

        if (this.labelsDimmed && projectedCameraRadius > this.LABEL_VISIBILITY_DIMING_POINT && delta < 0) {
            this.labelsDimmed = false
            this.animateLabelVisibility(true)
        }
        else if (!this.labelsDimmed && projectedCameraRadius < this.LABEL_VISIBILITY_DIMING_POINT && delta > 0) {
            this.labelsDimmed = true
            this.animateLabelVisibility(false)
        }
    }

    private animateLabelVisibility(isReverseAnimation: boolean) {
        const startFrame = isReverseAnimation ? this.LABEL_ANIMATION_END_FRAME : this.LABEL_ANIMATION_START_FRAME
        const endFrame = isReverseAnimation ? this.LABEL_ANIMATION_START_FRAME : this.LABEL_ANIMATION_END_FRAME

        this.labelList.forEach((label) => {
            this.scene.beginAnimation(label.mesh, startFrame, endFrame)
            this.scene.beginAnimation(label.plane, startFrame, endFrame)
        })
    }
}
