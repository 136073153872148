import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { IStore } from '../../../../App/AppStore'
import { PROFILE, LOGIN } from '../../../../App/routes'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import ReactDOM from 'react-dom'

const HeaderContainer = styled.div`
	background-color: ${(props) => props.theme.header.background};
	height: 60px;
	display: flex;
	flex-direction: row;
	padding: 20px;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 3;
`

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	width: 250px;

	img {
		height: 25px;
	}
`

const MenuItemsContainer = styled.div`
	width: 250px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	color: ${(props) => props.theme.header.color};
`

const MenuItemContainer =styled.div<{ active: boolean }>`
    font-family: ${(props) => props.theme.font.family};
    font-size: ${(props) => props.theme.header.fontSize};
    color: ${(props) => props.theme.header.color};
    font-weight: ${(props) => (props.active ? 600 : 500)};
    letter-spacing: 1px;
    text-align: center;
    cursor: pointer;

    a {
        transition: color 0.5s ease;
        color: ${(props) => props.theme.header.color};
        text-decoration: none;

        &:hover {
            color: ${(props) => props.theme.colors.neutrals[3]};
        }
    }
`

const HeaderLabelContainer = styled.div`
	color: ${(props) => props.theme.header.color};
	flex: 2 auto;
	text-align: center;
	font-family: ${(props) => props.theme.font.family};
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
`

const AccountMenuContainer = styled.div<{ show: boolean }>`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 120px;
    margin: 2px 0 0 -10px;
    font-size: 12px;
    text-align: left;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    background-color: ${(props) => props.theme.colors.common[0]};
    margin-top: 12px;
    display:  ${(props) => (props.show ? 'block' : 'none')};
`

const AccountMenuItem = styled.div`
	color: ${(props) => props.theme.colors.secondary};
	padding: 10px;
	cursor: pointer;

    a {
        color: ${(props) => props.theme.colors.secondary};
	    cursor: pointer;
    }

	:hover {
		background-color: ${(props) => props.theme.colors.neutrals[1]};
		color: ${(props) => props.theme.colors.secondary};

		a {
			color: ${(props) => props.theme.colors.secondary};
		}
	}
`

const AccountLabel = styled.div`
	transition: color ${(props) => props.theme.transitions.duration.standard} ${(props) => props.theme.transitions.easing.easeInOut};

	&:hover {
		color: ${(props) => props.theme.colors.neutrals[3]};
	}
`

const AccountDropDownContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
`

const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid ${(props) => props.theme.colors.common[0]};
    margin-top: -5px;
    margin-left: 20px;
`

interface IHeaderProps extends IStore {
	logoAlt: string
	logo?: string
	history?: any
}

interface IHeaderState {
	accountMenuOpen: boolean
}

// @ts-ignore
@withRouter
@inject('store')
@observer
class Header extends React.Component<IHeaderProps, IHeaderState> {
	node: HTMLDivElement | null
	constructor(props: Readonly<IHeaderProps>) {
		super(props)

		this.state = {
			accountMenuOpen: false
		}

		this.handleLogout = this.handleLogout.bind(this)
		this.handleOpenAccountMenu = this.handleOpenAccountMenu.bind(this)
	}

	componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false)
	}

	componentWillUnMount() {
		document.removeEventListener('mousedown', this.handleClick, false)
	}

	public handleClick = (e: Event) => {
		if (!this.state.accountMenuOpen) {
			return
		}

		const element = e.target
		const domNode = ReactDOM.findDOMNode(this.node)
		if (this.state.accountMenuOpen && !(domNode && element instanceof Node && domNode.contains(element))) {
			this.setState({ accountMenuOpen: false })
		}
	}

	public handleLogout(event: any) {
		this.props.store!.login.logout()
        const { history }: any = this.props
		history.push(LOGIN)
	}

	public handleOpenAccountMenu(event: any) {
		this.setState({ accountMenuOpen: !this.state.accountMenuOpen })
	}

	public render() {
		const location = this.props.history.location.pathname
		if (!this.props.store!.login.loggedIn) {
			return ''
		}

		return (
			<HeaderContainer>
				<LogoContainer>
					<Link to='/'>
						<img alt={this.props.logoAlt} src={this.props.logo} />
					</Link>
				</LogoContainer>
				<HeaderLabelContainer>{this.props.store!.projectStore.currentProject.name}</HeaderLabelContainer>
				<MenuItemsContainer>
					<MenuItemContainer active={location === '/'}>
						<Link to='/'>
							<FormattedMessage id={'projects'} />
						</Link>
					</MenuItemContainer>
					<MenuItemContainer active={location === '/profile' || this.state.accountMenuOpen}>
						<AccountDropDownContainer ref={(node) => (this.node = node)}>
							<AccountLabel onClick={this.handleOpenAccountMenu}>
								<FormattedMessage id={'account'} />
							</AccountLabel>
							<AccountMenuContainer show={this.state.accountMenuOpen}>
                                <ArrowUp />
								<AccountMenuItem>
									<Link to={PROFILE}>
										<FormattedMessage id={'profile'} />
									</Link>
								</AccountMenuItem>
								<AccountMenuItem>{this.props.store!.formatMessage('settings')}</AccountMenuItem>
								<AccountMenuItem onClick={this.handleLogout}>
									{this.props.store!.formatMessage('logout')}
								</AccountMenuItem>
							</AccountMenuContainer>
						</AccountDropDownContainer>
					</MenuItemContainer>
				</MenuItemsContainer>
			</HeaderContainer>
		)
	}
}

export default Header
