import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Button, Form, FormControl, FormGroup, Table } from 'react-bootstrap'
import { IStore } from '../../../App/AppStore'
import { IQualityAssuranceDetailMessagesState } from './interfaces'
import { MessageTableRow } from './MessageTableRow'

@inject('store')
@observer
export class QualityAssuranceDetailMessages extends React.Component<IStore, IQualityAssuranceDetailMessagesState> {
    constructor(props: Readonly<IStore>) {
        super(props)

        this.onSend = this.onSend.bind(this)
        this.onChange = this.onChange.bind(this)

        this.state = {
            value: ''
        }
    }

    public onChange(event: any) {
        this.setState({ value: event.target.value })
    }

    public onSend() {
        this.props.store!.projectStore.addQADetailMessage(this.state.value)
        this.setState({ value: '' })
    }

    public render() {
        let messages: any[] = []
        if (this.props.store!.projectStore.currentQADetail.messages) {
            messages = this.props.store!.projectStore.currentQADetail.messages.map((msg, index) => {
                return <MessageTableRow key={index} message={msg} />
            })
        }

        return (
            <div>
                <div className='table-wrapper-2'>
                    <Table responsive={true} striped={true} style={{ backgroundColor: 'white', marginBottom: 0 }}>
                        <thead>
                            <tr>
                                <th>{this.props.store!.formatMessage('from')}</th>
                                <th>{this.props.store!.formatMessage('message')}</th>
                                <th>{this.props.store!.formatMessage('date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {messages}
                        </tbody>
                    </Table>
                </div>
                <Form inline={true}>
                    <FormGroup style={{ width: '80%' }} controlId='formInlineName'>
                        <FormControl style={{ width: '100%' }} type='text' value={this.state.value} onChange={this.onChange} />
                    </FormGroup>{' '}
                    <Button style={{ width: '19%' }} type='submit' onClick={this.onSend}>{this.props.store!.formatMessage('send')}</Button>
                </Form>
            </div>
        )
    }
}
