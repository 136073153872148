import { IGeometry, IModel } from '../interfaces'
import * as MatrixUtils from './MatrixUtils'

/**
 * This function takes data containing geometries and metadatas, gets the geometry matrix for each metadata and applies the matrix to the correct geometries
 *
 * @param data the geometry being transformed
 * @returns a map with the metadata id as the key and the transformed geometries as the value
 */
export function transformDataGeometries(data: any, modelNodes: IModel[]): Map<string, IGeometry> {
    const returnGeometryMap: Map<string, IGeometry> = new Map()
    if (data != null && modelNodes !== undefined) {
        const geometries = data.geometries
        if (geometries) {
            modelNodes.forEach((model) => {
                if (model.geometryChecksum !== null) {
                    const geometry = JSON.parse(JSON.stringify(geometries[model.geometryChecksum]))
                    const geometryMatrix = model.geometryMatrix
                    if (geometryMatrix !== null && geometry !== undefined && geometry.vertices.length > 0) {
                        geometry.vertices = transformGeometryVertices(geometry, geometryMatrix)
                        returnGeometryMap.set(model.guid, geometry)
                    }
                }
            })
        }
    }
    return returnGeometryMap
}


/**
 * This function takes a geometry and geometry matrix to calculate new vertices.
 *
 * @param geometry the geometry being transformed
 * @param geometryMatrix The geometry matrix being applied to the given geometry
 */
export function transformGeometryVertices(geometry: IGeometry, geometryMatrix: number[]): number[] {
    const geoMatrix = MatrixUtils.convertGeometryMatrixListToMatrix(MatrixUtils.flipListValues(geometryMatrix, MatrixUtils.Z_AXIS_ROW_INDEX, MatrixUtils.NUMBER_OF_LINE))
    const newVertices = new Array<number>(geometry.vertices.length)

    for (let x = 0; x < geometry.vertices.length; x += 3) {
        let verticesMatrix = MatrixUtils.convertPointToMatrix4By1(geometry.vertices[x], geometry.vertices[x + 1], geometry.vertices[x + 2])
        verticesMatrix = MatrixUtils.multiplyMatrix(geoMatrix, verticesMatrix)
        newVertices[x] = verticesMatrix[0][0]
        newVertices[x + 1] = verticesMatrix[1][0]
        newVertices[x + 2] = verticesMatrix[2][0]
    }

    return newVertices
}
