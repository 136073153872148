import { GEOMETRY_UVS, IGeometry, IGeometry2D, IGeometryDTO, IKeyValueObject, IMetadataProperty, IModel, JsonUtils, MetadataUtils, ModelDataUtils, ModelType, ModelUtils } from '@paradigm/blueprints-common-frontend'
import { action, observable, runInAction } from 'mobx'
import * as buildingModelApi from '../api/buildingModelApi'
import { IMappingData } from '../buildingModel/interfaces'
import { IBuilderAssetDotToolData } from '../project/containers/DotTool/models/IBuilderAssetDotToolData'
import { IDotToolData } from '../project/containers/DotTool/models/IDotToolData'
import { IAssetBundleDotToolData } from '../project/containers/DotTool/models/IAssetBundleDotToolData'
import { IAssetBundlePannellumConfig } from '../project/containers/DotTool/models/IAssetBundlePannellumConfig'

interface IModelDTO {
    structure: IModel
    mappings: { [key: string]: IMappingData }
}

interface IStorey {
    guid: string
    children: IModel[]
}

export class ModelStore {
    public static ELEVATIONS: string[] = ['frontElevation', 'rightElevation', 'backElevation', 'leftElevation']
    public static ITEMS_TO_EXCLUDE_REGEXP: RegExp = /TREES?/i
    @observable public isLoadingModel: boolean = false
    @observable public isLoadingGeometries: boolean = false
    @observable public isSavingMappingData: boolean = false
    @observable public isSavingDottingData: boolean = false
    @observable public isSavingPannellumConfig: boolean = false
    @observable public errors: string = ''
    public model: IModel
    public optionModel: IModel
    public geometries: Map<string, IGeometry> = new Map()
    public optionGeometries: Map<string, IGeometry> = new Map()
    public geometries2D: Map<string, IGeometry2D> = new Map()
    public storeys: IModel[] = []
    public mappings: IKeyValueObject = {}
    public dottings: IKeyValueObject[] = []

    @action
    public async loadModel(modelId: string): Promise<void> {
        this.isLoadingModel = true
        this.errors = ''
        try {
            const response = await buildingModelApi.model.fetchModel(modelId)
            const { structure, mappings } = response.data as IModelDTO
            runInAction(() => {
                this.model = structure
                this.mappings = mappings
            })
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isLoadingModel = false })
        }
    }

    @action
    public async loadGeometries(modelId: string, modelTypesToLoad: ModelType[], optionGeometries: boolean = false): Promise<void> {
        this.isLoadingGeometries = true
        this.optionGeometries = new Map()

        if (!optionGeometries) {
            this.geometries = new Map()
        }

        const children = optionGeometries ? this.optionModel.children : this.model.children
        const transformedStoreys = ModelUtils.findElementsByType(ModelType.STOREY, children)
            .map((item) => ({ guid: item.guid, children: item.children }))
        const modelNodes: IModel[] = this.findModelNodesInStoreys(transformedStoreys, modelTypesToLoad, ModelStore.ITEMS_TO_EXCLUDE_REGEXP)

        const geometriesDTOs: IGeometryDTO[] = []
        modelNodes.forEach((node) => {
            if (node.geometryChecksum !== null) {
                geometriesDTOs.push({ nodeID: node.guid, checksum: node.geometryChecksum })
            }
        })

        try {
            const result = await buildingModelApi.model.fetchGeometries(modelId, geometriesDTOs, false)
            const geometriesData = result.data
            const newGeometryMap = ModelDataUtils.transformDataGeometries(geometriesData, modelNodes)
            this.addGeometriesUvs(modelNodes, newGeometryMap)

            if (optionGeometries) {
                runInAction(() => { this.optionGeometries = newGeometryMap })
            } else {
                runInAction(() => { this.geometries = newGeometryMap })
            }

        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isLoadingGeometries = false })
        }
    }

    @action
    public async loadGeometries2D(modelId: string, modelTypesToLoad: ModelType[]): Promise<void> {
        this.isLoadingGeometries = true
        this.geometries2D = new Map()
        const storeys = ModelUtils.findElementsByType(ModelType.STOREY, this.model.children)
        storeys.forEach(async (storey) => {
            const metadataResponse = await buildingModelApi.model.fetchMetadata(modelId, storey.guid)
            const property = MetadataUtils.getMetadataProperty(
                metadataResponse.data,
                MetadataUtils.METADATA_PROPERTY_SET_MYBUILD,
                MetadataUtils.METADATA_PROPERTY_MY_BUILD_STOREY_NAME
            )
            if (property !== undefined) {
                storey.name = property.value
            }
        })
        runInAction(() => this.storeys = storeys)
        const transformedStoreys = storeys.map((item) => ({ guid: item.guid, children: item.children }))
        const modelNodes: IModel[] = this.findModelNodesInStoreys(transformedStoreys, modelTypesToLoad, ModelStore.ITEMS_TO_EXCLUDE_REGEXP)

        try {
            const result = await buildingModelApi.model.fetch2DDataForElements(modelId, modelNodes.map((item) => item.guid))

            const { metadata, geometries } = result.data

            const geometries2D: Map<string, IGeometry2D> = new Map()
            for (const metadataId in metadata) {
                if (metadata[metadataId] !== undefined && geometries[metadataId] !== undefined) {
                    geometries2D.set(metadataId, geometries[metadataId] as IGeometry2D)
                }
            }
            runInAction(() => { this.geometries2D = geometries2D })
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isLoadingGeometries = false })
        }
    }

    @action
    public async saveMappingData(modelId: string, mappingData: IMappingData): Promise<boolean> {
        this.isSavingMappingData = true
        let saved = false
        try {
            runInAction(() => { this.mappings[mappingData.tag] = mappingData })
            await buildingModelApi.model.updateMappingData(modelId, this.mappings)
            saved = true
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isSavingMappingData = false })
        }
        return saved
    }

    public findModelNodesInStoreys(storeys: IStorey[], modelTypesToLoad: ModelType[], itemsToExculdeRegExp?: RegExp, processItemCallback?: (item: IModel) => void): IModel[] {
        const modelNodes: IModel[] = []
        storeys.forEach((storey) => {
            modelTypesToLoad.forEach((type) => {
                const nodes: IModel[] = ModelUtils.findElementsByType(type, storey.children)
                    .filter((item) => {
                        if (item.name !== null) {
                            if (itemsToExculdeRegExp && item.name.match(itemsToExculdeRegExp) !== null) {
                                return false
                            }
                            if (processItemCallback !== undefined) {
                                processItemCallback(item)
                            }
                        }
                        return true
                    })
                modelNodes.push(...nodes)
            })
        })
        return modelNodes
    }

    public addGeometriesUvs(modelNodes: IModel[], geometryMap: Map<string, IGeometry>) {
        modelNodes.forEach((node) => {
            const geometry = geometryMap.get(node.guid)
            if (geometry && node.properties) {
                const properties: Map<string, IMetadataProperty> = JsonUtils.buildMap(node.properties)
                if (properties.has(GEOMETRY_UVS)) {
                    geometry.uvs = properties.get(GEOMETRY_UVS)!.value
                }
            }
        })
    }

    @action
    public async saveDotToolData(modelId: string, builderId: string, dottingData: any): Promise<any> {
        this.isSavingDottingData = true
        const cameras = [{builderId, data: dottingData }]
        try {
            const response = await buildingModelApi.model.updateDotToolData(modelId, cameras)
            return JSON.parse(response.data.dotToolData)
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isSavingDottingData = false })
        }
        return null
    }

    @action
    public async fetchDotToolData(modelId: string): Promise<any> {
        try {
            const result = await buildingModelApi.model.fetchDotToolData(modelId)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    @action
    public async savePannellumConfig(modelId: string, builderId: string, pannellumConfig: any): Promise<any> {
        this.isSavingPannellumConfig = true
        const builderPannellumConfigs = [{builderId, config: pannellumConfig }]

        try {
            await buildingModelApi.model.savePannellumConfig(modelId, builderPannellumConfigs)
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isSavingPannellumConfig = false })
        }
    }

    @action
    public async saveIntDotToolData(modelId: string, builderId: string, dottingData: any): Promise<any> {
        this.isSavingDottingData = true
        const cameras = [{builderId, data: dottingData }]
        try {
            const response = await buildingModelApi.model.updateIntDotToolData(modelId, cameras)
            return JSON.parse(response.data.intDotToolData)
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isSavingDottingData = false })
        }
        return null
    }

    @action
    public async fetchIntDotToolData(modelId: string): Promise<any> {
        try {
            const result = await buildingModelApi.model.fetchIntDotToolData(modelId)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    @action
    public async saveIntPannellumConfig(modelId: string, builderId: string, pannellumConfig: any): Promise<any> {
        this.isSavingPannellumConfig = true
        const builderPannellumConfigs = [{builderId, config: pannellumConfig }]

        try {
            await buildingModelApi.model.saveIntPannellumConfig(modelId, builderPannellumConfigs)
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isSavingPannellumConfig = false })
        }
    }

    @action
    public async saveAssetBundleDotToolData(modelId: string, builderId: string, assetDolToolData: IKeyValueObject<IAssetBundleDotToolData>): Promise<any> {
        this.isSavingDottingData = true

        const cameras: IBuilderAssetDotToolData[] = [{
            builderId, 
            assetDolToolData
        }]
        try {
            const response = await buildingModelApi.model.updateBuilderAssetDotToolData(modelId, cameras)
            return JSON.parse(response.data.assetBundleDotToolData)
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isSavingDottingData = false })
        }
        return null
    }

    @action
    public async fetchAssetBundleDotToolData(modelId: string): Promise<any> {
        try {
            const result = await buildingModelApi.model.fetchBuilderAssetDotToolData(modelId)
            return result
        } catch (error) {
            console.error(error)
        }
    }

    @action
    public async saveAssetBundlePannellumConfig(modelId: string, builderId: string, assetBundleConfigs: IKeyValueObject<IAssetBundlePannellumConfig>): Promise<any> {
        this.isSavingPannellumConfig = true

        const builderPannellumConfigs = [{
            builderId, 
            assetBundleConfigs
        }]

        try {
            await buildingModelApi.model.saveBuilderAssetPannellumConfig(modelId, builderPannellumConfigs)
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isSavingPannellumConfig = false })
        }
    }

    public async fetchModelStructure(modelId: string): Promise<IModel | null> {
        this.isLoadingModel = true
        this.errors = ''
        
        try {
            const response = await buildingModelApi.model.fetchModelStructure(modelId)
            const { structure } = response.data as IModelDTO
            return structure
        } catch (error) {
            console.error(error)
        } finally {
            runInAction(() => { this.isLoadingModel = false })
        }

        return null
    }

    @action
    public async loadOptionModelStructure(modelId: string) {
        const result = await this.fetchModelStructure(modelId)

        if (result) {
            this.optionModel = result
        }
    }

    public mergeBaseAndOptionGeometries(): Map<string, IGeometry> {
        const result = new Map<string, IGeometry>()
        this.geometries.forEach((value, key) => {
            result.set(key, value)
        })
        this.optionGeometries.forEach((value, key) => {
            result.set(key, value)
        })
        return result
    }
}

export default new ModelStore()
