import { styled } from '@paradigm/blueprints-common-frontend'

const PanelTitle = styled.h4<{ primary?: boolean }>`
    font-family: ${props => props.theme.font.family};
    font-size: 20px;
    font-weight: 500;
    color: ${props => (props.primary ? props.theme.colors.primary : props.theme.colors.secondary)};
`

export default PanelTitle
