import 'reflect-metadata'

export const formFieldMetadataKey = Symbol('field')

export interface IFormField {
    type: string
    rule: string
    name?: string
    value?: any
    label?: string
    placeholder?: string
    error?: string
    className?: string
}

export function field(formField: IFormField) {
    return Reflect.metadata(formFieldMetadataKey, formField)
}

export function getField(target: any, propertyKey: string) {
    const formField = Reflect.getMetadata(formFieldMetadataKey, target, propertyKey)
    if (formField !== undefined) {
        formField.name = propertyKey
        formField.value = target[propertyKey]
    }
    return formField
}
