import '@fortawesome/fontawesome-pro/css/all.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import { Fragment } from 'react'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './app/App/App'
import { Store } from './app/App/AppStore'
import { message } from './app/App/locale/message'
import { ScrollToTop } from './app/modules/common/components/ScrollToTop'
import modelStore from './app/modules/stores/modelStore'
import './index.css'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import { pannellumStore } from './app/modules/stores/PannellumStore'
import { errorStore } from './app/modules/stores/errorStore'

library.add(fal, far, fas)

configure({
    enforceActions: 'observed'
})

const appStore = new Store()
const language = appStore.locale

const stores = {
    store: appStore,
    modelStore,
    pannellumStore,
    errorStore
}

ReactDOM.render(
    <IntlProvider locale={language} textComponent={Fragment} messages={message[language]}>
        <Provider {...stores}>
            <Router basename={'/'}>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </Router>
        </Provider>
    </IntlProvider>,
    document.getElementById('root') as HTMLElement
)
unregisterServiceWorker()
