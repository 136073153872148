import { computed, observable } from 'mobx'

export enum UserRole {
    ADMIN = 'ADMIN',
    USER = 'USER',
    ARCHITECT = 'ARCHITECT',
    TEAM_LEADER = 'TEAM_LEADER',
    SUPER_USER = 'SUPER_USER'
}

export class User {
    public id: string
    public company_id: string
    @observable public username: string = ''
    @observable public password: string = ''
    @observable public role: string = ''

    @computed public get isQA() {
        return this.role === UserRole.ADMIN || this.role === UserRole.TEAM_LEADER || this.role === UserRole.ARCHITECT || this.role === UserRole.SUPER_USER
    }

    @computed public get email() {
        return this.username
    }

    @computed public get fullName() {
        const splitedEmail = this.email.split('@')[0].split('.')

        splitedEmail.map((name, index) => {
            splitedEmail[index] = name.charAt(0).toUpperCase() + name.substring(1)
        })

        return splitedEmail.join(' ')
    }
}
