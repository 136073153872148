import { Store } from "../../../App/AppStore"


export class BaseModel {
    protected store: Store

    constructor(store: Store) {
        this.store = store
    }
}
