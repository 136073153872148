import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ICameraData, ILockCameraRendererData, styled } from '@paradigm/blueprints-common-frontend'
import { action, observable, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as postal from 'postal'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IStore } from '../../../../App/AppStore'
import CameraToolViewer from '../../../buildingModel/components/CameraToolViewer/CameraToolViewer'
import Container from '../../../common/components/Layout/Container'
import Panel, { PanelActions, PanelContent, PanelHeader, PanelTitle } from '../../../common/components/Panel'
import { SPINNER_DEFAULT_SIZE } from '../../../common/components/Spinner'
import PageSpinner from '../../../common/components/Spinner/PageSpinner'
import EmptyMessage from '../../../common/components/Typography/EmptyMessage'
import ProjectHeader from '../../components/ProjectHeader'
import { RevisionInput } from '../../models/RevisionInput'

const EmptyMessageWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`

@inject('store')
@observer
class CameraTool extends React.Component<IStore & RouteComponentProps> {
    @observable private cameraData: ICameraData
    @observable private isLoading: boolean = true
    @observable private buildingModelId: string
    @observable private alternativeName: string
    @observable private revisionId: string
    @observable private lockCameraData: ILockCameraRendererData | undefined
    private subscriptions: Array<ISubscriptionDefinition<any>> = []

    @action
    public async componentDidMount() {
        this.isLoading = true

        const { match }: any = this.props
        const fetchModel = false
        const project = await this.props.store!.projectStore.getProject(match.params.projectId, fetchModel)

        if (project !== null) {
            if (project.alternatives) {
                const alternative = project.alternatives.find((a) => a.id === match.params.alternativeId)
                if (alternative && alternative.revisions) {
                    runInAction(() => {
                        this.alternativeName = alternative.name
                    })
                    const revision = alternative.revisions.find((r) => r.id === match.params.revisionId)
                    if (revision) {
                        runInAction(() => {
                            this.revisionId = revision.id
                            this.buildingModelId = revision.buildingModelId
                        })
                        if (revision.camerasData) {
                            runInAction(() => {
                                const cameraData = JSON.parse(revision.camerasData)[0]
                                this.lockCameraData = {
                                    cameraData,
                                    lock: false
                                }
                                this.cameraData = cameraData
                            })
                        }
                    }
                }
            }
        }

        runInAction(() => {
            this.isLoading = false
        })
    }

    public componentWillUnmount() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    public render() {
        if (this.isLoading) {
            return <PageSpinner size={SPINNER_DEFAULT_SIZE} />
        }

        if (!this.revisionId || !this.buildingModelId) {
            return (
                <EmptyMessageWrapper>
                    <EmptyMessage><FormattedMessage id={'modelNotLoaded'} /></EmptyMessage>
                </EmptyMessageWrapper>
            )
        }

        const modelCameraDataSubscription = postal.subscribe({
            channel: 'viewer',
            topic: 'camera.info.updated',
            callback: this.updateCameraData
        })
        this.subscriptions.push(modelCameraDataSubscription)

        return (
            <React.Fragment>
                <ProjectHeader />
                <Container>
                    <Panel>
                        <PanelHeader>
                            <PanelTitle>
                                {this.alternativeName} <FormattedMessage id={'cameraTool'} />
                            </PanelTitle>
                        </PanelHeader>
                        <PanelActions>
                            <Button onClick={this.saveCameraDataHandler.bind(this, this.revisionId)}>
                                <FontAwesomeIcon icon={['far', 'save']} /> <FormattedMessage id={'save'} />
                            </Button>
                        </PanelActions>
                        <PanelContent height={'100%'}>
                            {this.buildingModelId &&
                                <CameraToolViewer modelId={this.buildingModelId} lockCameraData={this.lockCameraData!} />
                            }
                        </PanelContent>
                    </Panel>
                </Container>
            </React.Fragment>
        )
    }

    @action
    private updateCameraData = (data: ICameraData) => {
        this.cameraData = data
    }

    private saveCameraDataHandler = async (revisionId: string) => {
        if (revisionId && this.cameraData) {
            const revisionInput = new RevisionInput()
            revisionInput.camerasData = JSON.stringify([this.cameraData])
            const savedRevision = await this.props.store!.projectStore.saveRevisionCamerasData(revisionId, revisionInput)
            if (savedRevision !== null) {
                this.props.store!.notifyUser(this.props.store!.formatMessage('successSave'), 'success')
            } else {
                this.props.store!.notifyUser(this.props.store!.formatMessage('somethingWentWrong'), 'error')
            }
        }
    }
}

export default withRouter(CameraTool)
