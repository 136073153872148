import { Icon, ICONS, IDotData, styled } from '@paradigm/blueprints-common-frontend'
import { action, observable, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { keyframes, withTheme } from 'styled-components'
import { Store } from '../../../../App/AppStore'
import { Section } from '../../../builderProject/models/Section'
import Container from '../../../common/components/Layout/Container'
import Panel, { PanelContent } from '../../../common/components/Panel'
import { SPINNER_DEFAULT_SIZE } from '../../../common/components/Spinner'
import PageSpinner from '../../../common/components/Spinner/PageSpinner'
import EmptyMessage from '../../../common/components/Typography/EmptyMessage'
import withErrorBoundary from '../../../common/components/withErrorBuondary'
import ITakeOffTheme from '../../../common/theme/ITakeOffTheme'
import { ErrorStore } from '../../../stores/errorStore'
import { PannellumStore } from '../../../stores/PannellumStore'
import ProjectHeader from '../../components/ProjectHeader'
import { CameraDataToolbar } from './CameraDataToolbar'
import { CameraList } from './CameraList'
import ChangeAnswerSpinner from './ChangeAnswerSpinner'
import PannellumViewerContainer from './PannellumContainer'
import PannellumViewer from './PannellumViewer'
import ToolBar from './ToolBar'
import AssetBundleList from './AssetBundleList'

interface IDotToolProps {
    store?: Store
    pannellumStore?: PannellumStore
    theme: ITakeOffTheme
    errorStore?: ErrorStore
}

@inject('store', 'pannellumStore', 'errorStore')
@observer
class DotTool extends React.Component<IDotToolProps & RouteComponentProps> {
    @observable private isLoading: boolean = true
    @observable private revisionId: string

    @action
    public async componentDidMount() {
        this.isLoading = true

        const { match }: RouteComponentProps<any> = this.props
        const params = new URLSearchParams(this.props.location.search)
        const isInterior: boolean = params.get('isInterior') !== null ? JSON.parse(params.get('isInterior')!) : false

        const fetchModel = false
        const project = await this.props.store!.projectStore.getProject(match.params.projectId, fetchModel)

        runInAction(() => {
            this.props.store!.builderProjectStore.clientId = match.params.clientId
            this.props.store!.builderProjectStore.selectedBuilder.id = match.params.builderId
        })

        // Load buildingModelId
        if (project !== null) {
            if (project.alternatives) {
                const alternative = project.alternatives.find((a) => a.id === match.params.alternativeId)

                if (alternative && alternative.revisions) {
                    this.props.store!.projectStore.setCurrentAlternative(alternative.id)
                    const revision = alternative.revisions.find((r) => r.id === match.params.revisionId)
                    if (revision) {
                        runInAction(() => {
                            this.revisionId = revision.id
                        })

                        await this.props.store!.builderProjectStore.fetchAssetBundlelabels()
                        await this.props.store!.builderProjectStore.fetchSections(
                            project.name,
                            revision.buildingModelId,
                            isInterior ? 'interior_editor' : 'exterior_editor'
                        )

                        let sceneUrl
                        let assetUrl
                        if (alternative.assetBundles.length > 0) {
                            const labels = this.props.store!.builderProjectStore.assetbundleLabels.sort((a, b) => a.displayOrder - b.displayOrder)
                            for(let i = 0; i < labels.length; i++) {
                                const assetBundle = alternative.assetBundles.find(a => a.externalAssetBundleLabelId === labels[i].id)
                                if (assetBundle) {
                                    sceneUrl = assetBundle.sceneUrl
                                    assetUrl = assetBundle.assetUrl
                                    runInAction(() => {
                                        this.props.pannellumStore!.selectedAssetBundleLabel = labels[i].code
                                        this.props.pannellumStore!.isInterior = labels[i].code !== 'Exterior'
                                    })
                                    break
                                }
                            }
                        } else {
                            sceneUrl = isInterior ? alternative.intSceneUrl! : alternative.extSceneUrl!
                            assetUrl = isInterior ? alternative.intAssetUrl! : alternative.extAssetUrl!
                            runInAction(() => {
                                this.props.pannellumStore!.isInterior = isInterior
                            })
                        }
                        if (sceneUrl !== undefined && assetUrl !== undefined) {
                            await this.props.pannellumStore!.init(
                                match.params.builderId,
                                revision.buildingModelId,
                                sceneUrl,
                                assetUrl,
                                this.props.store!.builderProjectStore.filteredSection,
                                alternative
                            )
                        }
                    }
                }
            }
        }

        runInAction(() => {
            this.isLoading = false
        })
    }

    public componentWillUnmount() {
        this.props.pannellumStore!.clear()
    }

    public render() {
        if (this.isLoading || this.props.pannellumStore!.isInitialLoading) {
            return <PageSpinner size={SPINNER_DEFAULT_SIZE} />
        }

        if (!this.revisionId || !this.props.pannellumStore!.modelId) {
            return (
                <EmptyMessageWrapper>
                    <EmptyMessage>
                        <FormattedMessage id={'modelNotLoaded'} />
                    </EmptyMessage>
                </EmptyMessageWrapper>
            )
        }

        return (
            <DotToolContainer>
                <ProjectHeader />
                <Container>
                    <DotPanel>
                        <PanelContent height={'100%'}>
                            <PanelContainer>
                                <PanelWrapper>
                                    <SidePanelContainer>
                                        <AssetBundleList />
                                        <SidePanelList>
                                            <SectionTitle><FormattedMessage id={'sectionDots'} /></SectionTitle>
                                            {this.props.store!.builderProjectStore!.filteredSection.map((section: Section) =>
                                                <SidePanelListItem
                                                    key={`section_${section.name}`}
                                                    selected={this.props.pannellumStore!.sectionName === section.name}
                                                    onClick={this.onSectionItemClick.bind(this, section)}
                                                >
                                                    <div>{section.name}</div>
                                                    {this.props.pannellumStore!.currentCamera && this.props.pannellumStore!.currentCamera.dotInfo &&
                                                        this.renderSidePanelRemoveDot(section)
                                                    }
                                                </SidePanelListItem>
                                            )}
                                            <RemoveAllDots onClick={this.onRemoveAllDotsClick}>Remove Current Camera Dots</RemoveAllDots>

                                            <SectionTitle><FormattedMessage id={'cameraAndScenes'} /></SectionTitle>
                                            <CameraList />
                                        </SidePanelList>

                                    </SidePanelContainer>
                                </PanelWrapper>
                                <PannellumContainer>
                                    <ToolBar />

                                    <PannellumViewerContainer isMove={this.props.pannellumStore!.isMove}>
                                        <Grid isVisible={this.props.pannellumStore!.isGridVisible} />
                                        <ChangeAnswerSpinner isVisible={this.props.pannellumStore!.isLoadingAnswer} />
                                        <PannellumViewer />
                                    </PannellumViewerContainer>
                                </PannellumContainer>
                                <CameraDataToolbar />
                            </PanelContainer>
                        </PanelContent>
                    </DotPanel>
                </Container>
            </DotToolContainer>
        )
    }

    @action
    private renderSidePanelRemoveDot = (section: Section): JSX.Element | null => {
        let dot: IDotData | null = null
        const { currentCamera } = this.props.pannellumStore!
        if (currentCamera && currentCamera.dotInfo) {
            const index = currentCamera.dotInfo.findIndex((dotData: IDotData) => dotData.sectionId === section.name)
            if (index !== -1) {
                dot = currentCamera.dotInfo[index]
            }
        }
        if (dot) {
            return (
                <RemoveIcon
                    title={`Remove ${section.name} dot`}
                    onClick={async () => await this.props.pannellumStore!.removeSectionDotPerCamera(section.name)}
                >
                    <Icon size={20} icon={ICONS.DELETE} />
                </RemoveIcon>
            )
        }
        return null
    }

    @action
    private onSectionItemClick = (section: Section) => {
        this.props.pannellumStore!.sectionName = section.name
    }

    @action
    private onRemoveAllDotsClick = async () => {
        await this.props.pannellumStore!.removeAllDotsPerCamera()
    }

    private getAssetBundlelabel(id: string) {
        const { assetbundleLabels } = this.props.store!.builderProjectStore
        const label = assetbundleLabels.find(label => label.id === id)
        if (label) {
            return label.code
        }
        return ''
    }
}

export default withRouter(withErrorBoundary(withTheme(DotTool)))

const slideIn = () => keyframes`
    0% {
        transform: translateX(-400px);
        z-index: 0;
    }
    100% {
        transform: translateX(0);
        z-index: 1;
    }
`

const DotToolContainer = styled.div`
    >div {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
`

const PanelContainer = styled.div`
    display: flex;
    height: 100%;
    .hidden {
        display: none;
    }
`

const DotPanel = styled(Panel)`
    margin-top: 15px;
    height: calc(100vh - 170px);
    padding-bottom: 0px;
`

const SidePanelContainer = styled.div`
    height: 100%;
    width: 0px;
    max-width: 100%;
    min-width: 325px;
    background-color: ${(props) => props.theme.colors.neutrals[0]};
    display: flex;
    flex-direction: column;
    z-index: 1;
    overflow: hidden;
`

const PanelWrapper = styled.div`
    position: relative;
    top: 0px;
    height: 100%;
`

const SidePanelHeader = styled.div`
    position: relative;
    height: 68px;
    background-color: ${(props) => props.theme.colors.neutrals[8]};
    color: white;
    display: flex;
    align-items: center;
    padding-left: 28px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px;
    -webkit-box-pack: justify;
    justify-content: space-between;
`

const SidePanelList = styled.div`
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    animation: ${slideIn()} 700ms ease-in-out;
`

const PannellumContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2 auto;
`

const SidePanelListItem = styled.div<{ selected: boolean }>`
    background-color: ${(props) => props.selected ? props.theme.colors.neutrals[1] : props.theme.colors.neutrals[0]};
    display: flex;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) => props.theme.colors.neutrals[3]};
    font-size: 14px;
    color: ${(props) => props.theme.colors.neutrals[7]};
    padding: 15px 10px 15px 28px;
    transition: background-color 150ms ease-in-out 0s;

    &:hover {
        background-color: rgb(235, 235, 235);
    }
`

const RemoveIcon = styled.div`
    svg {
        fill: ${(props) => props.theme.colors.error};
    }
`

const EmptyMessageWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`

const RemoveAllDots = styled.div`
    font-size: 15px;
    color: ${(props) => props.theme.colors.error};
    text-align: end;
    font-weight: 600;
    padding: 10px 0px;
    cursor: pointer;
    text-align: center;
    padding-bottom: 40px;
`

const SectionTitle = styled.div`
    background-color: ${(props) => props.theme.colors.neutrals[0]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) => props.theme.colors.neutrals[3]};
    font-size: 16px;
    color: ${(props) => props.theme.colors.neutrals[7]};
    font-weight: 600;
    padding: 15px 10px 15px 28px;
    -webkit-transition: background-color 150ms ease-in-out 0s;
    transition: background-color 150ms ease-in-out 0s;
`

const Grid = styled.div<{ isVisible?: boolean }>`
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    background-image:
      repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%);
    background-size: 30px 30px;
    z-index: 2;
    pointer-events: none;
    opacity: ${(props) => props.isVisible ? '1' : '0'};
`
