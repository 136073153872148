import { MeshTreeNode } from "../MeshTreeNode"
import { MeshTreeNodeFlaggedAction  } from "./MeshTreeNodeFlaggedAction "

export class DeleteChildrenAction extends MeshTreeNodeFlaggedAction  {

    public execute(node: MeshTreeNode): void {
        const children: MeshTreeNode[] = []
        node.getChildren().forEach(element => {
            if (this.allowAction(element)) {
                element.mesh!.dispose()
            } else {
                children.push(element)
            }
        })

        node.setChildren(children)
    }
}
