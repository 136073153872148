import { MaterialOptions } from '@paradigm/blueprints-common-frontend'

export const COLOR_HEX_WHITE = '#FFFFFF'
export const COLOR_HEX_BLACK = '#000000'
export const COLOR_HEX_GRAY50 = '#7F7F7F'
export const COLOR_HEX_BEIGE = '#FFF5E4'
export const COLOR_HEX_TIDE = '#C0B7B0'
export const COLOR_HEX_RED = '#FF0000'
export const COLOR_HEX_SHRUB_SLAB = '#4D3729'

const DEFAULT_GLASS_REFLECTIVITY_COLOR = '#727272'
const DEFAULT_GLASS_ALPHA = 0.77

const TEXTURE_URL = 'textures/Shake_CedarDiscovery_AlbedoTexture2.jpg'
const TEXTURE_NORMAL_URL = 'textures/Shake_CedarDiscovery_NormalTexture.png'
const TEXTURE_WIDTH = 6.416666666667
const TEXTURE_HEIGHT = 6.416666666667

export function createSimpleMaterialOption(color: string, alpha: number = 1, withTexture = false): MaterialOptions.PBRMaterialOptions {
    const material = new MaterialOptions.PBRMaterialOptions()
    material.color = color
    material.reflectionColor = color
    material.reflectivityColor = COLOR_HEX_WHITE
    material.emissiveColor = COLOR_HEX_BLACK
    material.alpha = alpha
    material.metallic = 0
    material.roughness = 1

    if (withTexture) {
        material.textureWidth = TEXTURE_WIDTH
        material.textureHeight = TEXTURE_HEIGHT
        material.textureUrl = TEXTURE_URL
        material.normalTextureUrl = TEXTURE_NORMAL_URL
    }

    return material
}

export function createGlassMaterialOptions(): MaterialOptions.PBRMaterialOptions {
    const glass = new MaterialOptions.PBRMaterialOptions()
    glass.indexOfRefraction = 1
    glass.alpha = DEFAULT_GLASS_ALPHA
    glass.microSurface = 1
    glass.reflectivityColor = DEFAULT_GLASS_REFLECTIVITY_COLOR
    glass.color = COLOR_HEX_BLACK
    glass.roughness = 0
    glass.metallic = 0
    glass.refractionTexture = true

    return glass
}