import { postal } from '@paradigm/blueprints-common-frontend'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { ModelStore } from '../../../stores/modelStore'
import { MappingToolAction, MappingToolActionsStyled } from './MappingToolStyles'

interface IMappingToolActionsProps {
    modelStore?: ModelStore
    children?: React.ReactNode
}

@inject('modelStore')
@observer
export class MappingToolActions extends React.Component<IMappingToolActionsProps & InjectedIntlProps> {
    public render() {
        const mappingToolActions = [
            {
                label: 'grid',
                action: this.requestToggleGrid,
                active: false
            },
            {
                label: 'move',
                action: this.requestMoveBlueprint,
                active: false
            },
            {
                label: 'origin',
                action: this.requestPlaceBlueprintOrigin,
                active: false
            },
            {
                label: 'scale',
                action: this.requestScaleBlueprint,
                active: false
            },
            {
                label: 'rotate',
                action: this.requestRotateBlueprint,
                active: false
            },
            {
                label: 'rectangle',
                action: this.requestSelectionTool,
                active: false
            },
            {
                label: 'pointer',
                action: this.requestUnselectAction,
                active: true
            }
        ]
        const { formatMessage } = this.props.intl
        return (
            <MappingToolActionsStyled direction={'row'}>
                {mappingToolActions.map((mappingToolAction, index) =>
                    <MappingToolAction
                        key={index}
                        onClick={mappingToolAction.action}
                    >
                        {formatMessage({ id: mappingToolAction.label })}
                    </MappingToolAction>
                )}
                {this.props.children}
            </MappingToolActionsStyled>
        )
    }

    private requestToggleGrid = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.grid.visible'
        })
    }

    private requestMoveBlueprint = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.transformations.move'
        })
    }

    private requestPlaceBlueprintOrigin = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.transformations.origin'
        })
    }

    private requestScaleBlueprint = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.transformations.scale'
        })
    }

    private requestRotateBlueprint = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.transformations.rotate'
        })
    }

    private requestSelectionTool = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.transformations.selection'
        })
    }

    private requestUnselectAction = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.transformations.reset'
        })
    }
}

export default injectIntl(MappingToolActions)
