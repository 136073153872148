import { styled } from '@paradigm/blueprints-common-frontend'

const Panel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px 0;
    border-radius: ${props => props.theme.shape.borderRadius};
    background: ${props => props.theme.colors.surface};
`

export default Panel
