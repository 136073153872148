import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import PageSpinner from '../../../common/components/Spinner/PageSpinner'
import { styled } from '@paradigm/blueprints-common-frontend'
import { IStore } from '../../../../App/AppStore'
import { ProjectForm } from '../../components/ProjectForm'
import ProjectHeader from '../../components/ProjectHeader'
import { UserRole } from '../../../auth/models/User'

const SideNavigation = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 10%;
    height: 100vh;
    margin-right: 10px;
    color: ${p => p.theme.colors.primary};
`

const SideNavigationItem = styled.li`
    display: block;
    transition: all 0.25s ease;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    &:hover {
        color: ${p => p.theme.colors.secondary};
        cursor: pointer;
    }
`

const Layout = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const MainContent = styled.div`
    flex: 80%;
    padding: 20px;
`

const StyledLink = styled.a<{ active?: boolean }>`
    color: ${p => p.active ? p.theme.colors.secondary : p.theme.colors.primary};
    text-decoration: none;
    font-size: ${p => p.theme.font.size.md};
    transition: color 250ms ease-in-out;
    &:hover {
        color: ${p => p.theme.colors.secondary};
        text-decoration: none;
    }
`

const AlternativePanel = React.lazy(() => import('../../components/Alternatives/AlternativePanel'))
const StatusHistoryList = React.lazy(() => import('../../components/StatusHistoryList'))
const PublishManagement = React.lazy(() => import('../../../builderProject/components/PublishManagement/PublishManagement'))

interface IView {
    name: string
    icon: string[]
    component: JSX.Element
}

@inject('store')
@observer
class ProjectDetails extends React.Component<IStore & RouteComponentProps> {
    @observable public currentViewIndex: number = 0

    public componentDidMount() {
        const { match }: any = this.props
        const fetchModel = false
        this.props.store!.projectStore.getProject(match.params.id, fetchModel)
    }

    public render() {
        const views: IView[] = this.createViews()
        const displayAdminTool = this.displayAdminTool()
        return (
            <React.Fragment>
                <ProjectHeader />
                <Layout>
                    <SideNavigation>
                        {views.map((view, index) => (
                            <SideNavigationItem key={index}>
                                <StyledLink
                                    active={this.currentViewIndex === index}
                                    onClick={this.handleOnViewChange.bind(this, index)}
                                >
                                    {displayAdminTool && <FontAwesomeIcon icon={view.icon as IconProp} fixedWidth={true} />}{' '}
                                    {displayAdminTool && <FormattedMessage id={view.name} />}
                                </StyledLink>
                            </SideNavigationItem>
                        ))}
                    </SideNavigation>
                    <MainContent>
                        <React.Suspense fallback={<PageSpinner size={4} />}>
                            {this.renderCurrentView(views)}
                        </React.Suspense>
                    </MainContent>
                </Layout>
            </React.Fragment>
        )
    }

    @action
    private handleOnViewChange = (index: number) => {
        this.currentViewIndex = index
    }

    private renderCurrentView(views: IView[]) {
        return views[this.currentViewIndex].component
    }

    private createViews(): IView[] {
        const { id, orderedStatusesHistory } = this.props.store!.projectStore.currentProject
        const { orderedAlternatives } = this.props.store!.projectStore

        const views: IView[] = [
            {
                name: 'details',
                icon: ['fal', 'file-alt'],
                component: <ProjectForm />
            },
            {
                name: 'alternatives',
                icon: ['fal', 'home'],
                component: <AlternativePanel alternatives={orderedAlternatives} />
            },
            {
                name: 'status',
                icon: ['fal', 'history'],
                component: <StatusHistoryList statusesHistory={orderedStatusesHistory} />
            },
            {
                name: 'publish',
                icon: ['fal', 'paper-plane'],
                component: <PublishManagement projectId={id} />
            }
        ]

        return views
    }
    private displayAdminTool() {
        return (this.props.store!.user.role == UserRole.ADMIN)
    }
}

export default withRouter(ProjectDetails)
