import * as React from 'react'
import './Card.css'


interface ICardProps extends React.HTMLProps<any> {
    title?: string
    children?: React.ReactNode
}

const Card: React.SFC<ICardProps> = ({ title, children, ...props }) => {
    return (
        <div className={'Card'} {...props}>
            <p className='CardTitle'>{title}</p>
            {children}
        </div>
    )
}

export default Card
