import * as React from 'react'
import { inject, observer } from "mobx-react"
import { ErrorStore } from "../../stores/errorStore"

interface IWithErrorBoundaryProps {
    errorStore?: ErrorStore
}

const withErrorBoundary = <P extends object>(
    Component: React.ComponentType<P>
): React.FC<P & IWithErrorBoundaryProps> => inject("errorStore")(observer(({
    errorStore,
    ...props
}: IWithErrorBoundaryProps) => {
    if (errorStore!.errors.length) {
        throw new Error("It seems something went wrong")
    } else {
        return <Component {...props as P} />
    }
}))

export default withErrorBoundary
