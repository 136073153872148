import {
    ArcRotateCamera, Axis, Color3,
    Mesh, MeshBuilder, Nullable, Observer, PlaneRotationGizmo,
    Quaternion, Scene, StandardMaterial, Vector2, Vector3
} from 'babylonjs'
import { ITransformationInfo } from '../interfaces'
import * as BabylonUtils from './babylonUtils'
import { PointerObserverManager } from './PointerObserverManager'

const DISTANCE_FROM_THE_GROUND = 0.002

const CROSSHAIR_RADIUS = 0.05
const CROSSHAIR_CIRCLE_TESSELLATION = 50
const CROSSHAIR_SCALE = 0.1

const MOUSE_CURSOR_DEFAULT = 'default'
const MOUSE_CURSOR_CROSSHAIR = 'crosshair'
const MOUSE_CURSOR_GRAB = 'grab'
const MOUSE_CURSOR_GRABBING = 'grabbing'

/**
 * Transformation Manager manages the interaction with the provided mesh
 * It uses an external pivot to manage the transformations
 *
 */
export class TransformationManager {
    private scene: Scene
    private ground: Mesh
    private pivot: Mesh
    private linkedMesh: Mesh
    private crosshairMesh: Mesh
    private name: string

    private beforeRenderObserver: Nullable<Observer<Scene>>
    private rotationGizmoY: PlaneRotationGizmo

    private dragAndDropEnabled: boolean = false
    private scaleEnabled: boolean = false
    private originEnabled: boolean = false
    private drawRectangleEnabled: boolean = false

    private selectedMesh: Mesh | null = null
    private startingPoint: Vector3 | null = null
    private startingScale: Vector3 = Vector3.One()

    private pointerObserverManager: PointerObserverManager

    private firstClickPosition: Vector3 | null = null
    private selectionRectangle: Mesh
    private selectionRectangleDiagonal: [Vector3, Vector3]

    public constructor(name: string, linkedMesh: Mesh, scene: Scene, ground: Mesh, isVisible: boolean = true, initialCameraRadius = 5) {
        this.name = name
        this.linkedMesh = linkedMesh
        this.scene = scene
        this.ground = ground
        this.createMesh()
        this.rotationGizmoY = new PlaneRotationGizmo(Axis.Y, Color3.Red())

        this.pointerObserverManager = new PointerObserverManager(this.scene)

        if (isVisible) {
            this.createCrosshairMesh()

            this.beforeRenderObserver = this.scene.onBeforeRenderObservable.add(() => {
                const activeCamera = this.scene.activeCamera

                if (activeCamera && activeCamera instanceof ArcRotateCamera) {
                    this.keepVisibleMeshScaleOnZoom(this.scene.activeCamera as ArcRotateCamera, initialCameraRadius)
                }
            })

            this.pointerObserverManager.registerToPointerDownObserver(this.updateOnPointerDownCallback)
            this.pointerObserverManager.registerToPointerMoveObserver(this.updateOnPointerMoveCallback)
            this.pointerObserverManager.registerToPointerUpObserver(this.updateOnPointerUpCallback)
        }

        this.pointerObserverManager.activateZoommingOnCursorPosition()
    }

    public enableRotate(enable: boolean = true): void {
        if (enable) {
            this.rotationGizmoY.attachedMesh = this.pivot
        } else {
            this.rotationGizmoY.attachedMesh = null
        }
    }

    public enableMoveAction(): void {
        this.setDefaultCursor(MOUSE_CURSOR_GRAB)
        this.dragAndDropEnabled = true
    }

    public enableOriginAction(): void {
        this.setDefaultCursor(MOUSE_CURSOR_CROSSHAIR)
        this.originEnabled = true
    }

    public enableScaleAction(): void {
        this.setDefaultCursor(MOUSE_CURSOR_CROSSHAIR)
        this.scaleEnabled = true
    }

    public enableDrawRectangleAction(): void {
        this.setDefaultCursor(MOUSE_CURSOR_CROSSHAIR)
        this.drawRectangleEnabled = true
    }

    public reset(): void {
        this.setDefaultCursor()
        this.scaleEnabled = false
        this.originEnabled = false
        this.dragAndDropEnabled = false
        this.drawRectangleEnabled = false
        this.enableRotate(false)
    }

    public getSelectionRectangleDiagonal(): [Vector3, Vector3] {
        return this.selectionRectangleDiagonal
    }

    public retrieveLinkedMeshMatrix(bakeCurrentTransformIntoVertices: boolean = true): number[] {
        let matrix: number[] = []

        if (this.linkedMesh) {
            if (bakeCurrentTransformIntoVertices) {
                this.linkedMesh.computeWorldMatrix(true)
            }

            matrix = Array.from(this.linkedMesh.getWorldMatrix().m)
        }

        return matrix
    }

    public retrieveTransformations(): ITransformationInfo {
        return {
            position: this.pivot.position,
            scaling: this.pivot.scaling,
            rotation: this.pivot.rotationQuaternion,
            linkedMeshPosition: this.linkedMesh.position,
            selectionDiagonal: this.selectionRectangleDiagonal
        }
    }

    public applyTransformations(transformationInfo: ITransformationInfo): void {
        if (transformationInfo.position) {
            this.updatePivot(Object.assign(new Vector3(), transformationInfo.position))
        }

        if (transformationInfo.scaling) {
            this.pivot.scaling = Object.assign(new Vector3(), transformationInfo.scaling)
        }

        if (transformationInfo.rotation) {
            this.pivot.rotationQuaternion = new Quaternion(
                transformationInfo.rotation.x,
                transformationInfo.rotation.y,
                transformationInfo.rotation.z,
                transformationInfo.rotation.w
            )
        }

        if (transformationInfo.linkedMeshPosition) {
            this.linkedMesh.position = Object.assign(new Vector3(), transformationInfo.linkedMeshPosition)
        }

        if (transformationInfo.selectionDiagonal) {
            this.drawSelectionRectangle(transformationInfo.selectionDiagonal[0], transformationInfo.selectionDiagonal[1])
            this.selection.bakeCurrentTransformIntoVertices()
        }
    }

    public dispose(): void {
        if (this.pivot) {
            if (this.linkedMesh) {
                this.pivot.removeChild(this.linkedMesh)
            }

            this.pivot.dispose()
        }

        if (this.crosshairMesh) {
            this.crosshairMesh.dispose()
        }

        if (this.selectionRectangle) {
            this.selectionRectangle.dispose()
        }

        if (this.beforeRenderObserver) {
            this.scene.onBeforeRenderObservable.remove(this.beforeRenderObserver)
        }

        if (this.pointerObserverManager) {
            this.pointerObserverManager.removeAllPointerObservers()
        }

        this.removeRotationGizmoYAttachedMesh()
        this.setDefaultCursor()
    }

    public hideAndZoomOnSelection(camera: ArcRotateCamera, zoomFactor: number) {
        if (this.selection) {
            this.selection.visibility = 0
            BabylonUtils.zoomAll(this.selection, camera, zoomFactor)
        }
    }

    public get mesh(): Mesh {
        return this.pivot
    }

    public get selection(): Mesh {
        return this.selectionRectangle
    }

    private removeRotationGizmoYAttachedMesh(): void {
        if (this.rotationGizmoY) {
            this.rotationGizmoY.attachedMesh = null
        }
    }

    private setDefaultCursor(cursorName: string = MOUSE_CURSOR_DEFAULT): void {
        this.scene.defaultCursor = cursorName
    }

    private keepVisibleMeshScaleOnZoom(arcRotateCamera: ArcRotateCamera, initialCameraRadius: number): void {
        if (this.crosshairMesh && arcRotateCamera) {
            const radius = arcRotateCamera.radius
            const scaleRatio = radius / initialCameraRadius
            this.crosshairMesh.scaling = new Vector3(scaleRatio, scaleRatio, scaleRatio)
        }
    }

    private createMesh(): void {
        const pivot = MeshBuilder.CreateSphere(this.name, { diameter: 1 }, this.scene)
        const materialPivot = new StandardMaterial('texturePivot', this.scene)
        materialPivot.diffuseColor = Color3.Red()
        pivot.material = materialPivot
        pivot.position = Vector3.Zero()
        pivot.visibility = 0
        this.pivot = pivot
        this.pivot.addChild(this.linkedMesh)
    }

    private createCrosshairMesh(): void {
        const points = new Array<Vector3>()

        for (let i = 0; i < CROSSHAIR_CIRCLE_TESSELLATION; i++) {
            const radian = (2 * Math.PI) * (i / (CROSSHAIR_CIRCLE_TESSELLATION - 1))
            points.push(new Vector3(CROSSHAIR_RADIUS * Math.sin(radian), DISTANCE_FROM_THE_GROUND, CROSSHAIR_RADIUS * Math.cos(radian)))
        }

        const crosshairLines = [
            [new Vector3(-CROSSHAIR_SCALE, DISTANCE_FROM_THE_GROUND, 0), new Vector3(CROSSHAIR_SCALE, DISTANCE_FROM_THE_GROUND, 0)],
            [new Vector3(0, DISTANCE_FROM_THE_GROUND, -CROSSHAIR_SCALE), new Vector3(0, DISTANCE_FROM_THE_GROUND, CROSSHAIR_SCALE)],
            points
        ]

        const linesMesh = MeshBuilder.CreateLineSystem('lineSystem', { lines: crosshairLines }, this.scene)
        linesMesh.color = Color3.Blue()

        this.crosshairMesh = linesMesh
    }

    private updatePosition(previousPosition: Vector3, currentPosition: Vector3): void {
        const diff = currentPosition.subtract(previousPosition)
        this.pivot.position.addInPlace(diff)
        this.crosshairMesh.position.addInPlace(diff)
    }

    private updateScale(previousScale: Vector3, previousPosition: Vector3, currentPosition: Vector3): void {
        const distancePivotStartingPoint = Vector3.Distance(this.pivot.position, previousPosition)
        const distancePivotCurrent = Vector3.Distance(this.pivot.position, currentPosition)

        const newScaleRatio = distancePivotCurrent / distancePivotStartingPoint

        if (isFinite(newScaleRatio)) {
            this.pivot.scaling = new Vector3(newScaleRatio * previousScale.x, 1, newScaleRatio * previousScale.z)
        }
    }

    private updatePivot(newPosition: Vector3): void {
        this.pivot.removeChild(this.linkedMesh)
        this.pivot.position = new Vector3(newPosition.x, 0, newPosition.z)
        this.pivot.addChild(this.linkedMesh)

        if (this.crosshairMesh) {
            this.crosshairMesh.position = this.pivot.position.clone()
        }
    }

    private drawSelectionRectangle(startPosition: Vector3, endPosition: Vector3) {
        if (this.selectionRectangle) {
            this.selectionRectangle.dispose()
        }

        const currentPosition = new Vector3(endPosition.x, 0, endPosition.z)
        const previousPosition = new Vector3(startPosition.x, 0, startPosition.z)

        this.selectionRectangleDiagonal = [previousPosition, currentPosition]

        const centerX = (currentPosition.x + previousPosition.x) / 2
        const centerZ = (currentPosition.z + previousPosition.z) / 2
        const center = new Vector2(centerX, centerZ)
        const width = 2 * Vector2.Distance(center, new Vector2(currentPosition.x, center.y))
        const height = 2 * Vector2.Distance(center, new Vector2(center.x, currentPosition.z))

        const selection = MeshBuilder.CreatePlane('selectionRentangle', { width, height, sideOrientation: Mesh.DOUBLESIDE }, this.scene)
        const material = new StandardMaterial('selectionRentangleMaterial', this.scene)
        material.diffuseColor = Color3.FromHexString(BabylonUtils.COLOR_ACTIVE_HEX_STRING)
        selection.material = material
        selection.visibility = 0.15
        selection.rotate(Axis.X, -Math.PI / 2)
        selection.rotate(Axis.Y, Math.PI)
        selection.position = new Vector3(centerX, DISTANCE_FROM_THE_GROUND, centerZ)
        this.selectionRectangle = selection
    }

    private updateOnPointerDownCallback = (event: PointerEvent) => {
        const pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => mesh === this.linkedMesh)
        this.startingPoint = BabylonUtils.getGroundPosition(this.scene, this.ground)

        if (event.button === 0 || event.which === 1) {
            if (this.dragAndDropEnabled) {
                this.setDefaultCursor(MOUSE_CURSOR_GRABBING)
            }

            if (this.drawRectangleEnabled && this.startingPoint) {
                if (this.selectionRectangle) {
                    this.selectionRectangle.dispose()
                }
                this.firstClickPosition = this.startingPoint
            }

            if (pickInfo !== null && pickInfo.hit) {
                this.selectedMesh = pickInfo.pickedMesh as Mesh
                this.startingScale = this.pivot.scaling

                if (this.startingPoint) {
                    BabylonUtils.detachCamera(this.scene)
                }
            }
        }
    }

    private updateOnPointerUpCallback = () => {
        if (this.startingPoint) {
            if (this.dragAndDropEnabled) {
                this.setDefaultCursor(MOUSE_CURSOR_GRAB)
            }

            if (this.originEnabled) {
                this.updatePivot(this.startingPoint)
            }

            BabylonUtils.attachCamera(this.scene)

            this.selectedMesh = null
            this.startingPoint = null
            this.firstClickPosition = null
        }
    }

    private updateOnPointerMoveCallback = () => {
        if (this.startingPoint && this.selectedMesh) {
            const current = BabylonUtils.getGroundPosition(this.scene, this.ground)

            if (current) {
                if (this.dragAndDropEnabled) {
                    if (this.selectedMesh.name === this.linkedMesh.name) {
                        this.updatePosition(this.startingPoint, current)
                        this.startingPoint = current
                    }
                }

                if (this.scaleEnabled && this.startingScale) {
                    this.updateScale(this.startingScale, this.startingPoint, current)
                }

                if (this.drawRectangleEnabled && this.firstClickPosition) {
                    this.drawSelectionRectangle(this.firstClickPosition, current)
                }
            }
        }
    }
}
