import { ICameraConfig, IGeometryMaterialOptions, ILockCameraRendererData, MaterialOptions, MaterialOptionsType, ModelType, ModelUtils, styled, Viewer, Viewer3dManager, ViewerRenderingEngines } from '@paradigm/blueprints-common-frontend'
import { action, observable, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import SpinLoader from '../../../common/components/SpinLoader'
import { ModelStore } from '../../../stores/modelStore'
import * as MaterialOptionsLib from './MaterialOptionsLib'

const ViewerStyled = styled.div`
    width: 60%;
    height: auto;
    margin: 0 auto;
`

const CAMERA_TOOL_MODEL_TYPES_TO_LOAD: ModelType[] = [
    ModelType.WALL,
    ModelType.DOOR_STRUCTURE,
    ModelType.DOOR_FRAME,
    ModelType.DOOR_GLASS,
    ModelType.DOOR_SILL,
    ModelType.DOOR_GRILLS,
    ModelType.WINDOW_FRAME,
    ModelType.WINDOW_GLASS,
    ModelType.ENTRY_DOOR,
    ModelType.GARAGE_DOOR,
    ModelType.ROOF,
    ModelType.SLAB,
    ModelType.BUILDING_ELEMENT_PROXY,
    ModelType.IFC_COLUMN,
    ModelType.IFC_COVERING,
    ModelType.IFC_RAILING,
    ModelType.IFC_STAIR_FLIGHT,
]

interface ICameraToolProps {
    modelId: string
    modelStore?: ModelStore
    lockCameraData: ILockCameraRendererData
}

@inject('modelStore')
@observer
export default class CameraToolViewer extends React.Component<ICameraToolProps> {
    private static readonly DEFAULT_SKY_TEXTUTURE_URL = 'textures/park_summer-lightergrass-sky-2i.jpg'
    private static readonly CAMERA_CONFIG: ICameraConfig = {
        inertia: 0.5,
        panningInertia: 0.5,
        panningSensibility: 30,
        wheelPrecision: 2,
    }

    private cameraToolViewerManager: Viewer3dManager
    private materialOptions: Map<MaterialOptionsType, IGeometryMaterialOptions[]> = new Map()
    @observable private isLoading: boolean = true

    public componentDidMount() {
        this.loadCameraToolModel()
    }

    @action
    public async loadCameraToolModel() {
        this.isLoading = true
        await this.props.modelStore!.loadModel(this.props.modelId)
        await this.props.modelStore!.loadGeometries(this.props.modelId, CAMERA_TOOL_MODEL_TYPES_TO_LOAD)
        this.populateMaterialOptions()
        const environemntConfig = { skyBoxTextureUrl: CameraToolViewer.DEFAULT_SKY_TEXTUTURE_URL }
        this.cameraToolViewerManager = new Viewer3dManager(this.props.modelStore!.geometries, environemntConfig)
        this.cameraToolViewerManager.setCameraConfig(CameraToolViewer.CAMERA_CONFIG)
        this.cameraToolViewerManager.setRecordCameraData(true)
        this.cameraToolViewerManager.setLockCameraData(this.props.lockCameraData)
        this.cameraToolViewerManager.setMaterialOptions(this.materialOptions)
        runInAction(() => this.isLoading = false)
    }

    public render() {
        if (this.isLoading) {
            return <SpinLoader isLoading={true} />
        }

        return (
            <ViewerStyled id={'my-gui-container'}>
                <Viewer
                    canvasId={'mapping-tool-viewer'}
                    renderingEngine={ViewerRenderingEngines.BABYLON}
                    viewerManager={this.cameraToolViewerManager}
                />
            </ViewerStyled>
        )
    }

    private populateMaterialOptions() {
        const beigeMaterialOption = MaterialOptionsLib.createSimpleMaterialOption(MaterialOptionsLib.COLOR_HEX_BEIGE)
        const tideMaterialOption = MaterialOptionsLib.createSimpleMaterialOption(MaterialOptionsLib.COLOR_HEX_TIDE)
        const glassMaterialOption = MaterialOptionsLib.createGlassMaterialOptions()
        Object.values(CAMERA_TOOL_MODEL_TYPES_TO_LOAD).forEach((modelType) => {
            switch (modelType) {
                case ModelType.SLAB:
                case ModelType.DOOR_FRAME:
                case ModelType.WINDOW_FRAME:
                case ModelType.DOOR_STRUCTURE:
                case ModelType.DOOR_GRILLS:
                case ModelType.BUILDING_ELEMENT_PROXY:
                case ModelType.IFC_COLUMN:
                case ModelType.IFC_COVERING:
                case ModelType.IFC_STAIR_FLIGHT:
                case ModelType.DOOR_SILL:
                case ModelType.ROOF:
                    this.createGeometryMaterialOptions(modelType, MaterialOptionsType.PBR, beigeMaterialOption)
                    break
                case ModelType.WALL:
                    this.createGeometryMaterialOptions(modelType, MaterialOptionsType.PBR, tideMaterialOption)
                    break
                case ModelType.WINDOW_GLASS:
                case ModelType.DOOR_GLASS:
                    this.createGeometryMaterialOptions(modelType, MaterialOptionsType.PBR, glassMaterialOption)
                    break
                default:
                    break
            }
        })
    }

    private createGeometryMaterialOptions(type: ModelType, materialOptionsType: MaterialOptionsType, materialOption: MaterialOptions.BaseMaterialOptions) {
        if (this.props.modelStore!.model !== null && materialOption !== undefined) {
            const ids = ModelUtils.findElementIdsByType(this.props.modelStore!.model.children, type)
            const option: IGeometryMaterialOptions = {
                ids,
                options: materialOption
            }
            const options = this.materialOptions.get(materialOptionsType)

            if (options === undefined) {
                this.materialOptions.set(materialOptionsType, [option])
            } else {
                options.push(option)
            }
        }
    }
}
