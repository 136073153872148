import styled from 'styled-components'

export const SPINNER_DEFAULT_SIZE = 4

export const Spinner = styled('div') <{ size?: number; speed?: number }>`
    border: ${(p) => p.size || SPINNER_DEFAULT_SIZE}px solid #ebebeb;
    border-top: ${(p) => p.size}px solid ${(p) => p.theme.colors.primary};
    border-radius: 50%;
    width: ${(p) => (p.size || SPINNER_DEFAULT_SIZE) * 7.5}px;
    height: ${(p) => (p.size || SPINNER_DEFAULT_SIZE) * 7.5}px;
    animation: spin ${(p) => p.speed || 1}s linear infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
