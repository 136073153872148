import { styled } from '@paradigm/blueprints-common-frontend'

export const MappingToolStyled = styled.div`
    display: flex;
    height: calc(100vh - 120px);
    flex-direction: column;
`

export const MappingToolLeftSideNavStyled = styled.div`
    flex: 0 0 20em;
    overflow-y: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const MappingToolRightSideNavStyled = styled.div`
    flex: 0 0 12em;
`

export const MappingToolBodyStyled = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
    background-color: white;
`

export const MappingToolContentStyled = styled.div`
    flex: 1;
`

export const BlueprintThumbnailsStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

export const BlueprintThumbnail = styled.img<{ selected?: boolean }>`
    padding: 5px 10px;
    width: 100%;
    margin: 5px 0;
    border: 2px solid ${(props) => props.selected ? props.theme.colors.primary : 'transparent'};
    transition-property: border-color;
    transition-duration: ${(props) => props.theme.transitions.duration.standard};
    transition-timing-function: ${(props) => props.theme.transitions.easing.easeInOut};
`

export const MappingToolViewerStyled = styled.div`
    height: calc(100vh - (109px + 62.8px + 45px));
`

export const MappingToolActionsStyled = styled.div<{ direction: 'row' | 'column' }>`
    display: flex;
    flex-direction: ${(props) => props.direction};
    margin: 10px;
    align-items: center;
`

export const MappingToolAction = styled.div<{ active?: boolean, selected?: boolean }>`
    border: 2px solid ${(props) => props.selected ? props.theme.colors.primary : 'transparent'};
    width: 100px;
    height: 50px;
    margin: 2px;
    padding: 15px;
    background-color: ${(props) => props.active ? props.theme.colors.primary : props.theme.colors.neutrals[1]};
    color: ${(props) => props.active ? props.theme.text.contrast : props.theme.text.main};
    transition-property: border-color;
    transition-duration: ${(props) => props.theme.transitions.duration.standard};
    transition-timing-function: ${(props) => props.theme.transitions.easing.easeInOut};
    text-align: center;
    &:hover {
        border-color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
    }
`
