import { Box } from '@rebass/grid'
import { styled } from '@paradigm/blueprints-common-frontend'

const Container = styled(Box)`
  width: 100%;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
`

export default Container
