import { faCommentAlt, faPaperPlane, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Button, ButtonToolbar, Col, Grid, Row } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { IStore } from '../../../App/AppStore'

import ModelRendering from '../../buildingModel/components/ModelRendering'
import { MeshPropertiesTable } from './MeshPropertiesTable'
import ProjectHeader from './ProjectHeader'
import { QualityAssuranceDetail } from './QualityAssuranceDetail'
import { QualityAssuranceHeader } from './QualityAssuranceHeader'


// @ts-ignore
@withRouter
@inject('store')
@observer
export class QualityAssurance extends React.Component<IStore> {
    constructor(props: Readonly<IStore>) {
        super(props)

        this.onAddComnnent = this.onAddComnnent.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.saveQA = this.saveQA.bind(this)

        const { match }: any = this.props
        this.props.store!.projectStore.getProject(match.params.id, true)
        this.props.store!.projectStore.getQA(match.params.qaId)

        this.props.store!.buildingModel.enableEditMode(false)
        this.props.store!.projectStore.resetQADetail()

        this.props.store!.buildingModel.setRenderingForQa(true)
    }

    public onAddComnnent() {
        this.props.store!.projectStore.resetQADetail()
        this.props.store!.buildingModel.enableEditMode(true)
    }

    public saveQA() {
        this.props.store!.projectStore.saveQA()
    }

    public onSubmit() {
        this.props.store!.projectStore.addQADetail()
    }

    public render() {
        return (
            <div>
                <ProjectHeader />
                <QualityAssuranceHeader />
                <Grid fluid={true}>
                    <Row>
                        <Col xs={12} md={3} >
                            <ButtonToolbar>
                                <Button bsStyle='default' onClick={this.onAddComnnent}>
                                    <FontAwesomeIcon icon={faCommentAlt} /> {this.props.store!.formatMessage('addComment')}
                                </Button>
                                <Button bsStyle='default' onClick={this.saveQA} disabled={this.props.store!.projectStore.isSavingQA}>
                                    <FontAwesomeIcon icon={faSave} /> {this.props.store!.formatMessage('save')}
                                </Button>
                            </ButtonToolbar>
                            <hr />
                            <MeshPropertiesTable />
                        </Col>
                        <Col xs={12} md={5} >
                            <ModelRendering />
                        </Col>
                        <Col xs={12} md={4} >
                            {this.props.store!.projectStore.showQADetail &&
                                <div>
                                    <QualityAssuranceDetail />
                                    {!this.props.store!.projectStore.currentQADetail.id &&
                                        <Button bsStyle='default' onClick={this.onSubmit}>
                                            <FontAwesomeIcon icon={faPaperPlane} /> {this.props.store!.formatMessage('submit')}
                                        </Button>
                                    }
                                </div>
                            }
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}
