import * as React from 'react'
import { Carousel, Lightbox, ICONS, Icon, styled } from '@paradigm/blueprints-common-frontend'
import ImageCarouselItemImage from './ImageCarouselItemImage'

const OpenModalIcon = styled(Icon)`
    fill: ${(props) => props.theme.colors.neutrals[6]};
    transition: all ${(props) => props.theme.transitions.duration.enteringScreen} ${(props) =>
	props.theme.transitions.easing.easeInOut};
`

const CarouselContainer = styled.div`
	display: flex;
	justify-content: center;
`

const ModalOverlay = styled.div<{ show: boolean }>`
    background-color: ${(props) => props.theme.colors.neutrals[2]};
    opacity: .5;
    z-index: 3;
    width: 100%;
    height: 97%;
    position: absolute;
    display: ${(props) => (props.show ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
`

const CarouselWrapper = styled(Carousel)`
    div:last-child {
        width: 89%;
    }
`

interface IImageCarouselState {
	slides: []
	showOpenModalOverlay: boolean
	showImageModal: boolean
	selectedItem: number
}

interface IImageCarouselProps {
	imageURLs: string[]
}

export default class ImageCarousel extends React.Component<IImageCarouselProps, IImageCarouselState> {
	constructor(props: Readonly<IImageCarouselProps>) {
		super(props)

		this.state = {
			slides: [],
			showOpenModalOverlay: false,
			showImageModal: false,
			selectedItem: 0
		}
	}

	public componentDidMount() {
		const baseChildren = (
			<div>
				{this.props.imageURLs.map((value, index) => (
					<ImageCarouselItemImage
						key={`${value}_${index}`}
						index={index}
						value={value}
						onImageHover={this.onImageHover}
					/>
				))}
			</div>
		);
		this.setState({ slides: baseChildren.props.children })
	}

	public onImageHover = (value: string, index: number) => {
		this.setState({ showOpenModalOverlay: true })
	}

	public onImageHoverOff = () => {
		this.setState({ showOpenModalOverlay: false })
	}

	public onOpenImageModal = () => {
		this.setState({ showImageModal: true })
	}

	public onCloseCallback = () => {
		this.setState({ showImageModal: false })
	}

	public onSelectItemHandler = (index: number, item: any) => {
		this.setState({ selectedItem: index })
	}

	public render() {
		return (
			<CarouselContainer onMouseLeave={this.onImageHoverOff}>
				<ModalOverlay onClick={this.onOpenImageModal} show={this.state.showOpenModalOverlay}>
					<OpenModalIcon size={90} icon={ICONS.LARGER_IMAGE} viewBox={'0 0 52 52'} />
				</ModalOverlay>
				<Carousel
					width={'85%'}
					imageWidth={'100%'}
					height={'100%'}
					imageHeight={'100%'}
					onClickItem={this.onSelectItemHandler}
				>
					{this.state.slides}
				</Carousel>
				<Lightbox
					height={'85%'}
					width={'90%'}
					closeCallback={this.onCloseCallback}
					show={this.state.showImageModal}
				>
						<CarouselWrapper
							width={'100%'}
							height={'97%'}
                            imageHeight={'95%'}
                            imageWidth={'95%'}
                            autoHeight={false}
							showThumbnails={true}
							selectedItem={this.state.selectedItem}
							showSelectedUnderline={true}
						>
							{this.state.slides}
						</CarouselWrapper>
				</Lightbox>
			</CarouselContainer>
		)
	}
}
