import { Button } from '@paradigm/blueprints-common-frontend'
import { Box, Flex } from '@rebass/grid'
import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router'
import { IStore } from '../../../App/AppStore'
import BlueprintsCarousel from '../../buildingModel/containers/BlueprintsCarousel'
import Container from '../../common/components/Layout/Container'
import Panel, { PanelActions, PanelContent, PanelHeader, PanelTitle } from '../../common/components/Panel'
import SpinLoader from '../../common/components/SpinLoader'
import EmptyMessage from '../../common/components/Typography/EmptyMessage'
import ProjectHeader from '../components/ProjectHeader'

// @ts-ignore
@withRouter
@inject('store')
@observer
export class ProjectHome extends React.Component<IStore> {
    private subscriptions: Array<ISubscriptionDefinition<any>> = []

    constructor(props: Readonly<IStore>) {
        super(props)
        const { match }: any = this.props
        this.props.store!.projectStore.getProject(match.params.id, false)
    }

    public componentWillUnmount() {
        document.body.style.overflow = 'initial'
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
    }

    @computed
    public get showLoader() {
        const { isLoadingModel } = this.props.store!.buildingModel
        const { isLoading } = this.props.store!.projectStore
        return isLoadingModel || isLoading
    }

    @computed
    public get showModelNotMappedMessage(): boolean {
        const { mapped } = this.props.store!.buildingModel

        if (!mapped) {
            document.body.style.overflow = 'hidden'
            return true
        } else {
            document.body.style.overflow = 'initial'
            return false
        }
    }

    public render() {
        const { currentProject } = this.props.store!.projectStore
        const basePath = `/project/${currentProject.id}`

        return (
            <div>
                <ProjectHeader />
                <SpinLoader isLoading={this.showLoader}>
                    <Container>
                        <Flex flexWrap='wrap'>
                            <Box my={30} width={[1, 1 / 2]}>
                                <Panel>
                                    <PanelHeader>
                                        <PanelTitle>
                                            <FormattedMessage id={'projectQuotes'} defaultMessage={'Project Quotes ({size}}'} values={{ size: 0 }} />
                                        </PanelTitle>
                                    </PanelHeader>
                                    <PanelActions>
                                        <Button disabled={true}>
                                            <FormattedMessage id={'newQuote'} defaultMessage={'New Quote'} />
                                        </Button>
                                    </PanelActions>
                                    <PanelContent>
                                        <EmptyMessage>
                                            <FormattedMessage id='projectEmptyQuoteList' values={{ name: currentProject.name }} />
                                        </EmptyMessage>
                                    </PanelContent>
                                </Panel>
                            </Box>
                            <Box mb={30} width={[1, 2 / 3]} pr={[0, 30]}>
                                {currentProject.lastRevision.buildingModelId && (
                                    <Panel>
                                        <PanelHeader>
                                            <PanelTitle>
                                                <FormattedMessage id={'projectOverview'} defaultMessage={'Project Overview'} />
                                            </PanelTitle>
                                        </PanelHeader>
                                        <PanelContent height={'100%'} minHeight={'230px'}>
                                            <BlueprintsCarousel modelId={currentProject.lastRevision.buildingModelId} />
                                        </PanelContent>
                                    </Panel>
                                )}
                            </Box>
                            <Box mb={30} width={[1, 1 / 3]}>
                                <Panel>
                                    <PanelHeader>
                                        <PanelTitle>
                                            <FormattedMessage id={'recentProjectActivity'} defaultMessage={'Recent Project Activity'} />
                                        </PanelTitle>
                                    </PanelHeader>
                                    <PanelContent>
                                        <EmptyMessage>
                                            <FormattedMessage id='projectEmptyActivityList' defaultMessage={'No Activity'} />
                                        </EmptyMessage>
                                    </PanelContent>
                                </Panel>
                            </Box>
                            <Box mb={30} width={[1]}>
                                <Panel>
                                    <PanelHeader>
                                        <PanelTitle>
                                            <FormattedMessage id={'projectFiles'} defaultMessage={'Project Files'} />
                                        </PanelTitle>
                                    </PanelHeader>
                                    <PanelContent height={'475px'}>
                                        <EmptyMessage>
                                            <FormattedMessage id='projectFilesInfo' defaultMessage={'Project Files Here? Gallery with area to upload new?'} />
                                        </EmptyMessage>
                                    </PanelContent>
                                </Panel>
                            </Box>
                        </Flex>
                    </Container>
                </SpinLoader>
            </div>
        )
    }
}
