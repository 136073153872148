
import { Icon, ICONS, styled } from '@paradigm/blueprints-common-frontend'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { rgba } from 'polished'
import React from 'react'
import { PannellumStore } from '../../../stores/PannellumStore'

@inject('pannellumStore')
@observer
class ToolBar extends React.Component<{ pannellumStore?: PannellumStore }> {
    public render() {
        const { pannellumStore } = this.props

        if (!pannellumStore) { return null }

        let helperText = '* Click on a section and then the image to add a dot.'
        if (pannellumStore.isMove) {
            helperText = '* Click on the image to move the house around.'
        }
        if (pannellumStore.isAddCameraScene) {
            helperText = '* Click on the image to place a scene camera hotspot.'
        }

        return (
            <Container>
                <RadioGroup isAddCameraScene={pannellumStore.isAddCameraScene}>
                    <input
                        type='checkbox'
                        id='Grid'
                        checked={!this.props.pannellumStore!.isGridVisible}
                        onChange={this.props.pannellumStore!.toggleGridVisiblity}
                    />
                    <label htmlFor='Grid' title={this.props.pannellumStore!.isGridVisible ? 'Hide Grid' : 'Show Grid'}>
                        <Icon size={20} icon={this.props.pannellumStore!.isGridVisible ? ICONS.GRID_ON: ICONS.GRID_OFF} />
                    </label>
                    <input
                        type='radio'
                        name='actions'
                        id='dot'
                        value='dot'
                        checked={!pannellumStore.isMove}
                        onChange={this.onToggleMoveClick}
                    />
                    <label htmlFor='dot' title={'Add or Move Dot'}>
                        <Icon size={20} icon={ICONS.CIRCLE_ADD} />
                    </label>
                    <input
                        type='radio'
                        name='actions'
                        id='move'
                        value='move'
                        checked={pannellumStore.isMove}
                        onChange={this.onToggleMoveClick}
                    />
                    <label htmlFor='move' title={'Move Camera'}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                            <path fill='none' d='M0 0h24v24H0V0z' />
                            <path d='M5.54 8.46L2 12l3.54 3.54 1.76-1.77L5.54 12l1.76-1.77zm6.46 10l-1.77-1.76-1.77 1.76L12 22l3.54-3.54-1.77-1.76zm6.46-10l-1.76 1.77L18.46 12l-1.76 1.77 1.76 1.77L22 12zm-10-2.92l1.77 1.76L12 5.54l1.77 1.76 1.77-1.76L12 2z' />
                            <circle cx='12' cy='12' r='3' />
                        </svg>
                    </label>
                </RadioGroup>
                <HelpText>{helperText}</HelpText>
            </Container>
        )
    }

    @action
    private onToggleMoveClick = () => {
        this.props.pannellumStore!.isMove = !this.props.pannellumStore!.isMove
    }
}

export default ToolBar

const RadioGroup = styled.div<{isAddCameraScene: boolean}>`
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    width: 100px;
    pointer-events: ${(props) => props.isAddCameraScene ? 'none' : 'auto'};

    input[type='checkbox'] + label {
        margin-right: 5px;
    }
    input[type='radio'], input[type='checkbox'] {
        position: absolute;
        left: -9999px;
    }
    input[type='radio'] + label, input[type='checkbox'] + label {
        width: 35px;
        height: 35px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: ${(props) => props.isAddCameraScene ? rgba('#202834', 0.2) : rgba('#202834', 0.9)};
        background-size: cover;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        opacity: ${(props) => props.isAddCameraScene ? 1 : 0.75};
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        cursor: pointer;
        svg {
            transition: all 0.3s;
            fill: ${(props) => props.theme.colors.common[0]};
        }
    }
    input[type='radio']:checked + label, input[type='checkbox']:checked + label {
        background-color: ${(props) => rgba('#202834', 0.2)};
        opacity: 1;

        &:hover {
            cursor: default;
        }
        svg {
            transform: rotate(45deg);
            fill: ${(props) => props.theme.colors.common[0]};
        }
    }
    input[type='radio'] + label:hover {
        opacity: 1;
        svg {
            transform: rotate(45deg);
        }
    }
    input[type='checkbox'] + label:hover {
        opacity: 1;
    }
    input[type='checkbox']:checked + label {
        svg {
            transform: none;
        }
    }
`

const HelpText = styled.div`
    color: ${(props) => props.theme.colors.neutrals[6]};
    font-size: 16px;
    padding-left: 10px;
    line-height: 43px;
`

const Container = styled.div`
    display: flex;
    align-self: center;
    width: 55vw;
    margin: 0 10px;
`
