export const ROOT = '/'
export const LOGIN = '/login'
export const PROFILE = '/profile'
export const PROJECT_ADD = '/project'
export const PROJECT_HOME = '/project/:id'
export const PROJECT_PAGE = '/project/:id/details'
export const QA = '/project/:id/revisions/:revisionId/qa/:qaId'
export const TAKEOFF_PROCESS = '/project/:id/process/:takeoffprocessId'
export const TAKEOFF_PROCESS_DETAILS = '/project/:id/process/:takeoffprocessId/details'
export const MAPPING_TOOL = '/project/:projectId/mappingtool'
export const PROCESS_ADD = '/project/:projectId/externalId/:externalId/process/add'
export const WIZARD = '/wizard/takeoff-process-creation/:projectId/'
export const CAMERA_TOOL = '/project/:projectId/alternatives/:alternativeId/revisions/:revisionId/cameratool'
export const DOT_TOOL = '/project/:projectId/alternatives/:alternativeId/revisions/:revisionId/builder/:builderId/client/:clientId/dottool'
export const PREVIEW = '/project/:projectId/alternatives/:alternativeId/revisions/:revisionId/preview'