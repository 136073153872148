import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { IStore } from '../../../../App/AppStore'
import { Button, styled } from '@paradigm/blueprints-common-frontend'
import { PROJECT_ADD } from '../../../../App/routes'

const ProjectListNavBar = styled.div`
    margin-bottom: 2%;
    width: 100%;
    background-color: ${props => props.theme.colors.common[0]};
    height: 100px;
    display: flex;
    align-items: center;

    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
`

const Header = styled.div`
    width: 300px;
    font-size: 32px;
    color: ${props => props.theme.colors.neutrals[7]};
    font-weight: 600;
    letter-spacing: 5px;
    flex: 1 auto;
    transition: color 0.5s ease-in-out;

    @media all and (max-width: 800px) {
        font-size: 24px;
    }
`

const CreateProjectButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
        height: 43px;
        background-color: ${props => props.theme.colors.common[0]};
        font-size: 16px;
        color: ${props => props.theme.colors.neutrals[5]};
        line-height: 18px;
        letter-spacing: 2px;
        border-width: 2px;
        border-style: solid;
        border-color: ${props => props.theme.colors.neutrals[5]};
        white-space: nowrap;

        &:hover {
            transform: none;
        }

        &:hover:after {
            background-color: ${props => props.theme.colors.neutrals[1]};
            opacity: .2;
        }
    }
`

// @ts-ignore
@withRouter
@inject('store')
@observer
export default class ProjectListHeader extends React.Component<IStore> {
    public render() {
        return (
            <ProjectListNavBar>
                <Header>{this.props.store!.formatMessage('myProjects').toUpperCase()}</Header>
                <CreateProjectButtonContainer>
                    <Link to={PROJECT_ADD} >
                        <Button>
                            {this.props.store!.formatMessage('createProject')}
                        </Button>
                    </Link>
                </CreateProjectButtonContainer>
            </ProjectListNavBar>
        )
    }
}
