import { Button, Icon, ICONS, styled, TextField } from '@paradigm/blueprints-common-frontend'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { ModelStore } from '../../../stores/modelStore'
import { PannellumStore } from '../../../stores/PannellumStore'

interface ICameraDataToolbarProps {
    pannellumStore?: PannellumStore
    modelStore?: ModelStore
}

interface IMeasurements {
    measurement: string
    degree: number
}

@inject('pannellumStore', 'modelStore')
@observer
export class CameraDataToolbar extends React.Component<ICameraDataToolbarProps> {
    @observable private upButton: IMeasurements = { measurement: '', degree: 0 }
    @observable private leftButton: IMeasurements = { measurement: '', degree: 0 }
    @observable private downButton: IMeasurements = { measurement: '', degree: 0 }
    @observable private rightButton: IMeasurements = { measurement: '', degree: 0 }

    public render() {
        const { currentCamera } = this.props.pannellumStore!

        if (!currentCamera) {
            return null
        }

        const isShow = currentCamera ? true : false

        this.getDirection(currentCamera.yawAngle)

        return (
            <CameraListContainer isShow={isShow}>
                <CameraDataInputContainer>
                    <InputColumn>
                        <InputRow>
                            <InputContainer>
                                <Label>X</Label>
                                <input
                                    name={'x'}
                                    type={'text'}
                                    value={currentCamera.cameraCoordinates.x}
                                    onChange={this.handleOnCameraCoordsChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraCoordsBlur.bind(this)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>Y</Label>
                                <input
                                    name={'y'}
                                    type={'text'}
                                    value={currentCamera.cameraCoordinates.y}
                                    onChange={this.handleOnCameraCoordsChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraCoordsBlur.bind(this)}
                                />
                            </InputContainer>
                        </InputRow>
                        <InputRow>
                            <InputContainer>
                                <Label>Z</Label>
                                <input
                                    name={'z'}
                                    type={'text'}
                                    value={currentCamera.cameraCoordinates.z}
                                    onChange={this.handleOnCameraCoordsChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraCoordsBlur.bind(this)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>Yaw Angle</Label>
                                <input
                                    name={'yawAngle'}
                                    type={'text'}
                                    value={currentCamera.yawAngle}
                                    onChange={this.handleOnCameraChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraBlur.bind(this)}
                                />
                            </InputContainer>
                        </InputRow>
                        <InputRow>
                            <InputContainer>
                                <Label>Pitch Angle</Label>
                                <input
                                    name={'pitchAngle'}
                                    type={'text'}
                                    value={currentCamera.pitchAngle}
                                    onChange={this.handleOnCameraChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraBlur.bind(this)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>FOV</Label>
                                <input
                                    name={'fov'}
                                    type={'text'}
                                    value={currentCamera.fov}
                                    onChange={this.handleOnCameraChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraBlur.bind(this)}
                                />
                            </InputContainer>
                        </InputRow>

                        <InputRow>
                            <InputContainer>
                                <Label>Min Zoon FOV</Label>
                                <input
                                    name={'minHFov'}
                                    type={'text'}
                                    value={currentCamera.minHFov}
                                    onChange={this.handleOnCameraChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraBlur.bind(this)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>Max Zoon FOV</Label>
                                <input
                                    name={'maxHFov'}
                                    type={'text'}
                                    value={currentCamera.maxHFov}
                                    onChange={this.handleOnCameraChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraBlur.bind(this)}
                                />
                            </InputContainer>
                        </InputRow>
                        <InputRow>
                            <InputContainer>
                                <Label>Min Pitch</Label>
                                <input
                                    name={'minPitch'}
                                    type={'text'}
                                    value={currentCamera.minPitch}
                                    onChange={this.handleOnCameraChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraBlur.bind(this)}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>Max Pitch</Label>
                                <input
                                    name={'maxPitch'}
                                    type={'text'}
                                    value={currentCamera.maxPitch}
                                    onChange={this.handleOnCameraChange.bind(this)}
                                    onFocus={this.focus.bind(this)}
                                    onBlur={this.handleOnCameraBlur.bind(this)}
                                />
                            </InputContainer>
                        </InputRow>
                        <InputRow>
                            <NameInput
                                name={'cameraName'}
                                label={'Name'}
                                type={'text'}
                                value={currentCamera.cameraName}
                                onChange={this.handleOnCameraNameChange.bind(this)}
                            />
                        </InputRow>
                        <NavigationContainer>
                            <TiltingContainer>
                                <TiltUpButton
                                    title={'Pitch Up'}
                                    onClick={this.updateCameraMeasurementPosition.bind(this, 'pitchAngle', 5)}
                                >
                                    <Icon icon={ICONS.UP_ARROW} size={40} />
                                </TiltUpButton>
                                <TiltDownButton
                                    title={'Pitch Down'}
                                    onClick={this.updateCameraMeasurementPosition.bind(this, 'pitchAngle', -5)}
                                >
                                    <Icon icon={ICONS.DOWN_ARROW} size={40} />
                                </TiltDownButton>
                            </TiltingContainer>

                            <MoveCameraContainer>
                                <TwoRowContainer>
                                    <MoveCameraButton
                                        title={'Yaw'}
                                        onClick={this.updateCameraMeasurementPosition.bind(this, 'yawAngle', -5)}
                                    >
                                        <Icon icon={ICONS.ROTATE_RIGHT} size={30} />
                                    </MoveCameraButton>
                                    <MoveCameraButton
                                        title={`Move ${this.leftButton.degree} on ${this.leftButton.measurement} Angle`}
                                        onClick={this.updateCameraCoordsPosition.bind(this, this.leftButton.measurement, this.leftButton.degree)}
                                    >
                                        <Icon icon={ICONS.CHEVRON_LEFT} size={40} />
                                    </MoveCameraButton>
                                </TwoRowContainer>
                                <TwoRowContainer>
                                    <MoveCameraButton
                                        title={`Move ${this.upButton.degree} on ${this.upButton.measurement} Angle`}
                                        onClick={this.updateCameraCoordsPosition.bind(this, this.upButton.measurement, this.upButton.degree)}
                                    >
                                        <Icon icon={ICONS.UP_ARROW} size={40} />
                                    </MoveCameraButton>
                                    <MoveCameraButton
                                        title={`Move ${this.downButton.degree} on ${this.downButton.measurement} Angle`}
                                        onClick={this.updateCameraCoordsPosition.bind(this, this.downButton.measurement, this.downButton.degree)}
                                    >
                                        <Icon icon={ICONS.DOWN_ARROW} size={40} />
                                    </MoveCameraButton>
                                </TwoRowContainer>
                                <TwoRowContainer>
                                    <MoveCameraButton
                                        title={'Yaw'}
                                        onClick={this.updateCameraMeasurementPosition.bind(this, 'yawAngle', 5)}
                                    >
                                        <Icon icon={ICONS.ROTATE_LEFT} size={30} />
                                    </MoveCameraButton>
                                    <MoveCameraButton
                                        title={`Move ${this.rightButton.degree} on ${this.rightButton.measurement} Angle`}
                                        onClick={this.updateCameraCoordsPosition.bind(this, this.rightButton.measurement, this.rightButton.degree)}
                                    >
                                        <Icon icon={ICONS.CHEVRON_RIGHT} size={40} />
                                    </MoveCameraButton>
                                </TwoRowContainer>
                            </MoveCameraContainer>
                        </NavigationContainer>

                        <InputRow>
                            <ApplyButton disabled={false} onClick={this.props.pannellumStore!.applyCameraUpdates}>
                                <div>
                                    <FormattedMessage id={'Apply'} />
                                </div>
                            </ApplyButton>
                            <SaveButton onClick={this.saveCameraHandler.bind(this)}>
                                <Icon icon={ICONS.SAVE} size={20} />
                                <div>
                                    <FormattedMessage id={'save'} />
                                </div>
                            </SaveButton>
                        </InputRow>
                    </InputColumn>
                </CameraDataInputContainer>
            </CameraListContainer>
        )
    }

    @action
    private getDirection = (angle: number) => {
        switch (true) {
            case (angle >= 45 && angle < 135): // Facing right of house
                this.upButton.measurement = 'x'
                this.upButton.degree = 5
                this.leftButton.measurement = 'z'
                this.leftButton.degree = 5
                this.downButton.measurement = 'x'
                this.downButton.degree = -5
                this.rightButton.measurement = 'z'
                this.rightButton.degree = -5
                break
            case (angle >= 135 && angle < 225): // Facing front of house
                this.upButton.measurement = 'z'
                this.upButton.degree = -5
                this.leftButton.measurement = 'x'
                this.leftButton.degree = 5
                this.downButton.measurement = 'z'
                this.downButton.degree = 5
                this.rightButton.measurement = 'x'
                this.rightButton.degree = -5
                break
            case (angle >= 225 && angle < 315): // Facing left of house
                this.upButton.measurement = 'x'
                this.upButton.degree = -5
                this.leftButton.measurement = 'z'
                this.leftButton.degree = -5
                this.downButton.measurement = 'x'
                this.downButton.degree = 5
                this.rightButton.measurement = 'z'
                this.rightButton.degree = 5
                break
            default: // Facing back of house
                this.upButton.measurement = 'z'
                this.upButton.degree = 5
                this.leftButton.measurement = 'x'
                this.leftButton.degree = -5
                this.downButton.measurement = 'z'
                this.downButton.degree = -5
                this.rightButton.measurement = 'x'
                this.rightButton.degree = 5
        }
    }

    private focus = (event: any) => {
        event.target.select()
    }

    @action
    private updateCameraMeasurementPosition = (measurement: string, degree: number) => {
        const { currentCamera } = this.props.pannellumStore!

        if (currentCamera) {
            currentCamera[measurement] += degree
        }

        this.props.pannellumStore!.applyCameraUpdates()
    }

    @action
    private updateCameraCoordsPosition = (coord: string, degree: number) => {
        const { currentCamera } = this.props.pannellumStore!

        if (currentCamera) {
            const coordValue = currentCamera.cameraCoordinates[coord]
            currentCamera.cameraCoordinates[coord] = parseFloat(coordValue) + degree
        }

        this.props.pannellumStore!.applyCameraUpdates()
    }

    @action
    private async saveCameraHandler() {
        const { builderDotToolData, modelId, builderId } = this.props.pannellumStore!

        // Filter out any blank names.
        const filteredbuilderDotToolData = Object.keys(builderDotToolData).reduce((obj, key) => {
            if (
                builderDotToolData[key].cameraId !== null &&
                builderDotToolData[key].cameraName !== null &&
                builderDotToolData[key].cameraName.length > 0
            ) {
                obj[key] = builderDotToolData[key]
            }
            return obj
        }, {})

        if (builderDotToolData && modelId && builderId) {
            await this.props.pannellumStore!.saveDotToolData(filteredbuilderDotToolData)
        }
    }

    @action
    private handleOnCameraCoordsChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement
        const { currentCamera } = this.props.pannellumStore!

        target.value = target.value.replace(/\s/g, '').replace(/[^\d.-]/g, '')

        if (currentCamera) {
            if (target.value !== '-' && target.value.length > 0 && target.value[target.value.length - 1] !== '.') {
                currentCamera.cameraCoordinates[target.name] = parseFloat(target.value)
            } else {
                currentCamera.cameraCoordinates[target.name] = target.value
            }
        }
    }

    @action
    private handleOnCameraCoordsBlur(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement
        const { currentCamera } = this.props.pannellumStore!
        target.value = target.value.replace(/\s/g, '').replace(/[^\d.-]/g, '')

        if (currentCamera) {
            currentCamera.cameraCoordinates[target.name] = parseFloat(target.value)

            if (Number.isNaN(currentCamera.cameraCoordinates[target.name])) {
                currentCamera.cameraCoordinates[target.name] = 0
            }
        }
    }

    @action
    private handleOnCameraChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement
        const { currentCamera } = this.props.pannellumStore!

        target.value = target.value.replace(/\s/g, '').replace(/[^\d.-]/g, '')

        if (currentCamera) {
            if (target.value !== '-' && target.value.length > 0 && target.value[target.value.length - 1] !== '.') {
                currentCamera[target.name] = parseFloat(target.value)
            } else {
                currentCamera[target.name] = target.value
            }
        }
    }

    @action
    private handleOnCameraBlur(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement
        const { currentCamera } = this.props.pannellumStore!
        target.value = target.value.replace(/\s/g, '').replace(/[^\d.-]/g, '')

        if (currentCamera) {
            currentCamera[target.name] = parseFloat(target.value)

            if (Number.isNaN(currentCamera[target.name])) {
                currentCamera[target.name] = 0
            }
        }
    }

    @action
    private handleOnCameraNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement
        const name = target.value
        const { currentCamera, cameras, builderDotToolData } = this.props.pannellumStore!

        const isValid = name.trim() !== '' && currentCamera !== undefined && builderDotToolData !== undefined

        if (isValid) {
            const cameraIndex = cameras.findIndex((c) => c.cameraName === currentCamera!.cameraName)
            if (cameraIndex !== -1) {
                delete builderDotToolData[currentCamera!.cameraName]
                cameras[cameraIndex].cameraName = name
                currentCamera![target.name] = name
                builderDotToolData[name] = currentCamera!
            }
        }
    }
}

const CameraListContainer =
    styled.div <
        { isShow: boolean } >
        `
    display: flex;
    flex-direction: row;
    margin: 0px 10px 0px;
    padding: 0px 10px 0px;
    justify-content: center;
    visibility: ${(props) => (props.isShow ? 'visible' : 'hidden')};
`

const NavigationContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px;
	justify-content: center;

	svg {
		cursor: pointer;
	}
`

const TiltingContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const TiltUpButton = styled.div`
	display: flex;
	align-items: center;
	background-color: black;
	margin: 5px 15px 0px 0px;
	width: 40px;
	height: 40px;

	svg {
		fill: ${(props) => props.theme.colors.common[0]};
	}
`

const TiltDownButton = styled.div`
	display: flex;
	align-items: center;
	background-color: black;
	margin: 5px 15px 0px 0px;
	width: 40px;
	height: 40px;
	justify-content: center;

	div {
		font-size: 16px;
		padding-left: 5px;
	}
	svg {
		fill: ${(props) => props.theme.colors.common[0]};
	}
`

const TwoRowContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const MoveCameraContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const MoveCameraButton = styled.div`
	display: flex;
	align-items: center;
	background-color: black;
	margin: 5px 5px 0px 0px;
	width: 40px;
	height: 40px;
	justify-content: center;

	svg {
		fill: ${(props) => props.theme.colors.common[0]};
	}
`

const CameraDataInputContainer = styled.div`
	display: flex;

	padding: 10px;
	border: 1px solid grey;
	border-radius: 3px;

    height: 100%;
    width: 100%;
    background-color: #f7f7f7;
    flex-direction: column;
    z-index: 1;
    overflow-x: hidden;
`

const InputRow = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 10px;

	> div {
		padding: 2.5px 5px;
		width: 100%;
	}
	input {
		width: 100%;
		padding-right: 10px;
		height: 28px;
	}
`

const InputColumn = styled.div`
	display: flex;
	flex-direction: column;
`

const SaveButton = styled(Button)`
    display: flex;
    align-items: center;
    height: 40px;
    padding-top: 10px;
    margin-top: 20px;
    justify-content: center;

    div {
        font-size: 16px;
        padding-left: 5px;
    }
    svg {
        fill: ${(props) => props.theme.colors.common[0]};
    }
`

const ApplyButton = styled(Button)`
    display: flex;
    align-items: center;
    height: 40px;
    /* width: 200px; */
    padding-top: 10px;
    margin-top: 20px;
    justify-content: center;
    background-color: #202834;

    &:hover {
        background-color: #202834;
    }
    div {
        font-size: 16px;
        padding-left: 5px;
    }
`

const Label = styled.div`
	color: #8a8a8a;
	font-size: 12px;
`

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;

	input {
		width: 100%;
		padding-left: 10px;
		height: 28px;
		font-size: 16px;
		color: #8a8a8a;
	}
`

const NameInput = styled(TextField)`
    width: 100%;
    padding: 0 5px;
`
