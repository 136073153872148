import { styled } from '@paradigm/blueprints-common-frontend'
import { rgba } from 'polished'

const PannellumViewerContainer = styled.div<{ isMove: boolean }>`
    flex: 2 auto;
    align-self: center;
    width: 55vw;
    height: 100%;
    position: relative;

    .alert {
        padding: 5px;
    }

    .pnlm-grab {
        cursor: ${(props) => !props.isMove && 'crosshair'};
    }

    .sceneDot {
        position: absolute;
        top: 0;
        left: 0;
        width: 55px;
        height: 55px;
        background: ${(props) => rgba(props.theme.colors.common[0], 0.6)};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }
    .sceneDot:hover {
        transform: scale(1.1);
    }

    .custom-scene-dot {
        background: ${(props) => rgba(props.theme.colors.common[0], 0.6)};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        content: url(street-view.svg);
        padding: 15px;
    }
    .custom-scene-dot:hover {
        transform: scale(1.1);
    }

    .custom-hotspot {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 15px;
        height: 15px;
        opacity: 0.85;
        border: 2px solid #999999;
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.common[0]};
        background-size: cover;
        box-shadow: 0 0 8px 3px rgba(255, 255, 255, 0.6), 0 0 3px 2px rgba(255, 255, 255, 0.6);
        transition: opacity 0.3s;
    }
    .custom-hotspot:hover {
        opacity: 1;
    }
    div.custom-tooltip span {
        opacity: 0;
        position: absolute;
        border-radius: 2px;
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.text.contrast};
        text-align: center;
        max-width: 200px;
        padding: 5px 10px;
        margin-left: -220px;
        bottom: -40px;
        cursor: default;
        transition: opacity 0.25s;
        font-size: 14px;
        font-family: ${(props) => props.theme.font.family};
        text-decoration: none solid rgb(255, 255, 255);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    }
    div.custom-tooltip:hover span {
        opacity: 1;
    }
    div.custom-tooltip span:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent ${(props) => props.theme.colors.primary} transparent;
    }

    .selectedInfoDot {
        border: 2px solid ${(props) => props.theme.colors.primary};
    }

    .sectionDot {
        &#textInfo {
            visibility: visible;

            .hotspot .in {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 24px;
                height: 24px;
                opacity: 0.85;
                border: 2px solid #999999;
                background-color: ${(props) => props.theme.colors.common[0]};
                background-size: cover;
                box-shadow: 0px 0px 8px 3px rgba(255, 255, 255, 0.6);
            }
        }
    }
`

export default PannellumViewerContainer
