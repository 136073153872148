import * as queryString from 'query-string'
import { TemplateProjectInput } from '../builderProject/models/TemplateProjectInput'
import * as transport from '../common/service/transport'
import { Alternative } from './models/Alternative'
import { AlternativeInput } from './models/AlternativeInput'
import { DesignOption } from './models/DesignOption'
import { Project } from './models/Project'
import { RevisionInput } from './models/RevisionInput'
import { AssetBundle } from './models/AssetBundle'
import { AxiosPromise } from 'axios'
import { IBuilderAssetPannellumConfig } from './containers/DotTool/models/IBuilderAssetPannellumConfig'
import { IBuilderAssetDotToolData } from './containers/DotTool/models/IBuilderAssetDotToolData'

const PROJECT_API = '/project/api'

export const projectService = {
    create: (project: Project) => {
        return transport.post(`${PROJECT_API}/projects`, project)
    },

    update: (project: Project) =>
        transport.put(`${PROJECT_API}/projects/${project.id}`, project),

    delete: (projectId: string) =>
        transport.del(`${PROJECT_API}/projects/${projectId}`),

    getAll: ({ pageNumber, pageSize, search, sort, order, assigneeFilter }: any) => {
        const queryParameters = queryString.stringify({
            assignee: assigneeFilter,
            order,
            pageNumber,
            pageSize,
            search,
            sort
        })
        return transport.get(`${PROJECT_API}/projects?${queryParameters}`)
    },

    getById: (id: string) =>
        transport.get(`${PROJECT_API}/projects/${id}?details=true`),

    addAlternative: (id: string, alternative: Alternative) =>
        transport.post(`${PROJECT_API}/projects/${id}/alternatives`, alternative),

    getPreviewImage: (id: string, type: string) => {
        return `${PROJECT_API}/image?projectId=${id}&fileFunction=${type}`
    },

    assign: (id: string, assigneeUserId: string) =>
        transport.put(`${PROJECT_API}/projects/${id}/assign/${assigneeUserId}`, null),

    publish: (projectId: string, templateProject: TemplateProjectInput) =>
        transport.post(`${PROJECT_API}/projects/${projectId}/publish`, templateProject),
    
    fetchPublishingData: (projectId: string) =>
        transport.get(`${PROJECT_API}/projects/${projectId}/publishedTemplates`)
}

export const revisionService = {
    saveFileUpload: (revisionId: string, revisionFile: any) => {
        const formData = new FormData()
        formData.append('file', revisionFile.data)
        revisionFile.data = null
        formData.append('jsonData', JSON.stringify(revisionFile))
        revisionFile.data = formData.get('file')

        const multiPartConfig = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return transport.post(`${PROJECT_API}/revisions/${revisionId}/upload`, formData, multiPartConfig)
    },

    getFiles: (id: string) => {
        return transport.get(`${PROJECT_API}/revisions/${id}/files`)
    },

    deleteRevisionFile: (id: string, revisionFileId: string) => {
        return transport.del(`${PROJECT_API}/revisions/${id}/files/${revisionFileId}`)
    },

    getDesignOptions: (id: string) => {
        return transport.get(`${PROJECT_API}/revisions/${id}/designoptions`)
    },

    getQaList: (id: string) => {
        return transport.get(`${PROJECT_API}/revisions/${id}/qualityAssurances`)
    },

    deleteQA: (id: string, qualityAssuranceId: string) => {
        return transport.del(`${PROJECT_API}/revisions/${id}/qualityAssurances/${qualityAssuranceId}`)
    },

    addQA: (id: string) =>
        transport.post(`${PROJECT_API}/revisions/${id}/qualityAssurances`, null),

    saveCamerasData: (id: string, revisionInput: RevisionInput) => {
        return transport.put(`${PROJECT_API}/revisions/${id}/camerasData`, revisionInput)
    }
}

export const qaService = {
    getById: (id: string) =>
        transport.get(`${PROJECT_API}/qualityAssurances/${id}`),

    addQADetail: (id: string, detail: any) =>
        transport.post(`${PROJECT_API}/qualityAssurances/${id}/details`, detail),

    update: (qa: any) =>
        transport.put(`${PROJECT_API}/qualityAssurances/${qa.id}`, qa),

    updateDetail: (qaDetail: any) =>
        transport.put(`${PROJECT_API}/qualityAssurances/details`, qaDetail)
}

export const statusService = {
    getQualityAssuranceStatuses: () =>
        transport.get(`${PROJECT_API}/statuses/qualityAssurance`),

    getQualityAssuranceDetailStatuses: () =>
        transport.get(`${PROJECT_API}/statuses/qualityAssuranceDetail`)

}

export const designOptionService = {
    update: (designOption: DesignOption) =>
        transport.put(`${PROJECT_API}/designoptions/${designOption.id}`, designOption),

    getFiles: (id: string) => {
        return transport.get(`${PROJECT_API}/designoptions/${id}/files`)
    },
}

export const alternativeService = {
    add: (alternativeInput: AlternativeInput, image: File) => {
        const formData = new FormData()
        if (image) {
            formData.append('file', image)
        }
        formData.append('jsonData', JSON.stringify(alternativeInput))

        const multiPartConfig = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return transport.post(`${PROJECT_API}/alternatives`, formData, multiPartConfig)
    },

    update: (alternativeId: string, alternativeInput: AlternativeInput, image?: File) => {
        const formData = new FormData()
        if (image) {
            formData.append('file', image)
        }
        formData.append('jsonData', JSON.stringify(alternativeInput))

        const multiPartConfig = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return transport.put(`${PROJECT_API}/alternatives/${alternativeId}`, formData, multiPartConfig)
    },

    delete: (alternativeId: string) =>
        transport.del(`${PROJECT_API}/alternatives/${alternativeId}`),

    removeAssetBundle: (alternativeId: string, assetBundleId: string) => {
        return transport.del(`${PROJECT_API}/alternatives/${alternativeId}/assetBundles/${assetBundleId}`)
    },

    addAssetBundle: (alternativeId: string, assetBundle: AssetBundle) => {
        return transport.post(`${PROJECT_API}/alternatives/${alternativeId}/assetBundles`, assetBundle)
    },

    updateAssetBundle: (alternativeId: string, assetBundle: AssetBundle) => {
        return transport.put(`${PROJECT_API}/alternatives/${alternativeId}/assetBundles/${assetBundle.id}`, assetBundle)
    },

    saveAssetBundleDotToolData: (alternativeId: string, input: IBuilderAssetDotToolData): AxiosPromise<Alternative> => {
        return transport.post(
            `${PROJECT_API}/alternatives/${alternativeId}/assetBundleDotToolData/save`,
            input,
            Alternative
        );
    },

    saveAssetBundlePannellumConfig: (
        alternativeId: string,
        input: IBuilderAssetPannellumConfig
    ): AxiosPromise<Alternative> => {
        return transport.post(
            `${PROJECT_API}/alternatives/${alternativeId}/assetBundlePannellumConfig/save`,
            input,
            Alternative
        );
    },
}

export const countryService = {
    findAll: () => {
        return transport.get(`${PROJECT_API}/countries`)
    },
    findACountryStates: (countryId: string) => {
        return transport.get(`${PROJECT_API}/countries/${countryId}/states`)
    },
}

export const builderService = {
    findAll: () => {
        return transport.get(`${PROJECT_API}/builders`)
    },
}

export const assetBundleLabelsService = {
    findAll: () => {
        return transport.get(`${PROJECT_API}/assetbundlelabels`)
    }
}
