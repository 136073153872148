import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Table } from 'react-bootstrap'
import { IStore } from '../../../App/AppStore'


@inject('store')
@observer
export class MeshPropertiesTable extends React.Component<IStore> {
    public render() {
        if (this.props.store!.buildingModel.metaData) {
            return (
                <div>
                    <p><strong>{this.props.store!.buildingModel.metaData!.parentName}</strong></p>
                    <div className='table-wrapper-2' style={{ maxHeight: 450 }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>{this.props.store!.formatMessage('name')}</th>
                                    <th>{this.props.store!.formatMessage('value')}</th>
                                    <th>{this.props.store!.formatMessage('unit')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.store!.buildingModel.meshProperties.map((property, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{property.name}</td>
                                            <td>{property.value}</td>
                                            <td>{property.unit}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            )
        } else {
            return ''
        }
    }
}
