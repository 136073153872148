import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { IStore } from '../../../../App/AppStore'
import { PrimaryButton, styled } from '@paradigm/blueprints-common-frontend'
import { Link } from 'react-router-dom'
import { PROJECT_ADD } from '../../../../App/routes'

const BannerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    img {
        height: 371px;
        width: 100%;
        object-fit: cover;
        filter: brightness(60%);
    }
`

const ContentContainer = styled.div`
    left: 0%;
    right: 0%;
    text-align: center;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    span {
        font-size: 36px;
        color: ${props => props.theme.colors.common[0]};
        font-weight: 300;
        padding: 10px;
    }

    button {
        height: 56px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 20px;
        line-height: 20px;
        font-weight: 300;
    }
`

interface IBannerProps extends IStore {
    content: string
    buttonContent: string
    image: any
    imageAlt: string
    isDisabled: boolean
    buttonRef: string
}

@inject('store')
@observer
class Banner extends React.Component<IBannerProps> {
    public render() {
        return (
            <BannerWrapper>
                <img src={this.props.image} alt={this.props.imageAlt} />
                <ContentContainer>
                    <span>{this.props.content}</span>
                    <div>
                        <Link to={PROJECT_ADD}>
                            <PrimaryButton>{this.props.buttonContent}</PrimaryButton>
                        </Link>
                    </div>
                </ContentContainer>
            </BannerWrapper>
        )
    }
}

export default Banner
