import { observable } from "mobx"

export class TemplateProjectInput {
    @observable public builderId: string
    @observable public internalName: string
    @observable public name: string
    @observable public clientId: string | null

    public validate(): boolean {
        if (this.name && this.name.trim() && this.internalName && this.internalName.trim() && this.builderId && this.clientId) {
            return true
        }

        return false
    }
}
