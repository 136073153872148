import { computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { IStore } from '../../../App/AppStore'


@inject('store')
@observer
export class ProjectName extends React.Component<IStore> {
    @computed get name() {
        return this.props.store!.projectStore.currentProject.name
    }

    public render() {
        return (
            <React.Fragment>
                {this.name}
            </React.Fragment>
        )
    }
}
