import { observable } from 'mobx'
import { RevisionFile } from './RevisionFile'

export class DesignOption {
    public id: string
    public createdDate: number
    public createdBy: string
    public modifiedDate: number
    public deletedDate: number
    public version: number
    @observable public name: string = ''
    @observable public groupName: string = ''
    @observable public files: RevisionFile[] = []
    @observable public buildingModelId: string
}
