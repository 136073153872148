import { computed, observable } from 'mobx'
import { DesignOption } from './DesignOption'
import { QualityAssurance } from './QualityAssurance'
import { RevisionFile } from './RevisionFile'

export class Revision {
    @observable public id: string
    @observable public revisionNumber: number
    @observable public createdDate: number
    @observable public revisionFiles: RevisionFile[] = []
    @observable public qualityAssurances: QualityAssurance[] = []
    @observable public options: DesignOption[] = []
    @observable public selectdOption: DesignOption = new DesignOption()
    @observable public buildingModelId: string
    @observable public camerasData: string
    @observable public dotData: string

    @computed get formatedCreatedDate(): string {
        if (this.createdDate !== undefined) {
            return new Date(this.createdDate).toLocaleDateString()
        }
        return ''
    }
}
