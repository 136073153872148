import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject } from 'mobx-react'
import * as React from 'react'
import Loader from 'react-loader-advanced'
import { IStore } from '../../../../App/AppStore'
import './SpinLoader.css'

interface ISpinLoaderProps extends IStore {
    isLoading: boolean
    children?: React.ReactNode
    message?: string
}
@inject('store')
export default class SpinLoader extends React.Component<ISpinLoaderProps> {
    private static LOADER_MESSAGE_STYLE = {
        display: 'block',
        padding: '25% 0'
    }

    private static LOADER_BACKGROUND_STYLE = {
        backgroundColor: 'white'
    }

    private static LOADER_SPINNER_COLOR: string = '#1C66D1'

    public render() {
        const { message, isLoading, children } = this.props

        let displayMessage: string

        if (message === undefined) {
            displayMessage = this.props.store!.formatMessage('loading', 'Loading...')
        } else {
            displayMessage = message
        }

        const spinner =
            <div className={'spinner-wrapper'}>
                <FontAwesomeIcon icon={['fal', 'spinner']} size='5x' color={SpinLoader.LOADER_SPINNER_COLOR} spin={true} />
                <h2 className={'spinner-message'}>{displayMessage}</h2>
            </div>

        return (
            <div className='SpinLoader'>
                <Loader
                    messageStyle={SpinLoader.LOADER_MESSAGE_STYLE}
                    show={isLoading}
                    hideContentOnLoad={true}
                    message={spinner}
                    backgroundStyle={SpinLoader.LOADER_BACKGROUND_STYLE}
                >
                    {children}
                </Loader>
            </div>
        )
    }
}

