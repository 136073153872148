
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'gsap/ScrollToPlugin'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import SpinLoader from '../../../common/components/SpinLoader'
import { IStore } from '../../../../App/AppStore'
import { FloorOption } from '../../models/FloorOption'
import './ModelRendering.css'

/** Need to be remove later */
@inject('store')
@observer
class ModelRendering extends React.Component<IStore> {
    public myRef: any

    constructor(props: Readonly<IStore>) {
        super(props)
        this.swipeRight = this.swipeRight.bind(this)
        this.swipeLeft = this.swipeLeft.bind(this)
        this.onNavigationClick = this.onNavigationClick.bind(this)
        this.myRef = React.createRef()
    }

    public componentDidMount() {
        this.props.store!.buildingModel.createScene()
    }

    public componentWillUnmount() {
        this.props.store!.buildingModel.resetVariables()
    }

    public swipeRight() {
        if (this.props.store!.buildingModel.show3DView) {
            const nextKey = this.props.store!.buildingModel.activeKey + 1

            if (nextKey < this.props.store!.buildingModel.floors.length) {
                this.props.store!.buildingModel.setActiveKey(nextKey, this.props.store!.buildingModel.floors[nextKey].id)
            }
        }
    }

    public swipeLeft() {
        if (this.props.store!.buildingModel.show3DView) {
            const prevKey = this.props.store!.buildingModel.activeKey - 1

            if (prevKey >= 0) {
                this.props.store!.buildingModel.setActiveKey(prevKey, this.props.store!.buildingModel.floors[prevKey].id)
            }
        }
    }

    public onNavigationClick(activeKey: number, floorId: string) {
        if (this.props.store!.buildingModel.show3DView) {
            if (activeKey !== this.props.store!.buildingModel.activeKey) {
                this.props.store!.buildingModel.setActiveKey(activeKey, floorId)
            }
        }
    }

    public render() {
        return (
            <div className='model-rendering'>
                <nav className='horizontal-nav-container' ref={this.myRef}>
                    <div className='horizontal-nav-Contents'>
                        {this.props.store!.buildingModel.floors.map((floor, i) =>
                            <h4
                                className={floor.active ? 'active-nav' : ''}
                                key={floor.id}
                                id={'a_' + floor.id}
                                onClick={this.onNavigationClick.bind(this, i, floor.id)}
                            >
                                { FloorOption.formatLabel(floor.label, this.props.store!) }
                            </h4>
                        )}
                    </div>
                </nav>

                <button className='left-button' onClick={this.swipeLeft}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>

                <button className='right-button' onClick={this.swipeRight}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
                <SpinLoader isLoading={this.props.store!.buildingModel.isLoadingScene}>
                    <div id='canvasParent'>
                        <canvas id='rendering-canvas' />
                    </div>
                </SpinLoader>
            </div>
        )
    }
}

export default ModelRendering
