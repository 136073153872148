export enum FileFunction {
    SOURCE = 'SOURCE',
    CONVERTED_3D = 'CONVERTED_3D',
    CONVERTED_IFC = 'CONVERTED_IFC',
    PREVIEW = 'PREVIEW',
    BLUEPRINT_IMAGES = 'BLUEPRINT_IMAGES'
}

export enum FileExtension {
    PDF = 'PDF',
    IFC = 'IFC',
    RVT = 'RVT',
    PNG = 'PNG'
}

export enum RevitModelValidationStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
    UNKNOWN = 'UNKNOWN'
}
