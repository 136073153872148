import { Button, Element2DRenderingType, IElement2D, IElement2DOptions, IElement2DToRender, IModel, ITransformationInfo, ModelType, ModelUtils, postal, Viewer, Viewer2dManager, ViewerRenderingEngines } from '@paradigm/blueprints-common-frontend'
import { action, observable, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Store } from '../../../../App/AppStore'
import SpinLoader from '../../../common/components/SpinLoader'
import * as fileStorageApi from '../../../common/service/fileStorageApi'
import { ModelStore } from '../../../stores/modelStore'
import { IMappingData } from '../../interfaces'
import MappingToolActions from './MappingToolActions'
import { BlueprintThumbnail, BlueprintThumbnailsStyled, MappingToolAction, MappingToolActionsStyled, MappingToolBodyStyled, MappingToolContentStyled, MappingToolLeftSideNavStyled, MappingToolRightSideNavStyled, MappingToolStyled, MappingToolViewerStyled } from './MappingToolStyles'

const MAPPING_TOOL_MODEL_TYPES_TO_LOAD: ModelType[] = [
    ModelType.WALL,
    ModelType.SPACE,
]

interface IBlueprintImagePropType {
    id: string,
    url: string
}

interface IMappingToolProps {
    modelId: string
    blueprintImages: IBlueprintImagePropType[]
    modelStore?: ModelStore
    store?: Store
}

@inject('modelStore', 'store')
@observer
export class MappingToolViewer extends React.Component<IMappingToolProps & InjectedIntlProps> {
    private static FLOOR: string = 'FLOOR'
    private static ORDINAL_ENDING: string = 'ordinalEnding'
    private static SPECIAL_LEVEL_KEY_ROOF: string = 'ROOF'
    private static SPECIAL_LEVEL_KEY_BASEMENT: string = 'BSMT'

    private mappingToolViewerManager: Viewer2dManager
    private storeysMap: Map<string, string[]> = new Map()

    @observable private isLoading: boolean = true
    @observable private selectedTag: string | null = null
    @observable private selectedImageId: string | null = null

    public componentDidMount() {
        this.loadMappingToolModel()
    }

    @action
    public async loadMappingToolModel() {
        this.isLoading = true
        await this.props.modelStore!.loadModel(this.props.modelId)
        await this.props.modelStore!.loadGeometries2D(this.props.modelId, MAPPING_TOOL_MODEL_TYPES_TO_LOAD)

        this.props.modelStore!.storeys.forEach((storey) => {
            this.storeysMap.set(storey.name, ModelUtils.findElementIdsByType(storey.children, ModelType.WALL))
        })

        const elementsToRender: Map<Element2DRenderingType, IElement2DToRender[]> = new Map()
        const elements2DToRender: IElement2DToRender[] = []

        const wallOptions: IElement2DOptions = {
            color: '#000000',
            alpha: 0.5,
            isVisible: false,
            isClickable: false,
        }

        Object.values(MAPPING_TOOL_MODEL_TYPES_TO_LOAD).forEach((modelType) => {
            const elements: IElement2D[] = []
            const elementsIds = ModelUtils.findElementIdsByType(this.props.modelStore!.model.children, modelType)
            elementsIds.forEach((id) => {
                const geometry = this.props.modelStore!.geometries2D.get(id)
                if (geometry !== undefined) {
                    elements.push({ id, geometry })
                }
            })
            let options: IElement2DOptions
            switch (modelType) {
                case ModelType.WALL:
                    options = wallOptions
                    break
                default:
                    options = { isVisible: false }
                    break
            }
            const element2DToRender: IElement2DToRender = { elements, options }
            elements2DToRender.push(element2DToRender)
        })

        elementsToRender.set(Element2DRenderingType.POLYGON, elements2DToRender)
        this.mappingToolViewerManager = new Viewer2dManager(elementsToRender)

        postal.subscribe({
            channel: 'visualizer2D',
            topic: 'transformations.info',
            callback: this.saveMappingData
        })

        runInAction(() => this.isLoading = false)
    }

    public render() {
        if (this.isLoading) {
            return <SpinLoader isLoading={true} />
        }

        const { formatMessage } = this.props.intl

        const blueprints: JSX.Element[] = this.props.blueprintImages.map((image: IBlueprintImagePropType) =>
            <BlueprintThumbnail
                key={image.id}
                src={image.url}
                selected={image.id === this.selectedImageId}
                onClick={this.requestShowSelectedBlueprint.bind(this, image)}
                alt={image.url}
            />
        )

        const elevations: JSX.Element[] = ModelStore.ELEVATIONS.map((elevation) =>
            <MappingToolAction
                key={elevation}
                active={this.isTagMapped(elevation)}
                selected={this.selectedTag === elevation}
                onClick={this.setSelectedTag.bind(this, elevation)}
            >
                {formatMessage({ id: elevation })}
            </MappingToolAction>
        )

        const storeys: JSX.Element[] = this.props.modelStore!.storeys.filter((storey) => this.isValidStorey(storey.name)).map((storey) =>
            <MappingToolAction
                key={storey.guid}
                active={this.isTagMapped(storey.name)}
                selected={this.selectedTag === storey.name}
                onClick={this.requestShowStorey.bind(this, storey)}
            >
                {this.formatStorey(storey.name)}
            </MappingToolAction>
        )

        return (
            <MappingToolStyled>
                <MappingToolBodyStyled>
                    <MappingToolLeftSideNavStyled>
                        <BlueprintThumbnailsStyled>
                            {blueprints}
                        </BlueprintThumbnailsStyled>
                    </MappingToolLeftSideNavStyled>
                    <MappingToolContentStyled>
                        <MappingToolActions>
                            <Button size='sm'
                                disabled={this.selectedTag === null || this.selectedImageId === null}
                                onClick={this.requestTransformationsInfo}>{formatMessage({ id: 'save' })}
                            </Button>
                        </MappingToolActions>
                        <MappingToolViewerStyled>
                            <Viewer
                                canvasId={'mapping-tool-viewer'}
                                renderingEngine={ViewerRenderingEngines.BABYLON}
                                viewerManager={this.mappingToolViewerManager}
                            />
                        </MappingToolViewerStyled>
                    </MappingToolContentStyled>
                    <MappingToolRightSideNavStyled>
                        <MappingToolActionsStyled direction={'column'}>
                            {elevations}
                            {storeys}
                        </MappingToolActionsStyled>
                    </MappingToolRightSideNavStyled>
                </MappingToolBodyStyled>
            </MappingToolStyled>
        )
    }

    @action
    public setSelectedTag(tag: string) {
        if (this.selectedTag && this.storeysMap.get(this.selectedTag)) {
            postal.publish({
                channel: 'visualizer2D',
                topic: 'request.geometries.visible',
                data: {
                    ids: this.storeysMap.get(this.selectedTag),
                    visible: false
                },
            })
        }
        this.selectedTag = tag
        const mappingData = this.props.modelStore!.mappings[tag]
        if (mappingData !== undefined) {
            const foundImage = this.props.blueprintImages.find((image) => image.id === mappingData.imageId)
            if (foundImage !== undefined) {
                let transformationInfo: ITransformationInfo | undefined
                if (mappingData.transformationInfo !== undefined) {
                    transformationInfo = JSON.parse(mappingData.transformationInfo)
                }
                this.requestShowSelectedBlueprint(foundImage, transformationInfo)
            }
        }
    }

    private isTagMapped(tag: string): boolean {
        return this.props.modelStore!.mappings[tag] !== undefined
    }

    private requestTransformationsInfo = () => {
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.transformations.info'
        })
    }

    private saveMappingData = async (transformationInfo: ITransformationInfo) => {
        if (this.selectedTag !== null && this.selectedImageId !== null) {
            const isStoreyMapping: boolean = this.storeysMap.has(this.selectedTag)
            const mappingData: IMappingData = {
                tag: this.selectedTag,
                imageId: this.selectedImageId,
                transformationInfo: JSON.stringify(transformationInfo),
            }

            if (isStoreyMapping) {
                mappingData.storeyId = this.selectedTag
            } else {
                mappingData.imageURL = await this.createMappingElevationScreenshot(this.selectedTag, this.props.modelId)
            }

            const isElevationCorrectlyMapped: boolean = mappingData.imageURL !== undefined && mappingData.imageURL !== '' && !isStoreyMapping

            if (isStoreyMapping || isElevationCorrectlyMapped) {
                const isSuccess = await this.props.modelStore!.saveMappingData(this.props.modelId, mappingData)
                if (isSuccess) {
                    this.props.store!.notifyUser(this.props.store!.formatMessage('succesSaveMapping'), 'success')
                } else {
                    this.props.store!.notifyUser(this.props.store!.formatMessage('errorSaveMapping'), 'error')
                }
            } else if (!isElevationCorrectlyMapped) {
                this.props.store!.notifyUser(this.props.store!.formatMessage('warningSaveMappingNoSelection'), 'warning')
            } else {
                this.props.store!.notifyUser(this.props.store!.formatMessage('errorSaveMapping'), 'error')
            }
        }
    }

    private async createMappingElevationScreenshot(imageName: string, modelId: string): Promise<string | undefined> {
        if (!this.mappingToolViewerManager.transformationManager || !this.mappingToolViewerManager.transformationManager.selection) {
            return
        }
        const storageFileContainer = 'mapping'

        // if the model id or the image name is empty, stop
        if (modelId.length === 0 || imageName.length === 0) {
            return
        }
        const fileName = modelId + '/' + imageName + '.png'

        // try to delete the image if it was already created
        try {
            await fileStorageApi.files.deleteFile(storageFileContainer, fileName)
        } catch (error) {
            // the image wasn't existing
        }

        const screenshotCreated: boolean = await this.mappingToolViewerManager.createMappingElevationScreenshot(
            storageFileContainer,
            fileName,
            fileStorageApi.files.storeFile
        )

        if (!screenshotCreated) {
            return
        }
        return '/' + storageFileContainer + '/' + fileName
    }

    private requestShowStorey = (storey: IModel) => {
        this.setSelectedTag(storey.name)
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.geometries.visible',
            data: {
                ids: this.storeysMap.get(storey.name),
                visible: true
            },
        })
    }

    @action
    private requestShowSelectedBlueprint = (image: IBlueprintImagePropType, transformationInfo?: ITransformationInfo) => {
        this.selectedImageId = image.id
        postal.publish({
            channel: 'visualizer2D',
            topic: 'request.image.render',
            data: { imageUrl: image.url, transformationInfo },
        })
    }

    private formatStorey(storeyName: string): string {
        const { formatMessage } = this.props.intl

        const formattedLabel = {
            label: storeyName,
            level: '',
            ordinalEnding: ''
        }

        const level = parseInt(storeyName, undefined)
        if (!isNaN(level) && level !== 0) {
            if (level > 0) {
                formattedLabel.level = storeyName

                if (level > 3) {
                    formattedLabel.ordinalEnding = formatMessage({ id: MappingToolViewer.ORDINAL_ENDING })
                } else {
                    formattedLabel.ordinalEnding = formatMessage({ id: storeyName })
                }

                formattedLabel.label = formatMessage({ id: MappingToolViewer.FLOOR })
            } else {
                if (level !== -1) {
                    formattedLabel.level = level * -1 + ''

                    if (level < -3) {
                        formattedLabel.ordinalEnding = formatMessage({ id: MappingToolViewer.ORDINAL_ENDING })
                    } else {
                        formattedLabel.ordinalEnding = formatMessage({ id: formattedLabel.level })
                    }
                }

                formattedLabel.label = formatMessage({ id: MappingToolViewer.SPECIAL_LEVEL_KEY_BASEMENT })
            }
        } else if (storeyName === MappingToolViewer.SPECIAL_LEVEL_KEY_ROOF) {
            formattedLabel.label = formatMessage({ id: storeyName })
        }

        return formattedLabel.level + formattedLabel.ordinalEnding + ' ' + formattedLabel.label
    }

    private isValidStorey(storeyName: string): boolean {
        const pattern = /ROOF/i
        return storeyName.match(pattern) === null
    }
}

export default injectIntl(MappingToolViewer)
