import {
    ArcRotateCamera, Axis, Epsilon, EventState,
    Matrix, Plane, PointerEventTypes, PointerInfo, Scene, Vector3
} from "babylonjs"
import { findSceneCanvas } from "./babylonUtils"

export class PointerObserverManager {
    // Value is based on Google Chrome’s delta value. It makes zooming smooth on all browsers.
    private readonly MOUSE_WHEEL_CUSTOM_DELTA: number = 100
    private scene: Scene
    private plane: Plane
    private inertialPanning: Vector3 = Vector3.Zero()

    private pointerDownObserver: (event: PointerEvent) => void
    private pointerUpObserver: (event: PointerEvent) => void
    private pointerMoveObserver: (event: PointerEvent) => void

    public constructor(scene: Scene) {
        this.scene = scene
    }

    public registerToPointerDownObserver(callback: (event: PointerEvent) => void): void {
        const canvas = findSceneCanvas(this.scene)

        if (canvas !== null) {
            canvas.addEventListener("pointerdown", callback, false)
            this.pointerDownObserver = callback
        }
    }

    public registerToPointerUpObserver(callback: (event: PointerEvent) => void): void {
        const canvas = findSceneCanvas(this.scene)

        if (canvas !== null) {
            canvas.addEventListener("pointerup", callback, false)
        }
    }

    public registerToPointerMoveObserver(callback: (event: PointerEvent) => void): void {
        const canvas = findSceneCanvas(this.scene)

        if (canvas !== null) {
            canvas.addEventListener("pointermove", callback, false)
        }
    }

    public registerToMouseWheelObserver(callback: (event: PointerInfo) => void): void {
        this.scene.onPointerObservable.add(callback, PointerEventTypes.POINTERWHEEL)
    }

    /**
     * Add control to camera when mouse wheel is triggered, the camera will zoom to the cursor location
     * The active camera have to be of instance ArcRotateCamera
     */
    public activateZoommingOnCursorPosition(): void {
        this.plane = Plane.FromPositionAndNormal(Vector3.Zero(), Axis.Y)
        this.scene.onPointerObservable.add(this.mouseWheelCallback, PointerEventTypes.POINTERWHEEL)
        this.scene.onBeforeRenderObservable.add(this.inertialPanningCallback)
    }

    public removeAllPointerObservers(): void {
        const canvas = findSceneCanvas(this.scene)

        if (canvas !== null) {
            canvas.removeEventListener("pointerdown", this.pointerDownObserver)
            canvas.removeEventListener("pointerup", this.pointerUpObserver)
            canvas.removeEventListener("pointermove", this.pointerMoveObserver)
        }
    }

    /**
     * Get the the vertical scroll amount (delta) from the wheel event
     */
    public calculateMouseWheelDelta(pointerInfo: PointerInfo, camera: ArcRotateCamera): number {
        const delta = this.retrieveMouseWheelDelta(pointerInfo)
        return this.normalizeMouseWheelDelta(delta, camera.wheelPrecision)
    }

    private retrieveMouseWheelDelta(pointerInfo: PointerInfo): number {
        const event = pointerInfo.event
        event.preventDefault()
        const wheelEvent = (event as WheelEvent)
        let delta = 0

        // Get the the vertical scroll amount (delta) from the wheel event
        // depending on the browser compatibity
        if (wheelEvent.deltaY) {
            delta = -wheelEvent.deltaY
        } else if ((wheelEvent as any).wheelDelta) {
            delta = (wheelEvent as any).wheelDelta
        } else if (wheelEvent.detail) {
            delta = -wheelEvent.detail
        }

        return delta
      }

      private normalizeMouseWheelDelta(delta: number, wheelPrecision: number): number {
        // Chrome, Firefox and Edge have different delta values for the same scroll.
        // Get scroll direction (negative or positive), and use the same custom delta for all browsers
        delta = delta > 0 ? this.MOUSE_WHEEL_CUSTOM_DELTA : -this.MOUSE_WHEEL_CUSTOM_DELTA
        return delta /= wheelPrecision
      }

    private findPositionOnPlane(scene: Scene, camera: ArcRotateCamera, plane: Plane): Vector3 | null {
        const ray = scene.createPickingRay(
            scene.pointerX, scene.pointerY, Matrix.Identity(), camera, false)
        const distance = ray.intersectsPlane(plane)

        // not using this ray again, so modifying its vectors here is fine
        return distance !== null ? ray.origin.addInPlace(ray.direction.scaleInPlace(distance)) : null
    }

    /**
     * Zoom to pointer position. Zoom amount determined by delta.
     */
    private zoomToPointerPosition(delta: number, scene: Scene, camera: ArcRotateCamera, plane: Plane, ref: Vector3): void {
        // Do nothing if outside the camera limit
        if (camera.radius - camera.lowerRadiusLimit! < 1 && delta > 0) {
            return
        } else if (camera.upperRadiusLimit! - camera.radius < 1 && delta < 0) {
            return
        }

        // Calculate the delta depending on the Current inertia value on the radius axis, and
        // the lower / upper radius limit
        const inertiaOffset = 1 - camera.inertia
        const projectedCameraRadius = camera.radius - (camera.inertialRadiusOffset + delta) / inertiaOffset
        if (projectedCameraRadius < camera.lowerRadiusLimit!) {
            delta = (camera.radius - camera.lowerRadiusLimit!) * inertiaOffset - camera.inertialRadiusOffset
        } else if (projectedCameraRadius > camera.upperRadiusLimit!) {
            delta = (camera.radius - camera.upperRadiusLimit!) * inertiaOffset - camera.inertialRadiusOffset
        }

        const zoomDistance = delta / inertiaOffset
        const ratio = zoomDistance / camera.radius
        const vec = this.findPositionOnPlane(scene, camera, plane)

        const directionToZoomLocation = vec!.subtract(camera.target)
        const offset = directionToZoomLocation.scale(ratio)
        offset.scaleInPlace(inertiaOffset)
        ref.addInPlace(offset)

        // Sets the camera inertialRadiusOffset
        // The bigger this number the longer it will take for the camera to stop.
        camera.inertialRadiusOffset += delta
    }

    private mouseWheelCallback = (p: PointerInfo, e: EventState) => {
        const camera = this.scene.activeCamera

        if (camera instanceof ArcRotateCamera) {
            const delta = this.calculateMouseWheelDelta(p, camera)
            this.zoomToPointerPosition(delta, this.scene, camera, this.plane, this.inertialPanning)
        }
    }

    /**
     * Sets x y or z of passed in vector to zero if less than Epsilon.
     */
    private zeroIfClose(vector: Vector3): void {
        if (Math.abs(vector.x) < Epsilon) {
            vector.x = 0
        }
        if (Math.abs(vector.y) < Epsilon) {
            vector.y = 0
        }
        if (Math.abs(vector.z) < Epsilon) {
            vector.z = 0
        }
    }

    private inertialPanningCallback = (): void => {
        const camera = this.scene.activeCamera

        if (camera instanceof ArcRotateCamera) {
            if (this.inertialPanning.x !== 0 || this.inertialPanning.y !== 0 || this.inertialPanning.z !== 0) {
                camera.target.addInPlace(this.inertialPanning)
                this.inertialPanning.scaleInPlace(camera.inertia)
                this.zeroIfClose(this.inertialPanning)
            }
        }
    }
}
