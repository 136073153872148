import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { IStore } from '../../../../App/AppStore'
import './Footer.css'

interface IFooterProps extends IStore {
    logoAlt: string
    logo?: string
}

@inject('store')
@observer
class Footer extends React.Component<IFooterProps> {
    public render() {
        return (
            <div className='footer-wrapper'>
                <div className='container'>
                    <div className='logo-wrapper navbar-brand'>
                        <span className='circle'>
                            <img alt={this.props.store!.formatMessage(this.props.logoAlt)} src={this.props.logo} />
                        </span>
                        <div className='logo-text footer-text'>
                            {this.props.store!.formatMessage('footerRightsReserved')}
                        </div>
                        <div className='terms-text footer-text'>
                            {this.props.store!.formatMessage('termsPrivacyPolicy')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
