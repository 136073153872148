import { AbstractMesh, Axis, Material, Mesh, MeshBuilder, Scene, ShadowGenerator, Vector3 } from 'babylonjs'
import { Control } from 'babylonjs-gui'
import { MeshType } from '../models/MeshType'
import { IMeshTreeNodeAction } from './MeshTreeNodeActions/MeshTreeNodeAction'

interface IOperation {
    operationType: string,
    axis: Vector3,
    x?: number,
    y?: number,
    z?: number
    angle?: number
}

interface INodeInfo {
    name?: string
    id?: string
    type?: string
}

export class MeshTreeNode {
    public static GROUPING_MESH: string = "groupingMesh"

    public mesh: Mesh | undefined
    private children: MeshTreeNode[] = []
    private controls: Control[] = []
    private info: INodeInfo
    private operation: IOperation[] = []
    private isGroupingNode: boolean = false
    private flags: Map<string, string>  = new Map<string, string>()
    public constructor(info: INodeInfo, mesh?: Mesh) {
        this.mesh = mesh
        this.info = info
    }

    public getInfo(): INodeInfo {
        return this.info
    }

    public getFlags(): Map<string, string> | undefined {
        return this.flags
    }

    public initInvisibleParentMesh(scene: Scene) {
        if (name !== undefined) {
            this.isGroupingNode = true
            this.mesh = MeshBuilder.CreateSphere(MeshTreeNode.GROUPING_MESH, { diameter: .001 }, scene)
            this.mesh.visibility = 0
        }
    }

    public applyMaterial(material: Material, name: string) {
        if (this.info.name === name && this.mesh !== undefined) {
            this.mesh.material = material
        }
        this.children.forEach(element => {
            element.applyMaterial(material, name)
        })
    }

    public rotate(axis: Vector3, angle: number) {
        if (this.mesh) {
            if (!this.isGroupingNode) {
                this.mesh.rotate(axis, Math.PI / 180 * angle)
                this.mesh.bakeCurrentTransformIntoVertices()
            }
        }

        this.operation.push({ operationType: "Rotation", "axis": axis, "angle": angle })

        this.children.forEach(element => {
            element.rotate(axis, angle)
        })
    }

    public translate(axis: Vector3, xTranslation: number, yTranslation: number, zTranslation: number) {
        if (this.mesh) {
            if (!this.isGroupingNode) {
                this.mesh.translate(Axis.X, xTranslation)
                this.mesh.translate(Axis.Y, yTranslation)
                this.mesh.translate(Axis.Z, zTranslation)
                this.mesh.bakeCurrentTransformIntoVertices()
            }
        }

        this.operation.push({ operationType: "Translation", "axis": axis, x: xTranslation, y: yTranslation, z: zTranslation })

        this.children.forEach(element => {
            element.translate(axis, xTranslation, yTranslation, zTranslation)
        })
    }

    public executeAction(action: IMeshTreeNodeAction) {
        this.children.forEach(element => {
            element.executeAction(action)
        })

        action.execute(this)
    }

    public addNodeChild(childMeshTreeNode: MeshTreeNode, applyParentTransformation: boolean = true) {
        if (childMeshTreeNode.mesh !== undefined && this.mesh !== undefined) {
            childMeshTreeNode.mesh.parent = this.mesh
        }

        if (applyParentTransformation) {
            childMeshTreeNode.applyOperation(this.operation)
        }

        this.children.push(childMeshTreeNode)
    }

    public addMeshChild(child: Mesh , childInfo: INodeInfo, applyParentTransformation: boolean = true): MeshTreeNode {
        const childMeshTreeNode = new MeshTreeNode(childInfo, child)

        if (childMeshTreeNode.mesh !== undefined && this.mesh !== undefined) {
            childMeshTreeNode.mesh.parent = this.mesh
        }

        if (applyParentTransformation) {
            childMeshTreeNode.applyOperation(this.operation)
        }

        this.children.push(childMeshTreeNode)
        return childMeshTreeNode
    }

    public addMeshChildren(children: Mesh[], childrenInfo: INodeInfo, applyParentTransformation: boolean = true): MeshTreeNode[] {
        const nodes: MeshTreeNode[] = []
        children.forEach(child => {
            nodes.push(this.addMeshChild(child, childrenInfo, applyParentTransformation))
        })

        return nodes
    }

    public setFlags(flags: Map<string, string>) {
        this.flags = flags
    }

    public addControl(control: Control) {
        this.controls.push(control)
    }

    public getChildren() {
        return this.children
    }

    public setChildren(children: MeshTreeNode[]) {
       this.children = children
    }

    public hasChildren(): boolean {
        return this.children && this.children.length > 0
    }

    public getControls(): Control[] {
        return this.controls
    }

    public getAllChildren() {
        let result: any[] = []
        result = result.concat(this.getChildren())
        this.children.forEach(element => {
            result = result.concat(element.getAllChildren())
        })
        return result
    }

    public getMeshNodeIndex(mesh: AbstractMesh): number | undefined {
        const children = this.getChildren()

        const result = children.map((child, i) => {
            if (child.getChildren().findIndex(grandChild => grandChild.mesh!.id === mesh.id) !== -1) {
                return i
            } else {
                return undefined
            }
        }).filter(foundIndex => foundIndex !== undefined)

        return result.length === 0 ? -1 : result[0]
    }

    public getChild(name?: string, type?: string, id?: string): MeshTreeNode | undefined{
        let result: MeshTreeNode | undefined

        for (const child of this.children) {
            const element = child
            const value = element.getChild(name)
            if (value != null) {
                result = value
            }
        }

        if ((this.info.name === name && this.info.type === type) || this.info.id === id) {
            result = this
        }

        return result
    }

    public getChildFromType(type: MeshType): MeshTreeNode[] {
        let result: MeshTreeNode[] = []

        if (this.info.type === type) {
            result.push(this)
        }

        for (const child of this.children) {
            const element = child
            const value = element.getChildFromType(type)
            if (value) {
                result = result.concat(value)
            }
        }

        return result
    }

    public dispose() {
        this.children.forEach(element => {
            element.dispose()
        })

        if (this.mesh !== undefined) {
            this.mesh.dispose()
        }

        this.mesh = undefined
        this.children = []
    }

    get visible(): number {
        let result = 0

        if (this.mesh !== undefined) {
            result = this.mesh.visibility
        }

        return result
    }

    set visible(value: number) {
        this.children.forEach(element => {
            element.visible = value
        })

        if (this.mesh !== undefined) {
            this.mesh.visibility = value
        }
    }

    get position(): number {
        let result = 0

        if (this.mesh !== undefined) {
            result = this.mesh.position.x
        }

        return result
    }

    set position(destination: number) {
        this.children.forEach(element => {
            if (element.mesh !== undefined) {
                element.mesh.position.x = destination
            }
        })
    }

    public setAlpha(newAlpha: number) {
        this.controls.forEach(control => {
            control.alpha = newAlpha
        })
    }

    public setVisible(value: boolean) {
        this.children.forEach(element => {
            element.setVisible(value)
        })

        if (this.mesh !== undefined) {
            this.mesh.isVisible = value
        }

    }

    public applyShadowCaster(shadowGenerator: ShadowGenerator) {
        this.children.forEach(element => {
            element.applyShadowCaster(shadowGenerator)
        })

        const shadowMap = shadowGenerator.getShadowMap()

        if (shadowMap !== null && shadowMap.renderList !== null) {
            shadowMap.renderList.push(this.mesh as AbstractMesh)
        }
    }

    private applyOperation(operations: IOperation[]) {
        if (operations) {
            operations.forEach(operation => {
                if (operation.operationType === "Translation" && (operation.x && operation.y && operation.z)) {
                    this.translate(operation.axis, operation.x, operation.y, operation.z)
                }
                else if (operation.operationType === "Rotation" && operation.angle) {
                    this.rotate(operation.axis, operation.angle)
                }
            })
        }
    }
}
