import { AxiosPromise } from 'axios'
import * as transport from '../common/service/transport'
import { Builder } from './models/Builder'
import { Design } from './models/Design'
import * as queryString from 'query-string'
import { Editor } from './models/Editor'
import { Question } from './models/Question'

const BUILDER_PROJECT_API = '/builderproject/api'
const CATALOG_MAPPINGS_END_POINT: string = 'catalogmappings'

export const builderService = {
    findAll: (): AxiosPromise<Builder[]> => {
        return transport.get(`${BUILDER_PROJECT_API}/builders`)
    },

    findUrl: (templateName: string, builderName: string, clientId: string): AxiosPromise<string> => {
        const queryParameters = queryString.stringify({
            clientId
        })
        return transport.get(`${BUILDER_PROJECT_API}/builders/${builderName}/templates/${templateName}/url?${queryParameters}`)
    }
}

export const designService = {
    // GET /designs/new?clientId={clientId}&templateId={templateId}
    createDesign: (clientId: string, templateId: string): AxiosPromise<Design> => {
        return transport.get(`${BUILDER_PROJECT_API}/designs/new?clientId=${clientId}&templateId=${templateId}`)
    },

    // GET /designs/new/{designName}?clientId={clientId}&builderId={builderId}&buildingModelId={buildingModelId}
    createDesignFromName: (designName: string, clientId: string, builderId: string, buildingModelId: string) => {
        const queryParameters = queryString.stringify({
            clientId,
            builderId,
            buildingModelId
        })
        return transport.get(`${BUILDER_PROJECT_API}/designs/new/${designName}?${queryParameters}`)
    },
    
    // GET /designs/clients
    fetchClients: () => {
        const response = transport.get(`${BUILDER_PROJECT_API}/designs/clients/`)
        return response
    },

    // GET /designs/{designId}/builder/{builderId}/editors/new/{editorName}
    createDesignEditor: (builderId: string, designId: string, editorName: string, token: string, oldToken: string | null): AxiosPromise<Editor> => {
        transport.useBearerToken(token)
        const response = transport.get(`${BUILDER_PROJECT_API}/designs/${designId}/builder/${builderId}/editors/new/${editorName}`)
        transport.useBearerToken(oldToken)
        return response
    },

    // GET /designs/{designId}/editors/{designEditorId}/sections
    fetchSections: (editorId: string, designId: string, token: string, oldToken: string | null) => {
        transport.useBearerToken(token)
        const response = transport.get(`${BUILDER_PROJECT_API}/designs/${designId}/editors/${editorId}/sections`)
        transport.useBearerToken(oldToken)
        return response
    },

    // GET /designs/{designId}/editors/{designEditorId}/sections/{sectionId}
    fetchSection: (designId: string, designEditorId: string, sectionId: string, token: string, oldToken: string | null) => {
        transport.useBearerToken(token)
        const response = transport.get(`${BUILDER_PROJECT_API}/designs/${designId}/editors/${designEditorId}/sections/${sectionId}`)
        transport.useBearerToken(oldToken)
        return response
    },

    // PUT /designs/{designId}/editors/{designEditorId}/sections/{sectionId}/questions/{questionId}
    answerQuestion: (designId: string, designEditorId: string, sectionId: string, question: Question, token: string, oldToken: string | null) => {
        transport.useBearerToken(token)
        const response = transport.put(`${BUILDER_PROJECT_API}/designs/${designId}/editors/${designEditorId}/sections/${sectionId}/questions/${question.id}`, question)
        transport.useBearerToken(oldToken)
        return response
    },
}

export const catalogMapping = {
    // GET /catalogmappings
    fetchAll: () => transport.get(`${BUILDER_PROJECT_API}/${CATALOG_MAPPINGS_END_POINT}`),
}

export const assetBundleLabelsService = {
    findAll: () => {
        return transport.get(`${BUILDER_PROJECT_API}/assetbundlelabels`)
    }
}
