import { LinesMesh } from "babylonjs"
import { MeshTreeNode } from "../MeshTreeNode"
import { MeshTreeNodeFlaggedAction  } from "./MeshTreeNodeFlaggedAction "

export class EnableEdgesRenderingAction extends MeshTreeNodeFlaggedAction  {
    private static EDGE_WIDTH: number = 25

    public execute(node: MeshTreeNode): void {
        if (this.allowAction(node)) {
            node.mesh!.enableEdgesRendering()
            node.mesh!.edgesWidth = EnableEdgesRenderingAction.EDGE_WIDTH
            node.mesh!.edgesColor = (node.mesh as LinesMesh).color.toColor4()
        }
    }
}
