import { inject, observer } from "mobx-react"
import * as React from "react"
import { Alert } from "react-bootstrap"
import { PrimaryButton, TextField, styled } from '@paradigm/blueprints-common-frontend'
import { FormattedMessage } from "react-intl"
import { IStore } from "../../../../App/AppStore"
import { ILoginFormState } from './interfaces'

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    >button {
        width: 100%;
        margin: 0px;
        text-transform: capitalize;
        padding: 1rem 2rem;
        font-family: ${(props) => props.theme.font.family};
    }
`

const InputContainer = styled.div`
    padding-bottom: 40px;
`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: center;

    div {
        font-family: ${(props) => props.theme.font.family};
        font-size: 14px;
        color: ${(props) => props.theme.colors.secondary};
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: ${(props) => props.theme.colors.primary};
        }
    }
`

const EmailInput = styled(TextField)`
    width: 100%;
    height: 50px;
    padding: 0px 10px;
`

@inject("store")
@observer
class LoginForm extends React.Component<IStore, ILoginFormState> {
    constructor(props: Readonly<IStore>) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
    }

    public onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.props.store!.login.onSubmit()
    }

    public onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.store!.login.onFieldChange(
            event.target.name,
            event.target.value
        )
    }

    public render() {
        const email = this.props.store!.login.getFormField("email")
        const password = this.props.store!.login.getFormField("password")
        const error = this.props.store!.login.form.meta.error

        const loginStyle = {
            display: this.props.store!.login.isPasswordReset ? 'none' : 'block'
        }

        return (
            <form onSubmit={this.onSubmit} style={loginStyle}>
                {
                    error && (
                        <Alert bsStyle="danger">
                            <strong>
                                {this.props.store!.formatMessage(error)}
                            </strong>
                        </Alert>
                    )
                }
                <InputContainer>
                    <EmailInput
                        onChange={this.onChange.bind(this)}
                        isRequired={true}
                        value={email.value}
                        name={email.name}
                        errorText={email.error}
                        type={'email'}
                        label={<FormattedMessage id='email' />} />
                </InputContainer>
                <InputContainer>
                    <TextField
                        onChange={this.onChange.bind(this)}
                        value={password.value}
                        name={password.name}
                        errorText={password.error}
                        type={'password'}
                        label={<FormattedMessage id='password' />}
                        isPassword={true}
                        isRequired={true} />
                </InputContainer>
                <ButtonContainer><PrimaryButton type="submit"><FormattedMessage id={'login'} /></PrimaryButton></ButtonContainer>
                <LinkContainer>
                    <div onClick={() => this.props.store!.login.setIsPasswordReset(true)}>
                        <FormattedMessage id={'forgotPassword'} />
                    </div>
                </LinkContainer>
            </form >
        )
    }
}

export default LoginForm
