import { action, observable, reaction } from 'mobx'
import { Store } from '../../../App/AppStore'
import { UserRole } from '../../auth/models/User'
import { BaseModel } from '../../common/stores/BaseModel'
import { Option } from './Options'


export enum ProjectAssigneeFilter {
  ALL = 'ALL',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  UNASSIGNED = 'UNASSIGNED'
}

export class CriteriaQuery extends BaseModel {
  public static DEFAULT_PAGE_NUMBER: number = 1
  public static DEFAULT_PAGE_SIZE: number = 12
  public static DEFAULT_SORT: string = 'createdDate'
  public static DEFAULT_ORDER: string = 'DESC'

  @observable public pageNumber: number = CriteriaQuery.DEFAULT_PAGE_NUMBER
  @observable public pageSize: number = CriteriaQuery.DEFAULT_PAGE_SIZE
  @observable public search: string = ''
  @observable public filter: string = ''
  @observable public sort: string = CriteriaQuery.DEFAULT_SORT
  @observable public order: string | undefined = CriteriaQuery.DEFAULT_ORDER
  @observable public sortOptions: Option[] = [
      new Option('createdDate', 'dateCreated', CriteriaQuery.DEFAULT_ORDER),
      new Option('name', 'projectName', 'ASC')
  ]
  @observable public assigneeFilter: string = ProjectAssigneeFilter.ALL
  @observable public assigneeFilterOptions: Option[] = [
      new Option(ProjectAssigneeFilter.ALL, 'all'),
      new Option(ProjectAssigneeFilter.ASSIGNED_TO_ME, 'assigned'),
      new Option(ProjectAssigneeFilter.UNASSIGNED, 'unassigned')
  ]

  constructor(store: Store) {
    super(store)
    reaction(
        () => this.store.user,
        (loggedInUser, reaction) => {
            if (loggedInUser !== undefined && loggedInUser.role === UserRole.ARCHITECT) {
                this.assigneeFilter = ProjectAssigneeFilter.ASSIGNED_TO_ME
            }
            reaction.dispose()
        }
    )
  }

  @action public setSearchQuery(search: string) {
      this.search = search
  }

  @action public setSortQuery(sort: string) {
      this.sort = sort
      const optionIndex = this.sortOptions.findIndex(option => option.key === sort)

      if (optionIndex !== -1) {
          this.order = this.sortOptions[optionIndex].order
      }
  }

  @action public setFilterByAssigneeQuery(assigneeFilter: string) {
      this.assigneeFilter = assigneeFilter
  }

  @action public getActiveAssigneeFilter(assigneeFilter: string): boolean {
      return this.assigneeFilter === assigneeFilter
  }
}
