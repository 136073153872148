import { JsonProperty } from 'jsog-typescript'
import { observable } from 'mobx'
import { State } from './State'

export class Country {
    public id: string
    @observable public code: string
    @observable public description: any
    @JsonProperty(State)
    public states: State[] = []
}
