import { SORT_ORDER, SortUtils, styled, TextField, IKeyValueObject } from '@paradigm/blueprints-common-frontend'
import { JsogService } from 'jsog-typescript'
import { action, observable, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { FormEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { IStore } from '../../../App/AppStore'
import CreateProjectHeader from '../../project/components/CreateProjectHeader'
import { Builder } from '../models/Builder'
import * as PublishBuilder from '../../builderProject/models/Builder'
import { Country } from '../models/Country'
import { Project } from '../models/Project'
import { State } from '../models/State'
import * as projectService from '../ProjectService'
import CreatableSelect from 'react-select'

import { UploadFieldCreateProject } from './UploadFieldCreateProject'
import { Client } from '../../builderProject/models/Client'
import ChangeAnswerSpinner from '../containers/DotTool/ChangeAnswerSpinner'

const Container = styled.div`
	width: 955px;
	border-radius: 3px;
	background-color: ${(props) => props.theme.colors.common[0]};
	background-size: cover;
	margin: 0 auto;
	padding: 64px 124px;

	input {
		width: 100%;
	}
`

const ProjectInfosTitle = styled.div`
	font-family: ${(props) => props.theme.font.family};
	font-size: 16px;
	color: ${(props) => props.theme.colors.secondary};
	font-weight: 600;
	text-decoration: none solid rgb(32, 40, 52);
	margin: 14px 0;
	padding-bottom: 19px;
`
const ProjectInfosName = styled.div`
	font-family: ${(props) => props.theme.font.family};
	font-size: 16px;
	color: ${(props) => props.theme.colors.secondary};
	font-weight: 600;
	text-decoration: none solid rgb(32, 40, 52);
	padding-bottom: 3px;
`

const ProjectInfosBuilder = styled.div`
	font-family: ${(props) => props.theme.font.family};
	font-size: 16px;
	color: ${(props) => props.theme.colors.secondary};
	font-weight: 600;
	text-decoration: none solid rgb(32, 40, 52);
`
const DropdownContainer = styled.div`
	display: flex;
	flex-direction: column;

	ul {
		box-shadow: 3px 6px 13px -6px ${(props) => props.theme.colors.neutrals[3]};
	}
	> div {
		padding: 30px;
	}
`

const Dropdownlabel = styled.label`
	display: flex;
	flex-direction: row;
	font-weight: ${(props) => props.theme.textInput.labelFontWeight};
	font-family: ${(props) => props.theme.font.family};
	color: ${(props) => props.theme.colors.neutrals[6]};
`

const StyledFormGroup = styled.div`margin: 20px 0;`

const FormDelimeter = styled.hr`margin: 40px 0;`
const FlexBox = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const StyledSelect = styled.select`
	height: 35px;
	padding: 0px 10px;
	font-family: ${(props) => props.theme.font.family};
	border: 1px solid ${(props) => props.theme.colors.neutrals[6]};
	border-radius: 0px;
	background-color: ${(props) => props.theme.colors.common[0]};
	background-size: cover;
	font-size: 16px;
	text-overflow: ellipsis;
	color: ${(props) => props.theme.colors.neutrals[6]};
	text-decoration: none solid rgb(138, 138, 138);
	padding-right: 35px;
	width: 100%;
	outline-color: transparent;
	outline-style: none;
`

interface IDropdownState {
	[key: string]: IKeyValueObject
}

@inject('store')
@observer
export class CreateProject extends React.Component<IStore, IDropdownState> {
    private static readonly DEFAULT_COUNTRY_CODE: string = 'US'
	@observable private countries: Country[] = []
	@observable private selectedCountryId: string
    @observable private states: State[] = []
    @observable private builders: Builder[] = []
    @observable private selectedBuilderId: string
	@observable private isLoadingCountries: boolean = true
	@observable private isLoadingExternalBuilders = true
	@observable private isLoadingClients: boolean = true
	@observable private externalBuilderId: string = ''
	@observable private clientId: string = ''

	private jsog = new JsogService()

	@action
	public async componentDidMount() {
		this.props.store!.projectStore.currentProject = new Project()
		try {
			this.isLoadingCountries = true
            const response = await projectService.countryService.findAll()
            const responseBuilder = await projectService.builderService.findAll()
			runInAction(() => {
                this.countries = this.jsog.deserialize(response.data, Country) as Country[]
                this.builders = this.jsog.deserialize(responseBuilder.data, Builder) as Builder[]
                const us = this.countries.find((c) => c.code === CreateProject.DEFAULT_COUNTRY_CODE)
                let country
				if (us) {
                    country = us
					this.fetchStates(us.id)
				} else {
                    country = this.countries[0]
					this.fetchStates(this.countries[0].id)
                }
                if (this.builders != null && this.builders.length > 0) {
                    this.props.store!.projectStore.currentProject.builder = this.builders[0]
                }
                this.props.store!.projectStore.currentProject.country = country
			})
		} catch (error) {
			console.error(error)
		} finally {
			runInAction(() => {
				this.isLoadingCountries = false
			})
		}

		this.setIsLoadingExternalBuilders(true)
		await this.props.store!.builderProjectStore.fetchBuilders()
		this.setIsLoadingExternalBuilders(false)

		this.setIsLoadingClients(true)
		await this.props.store!.builderProjectStore.fetchClients()
		this.setIsLoadingClients(false)
	}

	@action
    public componentWillUnmount() {
        console.log('componentWillUnmount');
        
        this.props.store!.projectStore.revisionFile = null
    }


	public render() {
		if (this.isLoadingCountries || this.isLoadingExternalBuilders || this.isLoadingClients) {
			return null
		}
		
		const { currentProject } = this.props.store!.projectStore

		const { builders, clients} = this.props.store!.builderProjectStore
		const builderListAsSelectOptions = builders.map(builder => ({ value: builder, label: builder.name }))
		const clientListAsSelectOptions = clients.map(client => ({ value: client, label: client.ClientName }))

		let selectedBuilderAsSelectOption = this.findSelectedExternalBuilderAsOption(builders)
		let selectedClientAsSelectOption = this.findSelectedClientAsOption(clients)
		
		return (
			<React.Fragment>
				<ChangeAnswerSpinner isVisible={this.props.store!.projectStore.savingProject} /> 
				<CreateProjectHeader />
				<Container>
					<StyledFormGroup>
                    <ProjectInfosName>{this.props.store!.formatMessage('projectName')}</ProjectInfosName>
						<TextField
							name={'name'}
							type={'text'}
							value={currentProject.name}
							onChange={this.handleOnChange.bind(this)}
						/>
					</StyledFormGroup>
                    <DropdownContainer>
						<Dropdownlabel>
							<ProjectInfosBuilder>{this.props.store!.formatMessage('builder')}</ProjectInfosBuilder>
						</Dropdownlabel>
						<StyledSelect value={this.selectedBuilderId} onChange={this.handleBuilderSelected}>
							{this.builders.map((builder) => (
								<option key={builder.id} value={builder.id}>
									{builder.name}
								</option>
							))}
						</StyledSelect>
					</DropdownContainer>

					<FormDelimeter />

					<ProjectInfosTitle>{this.props.store!.formatMessage('publishinProjectInfo')}</ProjectInfosTitle>
					<StyledFormGroup>
						<FormattedMessage id={'externalBuilderName'} />
						<CreatableSelect className='externalBuilder'
							isDisabled={this.isLoadingExternalBuilders} isLoading={this.isLoadingExternalBuilders}
							onChange={this.onSelectedBuilderChange.bind(this)} options={builderListAsSelectOptions}
							value={selectedBuilderAsSelectOption} isClearable={true} isSearchable={true}
						/>
					</StyledFormGroup>
					<StyledFormGroup>
						<FormattedMessage id={'clientName'} />
						<CreatableSelect className='client'
							isDisabled={this.isLoadingClients} isLoading={this.isLoadingClients}
							onChange={this.onSelectedClientChange.bind(this)} options={clientListAsSelectOptions}
							value={selectedClientAsSelectOption} isClearable={true} isSearchable={true}
						/>
					</StyledFormGroup>

					<FormDelimeter />

					<ProjectInfosTitle>{this.props.store!.formatMessage('projectAddress')}</ProjectInfosTitle>
					<StyledFormGroup>
						<TextField
							name={'addressLine1'}
							label={<FormattedMessage id={'addressLine1'} />}
							type={'text'}
							value={currentProject.addressLine1}
							onChange={this.handleOnChange.bind(this)}
						/>
					</StyledFormGroup>

					<StyledFormGroup>
						<TextField
							name={'addressLine2'}
							label={<FormattedMessage id={'addressLine2'} />}
							type={'text'}
							value={currentProject.addressLine2}
							onChange={this.handleOnChange.bind(this)}
						/>
					</StyledFormGroup>
					<StyledFormGroup>
						<TextField
							name={'city'}
							label={<FormattedMessage id={'city'} />}
							type={'text'}
							value={currentProject.city}
							onChange={this.handleOnChange.bind(this)}
						/>
					</StyledFormGroup>
					<FlexBox>
						<DropdownContainer>
							<Dropdownlabel>
								<span>
									{this.props.store!.formatMessage('country')}
								</span>
							</Dropdownlabel>
							<StyledSelect value={this.selectedCountryId} onChange={this.handleCountrySelected}>
								{this.countries.map((country) => (
									<option key={country.id} value={country.id}>
										{this.props.store!.getLocalizedDescription(country.description)}
									</option>
								))}
							</StyledSelect>
						</DropdownContainer>
						<DropdownContainer style={{ width: 300 }}>
							<Dropdownlabel>
								<span>
									{' '}
									{this.props.store!.formatMessage('state')}
								</span>
							</Dropdownlabel>
							<StyledSelect onChange={this.handleStateSelected}>
								{this.states.map((state) => (
									<option key={state.id} value={state.id}>
										{this.props.store!.getLocalizedDescription(state.description)}
									</option>
								))}
							</StyledSelect>
						</DropdownContainer>
						<div style={{ width: 200 }}>
							<TextField
								name={'zipCode'}
								label={<FormattedMessage id={'zipCode'} />}
								type={'text'}
								value={currentProject.zipCode}
								onChange={this.handleOnChange.bind(this)}
							/>
						</div>
					</FlexBox>
					<FormDelimeter />
					<ProjectInfosTitle>
						<FormattedMessage id={'projectFiles'} />
					</ProjectInfosTitle>
					<form onClick={(event) => event.preventDefault()}>
						<UploadFieldCreateProject />
					</form>
				</Container>
			</React.Fragment>
		)
	}

	@action
	private fetchStates = async (countryId: string) => {
		this.selectedCountryId = countryId
		try {
			const response = await projectService.countryService.findACountryStates(countryId)
			runInAction(() => {
				const statesData = this.jsog.deserialize(response.data, State) as State[]
				const states: State[] = statesData.map((data: State) => Object.assign(new State(), data))
				SortUtils.sort(states, (state) => state.code, SORT_ORDER.ASC)
				this.states = states
  				if (states != null && states.length > 0) {
				    this.props.store!.projectStore.currentProject.state = states[0]
				}
			})
		} catch (error) {
			console.error(error)
		}
	}

	@action
	private handleCountrySelected = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const countryId = event.currentTarget.value
		const selectedCountry = this.countries.find((country) => country.id === countryId)
		if (selectedCountry !== undefined) {
			this.props.store!.projectStore.currentProject.country = selectedCountry
		}
		this.fetchStates(countryId)
    }

	@action
	private handleBuilderSelected = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const builderId = event.currentTarget.value
		const selectedBuilder = this.builders.find((builder) => builder.id === builderId)
		if (selectedBuilder !== undefined) {
			this.props.store!.projectStore.currentProject.builder = selectedBuilder
		}
	}

	@action
	private handleStateSelected = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const stateId = event.currentTarget.value
		const selectedState = this.states.find((state) => state.id === stateId)
		if (selectedState !== undefined) {
			this.props.store!.projectStore.currentProject.state = selectedState
		}
	}

	@action
	private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement
		this.props.store!.projectStore.currentProject[target.name] = target.value
	}

	@action private setIsLoadingExternalBuilders(value: boolean) {
		this.isLoadingExternalBuilders = value
	}

	@action private setIsLoadingClients(value: boolean) {
		this.isLoadingClients = value
	}

	private findSelectedExternalBuilderAsOption(builders: PublishBuilder.Builder[])
	{
		let selectedBuilderAsSelectOption = null
		const selectedBuilder = builders.find(builder => builder.id === this.externalBuilderId)
		if (selectedBuilder) {
            selectedBuilderAsSelectOption = { value: selectedBuilder, label: selectedBuilder.name }
		}
		return selectedBuilderAsSelectOption
	}

	private findSelectedClientAsOption(clients: Client[])
	{
		let selectedClientAsSelectOption = null
		const selectedClient = clients.find(client => client.ClientId === this.clientId)
		if (selectedClient) {
			selectedClientAsSelectOption = { value: selectedClient, label: selectedClient.ClientName }
		}
		return selectedClientAsSelectOption
	}

	@action private onSelectedBuilderChange(eventValue: any) {
		const { builderProjectStore } = this.props.store!
		
        if (eventValue) {
			this.externalBuilderId = eventValue.value.id
			this.props.store!.projectStore.currentProject.externalBuilderId = eventValue.value.id
        }
        else {
            this.externalBuilderId = ''
			this.props.store!.projectStore.currentProject.externalBuilderId = null
		}
	}

	@action private onSelectedClientChange(eventValue: any) {
		if (eventValue) {
			const client = this.props.store!.builderProjectStore.clients.find(client => client.ClientId === eventValue.value.ClientId)
			if (client) {
				this.clientId = client.ClientId
				this.props.store!.projectStore.currentProject.externalClientId = client.ClientId
			}
		}
		else {
			this.clientId = ''
			this.props.store!.projectStore.currentProject.externalClientId = null
		}
	}
}
