import { action } from 'mobx'
import { Store } from "../../App/AppStore"
import { FormStore } from "../common/stores/FormSrore"
import { Project } from "./models/Project"


export class ProjectFormModel extends FormStore<Project> {
    constructor(store: Store) {
        super(store)
    }

    @action public async onSubmit() {
        if (this.form.meta.isValid) {
            const currentProject = this.store.projectStore.currentProject

            if (currentProject.id) {
                this.store.projectStore.updateProject(currentProject)
            } else {
                await this.store.projectStore.createProject()
            }
        }
    }
}

