import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { TotalFinishArea } from '../../../buildingModel/components/TotalFinishArea'
import { IStore } from '../../../../App/AppStore'
import { ProjectName } from '../ProjectName'
import styled from "styled-components"
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProjectHeaderContainer = styled.div`
    z-index: 1;
    background-color:  ${props => props.theme.colors.common[0]};
    filter: drop-shadow(1px 0px 2px ${props => props.theme.colors.neutrals[3]});
    margin-bottom: 2px !important;
    border-color:  ${props => props.theme.colors.neutrals[3]};

    display: flex;
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 15px;

    width: 100%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;

    @media all and (max-width: 1300px) {
        flex-direction: column;
    }
`

const ProjectInfoContainer = styled.div`
   display: flex;
   flex-direction: row;
`

const ProjectInfoWrapper = styled.div`
   display: flex;
   flex-direction: column;
`

const ProjectInfoLabel = styled.div`
    width: 170px;
    font-family: ${props => props.theme.font.family};;
    font-size: 11px;
    color: ${props => props.theme.colors.neutrals[5]};
    font-weight: 600;
    letter-spacing: 2px;
    padding: 5px 0px;
    text-transform: uppercase;
`

const ProjectInfoName = styled.div`
    width: 230px;
    font-family: ${props => props.theme.font.family};
    font-size: 18px;
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;

    a:first-child{
        color:${props => props.theme.colors.secondary};
        display: block;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            text-decoration: none;
        }
    }
`

const ProjectFinishArea = styled.div`
    width: 0px;
    font-family: ${props => props.theme.font.family};
    font-size: 18px;
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
`

const ProjectBuilder = styled.div`
    width: 220px;
    font-family: ${props => props.theme.font.family};
    font-size: 18px;
    color: ${props => props.theme.colors.secondary};
    font-weight: 500;
`

const ProjectRightWrapper = styled.div`
    flex: 2 auto;
`

const ActionsWrapper = styled.div`
    float: right;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 300;

    @media all and (max-width: 1300px) {
        float: left;
        padding-top: 5px;
    }
`

const EditPencil = styled.span`
    width: 40px;
    text-decoration: none;
    padding: 5px 10px;
    font-size: 16px;
    color: ${props => props.theme.colors.neutrals[4]};
`

interface IProjectHeaderProps extends IStore {
    children?: React.ReactNode
}

@inject("store")
@observer
class ProjectHeader extends React.Component<IProjectHeaderProps> {
    constructor(props: Readonly<IProjectHeaderProps>) {
        super(props)
    }

    public render() {
        const { children } = this.props

        return (
            <React.Fragment>
                <ProjectHeaderContainer>
                    <ProjectInfoContainer>
                        <ProjectInfoWrapper>
                            <ProjectInfoLabel>
                                <FormattedMessage id={'projectName'} />
                            </ProjectInfoLabel>
                            <ProjectInfoName>
                                <Link to={`/project/${this.props.store!.projectStore.currentProject.id}`} >
                                    <ProjectName />
                                </Link>
                                <Link
                                    to={`/project/${this.props.store!.projectStore.currentProject.id}/details`}
                                    title={this.props.store!.formatMessage("projectDetails")}
                                >
                                    <EditPencil className='edit-button'>
                                        <FontAwesomeIcon icon={["fal", "pencil"]} />
                                    </EditPencil>
                                </Link>
                            </ProjectInfoName>
                        </ProjectInfoWrapper>
                        <ProjectInfoWrapper style={{ display: 'none' }}>
                            <ProjectInfoLabel>
                                <FormattedMessage id={'finishArea'} />
                            </ProjectInfoLabel>
                            <ProjectFinishArea><TotalFinishArea /></ProjectFinishArea>
                        </ProjectInfoWrapper>
                        <ProjectInfoWrapper>
                            <ProjectInfoLabel>
                                <FormattedMessage id={'builder'} />
                            </ProjectInfoLabel>
                            <ProjectBuilder>Paradigm Builder</ProjectBuilder>
                        </ProjectInfoWrapper>
                    </ProjectInfoContainer>
                    <ProjectRightWrapper>
                        <ActionsWrapper>
                            {children}
                        </ActionsWrapper>
                    </ProjectRightWrapper>
                </ProjectHeaderContainer>
            </React.Fragment>
        )
    }
}

export default ProjectHeader
