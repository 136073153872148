import { MeshTreeNode } from '../MeshTreeNode'
import { IMeshTreeNodeAction } from './MeshTreeNodeAction'

/**
 * This class execute an action on node if the action is allowed
 */
export abstract class MeshTreeNodeFlaggedAction implements IMeshTreeNodeAction {
    protected flagsMap: Map<string, string>
    protected elementsId: string[] | undefined

    constructor(flagsMap: Map<string, string>, elementsId?: string[]) {
        this.flagsMap = flagsMap
        this.elementsId = elementsId
    }

    public abstract execute(node: MeshTreeNode): void

    protected allowAction(node: MeshTreeNode): boolean {
        let applyAction = false

        if (node.mesh) {
            const flags = node.getFlags()

            if (flags && this.flagsMap) {
                let count = 0
                for (const pair of this.flagsMap) {
                    const [key, value] = pair
                    if (flags.get(key) === value) {
                        count++
                        if (count === this.flagsMap.size) {
                            if (this.elementsId === undefined) {
                                applyAction = true
                            } else {
                                applyAction = this.elementsId.find((id) => id === node.getInfo().id) !== undefined
                            }
                        }
                    }
                }
            }
        }

        return applyAction
    }
}
