import { IGeometryDTO, IKeyValueObject } from '@paradigm/blueprints-common-frontend'
import * as queryString from 'query-string'

import * as transport from '../common/service/transport'

const BUILDING_MODEL_API: string = '/buildingmodel/api'
const MODEL_END_POINT: string = 'model'

export const model = {
    // GET /model/{modelId}
    fetchModel: (modelId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}`),

    // GET /model/{modelId}/structure
    fetchModelStructure: (modelId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/structure`),
  
    // POST /model/{modelId}/elements
    fetchGeometries: (modelId: string, geometryDTOs: IGeometryDTO[], includeMetadata: boolean) => {
        const queryParameters = queryString.stringify({ includeMetadata })
        return transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/elements?${queryParameters}`, geometryDTOs)
    },

    // POST /model/{modelId}/elements2d
    fetch2DDataForElements: (modelId: string, elementIds: string[]) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/elements2d`, elementIds),

    // POST /model/{modelId}/mapping
    updateMappingData: (modelId: string, mappingData: IKeyValueObject) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/mapping`, mappingData),

    // GET /model/{modelId}/metadata/{metadataId}
    fetchMetadata: (modelId: string, metadataId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/metadata/${metadataId}`),

    // POST /model/{modelId}/dotting
    updateDotToolData: (modelId: string, dottingData: any) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/dotting`, dottingData),

    // GET /model/{modelId}/dotting
    fetchDotToolData: (modelId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/dotting`),

    // POST /model/{modelId}/dotting
    savePannellumConfig: (modelId: string, builderPannellumConfigs: any) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/pannellumConfig`, builderPannellumConfigs),

    // GET /model/{modelId}/pannellumConfig
    fetchPannellumConfig: (modelId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/pannellumConfig`),

    // POST /model/{modelId}/dotting/interior
    updateIntDotToolData: (modelId: string, dottingData: any) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/dotting/interior`, dottingData),

    // GET /model/{modelId}/dotting/interior
    fetchIntDotToolData: (modelId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/dotting/interior`),

    // POST /model/{modelId}/dotting/interior
    saveIntPannellumConfig: (modelId: string, builderPannellumConfigs: any) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/pannellumConfig/interior`, builderPannellumConfigs),

    // POST /model/{modelId}/dotting/assetbundle
    updateBuilderAssetDotToolData: (modelId: string, builderAssetDotToolData: any) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/dotting/assetbundle`, builderAssetDotToolData),

    // GET /model/{modelId}/dotting/assetbundle
    fetchBuilderAssetDotToolData: (modelId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/dotting/assetbundle`),

    // POST /model/{modelId}/dotting/assetbundle
    saveBuilderAssetPannellumConfig: (modelId: string, builderAssetPannellumConfig: any) =>
        transport.post(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/pannellumConfig/assetbundle`, builderAssetPannellumConfig),

    // GET /model/{modelId}/pannellumConfig/assetbundle
    fetchBuilderAssetPannellumConfig: (modelId: string) =>
        transport.get(`${BUILDING_MODEL_API}/${MODEL_END_POINT}/${modelId}/pannellumConfig/assetbundle`),
}
