import { MeshTreeNode } from '../MeshTreeNode'
import { MeshTreeNodeFlaggedAction } from './MeshTreeNodeFlaggedAction '

export class MakeVisibleAction extends MeshTreeNodeFlaggedAction {
    private isVisible: boolean = false

    constructor(flagsMap: Map<string, string>, isVisible: boolean, elementsId?: string[]) {
        super(flagsMap, elementsId)
        this.isVisible = isVisible
    }

    public execute(node: MeshTreeNode): void {
        if (this.allowAction(node)) {
            node.mesh!.isVisible = this.isVisible
        }
    }
}
