import { IKeyValueObject, JsonUtils } from '@paradigm/blueprints-common-frontend'
import { action, computed, observable, runInAction } from 'mobx'
import { v1 as uuidv1 } from 'uuid'
import * as buildingModelApi from '../api/buildingModelApi'
import dispatcherApi from '../api/dispatcherApi'
import { Section } from '../builderProject/models/Section'
import { CubeMapScene } from '../project/containers/DotTool/models/CubeMapScene'
import { IAssetBundleDotToolData } from '../project/containers/DotTool/models/IAssetBundleDotToolData'
import { IAssetBundlePannellumConfig } from '../project/containers/DotTool/models/IAssetBundlePannellumConfig'
import { IBuilderAssetDotToolData } from '../project/containers/DotTool/models/IBuilderAssetDotToolData'
import { IBuilderAssetPannellumConfig } from '../project/containers/DotTool/models/IBuilderAssetPannellumConfig'
import { IBuilderDotToolData } from '../project/containers/DotTool/models/IBuilderDotToolData'
import { ICamera } from '../project/containers/DotTool/models/ICamera'
import { IDot } from '../project/containers/DotTool/models/IDot'
import { IDotData, IDotToolData, ISceneData } from '../project/containers/DotTool/models/IDotToolData'
import { IFindCollisionRequestData } from '../project/containers/DotTool/models/IFindCollisionRequestData'
import { IPannellumConfig } from '../project/containers/DotTool/models/IPannellumConfig'
import { IPannellumConfigRequestData } from '../project/containers/DotTool/models/IPannellumConfigRequestData'
import { IPlaceCamerasRequestData } from '../project/containers/DotTool/models/IPlaceCamerasRequestData'
import { IPlaceCamerasResponse } from '../project/containers/DotTool/models/IPlaceCamerasResponse'
import { IPosition } from '../project/containers/DotTool/models/IPosition'
import { IRenderRequestData } from '../project/containers/DotTool/models/IRenderRequestData'
import { IRenderRequestResponse } from '../project/containers/DotTool/models/IRenderRequestResponse'
import { IScreenshotParameter } from '../project/containers/DotTool/models/IScreenshotParameter'
import { Alternative } from '../project/models/Alternative'
import { alternativeService } from '../project/ProjectService'
import { errorStore } from './errorStore'
import modelStore from './modelStore'

export class PannellumStore {
    public static readonly DEFAULT_CAMERA_SIZE = 2048
    private static readonly DEFAULT_ENVIRONMENT_SCENE: string = 'Day'

    @observable public isInitialLoading: boolean = false
    @observable public isLoadingAnswer: boolean = false
    @observable public error: string = ''
    @observable public pannellumConfig: IPannellumConfig | undefined
    @observable public scenes: { [key: string]: CubeMapScene } | undefined
    @observable public cameras: ICamera[] = []
    @observable public sections: Section[] = []
    @observable public update: boolean = false

    // Dot Tool
    @observable public modelId: string
    @observable public builderId: string
    @observable public currentCamera: IDotToolData | undefined
    @observable public viewer: any | undefined
    @observable public builderDotToolData: IKeyValueObject<IDotToolData> = {}
    @observable public sectionName: string = ''
    @observable public cameraSceneId: string = ''
    @observable public firstCameraId: string = ''
    @observable public isMove: boolean = true
    @observable public isAddCameraScene: boolean = false
    @observable public isSavingPannellumConfig: boolean = false
    @observable public isInterior: boolean = false
    @observable public cameraSize: number = PannellumStore.DEFAULT_CAMERA_SIZE
    @observable public isGridVisible: boolean = false
    @observable public selectedAssetBundleLabel: string = 'None'
    @observable public assetDolToolData: IKeyValueObject<IAssetBundleDotToolData> = {}
    @observable public assetBundleConfigs: IKeyValueObject<IAssetBundlePannellumConfig> = {}

    @observable private sceneUrl: string | undefined
    @observable private assetUrl: string | undefined

    @observable private alternative: Alternative

    public loadCamerasFromAlternative() {
        try {
            if (this.alternative.assetBundlePannellumConfig) {
                const builderAssetPannellumConfigs = JSON.parse(this.alternative.assetBundlePannellumConfig) as IBuilderAssetPannellumConfig
                if (builderAssetPannellumConfigs.assetBundleConfigs) {
                    const { assetBundleConfigs } = builderAssetPannellumConfigs
                    if (Object.keys(assetBundleConfigs).length !== 0 && assetBundleConfigs.hasOwnProperty(this.selectedAssetBundleLabel)) {
                        const pannellumConfig = assetBundleConfigs[this.selectedAssetBundleLabel].config
                        this.firstCameraId = pannellumConfig.default.firstScene
                        this.setPannellumConfig(pannellumConfig);
                    }
                    runInAction(() => this.assetBundleConfigs = assetBundleConfigs)
                }
            }

            if (this.alternative.assetBundleDotToolData) {
                const assetBundleDotToolData = JSON.parse(this.alternative.assetBundleDotToolData) as IBuilderAssetDotToolData
                this.processDotToolDataFromAlternative(assetBundleDotToolData)
            }
        } catch (error) {
            console.error('Could not load cameras from dot tool data', error)
            errorStore.addError('Could not load cameras from dot tool data')
        }
    }

    public async loadCamerasFromModel(modelId: string): Promise<void> {
        try {
            let dotToolDataResponse

            if (this.isUsingAssetBundle) {
                dotToolDataResponse = await buildingModelApi.model.fetchBuilderAssetDotToolData(modelId)
                const response = await buildingModelApi.model.fetchBuilderAssetPannellumConfig(modelId)
                const builderAssetPannellumConfigs = response.data as IBuilderAssetPannellumConfig[]
                const builderConfig = builderAssetPannellumConfigs.find((p) => p.builderId === this.builderId)
                if (builderConfig && builderConfig.assetBundleConfigs) {
                    const { assetBundleConfigs } = builderConfig
                    if (Object.keys(assetBundleConfigs).length !== 0 && assetBundleConfigs.hasOwnProperty(this.selectedAssetBundleLabel)) {
                        const pannellumConfig = assetBundleConfigs[this.selectedAssetBundleLabel].config
                        this.firstCameraId = pannellumConfig.default.firstScene
                        this.setPannellumConfig(pannellumConfig);
                    }
                    runInAction(() => this.assetBundleConfigs = assetBundleConfigs)
                }
            } else {
                if (this.isInterior) {
                    dotToolDataResponse = await buildingModelApi.model.fetchIntDotToolData(modelId)
                } else {
                    dotToolDataResponse = await buildingModelApi.model.fetchDotToolData(modelId)

                    const pannellumConfig = await buildingModelApi.model.fetchPannellumConfig(modelId)

                    if (pannellumConfig && pannellumConfig!.data[0]) {
                        this.firstCameraId = pannellumConfig!.data[0].config.default.firstScene
                        this.setPannellumConfig(pannellumConfig!.data[0].config);
                    }
                }
            }
            this.processDotToolData(dotToolDataResponse.data)
        } catch (error) {
            console.error('Could not load cameras from dot tool data', error)
            errorStore.addError('Could not load cameras from dot tool data')
        }
    }

    @computed get isUsingAssetBundle(): boolean {
        return this.selectedAssetBundleLabel !== 'None'
    }

    @action
    public toggleGridVisiblity = () => {
        this.isGridVisible = !this.isGridVisible
    }

    @action
    public setCameraSize(size: number) {
        this.cameraSize = size
    }

    @action
    public applyCameraUpdates = async () => {
        if (this.currentCamera) {
            const cameraIndex = this.cameras.findIndex((c) => c.cameraName === this.currentCamera!.cameraName)
            if (cameraIndex !== -1) {
                this.cameras[cameraIndex] = this.createCameraFromDotToolData(this.currentCamera)
                await this.updateViewer()
            }
        }
    }

    @action
    public async processGeneratedCameras(response: IPlaceCamerasResponse) {
        const builderDotToolData: IKeyValueObject<IDotToolData> = {}

        Array.from(response.result.keys()).map((key: string) => {
            const placeCamerasMessageData = response.result.get(key)

            builderDotToolData[key] = {
                cameraId: uuidv1(),
                cameraName: key,
                cameraCoordinates: placeCamerasMessageData!.position,
                yawAngle: placeCamerasMessageData!.yaw,
                pitchAngle: placeCamerasMessageData!.pitch,
                dotInfo: [],
                cameraData: [],
                fov: placeCamerasMessageData!.fov,
                minPitch: placeCamerasMessageData!.minPitch,
                maxPitch: placeCamerasMessageData!.maxPitch,
                minHFov: placeCamerasMessageData!.minHFov,
                maxHFov: placeCamerasMessageData!.maxHFov,
            }
        })

        Object.keys(builderDotToolData).forEach((key: string) => {
            const placeCamerasMessageData = response.result.get(key);

            if (placeCamerasMessageData!.scenes !== null) {
                builderDotToolData[
                    key
                ].cameraData = placeCamerasMessageData!.scenes.map(
                    (scene: any) => {
                        return {
                            cameraId: scene.sceneId,
                            coordinates: scene.position,
                        } as ISceneData
                    }
                )
            }
        })

        await this.saveDotToolData(builderDotToolData)
    }

    @action
    public processDotToolData(datas: Array<IBuilderDotToolData | IBuilderAssetDotToolData>) {
        const builderDotToolData = datas.find((d) => d.builderId === this.builderId)

        let data: IKeyValueObject<IDotToolData> | undefined

        if (builderDotToolData) {
            if (this.isUsingAssetBundle) {
                const builderAssetDotToolData = (builderDotToolData as IBuilderAssetDotToolData)
                const { assetDolToolData } = builderAssetDotToolData
                this.assetDolToolData = assetDolToolData

                if (Object.keys(assetDolToolData).length !== 0 && assetDolToolData.hasOwnProperty(this.selectedAssetBundleLabel)) {
                    data = assetDolToolData[this.selectedAssetBundleLabel].data
                }
            } else {
                data = (builderDotToolData as IBuilderDotToolData).data
            }
        }

        if (data) {
            const cameras = Object.keys(data)
                .filter((key: string) => {
                    const dotToolData = data![key]
                    return dotToolData !== undefined && dotToolData.cameraName !== null
                })
                .map((key: string) => {
                    return this.createCameraFromDotToolData(data![key])
                })

            this.builderDotToolData = data
            this.cameras = cameras
        }
    }

    @action
    public processDotToolDataFromAlternative(builderDotToolData: IBuilderAssetDotToolData) {
        let data: IKeyValueObject<IDotToolData> | undefined

        if (builderDotToolData) {
            const builderAssetDotToolData = (builderDotToolData as IBuilderAssetDotToolData)
            const { assetDolToolData } = builderAssetDotToolData
            this.assetDolToolData = assetDolToolData

            if (Object.keys(assetDolToolData).length !== 0 && assetDolToolData.hasOwnProperty(this.selectedAssetBundleLabel)) {
                data = assetDolToolData[this.selectedAssetBundleLabel].data
            }
        }

        if (data) {
            const cameras = Object.keys(data)
                .filter((key: string) => {
                    const dotToolData = data![key]
                    return dotToolData !== undefined && dotToolData.cameraName !== null
                })
                .map((key: string) => {
                    return this.createCameraFromDotToolData(data![key])
                })

            this.builderDotToolData = data
            this.cameras = cameras
        }
    }

    @action
    public addToAssetDolToolData(data: IKeyValueObject<IDotToolData>) {
        this.assetDolToolData[this.selectedAssetBundleLabel] = {
            label: this.selectedAssetBundleLabel,
            data
        }
    }

    @action
    public addToAssetBundleConfigs(config: IPannellumConfig) {
        this.assetBundleConfigs[this.selectedAssetBundleLabel] = {
            label: this.selectedAssetBundleLabel,
            config
        }
    }

    public async init(
        builderId: string,
        modelId: string,
        sceneUrl: string,
        assetUrl: string,
        sections: Section[],
        alternative: Alternative
    ): Promise<void> {
        runInAction(() => {
            this.isInitialLoading = true
            this.builderId = builderId
            this.modelId = modelId
            this.assetUrl = assetUrl
            this.sceneUrl = sceneUrl
            this.sections = sections
            this.alternative = alternative
        })

        if (isUsingDotDataFromAlternative()) {
            this.loadCamerasFromAlternative()
        } else {
            await this.loadCamerasFromModel(modelId)
        }

        if (!this.isInterior && this.cameras.length === 0) {
            await this.placeCameras()
        } else {            
            if (this.pannellumConfig) {
                this.updateCamerasFromPannellumConfig(this.pannellumConfig)
                await this.takeScreenshot()
            } else if (this.cameras.length > 0) {
                await this.generatePannellumConfig()
            } else {
                this.isLoadingAnswer = false
            }
        }
    }

    @action
    public updateViewer = async(isSavingPannellumConfig: boolean = false) => {
        if (!this.isLoadingAnswer) {
            this.isLoadingAnswer = true
        }
        this.isSavingPannellumConfig = isSavingPannellumConfig
        await this.generatePannellumConfig()
    }

    public async savePannellumConfig() {
        if (isUsingDotDataFromAlternative()) {
            await this.savePannellumConfigFromAlternative()
        } else {
            await this.savePannellumConfigFromModel()
        }
    }

    public async saveDotToolData(builderDotToolData: IKeyValueObject<IDotToolData>) {
        if (this.modelId && this.builderId) {   
            if (isUsingDotDataFromAlternative()) {
                this.addToAssetDolToolData(builderDotToolData)
                await this.saveDotToolDataFromAlternative()
                await this.updateViewer(true)
            } else {
                let dotToolData
                if (this.isUsingAssetBundle) {
                    this.addToAssetDolToolData(builderDotToolData)
                    dotToolData = await modelStore.saveAssetBundleDotToolData(this.modelId, this.builderId, this.assetDolToolData)
                } else {
                    if (this.isInterior) {
                        dotToolData = await modelStore.saveIntDotToolData(this.modelId, this.builderId, builderDotToolData)
                    } else {
                        dotToolData = await modelStore.saveDotToolData(this.modelId, this.builderId, builderDotToolData)
                    }
                }
                if (dotToolData) {
                    this.processDotToolData(dotToolData)
                    await this.updateViewer(true)
                }
            }
        }
        
    }

    public async savePannellumConfigFromModel() {
        if (this.modelId && this.builderId && this.pannellumConfig) {
            if (this.isUsingAssetBundle) {
                this.addToAssetBundleConfigs(this.pannellumConfig)
                await modelStore.saveAssetBundlePannellumConfig(this.modelId, this.builderId, this.assetBundleConfigs)
            } else {
                if (this.isInterior) {
                    await modelStore.saveIntPannellumConfig(this.modelId, this.builderId, this.pannellumConfig)
                } else {
                    await modelStore.savePannellumConfig(this.modelId, this.builderId, this.pannellumConfig)
                }
            }
            runInAction(() => this.isSavingPannellumConfig = false)
        }
    }

    public async savePannellumConfigFromAlternative() {
        if (this.modelId && this.builderId && this.pannellumConfig) {
            this.addToAssetBundleConfigs(this.pannellumConfig)
            await this.saveDotToolConfig()
            runInAction(() => this.isSavingPannellumConfig = false)
        }
    }

    @action
    public setPannellumConfig(pannellumConfig: IPannellumConfig) {
        this.pannellumConfig = pannellumConfig
    }

    @action
    public addScenes(scenes: { [key: string]: CubeMapScene }) {
        if (this.scenes) {
            Object.assign(this.scenes, scenes)
        } else {
            this.scenes = scenes
        }

        if (this.isInitialLoading) {
            this.isInitialLoading = false
        } else {
            this.update = true
        }
    }

    @action
    public updateCamerasFromPannellumConfig(config: IPannellumConfig) {
        this.currentCamera = this.currentCamera ? Object.create(this.builderDotToolData[this.currentCamera.cameraName]) :
            Object.create(this.builderDotToolData[Object.keys(this.builderDotToolData)[0]])
    }

    @action
    public async saveCurrentCameraSceneData(coordinates: IPosition) {
        let sceneInfo: ISceneData | null = null

        if (this.currentCamera) {
            if (this.currentCamera.cameraData) {
                const index = this.currentCamera.cameraData.findIndex(sceneInfo => sceneInfo.cameraId === this.cameraSceneId)
                if (index !== -1) {
                    sceneInfo = this.currentCamera.cameraData[index]
                    sceneInfo.coordinates = coordinates
                }
            } else {
                this.currentCamera.cameraData = []
            }

            if (sceneInfo === null) {
                const sceneData: ISceneData = {
                    cameraId: this.cameraSceneId,
                    sceneId: this.cameraSceneId,
                    coordinates
                }
                this.currentCamera.cameraData.push(sceneData)
            }

            if (this.builderDotToolData && this.modelId && this.builderId) {
                this.saveDotToolData(this.builderDotToolData)
      
                runInAction(() => {
                    this.isAddCameraScene = false
                    this.isMove = true
                    this.cameraSceneId = ''
                })
            }

            const cameraIndex = this.cameras.findIndex((camera) => camera.cameraName === this.currentCamera!.cameraName)
            if (cameraIndex !== -1) {
                this.cameras[cameraIndex] = this.createCameraFromDotToolData(this.currentCamera)
            }
        }
    }

    @action
    public saveCurrentCameraDotData(coordinates: IPosition) {
        let dotInfo: IDotData | null = null

        if (this.currentCamera) {
            if (this.currentCamera.dotInfo) {
                const index = this.currentCamera.dotInfo.findIndex((dot: IDotData) => dot.sectionId === this.sectionName)
                if (index !== -1) {
                    dotInfo = this.currentCamera.dotInfo[index]
                    dotInfo.coordinates = coordinates
                }
            } else {
                this.currentCamera.dotInfo = []
            }
            if (dotInfo === null) {
                const dotData: IDotData = {
                    sectionId: this.sectionName,
                    coordinates
                }
                this.currentCamera.dotInfo.push(dotData)
            }
            const cameraIndex = this.cameras.findIndex((camera) => camera.cameraName === this.currentCamera!.cameraName)
            if (cameraIndex !== -1) {
                this.cameras[cameraIndex] = this.createCameraFromDotToolData(this.currentCamera)
            }
        }
    }

    @action
    public removeSectionDotPerCamera = async(sectionName: string) => {
        if (this.currentCamera && this.currentCamera.dotInfo) {
            const newDotList = this.currentCamera.dotInfo.filter((dotData: IDotData) => dotData.sectionId !== sectionName)
            this.currentCamera.dotInfo = newDotList
            const cameraIndex = this.cameras.findIndex((camera) => camera.cameraName === this.currentCamera!.cameraName)
            if (cameraIndex !== -1) {
                this.cameras[cameraIndex] = this.createCameraFromDotToolData(this.currentCamera)
            }
            await this.generatePannellumConfig()
        }
    }

    @action
    public removeAllDotsPerCamera = async () => {
        if (this.currentCamera && this.currentCamera.dotInfo) {
            this.currentCamera.dotInfo = []
            const cameraIndex = this.cameras.findIndex((camera) => camera.cameraName === this.currentCamera!.cameraName)
            if (cameraIndex !== -1) {
                this.cameras[cameraIndex] = this.createCameraFromDotToolData(this.currentCamera)
            }
            await this.generatePannellumConfig()
        }
    }

    @action
    public clear() {
        this.error = ''
        this.cameras = []
        this.pannellumConfig = undefined
        this.scenes = undefined
        this.currentCamera = undefined
        this.builderDotToolData = {}
    }

    public takeScreenshot = async () => {
        const scenes: { [key: string]: CubeMapScene } = {}

        if (this.cameras.length > 0) {
            const renderRequestData: IRenderRequestData = {
                assetUrl: this.assetUrl!,
                sceneUrl: this.sceneUrl!,
                scene: this.isInterior
                    ? 'interiorDay'
                    : PannellumStore.DEFAULT_ENVIRONMENT_SCENE,
                screenshots: this.cameras.map((camera) => {
                    const screenshot: IScreenshotParameter = {
                        size: camera.size,
                        fov: camera.fov,
                        yaw: camera.yaw!,
                        pitch: camera.pitch!,
                        cameraName: camera.cameraName,
                        position: camera.position,
                    }

                    return screenshot
                }),
            }

            const response = await dispatcherApi.unity.renderScene(
                renderRequestData
            )

            const renderRequestResponse: IRenderRequestResponse = response.data as IRenderRequestResponse

            if (renderRequestResponse !== null) {
                renderRequestResponse.screenshots = JsonUtils.buildMap(
                    renderRequestResponse.screenshots
                )
                this.cameras.forEach((camera) => {
                    const screenshot = renderRequestResponse.screenshots.get(
                        camera.cameraName
                    )

                    scenes[camera.cameraName] = {
                        Front: fixNullUrl(screenshot!.front),
                        Back: fixNullUrl(screenshot!.back),
                        Left: fixNullUrl(screenshot!.left),
                        Right: fixNullUrl(screenshot!.right),
                        Up:  fixNullUrl(screenshot!.up),
                        Down: fixNullUrl(screenshot!.down)
                    }
                })
                this.addScenes(scenes)
            }
        }
    }

    public generatePannellumConfig = async () => {
        if (this.cameras && this.cameras.length > 0) {
            const requestData: IPannellumConfigRequestData = {
                defaultCamera: this.findDefaultCameraIndex(),
                cameras: this.cameras,
            }
    
            const response = await dispatcherApi.unity.generatePannellumConfig(
                requestData
            )
    
            this.setPannellumConfig(response.data)
    
            if (this.isSavingPannellumConfig) {
                await this.savePannellumConfig()
            }
            this.updateCamerasFromPannellumConfig(response.data)
            await this.takeScreenshot()
        }
    }

    public findCollision3dPoint = async (coords: [number, number], forCamera: boolean) => {
        if (this.currentCamera) {
            const requestData: IFindCollisionRequestData = {
                assetUrl: this.assetUrl!,
                sceneUrl: this.sceneUrl!,
                sourcePosition: this.currentCamera.cameraCoordinates,
                sourceYaw: this.currentCamera.yawAngle,
                sourcePitch: this.currentCamera.pitchAngle,
                yaw: coords[1],
                pitch: coords[0],
                forCamera,
            }

            const response = await dispatcherApi.unity.findCollisionPoint(
                requestData
            )

            pannellumStore.isAddCameraScene ? await pannellumStore.saveCurrentCameraSceneData(response.data.result)  : await pannellumStore.saveCurrentCameraDotData(response.data.result)
            await pannellumStore.generatePannellumConfig()
        }
    }

    public placeCameras = async () => {
        const requestData: IPlaceCamerasRequestData = {
            assetUrl: this.assetUrl!,
            sceneUrl: this.sceneUrl!,
        }

        const response = await dispatcherApi.unity.placeCameras(requestData)

        response.data.result = JsonUtils.buildMap(response.data.result)

        pannellumStore.processGeneratedCameras(response.data)
    }

    public createCameraFromDotToolData(dotToolData: IDotToolData): ICamera {
        const dots: IDot[] = []
        let sceneDots: IDot[] = []

        if (dotToolData.dotInfo) {
            dotToolData.dotInfo.forEach((dot: IDotData) => {
                if (this.sections.findIndex((section) => section.name === dot.sectionId) !== -1) {
                    dots.push({
                        'position': dot.coordinates,
                        'sectionId': dot.sectionId
                    })
                } else {
                    console.warn('Unable to find section with id: ' + dot.sectionId)
                }
            })
        }

        if (dotToolData.cameraData) {
            sceneDots = dotToolData.cameraData.map((sceneDot: ISceneData) => ({
                'position': sceneDot.coordinates,
                'sceneId': sceneDot.cameraId
            }))
        }

        const camera: ICamera = {
            'cameraName': dotToolData.cameraName,
            'position': dotToolData.cameraCoordinates,
            'yaw': dotToolData.yawAngle,
            'pitch': dotToolData.pitchAngle,
            'fov': dotToolData.fov,
            'size': this.cameraSize,
            'dots': dots,
            'hotSpots': sceneDots,
            'minPitch': dotToolData.minPitch,
            'maxPitch': dotToolData.maxPitch,
            'minHFov': dotToolData.minHFov,
            'maxHFov': dotToolData.maxHFov
        }
        return camera
    }

    public async saveDotToolDataFromAlternative() {
        let assetBundleDotToolData: any

        if (this.alternative.assetBundleDotToolData) {
            assetBundleDotToolData = JSON.parse(this.alternative.assetBundleDotToolData) as any
        }

        assetBundleDotToolData = {
            assetDolToolData: this.assetDolToolData,
        }
        
        try {
            const response = await alternativeService.saveAssetBundleDotToolData(
                this.alternative.id,
                assetBundleDotToolData
            );

            runInAction(() => this.alternative = response.data)

            if (this.alternative.assetBundleDotToolData) {
                if (this.alternative.assetBundleDotToolData) {
                    const assetBundleDotToolData = JSON.parse(this.alternative.assetBundleDotToolData) as IBuilderAssetDotToolData
                    this.processDotToolDataFromAlternative(assetBundleDotToolData)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    public async saveDotToolConfig() {
        if (this.pannellumConfig) {
            let assetBundlePannellumConfig: any

            if (this.alternative.assetBundlePannellumConfig) {
                assetBundlePannellumConfig = JSON.parse(this.alternative.assetBundlePannellumConfig) as IBuilderAssetPannellumConfig
            }
    
            const assetBundleConfigs = {
                [this.selectedAssetBundleLabel]: {
                    label: this.selectedAssetBundleLabel,
                    config: this.pannellumConfig,
                },
            };
    
            if (!assetBundlePannellumConfig) {
                assetBundlePannellumConfig = {
                    assetBundleConfigs,
                }
            } else {
                assetBundlePannellumConfig.assetBundleConfigs[this.selectedAssetBundleLabel] = {
                    label: this.selectedAssetBundleLabel,
                    config: this.pannellumConfig,
                }
            }
    
            try {
                const response = await alternativeService.saveAssetBundlePannellumConfig(
                    this.alternative.id,
                    assetBundlePannellumConfig
                )
                
                runInAction(() => this.alternative = response.data)

                if (this.alternative.assetBundlePannellumConfig) {
                    const assetBundlePannellumConfig = JSON.parse(this.alternative.assetBundlePannellumConfig) as IBuilderAssetPannellumConfig;
                    this.setPannellumConfig(assetBundlePannellumConfig.assetBundleConfigs[this.selectedAssetBundleLabel].config);
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    private findDefaultCameraIndex(): number {
        let result = 0

        if (this.pannellumConfig || this.firstCameraId) {
            const index = this.pannellumConfig && this.pannellumConfig!.default.firstScene ?
                this.cameras.findIndex((c) => c.cameraName === this.pannellumConfig!.default.firstScene) :
                this.firstCameraId ? this.cameras.findIndex((c) => c.cameraName === this.firstCameraId) : 0
            if (index !== -1) {
                result = index
            }
        }

        return result
    }
}

export const pannellumStore = new PannellumStore()

export function fixNullUrl(url: string | undefined): string {
    if (url === null) { return 'null' }
    return url!
}

export function isUsingDotDataFromAlternative() {
    let useDotDataFromAlternative = '___ENV-VAR-USE_DOTDATA_FROM_ALTERNATIVE___';
    if (process.env.REACT_APP_USE_DOTDATA_FROM_ALTERNATIVE && process.env.NODE_ENV !== 'production') {
        useDotDataFromAlternative = process.env.REACT_APP_USE_DOTDATA_FROM_ALTERNATIVE;
    }
    const isTrue = /^true$/i.test(useDotDataFromAlternative);
    return isTrue;
}
