import { Transport } from '@paradigm/blueprints-common-frontend'
import { AxiosPromise } from 'axios'
import { IFindCollisionRequestData } from '../project/containers/DotTool/models/IFindCollisionRequestData'
import { IFindCollisionResponse } from '../project/containers/DotTool/models/IFindCollisionResponse'
import { IPannellumConfig } from '../project/containers/DotTool/models/IPannellumConfig'
import { IPannellumConfigRequestData } from '../project/containers/DotTool/models/IPannellumConfigRequestData'
import { IPlaceCamerasRequestData } from '../project/containers/DotTool/models/IPlaceCamerasRequestData'
import { IPlaceCamerasResponse } from '../project/containers/DotTool/models/IPlaceCamerasResponse'
import { IRenderRequestData } from '../project/containers/DotTool/models/IRenderRequestData'
import { IRenderRequestResponse } from '../project/containers/DotTool/models/IRenderRequestResponse'

const UNITY_DISPATCHER_API: string = '/dispatcher/api/unityapi'

export const unity = {
    // POST /unityapi/renderscene
    renderScene: (
        renderRequestData: IRenderRequestData
    ): AxiosPromise<IRenderRequestResponse> => {
        return Transport.post(
            `${UNITY_DISPATCHER_API}/renderscene`,
            renderRequestData
        )
    },
    findCollisionPoint: (
        findCollisionRequestData: IFindCollisionRequestData
    ): AxiosPromise<IFindCollisionResponse> => {
        return Transport.post(
            `${UNITY_DISPATCHER_API}/findcollisionpoint`,
            findCollisionRequestData
        )
    },
    placeCameras: (
        placeCamerasRequestData: IPlaceCamerasRequestData
    ): AxiosPromise<IPlaceCamerasResponse> => {
        return Transport.post(
            `${UNITY_DISPATCHER_API}/placecameras`,
            placeCamerasRequestData
        )
    },
    generatePannellumConfig: (
        generatePannellumConfigData: IPannellumConfigRequestData
    ): AxiosPromise<IPannellumConfig> => {
        return Transport.post(
            `${UNITY_DISPATCHER_API}/generatepannellumconfig`,
            generatePannellumConfigData
        )
    },
}

export default {
    unity,
}
