
export const NUMBER_OF_LINE: number = 4
export const Z_AXIS_ROW_INDEX: number = 2

/**
 * Takes 3 points and turns into a 4x1 matrix of x, y, z, 1
 * @param x
 * @param y
 * @param z
 * @returns a 4x1 matrix with the given x, y, z, and then a 1
 */
export function convertPointToMatrix4By1(x: number, y: number, z: number): number[][] {
    const retArray = [
        [x],
        [y],
        [z],
        [1],
    ]
    return retArray
}

/**
 * Multiplies two given matricies and returns the resulting matrix
 *
 * @param matrixA
 * @param matrixB
 * @returns resulting multiplied matrix
 */
export function multiplyMatrix(matrixA: number[][], matrixB: number[][]): number[][] {
    const aNumRows = matrixA.length
    const aNumCols = matrixA[0].length
    const bNumCols = matrixB[0].length
    const retMatrix = new Array(aNumRows)
    if (aNumCols === matrixB.length) {
        for (let r = 0; r < aNumRows; ++r) {
            retMatrix[r] = new Array(bNumCols) // initialize the current row of return matrix

            for (let c = 0; c < bNumCols; ++c) {
                retMatrix[r][c] = 0        // initialize the current cell of return matrix

                // add the row/column multiplication of every row/column
                for (let i = 0; i < aNumCols; ++i) {
                    retMatrix[r][c] += matrixA[r][i] * matrixB[i][c]
                }
            }
        }
    }
    return retMatrix
}

/**
 * Takes given array and creates matrix implying the format of the list is [x1, y1, z1, x2, y2, z2...]
 *
 * @param vertices the list of vertices to put into a matrix. Assuming the format [x1, y1, z1, x2, y2, z2...]
 * @return The resulting matrix
 */
export function convertGeometryMatrixListToMatrix(vertices: number[]): number[][] {
    const matrix: number[][] = []
    for (let x = 0; x < NUMBER_OF_LINE; x++) {
        const currRow: number[] = []
        for (let y = 0; y < NUMBER_OF_LINE; y++) {
            currRow.push(vertices[y * NUMBER_OF_LINE + x])
        }
        matrix[x] = currRow
    }
    return matrix
}

export function flipListValues(list: number[], start: number, step: number): number[] {
    if (list !== null && start > 0) {
        for (let x = start; x < list.length; x += step) {
            list[x] = -list[x]
        }
    }

    return list
}
