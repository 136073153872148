import { styled } from '@paradigm/blueprints-common-frontend'

interface IPanelContentProps {
    height?: string
    minHeight?: string
}

const PanelContent = styled.div<IPanelContentProps>`
    position: relative;
    overflow-x: hidden;
    height: ${(props) => props.height ? props.height : '226px'};
    padding: 10px 15px;
    width: 100%;
    min-height: ${(props) => props.minHeight ? props.minHeight : '100%'};
`

export default PanelContent
