import * as jwt from 'jsonwebtoken'
import { action, autorun, observable, runInAction } from 'mobx'
import { Store } from '../../App/AppStore'
import * as transport from '../common/service/transport'
import { FormStore } from '../common/stores/FormSrore'
import * as authService from './authService'
import { IMyBuildJSONWebToken } from './interfaces'
import { Principal } from "./models/Principal"
import { User } from './models/User'

export class LoginStore extends FormStore<Principal> {
    @observable public loggedIn: boolean = false
    @observable public principal: Principal = new Principal()
    @observable public currentUser: User = new User()
    @observable public isLoading: boolean = false
    @observable public isPasswordReset: boolean = false

    constructor(store: Store) {
        super(store)
        this.bind(this.principal)
        this.setIsPasswordReset = this.setIsPasswordReset.bind(this)
        autorun(() => {
            this.setUserFromToken(this.store.token)
            this.setCompanyIdFromToken(this.store.token)
        })

    }

    @action public setUserFromToken(token: string | null) {
        if (token !== null) {
            transport.useBearerToken(token)
            const decodedToken = jwt.decode(token) as IMyBuildJSONWebToken
            if (decodedToken !== null) {
                this.currentUser.id = decodedToken.id
                this.currentUser.role = decodedToken.role
                this.currentUser.username = decodedToken.sub
                this.loggedIn = true
            }
        } else {
            this.currentUser = new User()
            this.loggedIn = false
        }
    }

    @action public setCompanyIdFromToken(token: string | null) {
        if (token !== null) {
            transport.useBearerToken(token)
            const decodedToken = jwt.decode(token) as IMyBuildJSONWebToken
            if (decodedToken !== null) {
                this.currentUser.company_id = decodedToken.company_id
                this.loggedIn = true
            }
        } else {
            this.currentUser = new User()
            this.loggedIn = false
        }
    }
    @action public onSubmit() {
        this.loggedIn = false

        if (this.form.meta.isValid) {
            authService.authenticate(this.principal.email, this.principal.password)
                .then(response => {
                    if (response.headers.authorization !== undefined) {
                        const authorizationHeader = response.headers.authorization
                        const token = authorizationHeader.substring('Bearer '.length, authorizationHeader.length)
                        this.store.setToken(token)

                        this.setError('')
                    }
                })
                .catch(() => {
                    this.form.meta.isValid = false
                    this.setError('invalidUserNamePassword')

                    runInAction(() => {
                        this.loggedIn = false
                    })
                })
        }
    }

    @action public logout() {
        this.currentUser = new User()
        this.loggedIn = false
        this.store!.login.getFormField('email').value = ''
        this.store!.login.getFormField('password').value = ''
        this.store.setToken(null)
    }

    @action public setIsPasswordReset(isPasswordReset: boolean) {
        this.isPasswordReset = isPasswordReset
    }
}
