import { action, observable, toJS } from 'mobx'
import Validator from 'validatorjs'
import { getField, IFormField } from '../decorators/field'
import { BaseModel } from './BaseModel'

export interface IFormMeta {
    isValid: boolean
    error: string
}

export interface IForm {
    fields: IFormField[]
    meta: IFormMeta
}

export class FormStore<T> extends BaseModel {
    @observable public form: IForm
    @observable protected bindObj: T

    @action public getFields(): IFormField[] {
        const fields: IFormField[] = []
        const objectKeys = this.getGetters()

        objectKeys.forEach((key) => {
            const field = getField(this.bindObj, key)

            if (field !== undefined) {
                fields.push(field)
            }
        })
        return fields
    }

    @action public getFormField(field: string): IFormField {
        const foundFieldIndex = this.form.fields.findIndex((formField) => formField.name === field)

        if (foundFieldIndex !== -1) {
            return this.form.fields[foundFieldIndex]
        } else {
            throw Error(`Invalid field ${field}`)
        }
    }

    @action public bind(obj: T) {
        this.bindObj = obj
        this.form = {
            fields: this.getFields(),
            meta: {
                error: '',
                isValid: true
            }
        }
    }

    @action public updateProperty(key: string, value: any) {
        this.bindObj[key] = value
    }

    @action public onFieldChange(field: string, value: string | number | string[]) {
        const foundFieldIndex = this.form.fields.findIndex((formField) => {
            return formField.name === field
        })

        if (foundFieldIndex !== -1) {
            this.form.fields[foundFieldIndex].value = value

            const validation = new Validator(this.getFlattenedValues('value'), this.getFlattenedValues('rule'))
            this.form.meta.isValid = validation.passes() ? true : false

            this.form.fields[foundFieldIndex].error = validation.errors.first(field) as string

            this.updateProperty(field, value)
        } else {
            this.form.meta.isValid = false
        }
    }

    @action public setError(errMsg: string) {
        this.form.meta.error = errMsg
    }

    private getFlattenedValues(valueKey: string = 'value') {
        const data = {}
        const form = toJS(this.form).fields

        form.map((field, index) => {
            if (field.name) {
                return data[field.name] = form[index][valueKey]
            }
        })

        return data
    }

    private getGetters(): string[] {
        const obj = this.bindObj as any
        return Reflect.ownKeys(obj.constructor.prototype).filter((name) => {
            const propertyDescriptor = Reflect.getOwnPropertyDescriptor(obj.constructor.prototype, name)
            return propertyDescriptor ? typeof propertyDescriptor.get === 'function' : false
        }) as string[]
    }
}
