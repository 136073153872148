import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Redirect } from 'react-router'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { IStore } from '../../../../App/AppStore'
import Logo from '../../../../App/MyBuild-Logo-white.png'
import LoginForm from '../../components/LoginForm'
import PasswordReset from '../../components/PasswordReset'

const LoginWrapper = styled.div`
    background-size: cover;
    height: 100vh;
    background-image: ${props => props.theme.loginBackground};
`

const LoginFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: 100%;
`

const LoginContainer = styled.div`
    background-color: ${props => props.theme.colors.common[0]};
    padding: 2.5% 3%;
    margin-top: 35px;
    display: inline-flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    border-radius: ${props => props.theme.shape.borderRadius};
    width: 30%;
    min-width: 400px;

    input {
        width: 100%;
        height: 50px;
        padding: 0px 10px;
    }
`
const License = styled.div`
    padding: 20px 0px 0px 0px;
    font-size: 14px;
    color: ${props => props.theme.colors.neutrals[0]};
    font-family: ${(props) => props.theme.font.family};
    font-weight: 500;
    text-align: center;
`

@inject('store')
@observer
class Login extends React.Component<IStore> {
    public render() {
        if (this.props.store!.login.loggedIn) {
            return <Redirect to='/' />
        }

        const date = new Date();
        const year = date.getFullYear();

        return (
            <LoginWrapper>
                <LoginFormWrapper>
                    <img alt={"logo"} src={Logo} />
                    <LoginContainer>
                        <LoginForm />
                        <PasswordReset />
                    </LoginContainer>
                    <License>
                        <FormattedMessage id={'license'} values={{ currentYear: year }} />
                    </License>
                </LoginFormWrapper>
            </LoginWrapper>
        )
    }
}

export default withRouter(Login as React.ComponentType<any>)
