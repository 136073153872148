import { SpinnerGif } from '@paradigm/blueprints-common-frontend'
import * as React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'

const fadeIn = keyframes`
    0% {
        opacity: .4;
    }
    100% {
        opacity: .7;
    }
`

const fadeOut = keyframes`
    0% {
        opacity: .7;
    }
    60% {
        opacity: .4;
    }
    100% {
        opacity: 0;
    }
`

const Container = styled.div <{ isVisible?: boolean }>`
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 2;
    background: ${(props) => props.theme.colors.neutrals[0]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    visibility: ${(props) => !props.isVisible ? 'hidden' : 'visible'};
    animation: ${(props) => !props.isVisible ? fadeOut : fadeIn} 4s linear;
    transition: visibility 4s linear;
    opacity: 0.7;
`

const ImageWrapper = styled.div<IChangeAnswerSpinnerProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '250px'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        opacity: .7;
    }
`

interface IChangeAnswerSpinnerProps {
    width?: string
    height?: string
    spinnerSize?: string
    isVisible?: boolean
}

const ChangeAnswerSpinner: React.SFC<IChangeAnswerSpinnerProps> = (props) => {
    return (
        <Container isVisible={props.isVisible}>
            <ImageWrapper>
                <SpinnerGif size={props.spinnerSize} />
            </ImageWrapper>
        </Container>
    )
}

ChangeAnswerSpinner.defaultProps = {
    spinnerSize: '40px'
}

export default ChangeAnswerSpinner
