import { JsonUtils } from '@paradigm/blueprints-common-frontend'
import {
    AbstractMesh, ActionEvent, ActionManager, Angle, Animation, ArcRotateCamera, AssetsManager, Axis,
    BoundingInfo, Camera, Color3, Engine,
    EventState, HemisphericLight, HighlightLayer,
    Material, Matrix, Mesh, MeshBuilder, Node, Path2, PickingInfo, PolygonMeshBuilder, Scene, ShadowGenerator, StandardMaterial, Tools, Vector2, Vector3,
} from 'babylonjs'
import { AdvancedDynamicTexture, Control, Ellipse, Line, TextBlock, Vector2WithInfo } from 'babylonjs-gui'
import { GridMaterial } from 'babylonjs-materials'
import earcut from 'earcut'
import { action, autorun, computed, observable, runInAction } from 'mobx'
import * as UUID from 'uuid'
import { Store } from '../../App/AppStore'
import * as fileStorageApi from '../common/service/fileStorageApi'
import { BaseModel } from '../common/stores/BaseModel'
import { BaseboardMeasurement } from '../takeoff/models/domain/BaseboardMeasurement'
import { BaseMeasurement } from '../takeoff/models/domain/BaseMeasurement'
import { CasingMeasurement } from '../takeoff/models/domain/CasingMeasurement'
import { OpeningMeasurement } from '../takeoff/models/domain/OpeningMeasurement'
import { Takeoff } from '../takeoff/models/domain/Takeoff'
import * as buildingModelService from './buildingModelService'
import * as BabylonUtils from './helpers/babylonUtils'
import * as BuildingModelUtils from './helpers/buildingModelUtils'
import * as GeometryUtils from './helpers/GeometryUtils'
import { MeshTreeNode } from './helpers/MeshTreeNode'
import { DeleteChildrenAction } from './helpers/MeshTreeNodeActions/DeleteChildrenAction'
import { EnableEdgesRenderingAction } from './helpers/MeshTreeNodeActions/EnableEdgesRenderingAction'
import { MakeVisibleAction } from './helpers/MeshTreeNodeActions/MakeVisibleAction'
import * as MetadataUtils from './helpers/MetadataUtils'
import { OpeningLabelManager } from './helpers/OpeningLabelManager'
import { TransformationManager } from './helpers/TransformationManager'
import { enumKeys } from './helpers/utils'
import { IBlueprintImage, IGeometry, IGeometryDTO, IMappingData, IMetadata, IMetadataProperty, IModel, IOpeningMeasurementLabel, IStorey, ITransformationInfo } from './interfaces'
import { Edge } from './models/Edge'
import { FloorOption } from './models/FloorOption'
import { Geometry2D } from './models/Geometry2D'
import { MeshType } from './models/MeshType'
import { Opening } from './models/Opening'
import { QualityAssuranceDetailData } from './models/QualityAssuranceDetailData'
import { RevitConversionHistoryItem } from '../project/models/RevitConversionHistoryItem'

interface IRoom {
    name: string
    storeyId: string
    area: number
    perimeter: number
    elevation: number
    edges: Edge[]
    windows: string[]
    doors: string[]
}

interface ISpace {
    guid: string
    name: string
    storeyId: string
    rawSpaceArea: number
    rawMainRoomArea: number
    rawAdjacentRoomsArea: number
    intersectionAdjacentRoomsArea: number
    intersectionMainRoomArea: number
    rawSpacePerimeter: number
    rawMainRoomPerimeter: number
    rawAdjacentRoomsPerimeter: number
    computedAdjacentRoomsPerimeter: number
    computedMainRoomPerimeter: number
    intersectionAdjacentRoomsPerimeter: number
    elevation: number
    edges: Edge[]
    windows: string[]
    doors: string[]
    objects: string[]
    computedMainRoomPerimeterData: any[]
    computedAdjacentRoomsPerimeterData: any[]
    intersectionMainRoomsPerimeter: number
    openingsId: string[]
}

interface IOpening {
    id: string
    meshId: string
    storeyId: string
    storeyName: string
    type: string
    name: string
    data: string
}

export enum LabelColor {
    PROBLEMATIC = 'RED',
    CORRECTED = 'GREEN',
    SELECTED = 'ORANGE',
    BLACK = 'BLACK'
}

export enum StateFor2D {
    MAPPING_TOOL,
    TAKEOFF
}

export class BuildingModel extends BaseModel {
    @computed get isSaveMappingDataDisabled(): boolean {
        return this.selectedImageId === undefined || this.selectedTag === undefined
    }

    @computed get currentFloor(): FloorOption {
        return this.floors[this.activeKey]
    }

    @computed get HIGHLIGHT_COLOR(): Color3 {
        return Color3.FromHexString(BuildingModel.HIGHLIGHT_COLOR_HEX)
    }

    public static LIGHT_INTENSITY: number = 0.5
    public static SUN_LIGHT_INTENSITY: number = 0.9

    public static DEFAULT_CONTROL_ALPHA: number = 0.5
    public static EXTERIOR_KEY: number = 0
    public static LEFT_KEY: number = 3
    public static RIGHT_KEY: number = 4
    public static HIGHLIGHT_COLOR_HEX = '#FF8C3F'
    public static FRAME_RATE = 5

    public static GROUND_SIZE = 1000
    public static GROUND_ELEVATION = -0.0001

    private static BABYLON_GRID_MATERIAL_DEFAULT_OPACITY = 0.98
    private static BABYLON_GRID_DEFAULT_POSITION_Y = 0.005
    private static BABYLON_CAMERA_DEFAULT_LOWER_RADIUS_LIMIT = 10
    private static BABYLON_CAMERA_DEFAULT_UPPER_RADIUS_LIMIT = 1000
    private static BABYLON_CAMERA_DEFAULT_ANGULAR_SENSIBILITY = 500
    private static BABYLON_CAMERA_DEFAULT_INERTIA = 0.7
    private static BABYLON_CAMERA_DEFAULT_WHEEL_PRECISION = 150
    private static BABYLON_SHADOW_GENERATOR_MAP_SIZE = 4096
    private static BABYLON_LABEL_TEXT_BACKGROUND_COLOR: string = 'transparent'

    private static MAINMESHTREENODE_3D_X_ROTATION_DEGREES: number = 90
    private static MAINMESHTREENODE_2D_Y_ROTATION_DEGREES: number = 180
    private static LINES_2D_X_ROTATION_DEGREES: number = -90

    private static GEOMETRY2D_DEFAULT_THICKNESS = 0.01
    private static GEOMETRY2D_DEFAULT_COLOR = Color3.Black()

    private static TAKEOFF_SELECTION_MATERIAL_ALPHA_IN_2D = 0.25
    private static TAKEOFF_2D_SELECTION_COLOR_HEX_STRING: string = '#1C66D1'
    private static TAKEOFF_DEFAULT_ALPHA = BabylonUtils.DEFAULT_ALPHA_TRANSPARENT

    private static MAPPING_TOOL_DEFAULT_ALPHA = 0.5

    private static OPENING_SIDE_COUNT = 2

    private static BABYLON_CAMERA_ZOOM_FACTOR = 1
    private static LINES_ELEVATION = 0.002
    private static OPENING_ELEVATION = 0.003

    private static GROUP_BY_MESH_ID = 'meshId'

    private static BASEBOARD: string = 'BASEBOARD'
    private static CASING: string = 'CASING'
    private static HEADER: string = 'HEADER'
    private static CUSTOM_HEADER: string = 'CUSTOM_HEADER'
    private static CUSTOM_SILL: string = 'CUSTOM_SILL'
    private static JAMB: string = 'JAMB'
    private static APRON: string = 'APRON'
    private static INTERIOR_DOOR: string = 'INTERIOR_DOOR'
    private static PATIO_DOOR: string = 'PATIO_DOOR'
    private static WINDOW: string = 'WINDOW'
    private static TAKEOFF = 'takeoff'
    private static TRIM = 'TRIM'
    private static ROOT = 'ROOT'
    private static GROUP = 'GROUP'
    private static SUB_GROUP = 'SUB_GROUP'
    private static SUB_GROUP_2 = 'SUB_GROUP_2'

    @observable public activeKey: number = 0
    @observable public floors: FloorOption[] = []
    @observable public modelId: string

    @observable public metaData: IMetadata | undefined
    @observable public meshProperties: IMetadataProperty[] = []
    @observable public qaData: QualityAssuranceDetailData = new QualityAssuranceDetailData()
    @observable public editMode: boolean = false
    @observable public isLoadingScene: boolean = false
    @observable public isLoadingQALabels: boolean = true
    @observable public focusedMesh: Mesh | undefined
    @observable public numberOfWindowsInStorey: number = 0
    @observable public numberOfDoorsInStorey: number = 0
    @observable public currentTotalFinishArea: number = 0
    @observable public model: any

    /* Mapping Tool */
    @observable public elevationLabels: string[] = ['frontElevation', 'rightElevation', 'backElevation', 'leftElevation']
    @observable public selectedImageId: string | undefined
    @observable public selectedTag: string | undefined
    @observable public mappings: Map<string, IMappingData> = new Map()
    @observable public blueprintImages: IBlueprintImage[] = []
    @observable public isSavingMappingData: boolean = false
    @observable public isLoadingModel: boolean = false
    @observable public mapped: boolean = false

    /** For Demo */
    @observable public show3DView: boolean = true

    public advancedTexture: AdvancedDynamicTexture
    public modelToFetch: string
    public storeys: IStorey[] = []

    public currentSelectedQALabelId: string | undefined
    public previousSelectedQALabelId: string | undefined
    public baseBoardLines: Mesh[] = []
    private stateFor2D: StateFor2D | undefined

    private scene: Scene
    private canvas: HTMLCanvasElement
    private ground: Mesh
    private plane: Mesh
    private grid: Mesh
    private shadowGenerators: ShadowGenerator[] = []
    @observable private transformationManager: TransformationManager
    private spaces = new Map<string, ISpace>()
    private openings = new Map<string, IOpening>()
    private metadataById = new Map<string, IMetadata>()

    private meshTypesToLoad: string[] = []
    private selectedPickedPoint: Vector3
    private selectedNormal: Vector3
    private highlightLayer: HighlightLayer
    private mainMeshTreeNode: MeshTreeNode
    private MOUSE_LEFT_BUTTON: number = 0
    private EXTERIOR: string = 'EXTERIOR'

    private DISTANCE_BETWEEN_PLANE_AND_HOUSE_FOR_MAPPING_TOOL: number = 0.02
    private DISTANCE_BETWEEN_HOUSE_AND_OPENING_LABEL_MESH = 0.002
    private DISTANCE_BETWEEN_HOUSE_AND_OPENING_TEXT_MESH = 0.005
    private MAPPING_SCREENSHOT_FILE_CONTAINER = 'mapping'
    private MAPPING_SCREENSHOT_FILE_EXTENSION = '.png'
    private MAPPING_SCREENSHOT_RESOLUTION_DEFAULT = 800

    private camera: ArcRotateCamera

    private darkerGreyMaterial: Material
    private lighterGreyMaterial: Material

    private renderingForMappingTool: boolean = false
    private renderingForQa: boolean = false
    private renderingIn3D: boolean = false
    private controlsForLabel: Control[] = []
    private controlsMeshesForLabel: Mesh[] = []

    private takeoffDefault2DMaterial: StandardMaterial
    private takeoff2DSelectionMaterial: StandardMaterial
    private mappingToolMaterial: StandardMaterial
    private mappingToolTransparentMaterial: StandardMaterial
    private openingLabelManager: OpeningLabelManager

    /** for Monday demo */
    private animationStep: number = 0

    constructor(store: Store) {
        super(store)
        this.meshTypesToLoad = enumKeys(MeshType)

        autorun(() => {
            // tslint:disable-next-line:no-unused-expression
            this.store.projectStore.currentQA.qualityAssuranceDetails.length
            if (this.store.projectStore.currentQA.qualityAssuranceDetails) {
                runInAction(() => {
                    this.isLoadingQALabels = false
                })
            }
        })
    }

    public setMeshTypesToLoad(meshTypeToLoad: string[]) {
        this.meshTypesToLoad = meshTypeToLoad
    }

    public setStateFor2D(state: StateFor2D) {
        this.stateFor2D = state
    }

    public calculateTotalFinishArea(): number {
        let result = 0

        if (this.storeys && this.storeys.length > 0) {
            result = this.storeys.map((storey, index) => this.getStoreyArea(index)).reduce((total, area) => total + area)
        }

        return result
    }

    /* Mapping Tool */
    @computed get isModelMapped(): boolean {
        let mapping: boolean = false

        if (this.model && this.model.mappings) {
            mapping = true
        }

        return mapping
    }

    @action public setModelMapped(mapped: boolean) {
        this.mapped = mapped
    }

    @action public setLoadingModel(loading: boolean) {
        this.isLoadingModel = loading
    }

    public showGrid(toggle: boolean): void {
        if (this.grid) {
            this.grid.visibility = toggle ? 1 : 0
        }
    }

    @action public setSelectedImageId(id: string): void {
        this.selectedImageId = id
    }

    @action public setBlueprintImages(blueprintImages: IBlueprintImage[]) {
        this.blueprintImages = blueprintImages
    }

    @action public setSavingMappingData(saving: boolean) {
        this.isSavingMappingData = saving
    }

    @action public setSelectedTag(tag: string): void {
        this.selectedTag = tag
        const mappingData = this.mappings.get(tag)

        if (mappingData !== undefined) {
            const foundImage = this.blueprintImages.find((image) => image.id === mappingData.imageId)

            if (foundImage !== undefined) {
                this.selectImage(foundImage, mappingData)
            }
        }
    }

    @action public async saveMappingData() {
        if (this.selectedTag !== undefined && this.selectedImageId !== undefined) {
            try {
                this.setSavingMappingData(true)
                const mappingData: IMappingData = {
                    tag: this.selectedTag,
                    imageId: this.selectedImageId
                }

                const transformationInfo = this.transformationManager.retrieveTransformations()

                if (transformationInfo) {
                    mappingData.transformationInfo = JSON.stringify(transformationInfo)
                }

                const floor = this.floors.find((floorOption) => floorOption.label === this.selectedTag)
                if (floor) {
                    mappingData.storeyId = floor.id
                } else {
                    mappingData.imageURL = await this.createMappingElevationScreenshot(this.selectedTag, this.modelToFetch, this.transformationManager.selection)
                }
                runInAction(() => { this.mappings.set(this.selectedTag!, mappingData) })
                await buildingModelService.updateMappingData(this.modelToFetch, this.mappings)
                this.store.notifyUser(this.store.formatMessage('succesSaveMapping'), 'success')
            } catch (error) {
                this.store.notifyUser(this.store.formatMessage('errorSaveMapping'), 'error')
            } finally {
                this.setSavingMappingData(false)
            }
        }
    }
    /* End Mapping Tool */

    @action public enableEditMode(toggle: boolean) {
        this.editMode = toggle
    }

    @action public setFloors(floors: FloorOption[]) {
        this.floors = floors
    }

    @action public setModelId(modelId: string) {
        this.modelId = modelId
        this.initModel()
    }

    @action public setNumberOfWindowsAndDoorsInStorey() {
        if (this.model) {
            const structure = this.model.structure as IModel

            if (this.activeKey === 0) {
                this.numberOfWindowsInStorey = 0
                this.numberOfDoorsInStorey = 0

                this.storeys.forEach((storey) => {
                    this.numberOfWindowsInStorey += BuildingModelUtils.getNumberOfElementInStorey(MeshType.WINDOW, storey.guid,
                        structure.children)
                    this.numberOfDoorsInStorey += BuildingModelUtils.getNumberOfElementInStorey(MeshType.DOOR, storey.guid,
                        structure.children)
                })
            } else {
                this.numberOfWindowsInStorey = BuildingModelUtils.getNumberOfElementInStorey(MeshType.WINDOW, this.storeys[this.activeKey - 1].guid,
                    structure.children)
                this.numberOfDoorsInStorey = BuildingModelUtils.getNumberOfElementInStorey(MeshType.DOOR, this.storeys[this.activeKey - 1].guid,
                    structure.children)
            }
        }
    }

    @action public updateCurrentTotalFinishArea() {
        if (this.model) {
            if (this.activeKey === 0) {
                this.currentTotalFinishArea = this.calculateTotalFinishArea()
            } else {
                this.currentTotalFinishArea = this.getStoreyArea(this.activeKey - 1)
            }
        }
    }

    /** for Monday demo */
    public rotate(isRight: boolean) {
        this.scene.stopAllAnimations()
        const mesh = this.mainMeshTreeNode.mesh as Mesh
        if (isRight) {
            this.animationStep--
            this.animateStoreyForDemo(this.animationStep, isRight)

            if (this.animationStep === 0) {
                this.animationStep = this.storeys.length
                this.setCameraPosition(mesh, true)
            } else {
                this.setCameraPosition(mesh)
            }
        } else {
            this.animateStoreyForDemo(this.animationStep % this.storeys.length, isRight)

            if (this.animationStep === this.storeys.length - 1) {
                this.setCameraPosition(mesh, true)
            } else {
                this.setCameraPosition(mesh)
            }

            this.animationStep = (this.animationStep % this.storeys.length) + 1
        }
    }

    public setCameraPosition(mesh: Mesh, reset?: boolean) {
        const beta = new Animation('betaAngle', 'beta', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
        const alpha = new Animation('alphaAngle', 'alpha', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
        const targetX = new Animation('targetX', 'target.x', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
        const targetY = new Animation('targetY', 'target.y', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
        const targetZ = new Animation('targetZ', 'target.z', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
        const radius = new Animation('radius', 'radius', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)

        const boundingBox = mesh.getBoundingInfo().boundingBox

        const lengthX = (boundingBox.maximum.x - boundingBox.minimum.x) / 2
        const lengthY = (boundingBox.maximum.y - boundingBox.minimum.y) / 2
        const lengthZ = (boundingBox.maximum.z - boundingBox.minimum.z) / 2

        const zDist = Math.max(lengthX, lengthY) / Math.tan(this.camera.fov / 2)

        if (reset) {
            beta.setKeys([{ frame: 0, value: this.camera.beta }, { frame: BuildingModel.FRAME_RATE, value: Math.PI / 180 * 90 }])
            alpha.setKeys([{ frame: 0, value: this.camera.alpha }, { frame: BuildingModel.FRAME_RATE, value: -Math.PI / 180 * 90 }])
            targetX.setKeys([{ frame: 0, value: this.camera.target.x }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
            targetY.setKeys([{ frame: 0, value: this.camera.target.y }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
            targetZ.setKeys([{ frame: 0, value: this.camera.target.z }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
            radius.setKeys([{ frame: 0, value: this.camera.radius }, { frame: BuildingModel.FRAME_RATE, value: zDist + lengthZ * 2 }])
        } else {
            beta.setKeys([{ frame: 0, value: this.camera.beta }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
            alpha.setKeys([{ frame: 0, value: this.camera.alpha }, { frame: BuildingModel.FRAME_RATE, value: -Math.PI / 180 * 90 }])
            targetX.setKeys([{ frame: 0, value: this.camera.target.x }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
            targetY.setKeys([{ frame: 0, value: this.camera.target.y }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
            targetZ.setKeys([{ frame: 0, value: this.camera.target.z }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
            radius.setKeys([{ frame: 0, value: this.camera.radius }, { frame: BuildingModel.FRAME_RATE, value: zDist + lengthZ * 2 }])
        }

        this.scene.beginDirectAnimation(this.camera, [beta, alpha, radius, targetX, targetY, targetZ], 0, BuildingModel.FRAME_RATE, false, 2)
    }

    public animateStoreyForDemo(index: number, goingDown: boolean) {
        const DESTINATION = 50000 // use big value to make the text or label invisible
        const DEPARTURE = 0

        const storeys = this.mainMeshTreeNode.getChildFromType(MeshType.STOREY)

        storeys.forEach((storey, i) => {
            const position = new Animation('positionAnimation', 'position', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
            const visible = new Animation('visibleAnimation', 'visible', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
            if (goingDown) {
                if (i >= index && index !== 0) {
                    visible.setKeys([{ frame: 0, value: storey.visible }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
                    position.setKeys([{ frame: 0, value: storey.position }, { frame: BuildingModel.FRAME_RATE, value: DESTINATION }])

                } else {
                    visible.setKeys([{ frame: 0, value: storey.visible }, { frame: BuildingModel.FRAME_RATE, value: 1 }])
                    position.setKeys([{ frame: 0, value: storey.position }, { frame: BuildingModel.FRAME_RATE, value: DEPARTURE }])
                }
            } else {
                if (i <= index) {
                    visible.setKeys([{ frame: 0, value: storey.visible }, { frame: BuildingModel.FRAME_RATE, value: 1 }])
                    position.setKeys([{ frame: 0, value: storey.position }, { frame: BuildingModel.FRAME_RATE, value: DEPARTURE }])
                } else {
                    visible.setKeys([{ frame: 0, value: storey.visible }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
                    position.setKeys([{ frame: 0, value: storey.position }, { frame: BuildingModel.FRAME_RATE, value: DESTINATION }])
                }
            }

            this.scene.beginDirectAnimation(storey, [position, visible], 0, BuildingModel.FRAME_RATE, false, 2)
        })
    }
    /** End for Monday demo */

    @action public setActiveKey(key: number, id: string) {
        this.removeFocusOnMesh()

        if (this.activeKey > -1) {
            this.floors[this.activeKey].active = false
        }

        this.activeKey = key
        this.changeFloor(key, id)
    }

    @action public changeFloor(floorIndex: number, floorId: string) {
        if (floorIndex === -1) {
            this.mainMeshTreeNode.visible = 0
        } else {
            this.floors[floorIndex].active = true

            if (floorIndex === 0) {
                this.animateFloor(0, true)
            } else {
                for (let i = 1; i < this.floors.length; i++) {
                    if (this.floors[i].id === floorId) {
                        this.animateFloor(i - 1)
                        break
                    }
                }
            }

            this.setNumberOfWindowsAndDoorsInStorey()
            this.updateCurrentTotalFinishArea()
        }
    }

    @action public setActiveFloor(floorOption: FloorOption) {
        const floorIndex = this.floors.findIndex((floor) => floor.id === floorOption.id)

        if (floorIndex > -1) {
            this.removeFocusOnMesh()

            if (this.activeKey > -1) {
                this.floors[this.activeKey].active = false
            }

            this.activeKey = floorIndex
            this.floors[floorIndex].active = true
            this.animateFloor(floorIndex - 1)

            this.setNumberOfWindowsAndDoorsInStorey()
            this.updateCurrentTotalFinishArea()
        }
    }

    @action public setIsLoadingScene(value: boolean) {
        this.isLoadingScene = value
    }

    @action public resetVariables() {
        this.currentSelectedQALabelId = undefined
        this.previousSelectedQALabelId = undefined
        this.renderingForQa = false
        this.renderingForMappingTool = false
        this.activeKey = 0
        this.floors = []
        this.storeys = []

        this.selectedTag = undefined
        this.mappings = new Map()
        this.blueprintImages = []
        this.selectedImageId = undefined

        this.mainMeshTreeNode.dispose()
        this.scene.getEngine().dispose()
        this.scene.dispose()
        this.advancedTexture.dispose()
        this.highlightLayer.dispose()
    }

    public disposeGroupElement(group: string) {
        this.mainMeshTreeNode.executeAction(new DeleteChildrenAction(new Map([[BuildingModel.GROUP, group]])))
    }

    public setRenderingForQa(renderingForQa: boolean) {
        this.renderingForQa = renderingForQa
    }

    public setRenderingForMappingTool(renderingForMappingTool: boolean) {
        this.renderingForMappingTool = renderingForMappingTool
    }

    public createScene(for3D: boolean = true, withGround: boolean = false, initMeshClickHandler = true): void {
        let engine: Engine
        let canvas: HTMLCanvasElement | undefined

        canvas = document.getElementById('rendering-canvas') as HTMLCanvasElement

        if (canvas) {
            canvas.oncontextmenu = (e: any) => {
                e.preventDefault()
            }

            this.canvas = canvas
        }

        engine = new Engine(canvas, true, { stencil: true, doNotHandleContextLost: true, preserveDrawingBuffer: true })
        engine.enableOfflineSupport = false

        this.scene = new Scene(engine)
        this.scene.clearColor = Color3.White().toColor4()

        this.highlightLayer = new HighlightLayer('highlightLayer', this.scene)
        this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('UI')

        this.mainMeshTreeNode = new MeshTreeNode({ id: BuildingModel.ROOT, name: BuildingModel.ROOT })
        this.mainMeshTreeNode.initInvisibleParentMesh(this.scene)

        this.camera = new ArcRotateCamera('camera', -Math.PI / 2, Math.PI / 2, 100, new Vector3(0, 0, 0), this.scene)
        this.camera.attachControl(this.canvas, false, true)

        if (withGround) {
            const groundOptions = { width: BuildingModel.GROUND_SIZE, height: BuildingModel.GROUND_SIZE }
            this.ground = MeshBuilder.CreateGround('ground', groundOptions, this.scene)
            this.ground.position.y = BuildingModel.GROUND_ELEVATION
            this.ground.visibility = 0

            this.grid = MeshBuilder.CreatePlane('Grid', {
                width: BuildingModel.GROUND_SIZE,
                height: BuildingModel.GROUND_SIZE
            }, this.scene)

            this.grid.rotate(Axis.X, -Math.PI / 2)
            this.grid.rotate(Axis.Y, Math.PI)

            const gridMaterial = new GridMaterial('Grid_Material', this.scene)
            gridMaterial.mainColor = Color3.White()
            gridMaterial.lineColor = Color3.Blue()
            gridMaterial.backFaceCulling = false
            gridMaterial.opacity = BuildingModel.BABYLON_GRID_MATERIAL_DEFAULT_OPACITY
            this.grid.material = gridMaterial
            this.grid.visibility = 0
            this.grid.isPickable = false
            this.grid.position.y = BuildingModel.BABYLON_GRID_DEFAULT_POSITION_Y
            this.grid.bakeCurrentTransformIntoVertices()
        }

        if (for3D) {
            this.setCameraParametersFor3D()

            engine.runRenderLoop(() => {
                this.scene.render()

                if (!this.isLoadingQALabels && this.store.projectStore.currentQA.qualityAssuranceDetails) {
                    this.showLabels()
                    this.isLoadingQALabels = true
                }
            })

            if (initMeshClickHandler) {
                this.init3DClickHandler()
            }
        } else {
            this.setCameraParametersFor2D()
            engine.runRenderLoop(() => this.scene.render())
        }

        this.renderingIn3D = for3D

        this.mappingToolMaterial = BabylonUtils.createStandardMaterial(BuildingModel.GEOMETRY2D_DEFAULT_COLOR, this.scene)
        this.mappingToolTransparentMaterial = BabylonUtils.createStandardMaterial(BuildingModel.GEOMETRY2D_DEFAULT_COLOR, this.scene)

        this.takeoffDefault2DMaterial = BabylonUtils.createStandardMaterial(BuildingModel.GEOMETRY2D_DEFAULT_COLOR, this.scene)
        this.takeoff2DSelectionMaterial = BabylonUtils.createStandardMaterial(Color3.FromHexString(BuildingModel.TAKEOFF_2D_SELECTION_COLOR_HEX_STRING), this.scene)

        this.takeoffDefault2DMaterial.alpha = BuildingModel.TAKEOFF_DEFAULT_ALPHA
        this.takeoff2DSelectionMaterial.alpha = BuildingModel.TAKEOFF_SELECTION_MATERIAL_ALPHA_IN_2D

        this.mappingToolMaterial.alpha = BuildingModel.MAPPING_TOOL_DEFAULT_ALPHA
        this.mappingToolTransparentMaterial.alpha = BabylonUtils.DEFAULT_ALPHA_TRANSPARENT

        this.openingLabelManager = new OpeningLabelManager(this.scene, this.camera)
    }

    public setCameraParametersFor3D() {
        this.camera.wheelPrecision = 4
        this.camera.panningSensibility = 30
        this.camera.panningInertia = 0.7
        this.camera.lowerRadiusLimit = 2
        this.camera.checkCollisions = true
        this.camera.fovMode = Camera.FOVMODE_HORIZONTAL_FIXED
    }

    public setCameraParametersFor2D() {
        this.camera.panningSensibility = 250
        this.camera.lowerRadiusLimit = BuildingModel.BABYLON_CAMERA_DEFAULT_LOWER_RADIUS_LIMIT
        this.camera.upperRadiusLimit = BuildingModel.BABYLON_CAMERA_DEFAULT_UPPER_RADIUS_LIMIT
        this.camera.upperBetaLimit = 0
        this.camera.upperAlphaLimit = Math.PI / 2
        this.camera.lowerAlphaLimit = Math.PI / 2
        this.camera.angularSensibilityX = this.camera.angularSensibilityY = BuildingModel.BABYLON_CAMERA_DEFAULT_ANGULAR_SENSIBILITY
        this.camera.inertia = BuildingModel.BABYLON_CAMERA_DEFAULT_INERTIA
        this.camera.wheelPrecision = BuildingModel.BABYLON_CAMERA_DEFAULT_WHEEL_PRECISION

        this.createHemisphericLight('lightX', Axis.X, this.scene)
        this.createHemisphericLight('lightZ', Axis.Z, this.scene)
    }

    public animateFloor(index: number, animateAll?: boolean) {
        const DESTINATION = 50000 // use big value to make the text or label invisible
        const DEPARTURE = 0
        this.scene.stopAllAnimations()

        const storeys = this.mainMeshTreeNode.getChildFromType(MeshType.STOREY)

        for (let i = 0; i < storeys.length; i++) {
            const visible = new Animation('visibleAnimation', 'visible', BuildingModel.FRAME_RATE,
                Animation.ANIMATIONTYPE_FLOAT,
                Animation.ANIMATIONLOOPMODE_CONSTANT
            )

            const position = new Animation('positionAnimation', 'position', BuildingModel.FRAME_RATE,
                Animation.ANIMATIONTYPE_FLOAT,
                Animation.ANIMATIONLOOPMODE_CONSTANT
            )

            const storey = storeys[i]
            if (animateAll || i === index || index >= storeys.length || index < 0) {
                visible.setKeys([{ frame: 0, value: storey.visible }, { frame: BuildingModel.FRAME_RATE, value: 1 }])
                position.setKeys([{ frame: 0, value: storey.position }, { frame: BuildingModel.FRAME_RATE, value: DEPARTURE }])
            } else {
                visible.setKeys([{ frame: 0, value: storey.visible }, { frame: BuildingModel.FRAME_RATE, value: 0 }])
                position.setKeys([{ frame: 0, value: storey.position }, { frame: BuildingModel.FRAME_RATE, value: DESTINATION }])
            }

            this.scene.beginDirectAnimation(storey, [position, visible], 0, BuildingModel.FRAME_RATE, false, 2)
        }
    }

    public initModel(): void {
        this.isLoadingScene = true
        this.isLoadingQALabels = true
        this.isLoadingModel = true

        this.focusedMesh = undefined
        this.metaData = undefined
        this.meshProperties = []
        this.metadataById.clear()
        this.modelToFetch = this.modelId
        let promises: any[] = []
        const metaPromises: any[] = []
        this.darkerGreyMaterial = BabylonUtils.createStandardMaterial(BabylonUtils.DARKER_GREY_COLOR, this.scene)
        this.lighterGreyMaterial = BabylonUtils.createStandardMaterial(BabylonUtils.LIGHTER_GREY_COLOR, this.scene)

        buildingModelService.getModel(this.modelToFetch).then((modelResponse) => {
            const model: any = modelResponse.data
            const structure = model.structure as IModel
            const openingsMap: Map<string, Opening> = JsonUtils.buildMap(model.openings)

            this.populateSpaces(model.spaces)
            this.populateOpenings(openingsMap)

            const floorOpts: FloorOption[] = [new FloorOption(true, UUID.v4(), this.EXTERIOR)]

            this.storeys = BuildingModelUtils.findElementsByType(MeshType.STOREY, structure.children).map((element) => {
                const storey: IStorey = {
                    guid: element.guid,
                    metadata: undefined
                }

                floorOpts.push(new FloorOption(false, storey.guid, element.name))
                return storey
            })

            this.setFloors(floorOpts)
            this.animationStep = this.storeys.length

            this.storeys.forEach((storey) => {
                const promise = new Promise((resolve, reject) => {
                    buildingModelService.getMetadata(this.modelToFetch, storey.guid).then((response) => {
                        storey.metadata = response.data as IMetadata
                        const property = MetadataUtils.getMetadataProperty(storey!.metadata, MetadataUtils.METADATA_PROPERTY_SET_MYBUILD, MetadataUtils.METADATA_PROPERTY_MY_BUILD_STOREY_NAME)
                        const index = this.floors.findIndex((floor) => floor.id === storey.guid)

                        if (index !== -1 && property !== undefined) {
                            runInAction(() => {
                                this.floors[index].label = property.value
                            })
                        }

                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })

                metaPromises.push(promise)
            })

            Promise.all(metaPromises).then((values) => {
                this.storeys.forEach((storey) => {
                    const storeyMeshNode: MeshTreeNode = new MeshTreeNode({ id: storey.guid, type: MeshType.STOREY })
                    storeyMeshNode.initInvisibleParentMesh(this.scene)
                    this.mainMeshTreeNode.addNodeChild(storeyMeshNode)

                    const children = BuildingModelUtils.getChildrenOfElement(storey.guid, structure.children)
                    promises = promises.concat(this.fetchElements(this.meshTypesToLoad, children, storeyMeshNode, storey))
                })

                Promise.all(promises).then(() => {
                    if (this.mainMeshTreeNode.mesh && this.mainMeshTreeNode.hasChildren()) {

                        const node = (this.mainMeshTreeNode.mesh as Node)

                        if (this.renderingIn3D) {
                            this.mainMeshTreeNode.rotate(Axis.X, BuildingModel.MAINMESHTREENODE_3D_X_ROTATION_DEGREES)
                        } else {
                            this.mainMeshTreeNode.rotate(Axis.Y, BuildingModel.MAINMESHTREENODE_2D_Y_ROTATION_DEGREES)
                        }

                        let minMax = this.computeBounds(node)
                        const xTranslation = -(((minMax.maximum.x - minMax.minimum.x) / 2) + minMax.minimum.x)
                        let yTranslation = -(((minMax.maximum.y - minMax.minimum.y) / 2) + minMax.minimum.y)
                        const zTranslation = -(((minMax.maximum.z - minMax.minimum.z) / 2) + minMax.minimum.z)

                        if (this.renderingForMappingTool) {
                            yTranslation = this.DISTANCE_BETWEEN_PLANE_AND_HOUSE_FOR_MAPPING_TOOL
                        }

                        this.mainMeshTreeNode.translate(Axis.X, xTranslation, yTranslation, zTranslation)

                        minMax = this.computeBounds(node)
                        this.mainMeshTreeNode.mesh.setBoundingInfo(minMax)

                        this.mainMeshTreeNode.executeAction(new EnableEdgesRenderingAction(new Map([[BuildingModel.TAKEOFF, BuildingModel.TRIM]])))

                        if (!this.renderingIn3D && this.stateFor2D === StateFor2D.MAPPING_TOOL) {
                            this.mainMeshTreeNode.visible = 0
                        }

                        // Fix until 2d openings are generated in the back end
                        if (!this.renderingIn3D && this.stateFor2D === StateFor2D.TAKEOFF) {
                            this.generateOpenings2D()
                        }

                        runInAction(() => {
                            this.model = modelResponse.data
                            this.isLoadingScene = false
                            this.isLoadingQALabels = false
                            this.setNumberOfWindowsAndDoorsInStorey()
                            this.updateCurrentTotalFinishArea()

                            if (this.renderingForMappingTool && this.model.mappings) {
                                this.mappings = JsonUtils.buildMap(this.model.mappings)
                            }
                            this.isLoadingModel = false
                        })

                        if (this.renderingIn3D) {
                            BabylonUtils.zoomAll(this.mainMeshTreeNode.mesh, this.camera)
                            this.renderPointLights(Vector3.Zero(), BuildingModel.LIGHT_INTENSITY, this.mainMeshTreeNode)
                        }

                        if (!this.store.projectStore.isCurentProjectPreviewAvailable && this.renderingIn3D && !this.renderingForQa) {
                            this.scene.render()
                            this.takeScreenShot()
                        }
                    }
                })
            })
        }).catch(() => {
            runInAction(() => {
                this.isLoadingScene = false
                this.isLoadingQALabels = false
            })
        })
    }

    public takeScreenShot() {
        const fileName = this.modelToFetch + '_SCREENSHOT.png'
        const fileType = 'image/png'

        Tools.CreateScreenshotUsingRenderTarget(this.scene.getEngine(), this.camera, { width: 1600, height: 800 },
            (data) => {
                this.urltoFile(data, fileName, fileType).then((file) => {
                    this.store.projectStore.uploadPreviewImage(file)
                })
            }, fileType, 1, true, fileName)
    }

    public urltoFile(url: string, filename: string, mimeType: string): Promise<File> {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
        return (fetch(url)
            .then((res) => res.arrayBuffer())
            .then((buf) => new File([buf], filename, { type: mimeType }))
        )
    }

    public createRoomSlab(allRoomsString: string[], storeyMeshNode: MeshTreeNode, storeyId: string) {
        const allRooms: IRoom[] = allRoomsString.map((roomString) => JSON.parse(roomString))

        const ROOM_SLAB_THICKNESS = 0.1
        const storeysRooms: IRoom[] = allRooms.filter((room) => room.storeyId === storeyId)
        if (storeysRooms.length > 0) {
            storeysRooms.forEach((room, index) => {
                const points: Vector2[] = []
                room.edges.forEach((edge) => {
                    points.push(new Vector2(edge.p1.x, edge.p1.y))
                })

                const polygonTriangulation = new PolygonMeshBuilder(storeyId + 'room' + index, points, this.scene, earcut)
                const roomSlab: Mesh = polygonTriangulation.build(true, ROOM_SLAB_THICKNESS)
                roomSlab.id = storeyId + 'room' + index
                roomSlab.rotate(Axis.X, -Math.PI / 2)
                roomSlab.translate(Axis.Y, room.elevation + ROOM_SLAB_THICKNESS)
                roomSlab.bakeCurrentTransformIntoVertices()
                storeyMeshNode.addMeshChild(roomSlab, { id: roomSlab.id, type: MeshType.SLAB })
            })
        }
    }

    public selectImage(blueprintImage: IBlueprintImage, mappingData?: IMappingData) {
        this.setIsLoadingScene(true)
        this.setSelectedImageId(blueprintImage.id)
        const assetsManager = new AssetsManager(this.scene)
        const textureTask = assetsManager.addTextureTask('image task', blueprintImage.url)

        textureTask.onSuccess = (task) => {
            if (this.plane) {
                this.plane.dispose()
            }

            const PLANE_WIDTH_RATIO: number = 50

            this.plane = MeshBuilder.CreatePlane('Blueprint_Plane', {
                width: task.texture.getSize().width / PLANE_WIDTH_RATIO,
                height: task.texture.getSize().height / PLANE_WIDTH_RATIO, updatable: true, sideOrientation: Mesh.DOUBLESIDE
            }, this.scene)

            this.plane.rotate(Axis.X, -Math.PI / 2)
            this.plane.rotate(Axis.Y, Math.PI)

            const materialPlane = new StandardMaterial('texturePlane', this.scene)
            materialPlane.diffuseTexture = task.texture

            this.plane.material = materialPlane
            this.plane.bakeCurrentTransformIntoVertices()

            if (this.transformationManager) {
                this.transformationManager.dispose()
            }

            const isForTakeoff = !this.renderingIn3D && this.stateFor2D === StateFor2D.TAKEOFF
            this.transformationManager = new TransformationManager('Blueprint_Pivot', this.plane, this.scene, this.ground, !isForTakeoff)

            if (mappingData && mappingData.transformationInfo) {
                const transformationInfo: ITransformationInfo = JSON.parse(mappingData.transformationInfo)
                this.transformationManager.applyTransformations(transformationInfo)

                if (isForTakeoff) {
                    this.transformationManager.hideAndZoomOnSelection(this.camera, BuildingModel.BABYLON_CAMERA_ZOOM_FACTOR)
                }
            }

            this.setIsLoadingScene(false)
        }

        textureTask.onError = (task) => {
            this.setIsLoadingScene(false)
        }

        assetsManager.useDefaultLoadingScreen = false
        assetsManager.load()
    }

    public onLabelClick(eventData: Vector2WithInfo, eventState: EventState) {
        if (eventData.buttonIndex !== this.MOUSE_LEFT_BUTTON &&
            eventState.currentTarget && this.store.projectStore.currentQA.qualityAssuranceDetails) {

            const name = eventState.currentTarget.name as string

            if (this.currentSelectedQALabelId !== name.substring(0, name.indexOf('|'))) {
                this.currentSelectedQALabelId = name.substring(0, name.indexOf('|'))

                eventState.currentTarget.background = LabelColor.SELECTED
                const qaDetails = this.store.projectStore.currentQA.qualityAssuranceDetails.filter((qaDetail) => {
                    const data = JSON.parse(qaDetail.data) as QualityAssuranceDetailData
                    return data.labelId === this.currentSelectedQALabelId ||
                        (this.previousSelectedQALabelId !== undefined && this.previousSelectedQALabelId === data.labelId)
                })

                qaDetails.forEach((qaDetail, index) => {
                    const data = JSON.parse(qaDetail.data) as QualityAssuranceDetailData
                    if (data.labelId === this.previousSelectedQALabelId) {

                        if (this.previousSelectedQALabelId) {
                            this.advancedTexture.executeOnAllControls((control) => {

                                if (control instanceof Line) {
                                    const line = control as Line

                                    if (line.connectedControl.name === this.previousSelectedQALabelId + '|circle') {
                                        const circle = (line.connectedControl as Ellipse)
                                        line.color = LabelColor[qaDetail.status.code]
                                        circle.background = LabelColor[qaDetail.status.code]
                                    }
                                }
                            })
                        }
                    } else {
                        runInAction(() => {
                            this.store.projectStore.currentQADetail = qaDetail
                        })
                        this.enableEditMode(true)
                    }
                })

                this.previousSelectedQALabelId = this.currentSelectedQALabelId
            }
        }
    }

    public createLabel(mesh: AbstractMesh, pickedPoint: Vector3, normal: Vector3, statusCode: string, labelId: string): void {
        const DIAMETER = 0.1
        const LINE_WIDTH = 2.5
        const LINE_LENGTH = 10
        const CIRCLE_RAY = '25px'

        const matrix = new Matrix()
        mesh.getWorldMatrix().invertToRef(matrix)
        const localSpacePickedPoint = Vector3.TransformCoordinates(pickedPoint, matrix)

        const startMesh = MeshBuilder.CreateSphere(labelId + '|start', { diameter: DIAMETER }, this.scene)
        startMesh.parent = mesh
        startMesh.position.x = localSpacePickedPoint.x
        startMesh.position.y = localSpacePickedPoint.y
        startMesh.position.z = localSpacePickedPoint.z

        const endMesh = MeshBuilder.CreateSphere(labelId + '|end', { diameter: DIAMETER }, this.scene)
        endMesh.parent = mesh
        endMesh.position.x = LINE_LENGTH * normal.x + localSpacePickedPoint.x
        endMesh.position.y = LINE_LENGTH * normal.y + localSpacePickedPoint.y
        endMesh.position.z = LINE_LENGTH * normal.z + localSpacePickedPoint.z

        this.controlsMeshesForLabel.push(startMesh, endMesh)

        const circle = new Ellipse(labelId + '|circle')

        if (this.currentSelectedQALabelId === labelId) {
            circle.background = LabelColor.SELECTED
        } else {
            circle.background = LabelColor[statusCode]
        }

        circle.width = CIRCLE_RAY
        circle.height = CIRCLE_RAY
        circle.color = LabelColor.BLACK
        this.advancedTexture.addControl(circle)
        circle.linkWithMesh(endMesh)
        circle.onPointerClickObservable.add(
            (eventData: Vector2WithInfo, eventState: EventState) => this.onLabelClick(eventData, eventState))

        const line = new Line(labelId + '|line')
        line.alpha = BuildingModel.DEFAULT_CONTROL_ALPHA
        line.color = LabelColor[statusCode]
        line.lineWidth = LINE_WIDTH
        this.advancedTexture.addControl(line)
        line.linkWithMesh(startMesh)
        line.connectedControl = circle

        this.controlsForLabel.push(circle, line)
    }

    /**
     * This method focuses the Mesh matching the provided mesh ID. Only one mesh can be focused at a time. That mesh will be focused by the camera and highlighted.
     * @param meshId The ID of the Mesh to focus.
     */
    public focusMeshById(meshId: string) {
        const newFocusedMesh: Mesh = this.scene.getMeshByID(meshId) as Mesh
        this.focusMesh(newFocusedMesh)
    }

    /**
     * This method adds a highlight to the Mesh matching the provided mesh ID. The onus is on the consumer to remember which meshes are highlighted.
     * @param meshId The ID of the Mesh to add a highlight to.
     */
    public addMeshHighlightById(meshId: string) {
        const meshToHighlight: Mesh = this.scene.getMeshByID(meshId) as Mesh
        this.addMeshHighlight(meshToHighlight)
    }

    /**
     * This method removes the highlight from the Mesh matching the provided mesh ID, if any. The onus is on the consumer to remember which meshes are highlighted.
     * However, calling this method with the ID of the focused mesh will not remove its highlight.
     * @param meshId The ID of the Mesh to remove the highlight from.
     */
    public removeMeshHighlightById(meshId: string) {
        const meshToUnhighlight: Mesh = this.scene.getMeshByID(meshId) as Mesh
        this.removeMeshHighlight(meshToUnhighlight)
    }

    public showHideSubGroupElement(flagsMap: Map<string, string>, show: boolean, elementIds?: string[]) {
        if (this.currentFloor) {
            const storey = this.mainMeshTreeNode.getChildFromType(MeshType.STOREY).find((child) => child.getInfo().id === this.currentFloor.id)

            if (storey) {
                this.mainMeshTreeNode.executeAction(new MakeVisibleAction(flagsMap, show, elementIds))
            }
        }
    }

    private async createMappingElevationScreenshot(imageName: string, modelId: string, meshToScreenShot: Mesh): Promise<string | undefined> {
        const storageFileContainer = this.MAPPING_SCREENSHOT_FILE_CONTAINER

        // if the model id or the image name is empty, stop
        if (modelId.length === 0 || imageName.length === 0) {
            return
        }
        const fileName = modelId + '/' + imageName + this.MAPPING_SCREENSHOT_FILE_EXTENSION

        // try to delete the image if it was already created
        try {
            await fileStorageApi.files.deleteFile(storageFileContainer, fileName)
        } catch (error) {
            // the image wasn't existing
        }

        // generate elevation screenshot images (exclude floor mapping)
        const selectionVisibility: number = meshToScreenShot.visibility
        meshToScreenShot.visibility = 0
        const screenshotCreated: boolean = await BabylonUtils.screenshotMeshAndUploadToFileStorage(this.scene, fileName, storageFileContainer, meshToScreenShot, this.MAPPING_SCREENSHOT_RESOLUTION_DEFAULT)
        meshToScreenShot.visibility = selectionVisibility

        if (!screenshotCreated) {
            return
        }
        return '/' + storageFileContainer + '/' + fileName
    }

    private focusMesh(mesh: Mesh) {
        if (mesh) {
            this.removeFocusOnMesh()

            if (this.renderingIn3D) {
                this.addMeshHighlight(mesh)
                this.smoothAnimationCameraTarget(mesh)
            }

            this.focusedMesh = mesh
        }
    }

    private removeFocusOnMesh() {
        if (this.focusedMesh) {
            if (this.renderingIn3D) {
                this.removeMeshHighlight(this.focusedMesh, true)
                this.metaData = undefined
                this.meshProperties = []
            } else {
                if (this.stateFor2D === StateFor2D.TAKEOFF) {
                    this.focusedMesh.material = this.takeoffDefault2DMaterial
                }
            }

            this.focusedMesh = undefined
        }
    }

    private addMeshHighlight(mesh: Mesh) {
        if (mesh) {
            this.highlightLayer.addMesh(mesh, this.HIGHLIGHT_COLOR)
        }
    }

    private removeMeshHighlight(mesh: Mesh, removeFocusedHighlight?: boolean) {
        if (mesh) {
            if (removeFocusedHighlight || mesh !== this.focusedMesh) {
                this.highlightLayer.removeMesh(mesh)
            }
        }
    }

    private create2DMeshFromGeometry(geometry2D: Geometry2D, metadataId: string): Mesh {
        const points: Vector2[] = []

        geometry2D.figures.forEach((figure) => {
            figure.edges.forEach((edge) => {
                points.push(new Vector2(edge.p1.x, edge.p1.y))
                points.push(new Vector2(edge.p2.x, edge.p2.y))
            })
        })

        const polygonTriangulation = new PolygonMeshBuilder(metadataId, points, this.scene, earcut)
        const mesh: Mesh = polygonTriangulation.build(true, BuildingModel.GEOMETRY2D_DEFAULT_THICKNESS)
        mesh.id = metadataId
        mesh.bakeCurrentTransformIntoVertices()

        return mesh
    }

    private getStoreyArea(index: number): number {
        let area = 0
        const areaProperty = MetadataUtils.getMetadataProperty(this.storeys[index].metadata, MetadataUtils.METADATA_PROPERTY_SET_MYBUILD, MetadataUtils.METADATA_PROPERTY_STOREY_AREA)

        if (areaProperty !== undefined) {
            area = Math.ceil(areaProperty.value)
        }

        return area
    }

    private createLines(edges: Edge[], color: Color3, name: string): Mesh[] {
        const lines: Mesh[] = []
        edges.forEach((edge) => {
            edge.p1.z = BuildingModel.LINES_ELEVATION
            edge.p2.z = BuildingModel.LINES_ELEVATION
            lines.push(BabylonUtils.createLine(edge, color, name, this.scene))
        })

        return lines
    }

    private showLabels() {
        if (this.renderingForQa && this.store.projectStore.currentQA.qualityAssuranceDetails) {
            this.controlsForLabel.forEach((control) => {
                this.advancedTexture.removeControl(control)
            })

            this.controlsMeshesForLabel.forEach((mesh) => {
                mesh.dispose()
            })

            this.controlsForLabel = []
            this.controlsMeshesForLabel = []

            this.store.projectStore.currentQA.qualityAssuranceDetails.forEach((detail, i) => {
                const data = JSON.parse(detail.data) as QualityAssuranceDetailData
                const mesh = this.scene.getMeshByID(data.meshId)
                if (mesh !== null && mesh !== undefined) {
                    this.createLabel(mesh, data.points, data.normals, detail.status.code, data.labelId)
                }
            })
        }
    }


    /**
     * @param geometryResponse holds all information to render the meshes
     * @param meshType is one MeshType's values
     * @param storeyMeshNode the node that represent the parent
     * @param storey the storey
     */
    private generate3DGeometry(geometryResponse: any, meshType: string, storeyMeshNode: MeshTreeNode, storey: IStorey, elements: IModel[]) {

        const metadataIDToGeometries: Map<string, IGeometry> = GeometryUtils.transformDataGeometries(geometryResponse.data, elements)
        const metadatas = geometryResponse.data.metadata
        for (const metadataId in metadatas) {
            if (metadatas[metadataId] && metadataIDToGeometries.get(metadataId)) {
                let mesh: Mesh | undefined
                const geometry: IGeometry | undefined = metadataIDToGeometries.get(metadataId)

                if ((geometry !== undefined && meshType !== MeshType.SPACE) || (meshType === MeshType.SPACE && this.renderingForQa)) {
                    if (meshType === MeshType.DOOR || meshType === MeshType.WINDOW) {
                        if (geometry !== undefined) {
                            mesh = this.create3DOpeningMeshFromGeometry(geometry, metadataId)
                        }
                    } else if (meshType === MeshType.SPACE) {
                        if (geometry !== undefined) {
                            mesh = this.create3DSpaceMeshFromGeometryForQa(geometry, metadatas[metadataId], metadataId, storey)
                        }
                    } else {
                        if (geometry !== undefined) {
                            mesh = this.create3DMeshFromGeometry(geometry, metadataId, meshType)
                        }
                    }
                }

                if (mesh !== undefined) {
                    this.metadataById.set(metadataId, (metadatas[metadataId] as IMetadata))
                    mesh.receiveShadows = true
                    storeyMeshNode.addMeshChild(mesh, { id: metadataId, type: meshType })
                }
            }
        }
    }

    private init3DClickHandler() {
        this.scene.onPointerDown = (event: PointerEvent, pickResult: PickingInfo) => {
            if (pickResult.hit && event.button === this.MOUSE_LEFT_BUTTON && pickResult.pickedMesh !== null && pickResult.pickedMesh.visibility !== 0) {
                const normal = pickResult.getNormal(false, true)

                runInAction(() => {
                    if (pickResult.pickedMesh !== null && pickResult.pickedPoint !== null && normal !== null) {
                        this.selectedPickedPoint = pickResult.pickedPoint
                        this.selectedNormal = normal
                        this.qaData.labelId = UUID.v4()
                        this.qaData.meshId = pickResult.pickedMesh.id
                        this.qaData.normals = this.selectedNormal
                        this.qaData.points = this.selectedPickedPoint

                        this.focusMesh(pickResult.pickedMesh as Mesh)
                    }
                })

                if (this.renderingForQa) {
                    this.populateMeshPropertiesForFocusedMesh()
                }
            }
        }
    }

    private smoothAnimationCameraTarget(mesh: Mesh) {
        const targetX = new Animation('targetX', 'target.x', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
        const targetY = new Animation('targetY', 'target.y', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
        const targetZ = new Animation('targetZ', 'target.z', BuildingModel.FRAME_RATE, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)

        const originalXPosition = this.camera.target.x
        const originalYPosition = this.camera.target.y
        const originalZPosition = this.camera.target.z

        const finalXPosition = mesh.getBoundingInfo().boundingBox.center.x
        const finalYPosition = mesh.getBoundingInfo().boundingBox.center.y
        const finalZPosition = mesh.getBoundingInfo().boundingBox.center.z

        const frameNumbers = [0, 25, 35, 43, 50, 57, 65, 75, 100]
        const midXValue = (finalXPosition - originalXPosition) / (frameNumbers.length - 1)
        const midYValue = (finalYPosition - originalYPosition) / (frameNumbers.length - 1)
        const midZValue = (finalZPosition - originalZPosition) / (frameNumbers.length - 1)

        const xKeys: any = []
        const yKeys: any = []
        const zKeys: any = []

        for (let index = 0; index < frameNumbers.length; index++) {
            const frameNumber = frameNumbers[index]
            xKeys.push({ frame: frameNumber, value: originalXPosition + (midXValue * index) })
            yKeys.push({ frame: frameNumber, value: originalYPosition + (midYValue * index) })
            zKeys.push({ frame: frameNumber, value: originalZPosition + (midZValue * index) })
        }

        targetX.setKeys(xKeys)
        targetY.setKeys(yKeys)
        targetZ.setKeys(zKeys)

        this.scene.beginDirectAnimation(this.camera, [targetX, targetY, targetZ], 0, 100, false, 20)
    }

    @action private populateMeshPropertiesForFocusedMesh() {
        this.meshProperties = []
        if (this.focusedMesh !== undefined) {
            this.metaData = this.metadataById.get(this.focusedMesh.id)

            if (this.metaData) {
                for (const propertySetName in this.metaData.propertySets) {
                    if (propertySetName !== MetadataUtils.METADATA_PROPERTY_SET_GEOMETRY) {
                        for (const propertyName in this.metaData.propertySets[propertySetName]) {
                            if (this.metaData.propertySets[propertySetName].hasOwnProperty(propertyName)) {
                                this.meshProperties.push(this.metaData.propertySets[propertySetName][propertyName])
                            }
                        }
                    }
                }
            }
        }
    }

    private computeBounds(node: Node) {
        const min = new Vector3(Infinity, Infinity, Infinity)
        const max = new Vector3(-Infinity, -Infinity, -Infinity)
        const descendants = node.getDescendants()

        for (const descendant of descendants) {
            const test = (descendant as Mesh)
            if (!test.isVisible || test.name === MeshTreeNode.GROUPING_MESH) {
                continue
            }

            const bounds = test.getBoundingInfo()

            if (bounds.minimum.x + test.position.x < min.x) {
                min.x = bounds.minimum.x + test.position.x
            }
            if (bounds.minimum.y + test.position.y < min.y) {
                min.y = bounds.minimum.y + test.position.y
            }
            if (bounds.minimum.z + test.position.z < min.z) {
                min.z = bounds.minimum.z + test.position.z
            }
            if (max.x < bounds.maximum.x + test.position.x) {
                max.x = bounds.maximum.x + test.position.x
            }
            if (max.y < bounds.maximum.y + test.position.y) {
                max.y = bounds.maximum.y + test.position.y
            }
            if (max.z < bounds.maximum.z + test.position.z) {
                max.z = bounds.maximum.z + test.position.z
            }
        }

        return new BoundingInfo(min, max)
    }

    private createHemisphericLight(name: string, axis: Vector3, scene: Scene) {
        // tslint:disable-next-line:no-unused-expression
        new HemisphericLight(name, axis, scene)
    }

    private fetchElements(meshTypes: string[], children: IModel[], storeyMeshNode: MeshTreeNode, storey: IStorey): any[] {
        const promises: any[] = []
        return promises
    }

    private renderPointLights(direction: Vector3, intensity: number, node: MeshTreeNode, withShadow: boolean = true) {
        const boundingBox = node.mesh!.getBoundingInfo().boundingBox
        const height = Math.abs(boundingBox.maximum.y) + Math.abs(boundingBox.minimum.y)
        const pos = height * (3 / 4) + (height / 2)

        const sun = new HemisphericLight('SunHemisphericLight', new Vector3(0, 500, 0), this.scene)
        sun.intensity = BuildingModel.SUN_LIGHT_INTENSITY

        const light1 = BabylonUtils.createPoinLight('PointLight1', new Vector3(0, pos - (height / 2), -(boundingBox.maximum.z + pos)), direction, intensity, this.scene)
        const light2 = BabylonUtils.createPoinLight('PointLight2', new Vector3((boundingBox.maximum.x + pos), pos - (height / 2), boundingBox.maximum.z + pos / 2), direction, intensity, this.scene)
        const light3 = BabylonUtils.createPoinLight('PointLight3', new Vector3(-(boundingBox.maximum.x + pos), pos - (height / 2), boundingBox.maximum.z + pos / 2), direction, intensity, this.scene)

        if (withShadow) {
            const shadowGenerator1 = new ShadowGenerator(BuildingModel.BABYLON_SHADOW_GENERATOR_MAP_SIZE, light1)
            const shadowGenerator2 = new ShadowGenerator(BuildingModel.BABYLON_SHADOW_GENERATOR_MAP_SIZE, light2)
            const shadowGenerator3 = new ShadowGenerator(BuildingModel.BABYLON_SHADOW_GENERATOR_MAP_SIZE, light3)

            this.shadowGenerators.push(shadowGenerator1)
            this.shadowGenerators.push(shadowGenerator2)
            this.shadowGenerators.push(shadowGenerator3)

            const meshes = node.getAllChildren().map((child) => (child.mesh))

            this.shadowGenerators.forEach((shadowGenerator) => {
                shadowGenerator.usePercentageCloserFiltering = true
                shadowGenerator.filteringQuality = ShadowGenerator.QUALITY_HIGH

                if (shadowGenerator.getShadowMap() && shadowGenerator.getShadowMap()!.renderList) {
                    shadowGenerator.getShadowMap()!.renderList!.push(...meshes)
                }
            })
        }
    }

    private create3DMeshFromGeometry(geometry: IGeometry, metadataId: string, meshType?: string): Mesh {
        let mesh = BabylonUtils.createCustomMesh(geometry.vertices, geometry.indices, this.scene)
        mesh = this.changeMeshDetailsAndBake(metadataId, this.assignMaterialFromMeshType(meshType), mesh)
        return mesh
    }

    private create3DOpeningMeshFromGeometry(geometry: IGeometry, metadataId: string): Mesh {
        const geometryHasTransparency = BabylonUtils.materialsContainTransparency(geometry.materials)
        let mesh: Mesh

        if (geometryHasTransparency) {
            const vertexData = BabylonUtils.createVertexDataToShowTranparency(geometry.vertices, geometry.indices, geometry.materialIndices, geometry.materials)
            mesh = BabylonUtils.createCustomMeshFormVertexData(vertexData, this.scene)
            mesh = this.changeMeshDetailsAndBake(metadataId, this.lighterGreyMaterial, mesh)
        } else {
            mesh = BabylonUtils.createCustomMesh(geometry.vertices, geometry.indices, this.scene)
            mesh = this.changeMeshDetailsAndBake(metadataId, this.darkerGreyMaterial, mesh)
        }

        mesh.hasVertexAlpha = geometryHasTransparency

        return mesh
    }

    private create3DSpaceMeshFromGeometryForQa(geometry: IGeometry, metadata: IMetadata, metadataId: string, storey?: IStorey): Mesh {
        const elevationProperty = MetadataUtils.getMetadataProperty(storey!.metadata, MetadataUtils.METADATA_PROPERTY_SET_ELEMENT_SPECIFIC,
            MetadataUtils.METADATA_PROPERTY_ELEVATION)

        if (elevationProperty !== undefined) {
            const elevation = elevationProperty.value as string
            for (let i = 0; i < geometry.vertices.length; i += 3) {
                geometry.vertices[i + 2] = -elevation.replace(',', '.') - 0.5
            }
        }

        let mesh = BabylonUtils.createCustomMesh(geometry.vertices, geometry.indices, this.scene)
        mesh = this.changeMeshDetailsAndBake(metadataId, this.darkerGreyMaterial, mesh)

        const longNameProperty = MetadataUtils.getMetadataProperty(metadata, MetadataUtils.METADATA_PROPERTY_SET_ELEMENT_SPECIFIC,
            MetadataUtils.METADATA_PROPERTY_LONGNAME)

        if (longNameProperty !== undefined) {
            const text = new TextBlock()
            text.text = longNameProperty.value as string
            text.color = 'red'
            text.fontSize = 15
            this.advancedTexture.addControl(text)
            text.linkWithMesh(mesh)
        }

        return mesh
    }

    private changeMeshDetailsAndBake(metadataId: string, material: Material, mesh: Mesh): Mesh {
        mesh.name = metadataId
        mesh.id = metadataId
        mesh.material = material
        mesh.bakeCurrentTransformIntoVertices()
        return mesh
    }

    private assignMaterialFromMeshType(meshType?: string): Material {
        let material: Material

        if (meshType === MeshType.SLAB || meshType === MeshType.ROOF) {
            material = this.darkerGreyMaterial
        } else {
            material = this.lighterGreyMaterial
        }

        return material
    }

    private processCasingMeasurements(materialId: string, measurements: CasingMeasurement[], categoryCode: string, isJamb: boolean, colorInHex: string) {
        const storeys = this.mainMeshTreeNode.getChildFromType(MeshType.STOREY)
        const meshNodesFlags = new Map<string, string>()
        meshNodesFlags.set(BuildingModel.GROUP, BuildingModel.TAKEOFF)
        meshNodesFlags.set(BuildingModel.SUB_GROUP, categoryCode)

        measurements.forEach((measurement) => {
            const opening = this.openings.get(measurement.openingId)

            if (opening) {
                const edgesMap = JSON.parse(opening.data)
                let openingEdges: Edge[] = []

                if (isJamb) {
                    const casing: Edge[] = edgesMap[BuildingModel.CASING]
                    const header: Edge[] = edgesMap[BuildingModel.HEADER]

                    if (casing) {
                        openingEdges = openingEdges.concat(casing)
                    }

                    if (header) {
                        openingEdges = openingEdges.concat(header)
                    }
                } else {
                    openingEdges = edgesMap[measurement.measurementType.code]
                }

                if (openingEdges !== undefined) {
                    const lines = this.createLines(openingEdges, Color3.FromHexString(colorInHex), BuildingModel.TAKEOFF)

                    if (!this.renderingIn3D) {
                        lines.forEach((line) => {
                            line.rotate(Axis.X, Angle.FromDegrees(BuildingModel.LINES_2D_X_ROTATION_DEGREES).radians())
                        })
                    }

                    const measurementStorey = storeys.find((storey) => storey.getInfo().id === measurement.storeyId)

                    if (measurementStorey) {
                        const nodes = measurementStorey.addMeshChildren(lines, { id: measurement.id, type: materialId })
                        nodes.forEach((node) => {
                            node.setFlags(meshNodesFlags)
                        })
                    }
                }
            }
        })
    }

    private processBaseboardMeasurements(materialId: string, measurements: BaseboardMeasurement[], categoryCode: string, colorInHex: string) {
        const storeys = this.mainMeshTreeNode.getChildFromType(MeshType.STOREY)
        const meshNodesFlags = new Map<string, string>()
        meshNodesFlags.set(BuildingModel.GROUP, BuildingModel.TAKEOFF)
        meshNodesFlags.set(BuildingModel.SUB_GROUP, categoryCode)

        measurements.forEach((measurement) => {
            const space = this.spaces.get(measurement.spaceId)

            if (space) {
                const baseboardEdges: Edge[] = space.computedMainRoomPerimeterData.concat(space.computedAdjacentRoomsPerimeterData)
                const lines = this.createLines(baseboardEdges, Color3.FromHexString(colorInHex), BuildingModel.TAKEOFF)

                if (!this.renderingIn3D) {
                    lines.forEach((line) => {
                        line.rotate(Axis.X, Angle.FromDegrees(BuildingModel.LINES_2D_X_ROTATION_DEGREES).radians())
                    })
                }

                const measurementStorey = storeys.find((storey) => storey.getInfo().id === measurement.storeyId)

                if (measurementStorey) {
                    const nodes = measurementStorey.addMeshChildren(lines, { id: measurement.id, type: materialId })
                    nodes.forEach((node) => {
                        node.setFlags(meshNodesFlags)
                    })
                }
            }
        })
    }

    private processOpeningMeasurements(materialId: string, measurements: OpeningMeasurement[], categoryCode: string, colorInHex: string, doorMaterial: StandardMaterial) {
        const storeys = this.mainMeshTreeNode.getChildFromType(MeshType.STOREY)
        const meshNodesFlags = new Map<string, string>()
        meshNodesFlags.set(BuildingModel.GROUP, BuildingModel.TAKEOFF)
        meshNodesFlags.set(BuildingModel.SUB_GROUP, categoryCode)
        meshNodesFlags.set(BuildingModel.SUB_GROUP_2, 'INDIVIDUAL')

        const meshNodesFlags2 = new Map<string, string>()
        meshNodesFlags2.set(BuildingModel.GROUP, BuildingModel.TAKEOFF)
        meshNodesFlags2.set(BuildingModel.SUB_GROUP, categoryCode)
        meshNodesFlags2.set(BuildingModel.SUB_GROUP_2, 'GROUP')

        measurements.forEach((measurement) => {
            const mesh = this.scene.getMeshByID(measurement.meshId) as Mesh
            let label
            let grouplabel

            if (mesh !== null) {
                label = BabylonUtils.createSquareLabel(measurement.orderNumber + '', colorInHex, Color3.White().toHexString(), this.scene)
                grouplabel = BabylonUtils.createDiscLabel(measurement.material.label + '', colorInHex, Color3.White().toHexString(), this.scene)

                this.processLabel(label, doorMaterial, mesh)
                this.processLabel(grouplabel, doorMaterial, mesh)

                const storeyNode = storeys.find((storey) => storey.getInfo().id === measurement.storeyId)

                this.openingLabelManager.addLabelVisibilityAnimation(label)

                this.openingLabelManager.addLabelVisibilityAnimation(grouplabel)
                grouplabel.mesh.isVisible = false
                grouplabel.plane.isVisible = false

                if (storeyNode) {
                    const nodes = storeyNode.addMeshChildren([label.mesh, label.plane], { id: measurement.id, type: materialId }, false)
                    nodes.forEach((node) => {
                        node.setFlags(meshNodesFlags)
                    })

                    const nodesGroup = storeyNode.addMeshChildren([grouplabel.mesh, grouplabel.plane], { id: measurement.id, type: materialId }, false)
                    nodesGroup.forEach((node) => {
                        node.setFlags(meshNodesFlags2)
                    })
                }
            }
        })
    }

    private processLabel(label: IOpeningMeasurementLabel, material: StandardMaterial, mesh: Mesh) {
        label.mesh.material = material

        const meshPosition = mesh.getBoundingInfo().boundingBox.center
        label.mesh.position = meshPosition.clone()
        label.plane.position = meshPosition.clone()

        label.mesh.rotate(Axis.X, Math.PI / 2)
        label.plane.rotate(Axis.X, Math.PI / 2)
        label.plane.rotate(Axis.Z, Math.PI)
        label.mesh.translate(BabylonUtils.AXIX_NEGATIVE_Z, this.DISTANCE_BETWEEN_HOUSE_AND_OPENING_LABEL_MESH)
        label.plane.translate(BabylonUtils.AXIX_NEGATIVE_Z, this.DISTANCE_BETWEEN_HOUSE_AND_OPENING_TEXT_MESH)

        label.mesh.bakeCurrentTransformIntoVertices()
        label.plane.bakeCurrentTransformIntoVertices()
    }

    private populateSpaces(spacesResponse: any) {
        this.spaces.clear()

        if (spacesResponse) {
            for (const id in spacesResponse) {
                if (spacesResponse.hasOwnProperty(id)) {
                    this.spaces.set(id, JSON.parse(spacesResponse[id]) as ISpace)
                }
            }
        }
    }

    private populateOpenings(openingsMap: Map<string, Opening>) {
        this.openings.clear()

        openingsMap.forEach((value, key) => {
            this.openings.set(key, value as Opening)
        })
    }

    private generateOpenings2D() {
    }

    public async fetchTranslationsByExternalId(externalId: string): Promise<RevitConversionHistoryItem[]> {
        let result: RevitConversionHistoryItem[] = []

        try {
            const response = await buildingModelService.translationService.fetchTranslationByExternalId(externalId)
            result = response.data as RevitConversionHistoryItem[]
            result.forEach(item => {
                if (item.cruncherLogUrl !== null) {
                    item.cruncherLogUrl = `${fileStorageApi.FILE_STORAGE_API}/${fileStorageApi.FILES_ENDPOINT}${item.cruncherLogUrl}`
                }
            })
        } catch (error) {
            console.error(error)
        }

        return result
    }
}
