import { PrimaryButton, styled } from '@paradigm/blueprints-common-frontend'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IStore } from '../../../App/AppStore'

const ProjectHeaderContainer = styled.div`
	z-index: 1;
	background-color: ${(props) => props.theme.colors.common[0]};
	filter: drop-shadow(1px 0px 2px ${(props) => props.theme.colors.neutrals[3]});
	margin-bottom: 2px !important;
	border-color: ${(props) => props.theme.colors.neutrals[3]};

	display: flex;
	flex-direction: row;
	padding-top: 15px;
	padding-bottom: 15px;

	width: 100%;
	padding-right: 5%;
	padding-left: 5%;
	margin-right: auto;
	margin-left: auto;

	@media all and (max-width: 1300px) {
		flex-direction: column;
	}
`

const ProjectDetailsName = styled.div`
	text-align: center;
	font-family: ${(props) => props.theme.font.family};
	font-size: 18px;
	color: ${(props) => props.theme.colors.secondary};
	font-weight: 500;
	letter-spacing: 1px;

	a:first-child {
		color: ${(props) => props.theme.colors.secondary};
		display: block;
		max-width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`

const LeftWrapper = styled.div`
	display: flex;
	width: 300px;
	justify-content: flex-start;
`

const CenterWrapper = styled.div`flex: 2 auto;`

const RightWrapper = styled.div`
	display: flex;
	width: 300px;
	justify-content: flex-end;
`
@inject('store')
@observer
class CreateProjectHeader extends React.Component<IStore> {
	constructor(props: Readonly<IStore>) {
		super(props)
		this.handleSaveProject = this.handleSaveProject.bind(this)
	}

	public handleSaveProject = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const { projectStore } = this.props.store!
		projectStore.createProject()
	}

	public render() {
		return (
			<React.Fragment>
				<ProjectHeaderContainer>
					<LeftWrapper />
					<CenterWrapper>
						<ProjectDetailsName>Project Details</ProjectDetailsName>
					</CenterWrapper>
					<RightWrapper>
						<PrimaryButton onClick={this.handleSaveProject}>
							<FormattedMessage id={'Save and Continue'} defaultMessage={'Save and Continue'} />
						</PrimaryButton>
					</RightWrapper>
				</ProjectHeaderContainer>
			</React.Fragment>
		)
	}
}

export default CreateProjectHeader
