import { action, observable } from 'mobx'
import { Store } from '../../App/AppStore'
import { FormStore } from '../common/stores/FormSrore'
import { Email } from "./models/Email"

export class ResetPasswordStore extends FormStore<Email> {
    @observable public reCaptchaToken?: string | null = null
    @observable public email: Email = new Email()

    constructor(store: Store) {
        super(store)
        this.bind(this.email)
    }

    @action public onResetPasswordSubmit() {
        if (this.form.meta.isValid) {
            //Call Some api to reset password
        }
    }

    @action public onSetReCaptchaToken(reCaptchaToken?: string | null) {
        this.reCaptchaToken = reCaptchaToken
    }
}
