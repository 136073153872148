import * as transport from './transport'

export const FILE_STORAGE_API: string = '/filestorage/api'
export const FILES_ENDPOINT: string = 'files'

export const files = {
    // GET /files/{filePath}
    fetchFile: (filePath: string) => {
        if (filePath.startsWith('/')) {
            filePath = filePath.substr(1)
        }

        return transport.get(`${FILE_STORAGE_API}/${FILES_ENDPOINT}/${filePath}`)
    },

    // PUT /files/{filePath}
    storeFile: (container: string, path: string, file: File) => {
        return transport.put(`${FILE_STORAGE_API}/${FILES_ENDPOINT}/${container}/${path}`, file)
    },

    // DELETE /files/{filePath}
    deleteFile: (container: string, path: string) => {
        return transport.del(`${FILE_STORAGE_API}/${FILES_ENDPOINT}/${container}/${path}`)
    },
}
