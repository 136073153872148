import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { ControlLabel, FormControl, FormGroup, Well } from 'react-bootstrap'
import { Async } from 'react-select'
import { IStore } from '../../../App/AppStore'
import { QualityAssuranceDetailMessages } from './QualityAssuranceDetailMessages'


@inject('store')
@observer
export class QualityAssuranceDetail extends React.Component<IStore> {
    constructor(props: Readonly<IStore>) {
        super(props)

        this.getQADetailsStatusOptions = this.getQADetailsStatusOptions.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this)
        this.handleCommentChange = this.handleCommentChange.bind(this)
    }

    public getQADetailsStatusOptions() {
        return this.props.store!.projectStore.loadQADetailsStatusOptions()
    }

    public handleStatusChange(selectedOption: any) {
        this.props.store!.projectStore.setQADetailStatus(selectedOption)
    }

    public handleCommentChange(event: any) {
        this.props.store!.projectStore.setQADetailComment(event.target.value)
    }

    public render() {
        return (
            <Well>
                {this.props.store!.projectStore.currentQADetail.id &&
                    <p>
                        <small>
                            {this.props.store!.formatMessage('createdBy')} {this.props.store!.projectStore.currentQADetail.from}
                            {' ' + this.props.store!.formatMessage('on') + ' '}
                            {this.props.store!.projectStore.currentQADetail.formatedCreatedDate + ' '} |
                            {' ' + this.props.store!.formatMessage('uploadedOn') + ' '}
                            {this.props.store!.projectStore.currentQADetail.formatedModifiedDate}
                        </small>
                    </p>
                }
                <FormGroup controlId='qa-detail-status'>
                    <ControlLabel>{this.props.store!.formatMessage('status')}</ControlLabel>
                    <Async
                        name='qa-detail-status'
                        cacheOptions={true}
                        defaultOptions={true}
                        value={this.props.store!.projectStore.qaDetailStatusAsSelectOption}
                        loadOptions={this.getQADetailsStatusOptions}
                        onChange={this.handleStatusChange}
                        isClearable={true}
                    />
                </FormGroup>
                <FormGroup controlId='qa-comment'>
                    <ControlLabel>{this.props.store!.formatMessage('comment')}</ControlLabel>
                    <FormControl
                        name='qa-comment'
                        componentClass={'textarea'}
                        rows={5}
                        value={this.props.store!.projectStore.currentQADetail.comment}
                        onChange={this.handleCommentChange}
                    />
                </FormGroup>
                {this.props.store!.projectStore.currentQADetail.id && <QualityAssuranceDetailMessages />}
            </Well>
        )
    }
}
