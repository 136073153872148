import { observable } from "mobx"

import { JsonProperty } from "jsog-typescript"
import { Alternative } from "./Alternative"
import { AssetBundleLabel } from "../../builderProject/models/AssetBundleLabel"

export class AssetBundle {
    public id: string
    public version: number
    public createdDate: number
    public createdBy: string
    public modifiedDate: number
    public deletedDate: number
    @observable public sceneUrl: string = ''
    @observable public assetUrl: string = ''
    @observable public externalAssetBundleLabelId: string
    @JsonProperty(Alternative)
    @observable public alternative: Alternative
}