import * as React from "react"

interface IImageCarouselItemImageProps {
    index: number,
    value: any,
    onImageHover: (value: any, index: number) => void
}

const ImageCarouselItemImage: React.SFC<IImageCarouselItemImageProps> = ({ index, value, onImageHover, ...props }) => (
    <div key={index + 1}>
        <img src={value} onMouseEnter={onImageHover.bind(value, index)} />
    </div>
)

export default ImageCarouselItemImage
