import { inject, observer } from "mobx-react"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import { PrimaryButton, TextField, styled } from '@paradigm/blueprints-common-frontend'
import { IStore } from "../../../../App/AppStore"
import ReCaptcha from "./ReCaptcha"

const EmailContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const EmailInput = styled(TextField)`
    width: 100%;
    height: 50px;
    padding: 0px 10px;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    >button {
        width: 100%;
        margin: 0px;
        text-transform: capitalize;

        &:disabled {
            &:hover {
                &:after {
                    background: ${(props) => props.theme.action.disabled};
                }
            }
        }
    }
`

const PasswordResetHeader = styled.div`
    font-family: ${(props) => props.theme.font.family};
    font-size: 24px;
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
`

const PasswordResetInfo = styled.div`
    font-family: ${(props) => props.theme.font.family};
    font-size: 16px;
    color: ${(props) => props.theme.colors.secondary};
    text-align: center;
    padding: 20px 0px 50px 0px;
`

const BackToSignIn = styled.a`
    font-family: ${(props) => props.theme.font.family};
    font-size: 14px;
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 500;
    text-align: center;
    text-decoration: underline;
    padding: 10px;
    cursor: pointer;
`

const InputContainer = styled.div`
    padding-bottom: 40px;
`

@inject("store")
@observer
class PasswordReset extends React.Component<IStore> {
    constructor(props: Readonly<IStore>) {
        super(props)

        this.onSubmit = this.onSubmit.bind(this)
    }

    public onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        this.props.store!.resetPassword.onResetPasswordSubmit()
    }

    public onChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.props.store!.resetPassword.onFieldChange(
            event.target.name,
            event.target.value
        )
    }

    public render() {
        const email = this.props.store!.resetPassword.getFormField("email")

        const passwordResetStyle = {
            display: this.props.store!.login.isPasswordReset ? 'block' : 'none'
        }

        return (
            <form onSubmit={this.onSubmit} style={passwordResetStyle}>
                <EmailContainer>
                    <PasswordResetHeader><FormattedMessage id={'forgotYourPassword'} /></PasswordResetHeader>
                    <PasswordResetInfo><FormattedMessage id={'resetPasswordInfo'} /></PasswordResetInfo>
                    <InputContainer>
                        <EmailInput
                            onChange={this.onChange.bind(this)}
                            isRequired={true}
                            value={email.value}
                            name={email.name}
                            errorText={email.error}
                            type={'email'}
                            label={<FormattedMessage id='email' />} />
                    </InputContainer>
                    <ReCaptcha />
                    <ButtonContainer>
                        <PrimaryButton disabled={this.props.store!.resetPassword.reCaptchaToken === null || this.props.store!.resetPassword.reCaptchaToken === undefined}>
                            <FormattedMessage id={'sendResetLink'} />
                        </PrimaryButton>
                    </ButtonContainer>
                    <BackToSignIn onClick={() => this.props.store!.login.setIsPasswordReset(false)}><FormattedMessage id={'backToSignIn'} /></BackToSignIn>
                </EmailContainer>
            </form>
        )
    }
}

export default PasswordReset
