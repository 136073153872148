import { JsonProperty } from 'jsog-typescript'
import { action, computed, observable } from 'mobx'
import { User } from '../../auth/models/User'
import { field } from '../../common/decorators/field'
import placeholder from '../components/placeholder.jpg'
import { projectService } from '../ProjectService'
import { Alternative } from './Alternative'
import { Builder } from './Builder'
import { Country } from './Country'
import { Revision } from './Revision'
import { State } from './State'
import { Status } from './Status'
import { StatusHistory } from './StatusHistory'

export class Project {
    public id: string
    public createdDate: number
    public createdBy: string
    public modifiedDate: number
    public deletedDate: number
    public version: number
    @field({ label: 'name', type: 'text', rule: 'required' })
    @observable public name: string = ''
    @observable public status: Status
    @observable public ownerUserId: string
    @observable public assigneeUserId: string
    @observable public previewImage: string
    @observable public alternatives: Alternative[] | null = []
    @observable public statusesHistory: StatusHistory[] = []
    @observable public newMessageBy: string | null
    @observable public currentRevision: Revision
    @observable public hasPreviewImage: boolean = false
    @observable public currentAssignedUser: User = new User()
    @observable public company_id: string
    @observable public builder: Builder
    @observable public externalBuilderId: string | null
    @observable public externalClientId: string | null
    @field({ label: 'addressLine1', type: 'text', rule: 'required' })
    @observable public addressLine1: string = ''
    @observable public addressLine2: string = ''
    @observable public city: string = ''
    @JsonProperty(Country)
    @observable public country: Country
    @JsonProperty(State)
    @observable public state: State
    @observable public zipCode: string = ''


    @computed get lastRevision() {
        let result = new Revision()

        if (this.alternatives && this.alternatives.length > 0) {
            const sortedAlternatives = this.alternatives.slice().sort((a, b) => a.createdDate - b.createdDate)
            const sortedRevisions = sortedAlternatives[0].revisions.slice().sort((a, b) => a.revisionNumber - b.revisionNumber)
            result = sortedRevisions[0]
        }

        return result
    }

    @computed get formatedCreatedDate(): string {
        if (this.createdDate !== undefined) {
            return new Date(this.createdDate).toLocaleString()
        }
        return ''
    }

    @computed get upperCaseName(): string {
        if (this.name !== undefined) {
            return this.name.toUpperCase()
        }
        return ''
    }

    @computed get isInProgress(): boolean {
        return this.previewImage === placeholder
    }

    @action public getPreviewImage() {
        this.previewImage = projectService.getPreviewImage(this.id, 'PREVIEW')
    }

    @action public onErrorPreviewImage() {
        this.previewImage = placeholder
        this.hasPreviewImage = false
    }

    @action public setCurrentRevision(revision: Revision) {
        this.currentRevision = revision
    }

    @computed get orderedStatusesHistory(): StatusHistory[] {
        let list: StatusHistory[] = []

        if (this.statusesHistory && this.statusesHistory.length > 0) {
            list = this.statusesHistory.slice().sort((a, b) => a.createdDate - b.createdDate)
        }

        return list
    }
    @computed get formatedLastModifiedDate(): string {
        if (this.modifiedDate !== undefined) {
            return new Date(this.modifiedDate).toLocaleString()
        }
        return ''
    }
}
