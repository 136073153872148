import IntlMessageFormat from 'intl-messageformat'
import { action, computed, observable, reaction } from 'mobx'
import { notify } from 'react-notify-toast'
import { LoginStore } from '../modules/auth/LoginStore'
import { ResetPasswordStore } from '../modules/auth/ResetPasswordStore'
import BuilderProjectStore from '../modules/builderProject/BuilderProjectStore'
import { BuildingModel } from '../modules/buildingModel/BuildingModelStore'
import * as transport from '../modules/common/service/transport'
import { ProjectStore } from '../modules/project/ProjectStore'
import { message } from './locale/message'

const JWT_LOCAL_STORAGE_KEY = 'jwt'

/**
 * Used to define the props type of a react component when only refering to the store
 *
 * @inject('store')   // This will be available as  this.prop.store
 * @observer          // Mobx auto-binding
 * export class MyReactComponent extends React.Component<IStore> {
 */
export interface IStore { store?: Store }
/**
 * This is the main store for the application.
 * All the state, UI or otherwise gets stored here and is dispatched automatically
 * using the @observable pattern
 */
export class Store {
    public appName = 'MyBuild'
    public locale = 'en-US'

    @observable public token = window.localStorage.getItem(JWT_LOCAL_STORAGE_KEY)

    @observable public login: LoginStore = new LoginStore(this)
    @observable public resetPassword: ResetPasswordStore = new ResetPasswordStore(this)
    @observable public projectStore: ProjectStore = new ProjectStore(this)
    @observable public buildingModel: BuildingModel = new BuildingModel(this)
    @observable public builderProjectStore: BuilderProjectStore = new BuilderProjectStore(this)

    constructor() {
        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem(JWT_LOCAL_STORAGE_KEY, token)
                } else {
                    window.localStorage.removeItem(JWT_LOCAL_STORAGE_KEY)
                }
                transport.useBearerToken(token)
            }
        )

        transport.handleResponse(
            (response: any) => response,
            (error: any) => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            this.login.logout()
                            break

                        default:
                            break
                    }
                }
                return Promise.reject(error)
            })

    }

    @action public setToken(token: string | null) {
        this.token = token
    }

    @action public getToken() {
        return this.token
    }

    @computed get user() {
        return this.login.currentUser
    }

    @action public formatMessage(messageId: string, defaultMessage: string = '') {
        if (message && message.hasOwnProperty(this.locale) && message[this.locale].hasOwnProperty(messageId)) {
            return new IntlMessageFormat(message[this.locale][messageId], this.locale).format()
        } else {
            return defaultMessage
        }
    }

    @action
    public getLocalizedDescription(description: string): string {
        let localizedDescription: string = ''

        if (description !== '') {
            try {
                localizedDescription = JSON.parse(description)[(this.locale.replace(/[-_][a-z]+$/i, ''))]
            } catch (error) {
                console.log(error)
                // do nothing here, we will return empty string value
            }
        }

        return localizedDescription
    }

    public formatDate(date: string | number | Date): string | null {
        let formattedDate = null

        if (date !== undefined) {
            formattedDate = new Date(date).toLocaleString(this.locale)
        }

        return formattedDate
    }

    public notifyUser(notifyMessage: string, type: 'success' | 'error' | 'warning' | 'custom', timeout: number = 5000) {
        notify.show(notifyMessage, type, timeout)
    }

    public hideUserNotifications() {
        notify.hide()
    }
}
