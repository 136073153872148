import { JsogService } from 'jsog-typescript'
import { action, computed, observable, runInAction } from 'mobx'
import { Store } from '../../App/AppStore'
import * as authService from '../auth/authService'
import { User, UserRole } from '../auth/models/User'
import { BaseModel } from '../common/stores/BaseModel'
import { FileFunction } from './enums/FileFunction'
import { Alternative } from './models/Alternative'
import { AlternativeInput } from './models/AlternativeInput'
import { CriteriaQuery } from './models/CriteriaQuery'
import { DesignOption } from './models/DesignOption'
import { Message } from './models/Message'
import { Project } from './models/Project'
import { QualityAssurance } from './models/QualityAssurance'
import { QualityAssuranceDetail } from './models/QualityAssuranceDetail'
import { Revision } from './models/Revision'
import { RevisionFile } from './models/RevisionFile'
import { RevisionInput } from './models/RevisionInput'
import { Status } from './models/Status'
import { ProjectFormModel } from './ProjectFormStore'
import { alternativeService, designOptionService, projectService, qaService, revisionService, statusService } from './ProjectService'
import { PublishedHistoryItem } from './models/PublishedHistoryItem'
import { AssetBundle } from './models/AssetBundle'
import { AssetBundleLabel } from '../builderProject/models/AssetBundleLabel'

export class ProjectStore extends BaseModel {
    @observable public projects: Project[] = []
    @observable public isLoading: boolean = false
    @observable public hasMore: boolean = true
    @observable public criteriaQuery: CriteriaQuery = new CriteriaQuery(this.store)

    @observable public projectForm: ProjectFormModel = new ProjectFormModel(this.store)
    @observable public currentProject: Project = new Project()
    @observable public revisionFile: RevisionFile | null
    @observable public isUploading: boolean = false

    @observable public currentQA: QualityAssurance = new QualityAssurance()
    @observable public qaStatuses: Status[] = []
    @observable public currentQaStatus: Status = new Status()
    @observable public currentQADetail: QualityAssuranceDetail = new QualityAssuranceDetail()
    @observable public qaDetailStatuses: Status[] = []
    @observable public isLoadingQA: boolean = false
    @observable public isSavingQA: boolean = false
    @observable public savingProject: boolean = false

    @observable public currentAlternative: Alternative = new Alternative()
    @observable public currentRevision: Revision = new Revision()
    @observable public currentDesignOption: DesignOption = new DesignOption()

    constructor(store: Store) {
        super(store)
        this.projectForm.bind(this.currentProject)
    }

    @computed get hasNewMessage() {
        const { user } = this.store
        const { newMessageBy } = this.currentProject

        return newMessageBy !== null && user !== null && newMessageBy !== user.id
    }

    @action public async deleteProject(projectId: string): Promise<boolean> {
        let result = false
        try {
            await projectService.delete(projectId)
            result = true
        } catch (error) {
            console.error(error)
        }
        return result
    }

    // Aternative
    @action public async addAlternative(alternativeInput: AlternativeInput, image: File): Promise<Alternative | null> {
        try {
            const response = await alternativeService.add(alternativeInput, image)
            const jsog = new JsogService()
            const savedAlternative = jsog.deserialize(response.data, Alternative) as Alternative
            runInAction(() => {
                this.currentProject.alternatives!.push(savedAlternative)
            })
            return savedAlternative
        } catch (error) {
            console.error(error)
            return null
        }
    }

    @action public async updateAlternative(alternativeId: string, alternativeInput: AlternativeInput, image?: File): Promise<Alternative | null> {
        try {
            const response = await alternativeService.update(alternativeId, alternativeInput, image)
            const jsog = new JsogService()
            const savedAlternative = jsog.deserialize(response.data, Alternative) as Alternative

            const alternatives = this.currentProject.alternatives!
            const index = alternatives.findIndex((a) => a.id === alternativeId)
            if (index !== -1) {
                runInAction(() => {
                    alternatives[index] = savedAlternative
                })
            }
            return savedAlternative
        } catch (error) {
            console.error(error)
            return null
        }
    }

    @action public async deleteAlternative(alternativeId: string): Promise<Alternative | null> {
        let result: Alternative | null = null
        try {
            const deletedAlternative = await alternativeService.delete(alternativeId)
            result = deletedAlternative.data
        } catch (error) {
            console.error(error)
        }
        return result
    }

    @action public async loadRevisionFiles(revision: Revision): Promise<RevisionFile[] | []> {
        let result: RevisionFile[] = []

        if (revision.id !== undefined) {
            try {
                this.isLoading = true
                const response = await revisionService.getFiles(revision.id)
                const jsog = new JsogService()
                const files = jsog.deserialize(response.data, RevisionFile) as RevisionFile[]
                runInAction(() => {
                    revision.revisionFiles = files
                })
                result = files
            } catch (error) {
                console.error(error)
            } finally {
                runInAction(() => this.isLoading = false)
            }
        }

        return result
    }

    @action public setCurrentAlternative(alternativeId: string) {
        const alternative = this.currentProject.alternatives!.find((a) => a.id === alternativeId)

        if (alternative === undefined) {
            this.currentAlternative = new Alternative()
            this.currentRevision = new Revision()
            this.currentDesignOption = new DesignOption()
        } else {
            this.currentAlternative = alternative

            if (this.currentAlternative.revisions.length > 0) {
                this.setCurrentRevision(this.currentAlternative.revisions[0])
                this.loadRevisionFiles(this.currentRevision)
                this.loadDesignOptions(this.currentRevision)
                this.loadQualityAssurances(this.currentRevision)
            }
        }
    }

    @computed get alternativeAsSelectOption(): any {
        if (this.currentAlternative.id !== undefined) {
            return { value: this.currentAlternative.id, label: this.currentAlternative.name }
        }
        return null
    }

    @computed get orderedAlternatives(): Alternative[] {
        let list: Alternative[] = []

        if (this.currentProject.alternatives && this.currentProject.alternatives.length > 0) {
            list = this.currentProject.alternatives.slice().sort((a, b) => a.createdDate - b.createdDate)
        }

        return list
    }

    @computed get alternativesAsSelectOptions(): any[] {
        return this.orderedAlternatives.map((alternative) => ({ value: alternative.id, label: alternative.name }))
    }

    // Revision
    @action public setCurrentRevision(revision: Revision) {
        this.currentRevision = revision
    }

    @action public async saveRevisionCamerasData(revisionId: string, revisionInput: RevisionInput): Promise<Revision | null> {
        try {
            const response = await revisionService.saveCamerasData(revisionId, revisionInput)
            const jsog = new JsogService()
            const savedRevision = jsog.deserialize(response.data, Revision) as Revision
            return savedRevision
        } catch (error) {
            console.error(error)
            return null
        }
    }

    // Design Options
    @action public async loadDesignOptions(revision: Revision): Promise<DesignOption[] | []> {
        let result: DesignOption[] = []

        if (revision.id !== undefined) {
            try {
                this.isLoading = true
                const response = await revisionService.getDesignOptions(revision.id)
                const jsog = new JsogService()
                const options = jsog.deserialize(response.data, DesignOption) as DesignOption[]
                runInAction(() => {
                    revision.options = options
                })
                result = revision.options
            } catch (error) {
                console.error(error)
                result = []
            } finally {
                runInAction(() => this.isLoading = false)
            }
        }

        return result
    }

    @action public async loadDesignOptionFiles(designOption: DesignOption): Promise<RevisionFile[] | []> {
        let result: RevisionFile[] = []

        if (designOption.id !== undefined) {
            try {
                this.isLoading = true
                const response = await designOptionService.getFiles(designOption.id)
                const jsog = new JsogService()
                const files = jsog.deserialize(response.data, RevisionFile) as RevisionFile[]
                runInAction(() => {
                    designOption.files = files
                })
                result = files
            } catch (error) {
                console.error(error)
                result = []
            } finally {
                runInAction(() => this.isLoading = false)
            }
        }

        return result
    }

    @action public setCurrentDeisgnOption(designOptionId: string) {
        const option = this.currentRevision.options.find((a) => a.id === designOptionId)

        if (option !== undefined) {
            this.currentDesignOption = option
            this.loadDesignOptionFiles(this.currentDesignOption)
        } else {
            this.currentDesignOption = new DesignOption()
        }
    }

    @action public updateDesignOptionProperty(key: string, value: any) {
        this.currentDesignOption[key] = value
    }

    @computed get designOptionAsSelectOption(): any {
        if (this.currentDesignOption.id !== undefined) {
            return { value: this.currentDesignOption.id, label: this.currentDesignOption.name }
        }
        return null
    }

    @computed get orderedDesignOptions(): DesignOption[] {
        let list: DesignOption[] = []

        if (this.currentRevision.options && this.currentRevision.options.length > 0) {
            list = this.currentRevision.options.slice().sort((a, b) => a.createdDate - b.createdDate)
        }

        return list
    }

    @computed get designOptionsAsSelectOptions(): any[] {
        return this.orderedDesignOptions.map((option) => ({ value: option.id, label: option.name }))
    }

    @action public reset() {
        this.criteriaQuery.pageNumber = CriteriaQuery.DEFAULT_PAGE_NUMBER
        this.hasMore = true
        this.projects = []
        this.currentProject = new Project()
    }

    @action public refreshData() {
        this.reset()
        this.loadProjects()
    }

    @action public loadProjects() {
        if (this.hasMore) {
            this.isLoading = true
            projectService.getAll(this.criteriaQuery)
                .then((response) => {
                    runInAction(() => {
                        if (response.data.length > 0) {
                            response.data.forEach((element: any) => {
                                const project: Project = Object.assign(new Project(), element)
                                const projectIndex = this.projects.findIndex((p) => p.id === project.id)
                                if (projectIndex === -1) {
                                    this.projects.push(project)
                                }
                            })
                            this.criteriaQuery.pageNumber += 1
                        } else {
                            this.hasMore = false
                        }
                        this.isLoading = false
                    })
                })
                .catch(() => runInAction(() => {
                    this.isLoading = false
                }))
        }
    }

    @action public newProject() {
        this.currentProject = new Project()
        this.projectForm.bind(this.currentProject)
        this.revisionFile = null
    }

    @computed public get isNewProject() {
        return this.currentProject.id === undefined || this.currentProject.id === null
    }

    @computed public get didQADetailChange() {
        return this.store.projectStore.currentQA.qualityAssuranceDetails !== undefined
    }

    @action public async getProject(id: string, loadModel: boolean = false): Promise<Project | null> {
        if (id) {
            this.currentProject = new Project()
            this.isLoading = true

            if (this.store.user.isQA && this.currentProject.id && this.hasNewMessage) {
                this.currentProject.newMessageBy = null
            }

            const response = await projectService.getById(id)
            try {
                const project: Project = Object.assign(new Project(), response.data)

                const alternatives = project.alternatives

                if (alternatives && alternatives.length > 0) {
                    project.alternatives = alternatives.map((element) => {
                        return Object.assign(new Alternative(), element)
                    })

                    project.alternatives.forEach((alternative) => {
                        alternative.revisions = alternative.revisions.map((element) => Object.assign(new Revision(), element))
                        alternative.assetBundles = alternative.assetBundles.map((element) => {
                            const assetBundle = Object.assign(new AssetBundle(), element)
                            return assetBundle
                        })
                    })
                }

                runInAction(() => {
                    this.currentProject = project
                    this.fetchAssignedUser()

                    if (this.currentProject.alternatives && this.currentProject.alternatives.length > 0) {
                        this.setCurrentAlternative(this.orderedAlternatives[0].id)
                    }

                    if (loadModel && this.currentProject.lastRevision) {
                        this.store.buildingModel.setModelId(this.currentProject.lastRevision!.buildingModelId)
                    }

                    this.projectForm.bind(this.currentProject)

                    this.isLoading = false
                })
                return this.currentProject
            } catch (error) {
                console.error(error)
                return null
            }
        } else {
            this.newProject()
            return null
        }
    }

    @action public async createProject() {
        this.savingProject = true
        try {
            this.currentProject.createdBy = this.store.user.id
            this.currentProject.ownerUserId = this.store.user.id
            this.currentProject.company_id = this.store.user.company_id

            const jsog = new JsogService()
            const response = await projectService.create(jsog.serialize(this.currentProject))
            const project = Object.assign(new Project(), response.data)
            project.alternatives.map((element: any) => {
                return Object.assign(new Alternative(), element)
            })

            if (project.alternatives && project.alternatives.length > 0 && project.alternatives[0].revisions) {
                project.alternatives[0].revisions = project.alternatives[0].revisions.map((element: any) => {
                    return Object.assign(new Revision(), element)
                })
            }
            
            runInAction(() => {
                this.currentProject = project
            })

            if (this.revisionFile !== null) {
                await revisionService.saveFileUpload(this.currentProject.lastRevision!.id, this.revisionFile)
            }
            this.store.notifyUser(this.store.formatMessage('succesSaveProject'), 'success')
        } catch (error) {
            if (error.response.status === 400) {
                this.store.notifyUser(error.response.data.customMessage, 'error')
            } else {
                this.store.notifyUser(this.store.formatMessage('unexpectedError'), 'error')
                console.error(error)
            }
        } finally {
            runInAction(() => {
                this.savingProject = false
            })
        }
    }

    @action public updateProject(project: Project) {
        const projectToUpdate = Object.assign(new Project(), project)
        projectToUpdate.alternatives = null
        projectService.update(projectToUpdate)
            .then((response) => {
                const updatedProject: Project = Object.assign(new Project(), response.data)

                runInAction(() => {
                    this.currentProject.version = updatedProject.version
                    this.currentProject.name = updatedProject.name
                    this.currentProject.modifiedDate = updatedProject.modifiedDate
                    this.currentProject.addressLine1 = updatedProject.addressLine1

                    this.projectForm.bind(this.currentProject)
                })

                this.store.notifyUser(this.store.formatMessage('succesSaveProject'), 'success')
            })
    }

    @action public isAssignedToCurrentUser(project: Project): boolean {
        return this.store.user.id === project.ownerUserId
    }

    @action public fetchAssignedUser() {
        if (this.currentProject.assigneeUserId) {
            authService.getUserById(this.currentProject.assigneeUserId)
                .then((response) => runInAction(() => {
                    this.currentProject.currentAssignedUser = Object.assign(new User(), response.data)
                }))
        }
    }

    @action public handleFileUpload(file: File, fileFunction: string = FileFunction.SOURCE) {
        this.revisionFile = new RevisionFile()
        this.revisionFile.data = file
        this.revisionFile.fileType = file.type
        this.revisionFile.fileSize = file.size
        this.revisionFile.lastModifiedDate = file.lastModified
        this.revisionFile.name = file.name
        this.revisionFile.extension = file.name.split('.').pop()
        this.revisionFile.fileFunction = fileFunction
    }

    @action public getFiles(revision: Revision) {
        revisionService.getFiles(revision.id)
            .then((response) => {
                runInAction(() => {
                    revision.revisionFiles = response.data.map((element: any) => {
                        return Object.assign(new RevisionFile(), element)
                    })
                })
            })
    }

    @action public async uploadFile(revision: Revision, waitResponse: boolean): Promise<RevisionFile | null> {
        this.isUploading = true
        if (this.revisionFile !== null) {
            try {
                if (waitResponse) {
                    const response = await revisionService.saveFileUpload(revision.id, this.revisionFile)
                    const jsog = new JsogService()
                    const file = jsog.deserialize(response.data, RevisionFile) as RevisionFile
                    return file
                } else {
                    revisionService.saveFileUpload(revision.id, this.revisionFile)
                    return null
                }
            } catch (error) {
                console.error(error)
            } finally {
                runInAction(() => this.isUploading = false)
            }
        }

        return null
    }

    @computed get isCurentProjectPreviewAvailable(): boolean {
        let hasPreview = true

        if (this.projects.length > 0) {
            const foundIndex = this.projects.findIndex((project) => project.id === this.currentProject.id)
            if (foundIndex !== -1) {
                hasPreview = this.projects[foundIndex].hasPreviewImage
            }
        }

        return hasPreview
    }

    @action public uploadPreviewImage(file: File) {
        this.handleFileUpload(file, FileFunction.PREVIEW)

        this.isUploading = true

        if (this.revisionFile !== null) {
            revisionService.saveFileUpload(this.currentProject.lastRevision!.id, this.revisionFile)
                .then(() => {
                    runInAction(() => {
                        this.isUploading = false
                        this.currentProject.hasPreviewImage = true

                        const foundIndex = this.projects.findIndex((project) => project.id === this.currentProject.id)
                        if (foundIndex !== -1) {
                            this.projects[foundIndex].hasPreviewImage = true
                        }
                    })
                })
                .catch((err) => {
                    runInAction(() => this.isUploading = false)
                })
        }
    }

    @action public async deleteFile(revision: Revision, file: RevisionFile): Promise<RevisionFile | null> {
        try {
            const response = await revisionService.deleteRevisionFile(revision.id, file.id)
            const jsog = new JsogService()
            const deletedFile = jsog.deserialize(response.data, RevisionFile) as RevisionFile
            return deletedFile
        } catch (error) {
            console.error(error)
        }

        return null
    }

    @action public updateDesignOption(designOption: DesignOption) {
        designOptionService.update(designOption)
            .then((response) => {
                const updatedDesignOption: DesignOption = Object.assign(new DesignOption(), response.data)

                runInAction(() => {
                    designOption.version = updatedDesignOption.version
                    designOption.name = updatedDesignOption.name
                    designOption.modifiedDate = updatedDesignOption.modifiedDate
                    designOption.groupName = updatedDesignOption.groupName
                })
            })
    }

    // QA
    @action public loadQualityAssurances(revision: Revision) {
        revisionService.getQaList(revision.id)
            .then((response) => {
                runInAction(() => {
                    revision.qualityAssurances = response.data.map((element: any) => {
                        return Object.assign(new QualityAssurance(), element)
                    })
                })
                revision.qualityAssurances.forEach((qa) => {
                    authService.getUserById(qa.createdBy)
                        .then((userResponse) => action(() => qa.reviewer = Object.assign(new User(), userResponse.data)))
                })
            })
    }

    @computed get showQADetail() {
        const isNewQADetail = this.store.buildingModel.editMode && this.store.buildingModel.focusedMesh && !this.currentQADetail.id
        const isExistingQADetail = this.store.buildingModel.currentSelectedQALabelId !== undefined && this.currentQADetail.id
        return (isNewQADetail || isExistingQADetail)
    }

    @action public async loadUsersOptions(username: string = '') {
        return authService.getUsers([UserRole.ARCHITECT.toString()], username)
            .then((response) => {
                const architects = response.data.map((element: any) => Object.assign(new User(), element))
                const options: any[] = architects.map((user: User) => {
                    return Object.assign({}, {
                        value: user.id,
                        label: user.username
                    })
                })

                return options
            })
    }

    @action public async setProjectAssignee(assigneeUserId: string) {
        projectService.assign(this.currentProject.id, assigneeUserId)
            .then((response) => runInAction(() => {
                const project: Project = Object.assign(new Project(), response.data)
                this.currentProject.assigneeUserId = project.assigneeUserId
                this.fetchAssignedUser()
                this.store.notifyUser(this.store.formatMessage('projectAssigned'), 'success')
            }))
    }

    @action public addQA() {
        revisionService.addQA(this.currentRevision.id)
            .then(() => this.loadQualityAssurances(this.currentRevision))
    }

    @action public deleteQA(qa: QualityAssurance) {
        revisionService.deleteQA(this.currentRevision.id, qa.id)
            .then(() => this.loadQualityAssurances(this.currentRevision))
    }

    @action public getQA(id: string) {
        if (id) {
            this.isLoadingQA = true
            qaService.getById(id)
                .then((response) => {
                    runInAction(() => {
                        this.currentQA = Object.assign(new QualityAssurance(), response.data)
                        this.currentQaStatus = this.currentQA.status
                    })
                    return authService.getUserById(this.currentQA.createdBy)
                })
                .then((userResponse) => {
                    runInAction(() => {
                        this.currentQA.reviewer = Object.assign(new User(), userResponse.data)
                        this.isLoadingQA = false
                    })
                })
                .catch((err) => {
                    action(() => this.isLoadingQA = false)
                })
        }
    }

    @action public saveQA() {
        this.isSavingQA = true
        qaService.update(this.currentQA)
            .then((response) => {

                runInAction(() => {
                    this.currentQA = Object.assign(new QualityAssurance(), response.data)
                    this.currentQA.qualityAssuranceDetails.map((element: any) => {
                        Object.assign(new QualityAssuranceDetail(), element)
                    })
                })

                return authService.getUserById(this.currentQA.createdBy)
            })
            .then((userResponse) => {
                runInAction(() => {
                    this.currentQA.reviewer = Object.assign(new User(), userResponse.data)
                    this.isLoadingQA = false
                    this.isSavingQA = false
                })
            })
            .catch((err) => {
                runInAction(() => {
                    this.isLoadingQA = false
                    this.isSavingQA = false
                })
            })
    }

    @action public addQADetail() {
        if (this.currentQA.qualityAssuranceDetails === null) {
            this.currentQA.qualityAssuranceDetails = []
        }

        this.currentQADetail.data = JSON.stringify(this.store.buildingModel.qaData)

        if (this.currentQADetail.data) {
            this.currentQADetail.createdBy = this.store.user.id

            this.currentQA.qualityAssuranceDetails.push(this.currentQADetail)
            this.saveQA()
            this.store.buildingModel.enableEditMode(false)
            this.currentQADetail = new QualityAssuranceDetail()
        }
    }

    @action public async loadQAStatusOptions() {
        return statusService.getQualityAssuranceStatuses()
            .then((response) => {
                runInAction(() => this.qaStatuses = response.data.map((element: any) => Object.assign(new Status(), element)))
                const options: any[] = this.qaStatuses.map((status: Status) => {
                    return Object.assign({}, {
                        value: status.code,
                        label: this.store.getLocalizedDescription(status.description)
                    })
                })
                return options
            })
    }

    @computed get qaStatusAsSelectOption() {
        if (this.currentQaStatus.id !== undefined) {
            return { value: this.currentQaStatus.code, label: this.store.getLocalizedDescription(this.currentQaStatus.description) }
        }
        return null
    }

    @action public async loadQADetailsStatusOptions() {
        return statusService.getQualityAssuranceDetailStatuses()
            .then((response) => {
                runInAction(() => this.qaDetailStatuses = response.data.map((element: any) => Object.assign(new Status(), element)))
                const options: any[] = this.qaDetailStatuses.map((status: Status) => {
                    return Object.assign({}, {
                        value: status.code,
                        label: this.store.getLocalizedDescription(status.description)
                    })
                })
                return options
            })
    }

    @computed get qaDetailStatusAsSelectOption() {
        if (this.currentQADetail.status.id !== undefined) {
            return { value: this.currentQADetail.status.code, label: this.store.getLocalizedDescription(this.currentQADetail.status.description) }
        }
        return null
    }

    @action public setQAStatus(selectedOption: any) {
        if (selectedOption) {
            const result = this.qaStatuses.find((s) => s.code === selectedOption.value)
            if (result !== undefined) {
                this.currentQaStatus = result
            }
        } else {
            this.currentQaStatus = new Status()
        }
    }

    @action public setQAComment(value: string) {
        this.currentQA.comment = value
    }

    @action public setQADetailStatus(selectedOption: any) {
        if (selectedOption) {
            const result = this.qaDetailStatuses.find((s) => s.code === selectedOption.value)
            if (result !== undefined) {
                this.currentQADetail.status = result
                this.addQADetailMessage(this.store!.formatMessage('statusChanged') + this.currentQADetail.status.code)
            }
        } else {
            this.currentQADetail.status = new Status()
        }
    }

    @action public setQADetailComment(value: string) {
        this.currentQADetail.comment = value
    }

    @action public resetQADetail() {
        this.currentQADetail = new QualityAssuranceDetail()
    }

    @action public addQADetailMessage(message: string) {
        const newMessage = new Message()
        newMessage.comment = message
        newMessage.createdBy = this.store.user.id

        if (this.currentQADetail.messages === null) {
            this.currentQADetail.messages = []
        }

        this.currentQADetail.messages.push(newMessage)
    }

    @action saveCurrentProjectPublishingInfo(externalBuilderId: string, clientId: string)
    {
        this.currentProject.externalBuilderId = externalBuilderId
        this.currentProject.externalClientId = clientId
        this.updateProject(this.currentProject)
    }

    /**
     * fetchCurrentProjectPublishindData
     */
    public async fetchCurrentProjectPublishindData(): Promise<PublishedHistoryItem[]>
    {
        let histories: PublishedHistoryItem[] = []

        try {
            const response = await projectService.fetchPublishingData(this.currentProject.id)
            histories = response.data
        } catch (error) {
            console.error(error)
        }

        return histories
    }

    @action
    public async addAlternativeAssetBundle(alternativeId: string, assetBundle: AssetBundle) {
        try {
            const response = await alternativeService.addAssetBundle(alternativeId, assetBundle)
            let alternative = this.currentProject.alternatives!.find(a => a.id === alternativeId)
            if (alternative) {
                const jsog = new JsogService()
                const updatedAlternative = jsog.deserialize(response.data, Alternative) as Alternative
                runInAction(() => {
                    alternative!.assetBundles = updatedAlternative.assetBundles
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    @action
    public async updateAlternativeAssetBundle(alternativeId: string, assetBundle: AssetBundle) {
        try {
            const response = await alternativeService.updateAssetBundle(alternativeId, assetBundle)
            let alternative = this.currentProject.alternatives!.find(a => a.id === alternativeId)
            if (alternative) {
                const { assetBundles } = alternative
                const jsog = new JsogService()
                const updatedAssetBundle = jsog.deserialize(response.data, AssetBundle) as AssetBundle
                runInAction(() => {
                    assetBundles[assetBundles.indexOf(assetBundle)] = updatedAssetBundle
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    @action
    public async removeAlternativeAssetBundle(alternativeId: string, assetBundle: AssetBundle) {
        try {
            await alternativeService.removeAssetBundle(alternativeId, assetBundle.id)
            const alternative = this.currentProject.alternatives!.find(a => a.id === alternativeId)
            if (alternative) {
                runInAction(() => {
                    alternative.assetBundles.splice(alternative.assetBundles.indexOf(assetBundle), 1)
                })
            }
        } catch (error) {
            console.error(error)
        }
    }
}
