import * as queryString from 'query-string'
import * as transport from '../common/service/transport'

const USER_API = '/user/api'

export function authenticate(email: string, password: string) {
    const data = `username=${email}&password=${password}`
    return transport.post(`${USER_API}/auth/login`, data)
}

export function getUsers(roles: string[], username: string = '') {
    const queryParameters = queryString.stringify({ roles, search: username })
    return transport.get(`${USER_API}/users?${queryParameters}`)
}

export function getUserById(userId: string) {
    return transport.get(`${USER_API}/users/${userId}`)
}
