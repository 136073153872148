import { observer, inject } from 'mobx-react'
import * as React from 'react'
import { Store } from '../../../../App/AppStore'
import { PannellumStore } from '../../../stores/PannellumStore'
import { ErrorStore } from '../../../stores/errorStore'
import { action, runInAction } from 'mobx'
import { AssetBundle } from '../../models/AssetBundle'

interface ISideMenuProps {
    store?: Store
    pannellumStore?: PannellumStore
    errorStore?: ErrorStore
}

@inject('store', 'pannellumStore', 'errorStore')
@observer
class AssetBundleList extends React.Component<ISideMenuProps> {
    public render() {
        const { currentAlternative } = this.props.store!.projectStore

        if (currentAlternative.assetBundles.length === 0) {
            return null
        }

        const { selectedAssetBundleLabel } = this.props.pannellumStore!
        const { assetbundleLabels } = this.props.store!.builderProjectStore

        const assetBundleList = assetbundleLabels
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((assetBundleLabel) => {
                const asesetBundle = this.getAssetBundle(assetBundleLabel.id)
                if (!asesetBundle) {
                    return null
                }
                return (
                    <option key={this.getAssetBundle(assetBundleLabel.id)!.id}>
                        {assetBundleLabel.code}
                    </option>
                )
            }
        )

        return (
            <select style={{ fontSize: 16, height: 75 }} onChange={this.onAssetBundleChange} value={selectedAssetBundleLabel}>
                {assetBundleList}
            </select>
        )
    }

    private getAssetBundle(id: string): AssetBundle | undefined {
        const { currentAlternative } = this.props.store!.projectStore
        return currentAlternative.assetBundles.find(a => a.externalAssetBundleLabelId === id)
    }

    private getAssetBundlelabel(id: string) {
        const { assetbundleLabels } = this.props.store!.builderProjectStore
        const label = assetbundleLabels.find(label => label.id === id)
        if (label) {
            return label.code
        }
        return ''
    }

    @action
    private onAssetBundleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { selectedBuilder, filteredSection } = this.props.store!.builderProjectStore
        const { currentAlternative, currentRevision } = this.props.store!.projectStore

        const assetBundle = currentAlternative.assetBundles.find(a => {
            const scene = this.getAssetBundlelabel(a.externalAssetBundleLabelId)
            if (scene) {
                return scene === event.target.value
            }
            return false
        })

        if (assetBundle) {
            const { assetbundleLabels } = this.props.store!.builderProjectStore
            const label = assetbundleLabels.find(label => label.id === assetBundle.externalAssetBundleLabelId)

            if (label) {
                runInAction(() => {
                    this.props.pannellumStore!.selectedAssetBundleLabel = event.target.value
                    this.props.pannellumStore!.isInterior = label.code !== 'Exterior'
                })

                await this.props.store!.builderProjectStore.selectScene(event.target.value)

                this.props.pannellumStore!.clear()
                this.props.pannellumStore!.init(
                    selectedBuilder.id,
                    currentRevision.buildingModelId,
                    assetBundle.sceneUrl,
                    assetBundle.assetUrl,
                    filteredSection,
                    currentAlternative
                )
            }
        }
    }
}

export default AssetBundleList
