import { Icon, styled } from '@paradigm/blueprints-common-frontend'
import { ICONS } from '@paradigm/blueprints-common-frontend'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { rgba } from 'polished'
import React from 'react'
import Dropzone from 'react-dropzone'
import { IStore } from '../../../App/AppStore'

const FileInputField = styled.div`
    opacity: 1;
    mix-blend-mode: normal;
    background-color: ${(props) => props.theme.colors.neutrals[0]};
    background-image: none;
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    border-radius: 3px;
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.neutrals[3]};
    border-style: dashed;
    padding: 0px;
    width: 720px;
    height: 98px;
`

const FileInputText = styled.div`
    opacity: 1;
    mix-blend-mode: normal;
    color: ${(props) => props.theme.colors.secondaryVariant};
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    text-align: left;
    height: 19px;
`

const UploadIcon = styled.div`
    float: left;
 `

const FileInputFieldText = styled.div`
    opacity: 1;
    mix-blend-mode: normal;
    background-color: transparent;
    background-image: none;
    background-position: center center;
    background-repeat: repeat;
    background-size: cover;
    border-radius: 3px;
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.neutrals[1]};
    border-style: solid;
    padding: 0px;
    width: 722px;
    height: 89px;
`

const DeleteIcon = styled(Icon)`
    width: 22px;
    height: 21px;
    fill: ${(props) => rgba(props.theme.colors.secondaryVariant, 0.8)};
    cursor: pointer;
`

@inject('store')
@observer
export class UploadFieldCreateProject extends React.Component<IStore> {
    @observable private files: File[] = []

    @action
    public onDrop = (files: File[]) => {
        this.files.push(...files)
 		this.props.store!.projectStore.handleFileUpload(files[0])
    }

    @action
    public removeFile = (fileIndex: number) => {
        this.files.splice(fileIndex, 1)
    }

    public render() {
        const files = this.files.map((file, index) => (
            <li key={index} style={{ listStyleType: 'none' }}>
                <UploadIcon>
                <Icon size={30} icon={ICONS.PDF_FILE} />
                    <div onClick={this.removeFile.bind(this, index)} style={{ marginLeft: '620px', marginTop: '-26px' }}>
                        <DeleteIcon icon={ICONS.CANCEL} />
                    </div>
                    <FileInputText>{file.name} {file.size}</FileInputText>
                </UploadIcon>
            </li>
        ))
        return (
            <div>
                <FileInputField>
                    <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div style={{ padding: '34px' }} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <UploadIcon><Icon size={40} icon={ICONS.PDF_FILE} /></UploadIcon>
                                    <p style={{ paddingLeft: '38px' }}>
                                        {this.props.store!.formatMessage('uploadCreateProject')}
                                    </p>
                                </div>
                                <FileInputFieldText>
                                    <div style={{ padding: '19px' }}>
                                        {files}
                                    </div>
                                </FileInputFieldText>
                            </section>
                        )}
                    </Dropzone>
                </FileInputField>
            </div>
        )
    }
}
