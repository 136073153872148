import { inject, observer } from "mobx-react"
import * as React from "react"
import { Col, Grid, Row } from "react-bootstrap"
import { IStore } from '../../../../App/AppStore'
import Card from '../../../common/components/Card'

@inject('store')
@observer
export default class Profile extends React.Component<IStore>{
    public render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12} md={4}>
                        <Card title={this.props.store!.formatMessage('chatConnection')} >
                        </Card>
                    </Col>
                </Row>
            </Grid>
        )
    }
}
