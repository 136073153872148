import { observable, action } from "mobx"

export class ErrorStore {
    errors = observable.array<string>([])

    @action
    addError(error: string) {
        this.errors.push(error)
    }

    @action
    clear() {
        this.errors.clear()
    }
}

export const errorStore = new ErrorStore()