import { theme } from '@paradigm/blueprints-common-frontend'
import { rgba } from 'polished'
import ITakeOffTheme from './ITakeOffTheme'

export const NAVY = '#202834'
export const BLUE = '#1c66d1'
export const DARK_BLUE = '#21467e'

export const GRAY100 = '#f7f7f7'
export const GRAY200 = '#ebebeb'
export const GRAY300 = '#c8c8c8'
export const GRAY400 = '#cccccc'
export const GRAY500 = '#bababa'
export const GRAY600 = '#9c9c9c'
export const GRAY700 = '#8a8a8a'
export const GRAY800 = '#595959'
export const GRAY900 = '#353535'

export const themeName = 'visualize'
export const pageTitle = 'MyBuild Visualizer'
export const favIconFolder = 'paradigm'
export const defaultModelImg = 'DefaultHome.png'
export const builderSite = 'http://www.paradigm.com'
export const loginBackground = 'linear-gradient( 90deg, rgba(28, 102, 209, 0.85) 0%, rgba(28, 102, 209, 0.85) 100%), url("./MyBuild-Background.jpg")'

export const ERROR = '#ef4434'

export const font = theme.font

export const colors = {
    ...theme.colors,
    primary: BLUE,
    secondary: NAVY,
    secondaryVariant: DARK_BLUE,
    error: ERROR,
    icon: BLUE,
    neutrals: [GRAY100, GRAY200, GRAY300, GRAY400, GRAY500, GRAY600, GRAY700, GRAY800, GRAY900],
}

export const typography = {
    ...theme.typography,
    h1: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 500,
        fontSize: '40px',
        fontStyle: 'normal',
    },
    h2: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 'normal',
        fontSize: '32px',
        fontStyle: 'normal',
    },
    h3: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700,
        fontSize: '26px',
        fontStyle: 'normal',
    },
    h4: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 500,
        fontSize: '20px',
        fontStyle: 'normal',
    },
    paragraph: {
        fontWeight: 'normal',
        fontSize: '19px',
        fontStyle: 'normal',
    },
    HelpInfo: {
        fontWeight: 500,
        fontSize: '12px',
        letterSpacing: '1px',
        textDecoration: 'none solid rgb(191, 191, 191)',
        textTransform: 'uppercase',
        fontStyle: 'normal',
    },
}

export const text = {
    main: BLUE,
    contrast: theme.text.contrast,
    hint: theme.text.hint,
    disabled: theme.colors.neutrals[5],
}

export const shape = {
    borderRadius: '0px',
}

export const action = {
    ...theme.action,
    hover: BLUE,
    filter: `brightness(85%)`,
    disabled: rgba(GRAY600, 0.2),
    padding: '.8rem 2rem',
    margin: '0 1rem',
    fontWeight: 600,
    letterSpacing: 'normal',
    borderRadius: '0px',
}

export const logo = {
    src: 'logo-mybuild.svg',
    size: {
        sm: {
            width: 'auto',
            height: '19px',
        },
        md: {
            width: 'auto',
            height: '54px',
        },
        lg: {
            width: 'auto',
            height: '60px',
        },
    },
    alt: 'Paradigm',
}

export const header = {
    border: `1px solid ${NAVY}`,
    background: NAVY,
    fontSize: '14px',
    color: theme.colors.common[0],
    icon: {
        iconColor: theme.colors.neutrals[5],
        iconHoverColor: theme.colors.primary,
    },
}

export const sideMenu = {
    border: `1px solid ${theme.colors.neutrals[1]}`,
    background: theme.colors.common[0],
    color: theme.colors.neutrals[2],
    hoverColor: theme.colors.neutrals[5],
}

export const footerMenu = {
    background: theme.colors.neutrals[6],
    color: theme.colors.common[0],
}

export const popOverSection = theme.popOverSection

export const lightbox = {
    maxWidth: '90%',
    maxHeight: '99%',
    media: {
        device: {
            large: {
                size: 1200,
                width: '80%',
            },
            small: {
                size: 820,
                height: '95%',
            },
        },
    },
}

export const alternativeSpinner = {
    maxWidth: '700px',
    loadingTextSize: '22px',
    patienceTextSize: '15px',
}

export const textInput = {
    labelFontWeight: 500,
}

const defaultTheme: ITakeOffTheme = {
    ...theme,
    loginBackground,
    pageTitle,
    favIconFolder,
    defaultModelImg,
    builderSite,
    font,
    colors,
    header,
    action,
    shape,
    sideMenu,
    footerMenu,
    popOverSection,
    lightbox,
    alternativeSpinner,
    textInput,
}

export default defaultTheme
