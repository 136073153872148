import { observable } from 'mobx'
import { Store } from '../../../App/AppStore'

export class FloorOption {
    public static EXTERIOR: string = 'EXTERIOR'
    public static ROOF: string = 'ROOF'

    public static formatLabel(labelToFormat: string, store: Store): string {
        const formattedLabel = {
            label: labelToFormat,
            level: '',
            ordinalEnding: ''
        }

        const level = parseInt(labelToFormat, undefined)
        if (!isNaN(level) && level !== 0) {
            if (level > 0) {
                formattedLabel.level = labelToFormat

                if (level > 3) {
                    formattedLabel.ordinalEnding = store.formatMessage(FloorOption.ORDINAL_ENDING)
                } else {
                    formattedLabel.ordinalEnding = store.formatMessage(labelToFormat)
                }

                formattedLabel.label = store.formatMessage(FloorOption.FLOOR)
            } else {
                if (level !== -1) {
                    formattedLabel.level = level * -1 + ''

                    if (level < -3) {
                        formattedLabel.ordinalEnding = store.formatMessage(FloorOption.ORDINAL_ENDING)
                    } else {
                        formattedLabel.ordinalEnding = store.formatMessage(formattedLabel.level)
                    }
                }

                formattedLabel.label = store.formatMessage(FloorOption.SPECIAL_LEVEL_KEY_BASEMENT)
            }
        } else if (labelToFormat === FloorOption.SPECIAL_LEVEL_KEY_ROOF) {
            formattedLabel.label = store.formatMessage(labelToFormat)
        }

        return formattedLabel.level + formattedLabel.ordinalEnding + ' ' + formattedLabel.label
    }

    private static FLOOR: string = 'FLOOR'
    private static ORDINAL_ENDING: string = 'ordinalEnding'
    private static SPECIAL_LEVEL_KEY_ROOF: string = 'ROOF'
    private static SPECIAL_LEVEL_KEY_BASEMENT: string = 'BSMT'

    @observable public active: boolean
    @observable public id: string
    @observable public label: string

    constructor(active: boolean, id: string, label: string) {
        this.active = active
        this.id = id
        this.label = label
    }

    public isStorey(): boolean {
        return this.label !== 'EXTERIOR' && this.label !== 'ROOF'
    }
}
