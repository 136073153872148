import { action, autorun, computed, observable, runInAction } from 'mobx'
import * as authService from '../../auth/authService'
import { User } from '../../auth/models/User'
import { Message } from './Message'
import { Status } from './Status'

export class QualityAssuranceDetail {
    @observable public id: string
    @observable public createdDate: number
    @observable public modifiedDate: number
    @observable public createdBy: string
    @observable public comment: string = ''
    @observable public data: string
    @observable public status: Status = new Status()
    @observable public messages: Message[] = []
    @observable public fromUser: User

    constructor() {
        autorun(() => {
            if (this.createdBy) {
                this.getFrom()
            }
        })
    }

    @computed get formatedCreatedDate(): string {
        if (this.createdDate !== undefined) {
            return new Date(this.createdDate).toLocaleDateString()
        } else {
            return ''
        }
    }

    @computed get formatedModifiedDate(): string {
        if (this.modifiedDate !== undefined) {
            return new Date(this.modifiedDate).toLocaleDateString()
        } else {
            return ''
        }
    }

    @computed get from(): string {
        return this.fromUser ? this.fromUser.username : ''
    }

    @action public getFrom() {
        if (this.createdBy) {
            authService.getUserById(this.createdBy)
            .then(userResponse => runInAction(() => {
                this.fromUser = Object.assign(new User(), userResponse.data)
            }))
        }
    }
}
