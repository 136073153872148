import { inject, observer } from 'mobx-react'
import * as React from 'react'
import styled from "styled-components"
import { IStore } from "../../../../App/AppStore"

const CaptchaContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
`

@inject("store")
@observer
class ReCaptcha extends React.Component<IStore> {

    public onCaptchaCallback = (recaptchaToken: string) => {
        // Call API with this token to verify on server with the call to reset password
        this.props.store!.resetPassword.onSetReCaptchaToken(recaptchaToken)
    }

    public render() {
        const RecaptchaControl = require('react-recaptcha')

        return (
            <CaptchaContainer>
                <RecaptchaControl sitekey="6LdWi6sUAAAAAH5EuP3o8IUh-AOjzG_j0pDtGjtk" verifyCallback={this.onCaptchaCallback} />
            </CaptchaContainer>
        )
    }
}

export default ReCaptcha
