import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { Col, ControlLabel, FormControl, FormGroup, Grid, Row } from 'react-bootstrap'
import Async from 'react-select/lib/Async'
import { IStore } from '../../../App/AppStore'


@inject('store')
@observer
export class QualityAssuranceHeader extends React.Component<IStore> {
    constructor(props: Readonly<IStore>) {
        super(props)
        this.getQAStatusOptions = this.getQAStatusOptions.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this)
        this.handleCommentChange = this.handleCommentChange.bind(this)
    }

    public getQAStatusOptions = (input: any) => {
        return this.props.store!.projectStore.loadQAStatusOptions()
    }

    public handleStatusChange(selectedOption: any) {
        this.props.store!.projectStore.setQAStatus(selectedOption)
    }

    public handleCommentChange(event: any) {
        this.props.store!.projectStore.setQAComment(event.target.value)
    }

    public render() {
        return (
            <Grid fluid={true}>
                <Row>
                    <Col xs={12} md={8}>
                        <div style={{ textAlign: 'left' }}>
                            <p style={{ fontSize: 28 }}>
                            {this.props.store!.formatMessage('qualityAssurance')} #{this.props.store!.projectStore.currentQA.identifier}
                            </p>
                            <p>
                                <small>
                                {this.props.store!.formatMessage('createdBy')} {this.props.store!.projectStore.currentQA.reviewer.username}
                                {' ' + this.props.store!.formatMessage('on') + ' '}
                                    {this.props.store!.projectStore.currentQA.formatedCreatedDate + ' '} |
                                    {' ' + this.props.store!.formatMessage('updatedOn')+ ' '}
                                    {this.props.store!.projectStore.currentQA.formatedModifiedDate}
                                </small>
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormGroup controlId='qa-status'>
                            <ControlLabel>{this.props.store!.formatMessage('status')}</ControlLabel>
                            <Async
                                name='qa-status'
                                cacheOptions={true}
                                defaultOptions={true}
                                value={this.props.store!.projectStore.qaStatusAsSelectOption}
                                loadOptions={this.getQAStatusOptions}
                                onChange={this.handleStatusChange}
                                isClearable={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup controlId='qa-comment'>
                            <ControlLabel>{this.props.store!.formatMessage('comment')}</ControlLabel>
                            <FormControl
                                name='qa-comment'
                                componentClass={'textarea'}
                                value={this.props.store!.projectStore.currentQA.comment ? this.props.store!.projectStore.currentQA.comment : ''}
                                onChange={this.handleCommentChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Grid>
        )
    }
}
