import { IModel} from '../interfaces'


/**
 * @param elementId the element's id
 * @param children to find inside
 * @returns the children of element with the id equals to elementId
 */
export function getChildrenOfElement(elementGlobalId: string, children: IModel[]): IModel[] {
    let result: any[] = []

    for (const child of children) {
        if (child.guid === elementGlobalId) {
            result = result.concat(child.children)
        } else if (child.children) {
            result = result.concat(getChildrenOfElement(elementGlobalId, child.children))
        }
    }

    return result
}

/**
 * Finds elements with the specified type
 * @param elementType the element's type
 * @param children to find the elements of type elementType
 * @returns the elements with type elementType
 */
export function findElementsByType(elementType: string, children: IModel[]): IModel[] {
    let elements: IModel[] = []

    for (const child of children) {
        if (child.type.toUpperCase().includes(elementType.toUpperCase())) {
            elements.push(child)
        } else if (child.children) {
            const otherElements = findElementsByType(elementType, child.children)
            if (otherElements.length > 0) {
                elements = elements.concat(otherElements)
            }
        }
    }

    return elements
}

/**
 * @param elementType Name of element type being counted
 * @param storeyId ID of the story being checked
 * @param modelChildren
 * @returns integer value of number of elements in the story being searched
 */
export function getNumberOfElementInStorey(elementType: string, storeyId: string, modelChildren: IModel[]): number {
    const children = getChildrenOfElement(storeyId, modelChildren)
    return findElementsByType(elementType, children).length
}

