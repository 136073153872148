import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { IStore } from '../../../App/AppStore'
import { Message } from "../models/Message"

interface IMessageTableRowProps extends IStore {
  message: Message
}

@inject('store')
@observer
export class MessageTableRow extends React.Component<IMessageTableRowProps> {
  public render() {
      return (
          <tr>
              <td>{this.props.message.from}</td>
              <td>{this.props.message.comment}</td>
              <td>{this.props.message.formatedCreatedDate}</td>
          </tr>
      )
  }
}
