export const message = {
    'en-US': {
        createProject: 'Create a Project',
        createProjectPanelContent: 'Get Started',
        search: 'Search Homes',
        emptyProjects: 'No projects are here ...yet.',
        account: 'Account ',
        progress: 'IN PROGRESS',
        assigned: 'Assigned to me',
        newProject: 'NEW PROJECT',
        noMoreRecords: 'No more records',
        logout: 'Log Out',
        dateCreated: 'Date Created ',
        projectName: 'Project Name',
        email: 'Email',
        userNameOrEmail: 'Username or email',
        userName: 'Username',
        password: 'Password',
        rememberMe: 'Remember Me',
        forgotPassword: 'Forgot Password?',
        forgotUsername: 'Forgot Username?',
        forgotYourPassword: 'Forgot your password?',
        resetYourPassword: 'Reset Your Password',
        resetPasswordInfo: 'Enter your email to receive a password reset link.',
        backToSignIn: 'Back to Sign In',
        sendResetLink: 'Send reset link',
        address: 'Address',
        uploads: 'Uploads',
        uploadCreateProject: 'Drag files here or click to browse',
        status: 'Status',
        quotes: 'Quotes',
        projects: 'Projects',
        revision: 'Revision',
        uploaded: 'Uploaded on',
        editOptions: 'Click the category titles below to edit options.',
        takeOfProgress: ' Take-Off in Progress',
        tabsOffComplete: 'Tabs-Off Complete',
        quoteSent: ' Quote Sent',
        purchased: 'Purchased',
        details: 'Details',
        projectDetails: 'Project details',
        quoteNotification: 'You have a quote ',
        invalidUserNamePassword: 'Invalid username / password',
        noProjectFound: 'No project found',
        projectNotCreated: 'Project not created',
        updateProjectFailed: 'Update project failed',
        login: 'Log in',
        addNewProduct: 'Add New Product',
        'uploads.table.number': '#',
        'uploads.table.name': 'Name',
        'uploads.table.size': 'Size',
        'uploads.table.ext': 'Ext.',
        'uploads.table.uploaded': 'Uploaded',
        'uploads.table.lastModified': 'Last Modified',
        view2D: 'Show 2D View',
        view3D: 'Show 3D View',
        submit: 'Submit',
        'uploadField.label': 'File Upload',
        'uploadField.btn.label': 'Browse',
        'uploadField.help': 'Supported files: images, pdf , ifc, rvt',
        delete: 'Delete',
        uploading: 'Uploading',
        assignee: 'Assignee',
        unassigned: 'Unassigned',
        all: 'All',
        name: 'Name',
        value: 'Value',
        unit: 'Unit',
        add: 'Add',
        open: 'Open',
        qa: 'QA',
        'qa.comment': 'qaComment',
        'qa.table.identifier': 'Identifier',
        'qa.table.reviewer': 'Reviewer',
        'qa.table.role': 'Role',
        'qa.table.status': 'Status',
        'qa.table.createdDate': 'Created Date',
        'qa.table.modifiedDate': 'Modified Date',
        'qa-detail-status': 'Detail status',
        qualityAssurance: 'Quality Assurance',
        createdBy: 'Created by',
        updatedOn: 'Updated on',
        addComment: 'Add Comment',
        on: 'on',
        send: 'Send',
        from: 'From',
        message: 'Message',
        comment: 'Comment',
        date: 'Date',
        statusChanged: 'Status Changed to : ',
        save: 'Save',
        saveAs: 'Save as',
        footerRightsReserved: 'MyBuild 2018 . All Rights Reserved',
        termsPrivacyPolicy: 'Terms / Privacy Policy',
        fullScreenView: 'Full Screen View',
        interior: 'See Interior',
        front: 'Front',
        createQuotePanelContent: 'Continue where you left off.',
        createQuoteButton: 'Create a Quote',
        settings: 'Settings',
        profile: 'Profile',
        myBuildLogo: 'My Build Logo',
        MyBuild: 'MyBuild',
        files: 'Files',
        baseboardMaterialOptions: 'Baseboard Material Options',
        cancel: 'Cancel',
        pieceLength: 'Piece Length',
        perimeterTotal: 'Perimeter Total',
        estimatedPieces: 'Estimated Pieces',
        totalPieces: 'Total Pieces',
        confirm: 'Confirm',
        confirmNextMaterail: 'Confirm, see next material',
        productDefinition: 'Product Definition',
        myProjects: 'My Projects',
        previewModel: 'Preview model',
        searchProducts: 'Search for products',
        sortBy: 'Sort by',
        reviewTakeoff: 'Review Take-Off',
        setOptions: 'Set Options',
        kilburnHome: 'KILBURN HOME',
        'entry-Doors-Take-Off-Review': 'Entry Doors Take-Off Review',
        exterior: 'Exterior',
        firstFloor: 'First Floor',
        secondFloor: 'Second Floor',
        trimOptions: 'Trim Options',
        basementAreaOptions: 'Basement Area Options',
        materialIncluded: 'Material Included',
        separateMaterials: 'Separate Materials',
        noMaterials: 'No Materials',
        garageAreaOptions: 'Garage Area Options',
        casingOptions: 'Casing Options',
        doorCasing: 'Door Casing',
        doorsOnly: 'Doors Only',
        windowsOnly: 'Windows Only',
        doorsAndWindows: 'Doors and Windows',
        casingStyle: 'Casing Style',
        miteredCasing: 'Mitered Casing',
        casingWithHeader: 'Casing with Header',
        window_sill: 'Window Sill',
        noSill: 'No Sill',
        sillAndApron: 'Sill and Apron',
        bypassDoorOptions: 'Bypass Door Options',
        casing: 'Casing',
        bothSidesCased: 'Both Sides Cased',
        oneSideCased: 'One Side Cased',
        noCasing: 'No Casing',
        jambs: 'Jambs',
        passThroughOptions: 'Pass-Through Options',
        casedWithJambs: 'Cased with Jambs',
        cased: 'Cased',
        dryWalled: 'Dry-Walled',
        crownMouldingOptions: 'Crown Moulding Options',
        yes: 'Yes',
        no: 'No',
        selections: 'Selections',
        basementMaterial: 'Basement Material',
        basementMaterialIncluded: 'Basement Material Included',
        basementSeparateMaterial: 'Basement Separate Material',
        basementNoMaterial: 'Basement No Material',
        garageMaterialIncluded: 'Garage Material Included',
        garageNoMaterial: 'Garage No Material',
        doorCasingDoorsOnly: 'Door Casing Doors Only',
        doorCasingWindowsOnly: 'Door Casing Windows Only',
        doorCasingDoorsAndWindows: 'Door Casing Doors and Windows',
        casingStyleMiteredCasing: 'Casing Style Mitered Casing',
        casingStyleCasingWithHeader: 'Casing Style Casing With Header',
        windowSillNoSill: 'Window Sill No Sill',
        windowSillSillAndApron: 'Window Sill Sill and Apron',
        bypassDoorBothSidesCased: 'Bypass Door Casing Both Sides Cased',
        bypassDoorOneSideCased: 'Bypass Door Casing One Side Cased',
        bypassDoorNoCasing: 'Bypass Door Casing No Casing',
        bypassDoorJambsJambs: 'Bypass Door Jambs Jambs',
        bypassDoorJambsOneSideCased: 'Bypass Door Jambs One Side Cased',
        bypassDoorJambsNoCasing: 'Bypass Door Jambs No Casing',
        passThroughCasedWithJambs: 'Pass-Through Cased with Jambs',
        passThroughCased: 'Pass-Through Cased',
        passThroughDryWalled: 'Pass-Through Dry-Walled',
        crownMouldingYes: 'Crown Moulding Yes',
        crownMouldingNo: 'Crown Moulding No',
        materialOptions: 'Material Options',
        baseBoardMaterialOptions: 'Baseboard Material Options',
        qty: 'Qty',
        casingMaterialOptions: 'Casing Material Options',
        sixEighthsDoorMaterial: '6/8 Door Material',
        eightDoorMaterial: '8/0 Door Material',
        windowMaterial: 'Window Material',
        headerMaterialOptions: 'Header Material Options',
        jambMaterialOptions: 'Jamb Material Options',
        wasteFactor: 'Waste Factor',
        baseboard: 'Baseboard',
        casingSixEighthsDoors: 'Casing - 6/8 Doors',
        casingEightDoors: 'Casing - 8/0 Doors',
        casingWindows: 'Casing - Windows',
        header: 'Header',
        jamb: 'Jamb',
        crownMoulding: 'Crown Moulding',
        categoryPanelTitle: 'Add products to your project',
        addProduct: 'Add New Product',
        basement: 'Basement',
        garage: 'Garage',
        factor: 'Factor: ',
        piecesLength: 'Piece Length',
        unlabeledRoom: 'Unlabeled Room',
        wF: 'WF',
        saveDefaults: 'Save Defaults',
        defaults: ' Defaults',
        saveAsMyDefaults: 'Save as my defaults',
        saveAsNewDefaultSet: 'Save as new default set',
        door: 'Doors Only',
        window: 'Windows Only',
        both: 'Doors and Windows',
        sill: 'Sill',
        bypass_bifold_door: 'Bypass and BiFold Door',
        bothSides: 'Both Sides Cased',
        oneSide: 'One Side Cased',
        jambIncluded: 'Jambs',
        noJamb: 'No Jambs',
        pass_through: 'Pass-Through Options',
        casedWithJamb: 'Cased with jambs',
        dryWall: 'Dry-Walled',
        apron: 'Apron',
        casing_window: 'Casing Windows',
        casing_door: 'Casing Doors',
        linealFeet: 'Lineal Feet',
        openings: 'Openings',
        saveAsProjectDefaults: 'This project only',
        saveAsBuilderDefaults: 'Save as builder defaults',
        saveQuickpicksOptions: 'Save these options to use again on future projects?',
        noProjects: 'You don\'t have any projects. Click above to create a project.',
        created: 'Created',
        edit: 'Edit',
        BSMT: 'Basement',
        ROOF: 'Roof',
        GROUND: 'Ground',
        FLOOR: 'Floor',
        1: 'st',
        2: 'nd',
        3: 'rd',
        ordinalEnding: 'th',
        takeOff: 'Take Off',
        planSummary: 'PLAN SUMMARY',
        trimMaterials: 'TRIM MATERALS',
        trimQuotes: 'TRIM QUOTES',
        trimPurchases: 'TRIM PURCHASES',
        takeOffReview: 'Take-Off Review',
        designOptions: 'Options',
        groupName: 'Group Name',
        selectDesignOption: 'Select a Design Option',
        projectInformations: 'Project Infomation',
        alternative: 'Alternative',
        selectAlternative: 'Select or Create an Alternative',
        projectStatusHistory: 'Project Status History',
        tools: 'Tools',
        mappingTool: 'Mapping',
        pointer: 'Pointer',
        rotate: 'Rotate',
        origin: 'Origin',
        scale: 'Scale',
        move: 'Move',
        rectangle: 'Rectangle',
        rear: 'Rear',
        close: 'Close',
        frontElevation: 'Front',
        rightElevation: 'Right',
        backElevation: 'Back',
        leftElevation: 'Left',
        saving: 'Saving...',
        showGrid: 'Show Grid',
        hideGrid: 'Hide Grid',
        succesSaveMapping: 'Successfully saved mapping.',
        errorSaveMapping: 'Could not save mapping.',
        sqft: 'SQ FT',
        squareFeet: 'Square Feet',
        blueprintsNotFound: 'No blueprints found for project',
        createQuote: 'Quote',
        incompatibleTakeoffError: 'Old version of takeoff detected. Certain features may not work properly. Please reprocess the takeoff.',
        holdLeftButton: 'Hold Left Button',
        panBlueprint: 'Pan Blueprint',
        scrollWheel: 'Scroll Wheel',
        zoomInAndOut: 'Zoom in & out',
        materials: 'Materials',
        loading: 'Loading...',
        channelManagement: 'Project Chat Management',
        createChannel: 'Create Channel',
        users: 'Users',
        remove: 'Remove',
        removing: 'Removing...',
        adding: 'Adding...',
        projectBlueprint: 'Project Blueprint',
        succesSaveProject: 'Successfully saved project.',
        unexpectedError: 'The operation has failed',
        projectNotReady: 'This project is not quite ready yet.',
        checkBackInFewMinutes: 'Check back in a few minutes (we\'ll email you when it\'s ready).',
        sorryForWait: 'Sorry for the wait',
        successSave: 'Successfully saved.',
        chatConnection: 'Chat user',
        products: 'Products',
        options: 'Options',
        review: 'Review',
        seeInThreeD: 'See in 3D',
        finishTakeoff: 'Finish Take-Off',
        totalFinishArea: 'Total Finish Area',
        finishArea: 'Finish Area',
        builder: 'Builder',
        projectEmptyTakeoffList: 'The {name} project doesn\'t have any take-offs yet.',
        newTakeoff: 'New Take-Off',
        projectTakeoffs: 'Project Take-Offs ({size})',
        projectOverview: 'Project Overview',
        projectQuotes: 'Project Quotes ({size})',
        projectEmptyQuoteList: 'The {name} project doesn\'t have any quotes yet.',
        newQuote: 'New Quote',
        projectFilesInfo: 'Project Files Here? Gallery with area to upload new?',
        recentProjectActivity: 'Recent Project Activity',
        projectEmptyActivityList: 'No Activity',
        backToProject: 'Back to project',
        setDetails: 'Set Details',
        confirmTrimOptions: 'Confirm your trim options or make changes and save a new options set',
        takeoffSettings: 'Take-Off Settings',
        takeoffSettingsProductInfo: 'Give your take-off a title and select the products you\'d like to include in your take-off.',
        takeoffTitle: 'Take-Off Title',
        selectTakeoffProduct: 'Select Take-Off Products',
        stepOf: '(Step {current} of {max})',
        fetchingModel: 'Your project will load in a moment...',
        modelNotLoaded: 'Could not load model',
        assignedUser: 'Assigned user',
        projectAssigned: 'Project assigned',
        publishProject: 'Publish Project to Builder',
        templateName: 'Template name',
        publish: 'Publish',
        successPublished: 'Successfully published',
        somethingWentWrong: 'Something went wrong',
        buildersIsLoading: 'Builders is loading',
        noBuildersFound: 'No Builders found',
        displayOrder: 'Display Order',
        imageUrl: 'Image Url',
        newAlternative: 'New Alternative',
        projectAlternatives: 'Project Alternatives ({size})',
        alternatives: 'Alternatives',
        chat: 'Chat',
        designOption: 'Design Option',
        camera: 'Camera',
        cameraTool: 'Camera Tool',
        cameraInfo: 'Camera Informations',
        radius: 'Radius',
        alpha: 'Alpha',
        beta: 'Beta',
        position: 'Position',
        notFound: 'Not Found',
        fieldOfView: 'Field Of View',
        show: 'Show',
        hide: 'Hide',
        target: 'Target',
        fovMultiplier: 'FOV Multiplier',
        print: 'Print',
        download: 'Download',
        currentModelHasNoMappingImages: 'Current Model has no Mapping Image',
        errorWhileLoadingMappingImages: 'Error while loading Model Mapping Images',
        loadingModelMappingImages: 'Loading Model Mapping Images',
        thisActionCannotBeUndone: 'Warning: This action cannot be undone',
        deleteThis: 'Delete {itemName}?',
        license: '©2001–{currentYear} All Rights Reserved. MyBuild®',
        grid: 'Grid',
        warningSaveMappingNoSelection: 'No elevation was mapped using the selection tool!',
        combinationUnit: 'Combination Unit {comboUnitIndex}',
        commonOptions: 'Common Options',
        location: 'Location',
        wallDepth: 'Wall Depth',
        label: 'Label',
        groupOptions: 'Group Options',
        nameOptions: '{name} Options',
        share: 'Share',
        backTo: 'Back to {backTo}',
        back: 'Back',
        lastUpdated: 'Last Updated',
        'takeoff.details.title': 'Take Off Details',
        'takeoff.details.inclusions': 'Inclusions',
        'takeoff.details.exclusions': 'Exclusions',
        'takeoff.details.notes': 'Notes',
        'takeoff.details.add.inclusion': 'Hit enter to create new inclusion line',
        'takeoff.details.add.exclusion': 'Hit enter to create new exclusion line',
        'takeoff.details.add.note': 'Hit enter to create new note line',
        'takeoff.details.empty.inclusions': 'No inclusions added yet',
        'takeoff.details.empty.exclusions': 'No exclusions added yet',
        'takeoff.details.empty.notes': 'No notes added yet',
        'takeoff.details.name': 'Take Off Name',
        'takeoff.details.price': 'Take Off Price',
        'project.details.title': 'Project Details',
        'client.details.title': 'Client Details',
        'client.details.name': 'Client',
        projectAddress: 'Project Address',
        city: 'City',
        addressLine1: 'Address Line 1',
        addressLine2: 'Address Line 2',
        projectFiles: 'Project Files',
        state: 'State',
        zipCode: 'Zip Code',
        country: 'Country',
        rightClick: 'RIGHT CLICK AND DRAG TO PAN',
        scrollZoom: 'SCROLL TO ZOOM',
        deleteTakeOff: 'Delete Take Off {itemName}',
        dots: 'Dots',
        dotTool: 'Exterior Dot Tool',
        extSceneUrl: 'Exterior scene URL',
        extAssetUrl: 'Exterior asset URL',
        intSceneUrl: 'Interior scene URL',
        intAssetUrl: 'Interior asset URL',
        useCatalogFamilyMappings: 'Use catalog family mappings',
        intDotTool: 'Interior Dot Tool',
        publishinProjectInfo: 'Publish project information',
        clientName: 'Client name',
        externalBuilderName: 'External builder name',
        publishingHistory: 'Publishing History',
        builderName: 'Builder name',
        url: 'Url',
        overridenBy: 'Overriden by',
        mostRecentPublishing: 'Most recent publishing',
        goToVisualizer: 'Go to visualizer',
        seeProject: 'See project',
        uploadFileForAlternative: 'Upload file for {alternativeName} Alternative',
        filesOfAlternative: '{alternativeName} alternative\'s files',
        startDate: 'Start date',
        endDate: 'End date',
        lastCheckup: 'Last checkup',
        forgeInfo: 'Forge info',
        logs: 'logs',
        autodeskforgeinformation: 'Autodesk Forge Information',
        workItemId: 'WorkItem ID',
        endTime: 'End Time',
        startTime: 'startTime',
        queuedTime: 'Queued Time',
        conversionHistoryFor: 'Conversion history for {alternativeName} alternative',
        succesSavedFirstCamera: 'Saved Default First Camera',
        sectionDots: 'Section Dots',
        cameraAndScenes: 'Cameras and Scenes',
        successDeleted: 'Successfully deleted',
        templateInternalName: 'Template Internal Name',
        downloadRevitValidationFile: 'Click the following link to download the validation result file',
        noRevitValidationFile: 'There  is no validation result file',
        revitModelState: 'The state of the current revit model is',
        valid: 'valid',
        invalid: 'invalid',
        unknown: 'unknown',
        preview: 'Preview'
    }
}
