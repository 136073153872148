import { JsonProperty } from 'jsog-typescript'
import { observable } from 'mobx'
import { Country } from './Country'

export class State {
    public id: string
    @observable public code: string
    @observable public description: string
    @JsonProperty(Country)
    public country: Country
}
