
import { postal, styled } from '@paradigm/blueprints-common-frontend'
import { action, observable, runInAction, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Fragment } from 'react'
import { errorStore } from '../../../stores/errorStore'
import { ModelStore } from '../../../stores/modelStore'
import { PannellumStore, pannellumStore } from '../../../stores/PannellumStore'

const pannellum = (window as any).pannellum

interface ICameraListProps {
    pannellumStore?: PannellumStore
    modelStore?: ModelStore
}

@inject('pannellumStore', 'modelStore')
@observer
class PannellumViewer extends React.Component<ICameraListProps> {
    @observable private viewer: any

    @action
    public componentDidMount() {
        this.renderPannellum()
    }

    @action
    public renderPannellum(updateViewer: boolean = false) {
        const { pannellumConfig, scenes, currentCamera } = this.props.pannellumStore!

        if (pannellumConfig && scenes) {
            const config: any = {
                'default': {
                    'firstScene': currentCamera ? currentCamera.cameraName : pannellumConfig!.default.firstScene,
                    'sceneFadeDuration': pannellumConfig!.default.sceneFadeDuration,
                    'autoLoad': true,
                    'showControls': false,
                    'orientationOnByDefault': true,
                    'compass': true
                },
                'scenes': {}
            }

            Object.keys(scenes).forEach((sceneName) => {
                const configScene = pannellumConfig.scenes[sceneName]
                const sceneCubemaps = scenes[sceneName]
                if (configScene) {
                    configScene.type = 'cubemap'
                    configScene.cubeMap = [
                        sceneCubemaps.Front,
                        sceneCubemaps.Right,
                        sceneCubemaps.Back,
                        sceneCubemaps.Left,
                        sceneCubemaps.Up,
                        sceneCubemaps.Down
                    ]
                    if (configScene.hotSpots) {
                        configScene.hotSpots.forEach((hotSpot) => {
                            hotSpot['cssClass'] = hotSpot.sceneId ? 'custom-scene-dot' : 'custom-hotspot'
                            hotSpot['createTooltipFunc'] = hotSpot.sectionId ? this.createDotToolTip : null
                            hotSpot['createTooltipArgs'] = hotSpot.sectionId
                            hotSpot['clickHandlerFunc'] = this.handleHotSpotClick
                            hotSpot['clickHandlerArgs'] = { sectionId: hotSpot.sectionId, sceneId: hotSpot.sceneId }
                        })
                    }
                }

                config.scenes[sceneName] = toJS(configScene)
            })

            if (this.viewer && updateViewer) {
                this.viewer.destroy()
            }

            this.viewer = pannellum.viewer('panorama', config)

            this.viewer.on('load', this.sceneLoadListener)
            this.viewer.on('mousedown', this.onMouseDownListener)

            runInAction(() => pannellumStore.viewer = this.viewer)
        }
    }

    public render() {
        if (errorStore.errors.length) {
            throw new Error('PannellumViewer.')
        }

        const { pannellumStore } = this.props

        if (!pannellumStore) { return null }

        return (
            <Fragment>
                {pannellumStore.update && this.renderPannellum(true)}
                <Panorama id='panorama' />
            </Fragment>
        )
    }

    @action
    private handleHotSpotClick = (evt: Event, args: { sceneId: string, sectionId: string }) => {
        const { sectionId } = args
        if (sectionId) {
            this.props.pannellumStore!.sectionName = sectionId
        } else {
            this.props.pannellumStore!.currentCamera = this.props.pannellumStore!.builderDotToolData[args.sceneId]
            return
        }
    }

    private createDotToolTip(hotSpotDiv: HTMLElement, args: string) {
        hotSpotDiv.classList.add('custom-tooltip')
        const span = document.createElement('span')
        span.innerHTML = args
        hotSpotDiv.appendChild(span)
        span.style.width = span.scrollWidth - 20 + 'px'
        span.style.marginLeft = (-(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 - 3) + 'px'
        span.style.marginTop = -span.scrollHeight - 12 + 'px'
    }

    @action
    private sceneLoadListener = () => {
        this.props.pannellumStore!.update = false
        this.props.pannellumStore!.isLoadingAnswer = false

        const selectedInfoDot = document.getElementsByClassName(this.props.pannellumStore!.sectionName)[0]
        if (selectedInfoDot) {
            selectedInfoDot.className += ' selectedInfoDot'
        }
    }

    @action
    private onMouseDownListener = (evt: any) => {
        const isNotMove = this.viewer && !this.props.pannellumStore!.isMove
        evt.preventDefault()
        this.viewer.setUpdate(true)

        if (isNotMove && !this.props.pannellumStore!.isAddCameraScene) {
            this.props.pannellumStore!.isLoadingAnswer = true
            this.findCollision3DPoint(evt, false)

        } else  if (isNotMove && this.props.pannellumStore!.isAddCameraScene) {
            this.findCollision3DPoint(evt, true)
        }
    }

    private findCollision3DPoint(evt: any, forCamera: boolean) {
        const coords: [number, number] = this.viewer.mouseEventToCoords(evt)
        this.props.pannellumStore!.findCollision3dPoint(coords, forCamera)
    }
}

export default PannellumViewer

const Panorama = styled.div`
    height: 100%;
    width: 100%;
    top: 0;
`
