import axios from 'axios'
import Config from '../../../../Config'

export const BASE_URL = Config.API_PROTOCOL + '//' + Config.API_HOSTNAME + ':' + Config.API_PORT

const fetch = axios.create({
    baseURL: BASE_URL
})

export function handleResponse(onFulfilled?: (error: any) => any, onRejected?: (error: any) => any) {
    fetch.interceptors.response.use(onFulfilled, onRejected)
}

export function get(url: string) {
    return fetch.get(url)
}

export function post(url: string, data?: any, config?: any) {
    return fetch.post(url, data, config)
}

export function put(url: string, data?: any, config?: any) {
    return fetch.put(url, data, config)
}

export function patch(url: string, data?: any, config?: any) {
    return fetch.patch(url, data, config)
}

export function del(url: string) {
    return fetch.delete(url)
}

export function useBearerToken(token: string | null) {
    fetch.defaults.headers = {
        authorization: `Bearer ${token}`
    }
}
