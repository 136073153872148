import React from "react";
import { errorStore } from "../../stores/errorStore";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    goBack = () => {
        errorStore.clear()
        this.props.history.goBack()
    }

    refresh = () => {
        location.reload()
    }

    render() {
        let errors
        if (errorStore.errors.length) {
            errors = errorStore.errors.map((error, index) => 
                <p key={index}>
                    {error}
                </p>
            )
        }
        if (this.state.errorInfo) {
            return (
                <div style={{
                    background: '#FFFFFF',
                    padding: 20,
                    textAlign: 'center',
                    position: 'relative',
                    height: '100%'
                }}>
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <h2>Something went wrong.</h2>
                        {errors}
                        <button onClick={this.goBack}>Go Back</button>
                        <button onClick={this.refresh}>Try Again</button>
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

export default withRouter(ErrorBoundary)