export class Option {
  public key: string
  public value: string
  public order: string | undefined

  constructor(key: string, value: string, order?: string) {
      this.key = key
      this.value = value
      this.order = order
  }
}