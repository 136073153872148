import { observable } from 'mobx'
import { Country } from './Country'
import { State } from './State'

export class Builder {
    public id: string
    @observable public name: string
    @observable public mainContactName: string
    @observable public mainContactEmail: string
    @observable public logoUrl: string
    @observable public addressLine1: string
    @observable public addressLine2: string
    @observable public zipCode: string
    @observable public city: string
    @observable public companyId: string
    @observable public country: Country
    @observable public state: State
}
