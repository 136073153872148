import React from 'react'
import { observer, inject } from 'mobx-react'
import ProjectHeader from '../../../project/components/ProjectHeader'
import Container from '../../../common/components/Layout/Container'
import Panel, { PanelActions, PanelContent, PanelHeader, PanelTitle } from '../../../common/components/Panel'
import { FormattedMessage } from 'react-intl'
import CreatableSelect from 'react-select'
import { action, observable, runInAction } from 'mobx'
import { IStore } from '../../../../App/AppStore'
import { RouteComponentProps } from 'react-router-dom'
import { ValueType, ActionMeta } from 'react-select/lib/types'
import { IModel, Viewer, ViewerRenderingEngines, Viewer3dManager, ModelType, postal, IGeometryMaterialOptions, MaterialOptions, MaterialOptionsType, ModelUtils } from '@paradigm/blueprints-common-frontend'
import { ModelStore } from '../../../stores/modelStore'
import { DesignOption } from '../../../project/models/DesignOption'
import * as MaterialOptionsLib from '../CameraToolViewer/MaterialOptionsLib'

interface IModelStore { modelStore : ModelStore}

interface ISelectableOption { 
    label : string
    value: DesignOption
}

const MODEL_TYPES_TO_LOAD: ModelType[] = [
    ModelType.WALL,
    ModelType.WINDOW,
    ModelType.DOOR,
    ModelType.ROOF,
    ModelType.SLAB,
    ModelType.BUILDING_ELEMENT_PROXY,
    ModelType.IFC_COLUMN,
    ModelType.IFC_COVERING,
    ModelType.IFC_RAILING,
    ModelType.IFC_STAIR_FLIGHT,
]

@inject('store', 'modelStore')
@observer
class Preview extends React.Component<IStore & RouteComponentProps & IModelStore>
{
    @observable private viewerManager: Viewer3dManager | null
    @observable private alternativeName: string
    @observable private selectedOption: ISelectableOption | null
    @observable private modelId: string | null

    private materialOptions: Map<MaterialOptionsType, IGeometryMaterialOptions[]> = new Map()

    public async componentDidMount()
    {
        this.prepareComponant()
    }

    private async prepareComponant() {
        const { match }: any = this.props
        const fetchModel = false
        const project = await this.props.store!.projectStore.getProject(match.params.projectId, fetchModel)

        if (project !== null) {
            if (project.alternatives) {
                const alternative = project.alternatives.find((a) => a.id === match.params.alternativeId)
                
                if (alternative && alternative.revisions) {
                    this.props.store!.projectStore.setCurrentAlternative(alternative.id)
                    const { currentRevision } = this.props.store!.projectStore
                    
                    await this.props.modelStore.loadModel(currentRevision.buildingModelId)
                    await this.props.modelStore!.loadGeometries(currentRevision.buildingModelId, MODEL_TYPES_TO_LOAD)
                    
                    const manager = new Viewer3dManager(this.props.modelStore!.geometries)
                    this.populateMaterialOptions(this.props.modelStore!.model.children)
                    manager!.setMaterialOptions(this.materialOptions)
                    
                    runInAction(() => {
                        this.alternativeName = alternative.name
                        this.modelId = currentRevision.buildingModelId
                        this.viewerManager = manager
                    })
                }
            }
        }
    }

    public render()
    {
        const options = this.props.store!.projectStore.currentRevision.options
        var optionsForSelection: any[] =  options != null ? 
            options.filter(option => option.buildingModelId).map(option => ({ value: option, label: option.name + ' ' + option.groupName}))
            : []

        return (
            <React.Fragment>
                <ProjectHeader />
                <Container>
                    <Panel>
                        <PanelHeader>
                            <PanelTitle>
                                <FormattedMessage id='alternative'/>: {this.alternativeName}  <br/>
                                <FormattedMessage id='designOption'/>: {this.selectedOption && this.selectedOption.label }  
                            </PanelTitle>
                        </PanelHeader>
                        <PanelActions style={{width: '15%'}}>
                            <CreatableSelect onChange={this.onOptionSelect.bind(this)}
                                    options={optionsForSelection} value={this.selectedOption} isClearable={true} />
                        </PanelActions>
                        <PanelContent height={'100%'}>
                            {(this.modelId && this.viewerManager) &&
                                <Viewer
                                    canvasId={'preview-viewer'}
                                    renderingEngine={ViewerRenderingEngines.BABYLON}
                                    viewerManager={this.viewerManager}
                                />
                            }
                        </PanelContent>
                    </Panel>
                </Container>
            </React.Fragment>
        )
    }

    @action
    private async onOptionSelect(option: ValueType<any>, actionMeta: ActionMeta) {
        var geometries = null

        if (option) {
            if (this.selectedOption == null || option.value.id != this.selectedOption.value.id) {
                this.selectedOption = option
                await this.props.modelStore.loadOptionModelStructure(option.value.buildingModelId)
                await this.props.modelStore!.loadGeometries(option.value.buildingModelId, MODEL_TYPES_TO_LOAD, true)
                geometries = this.props.modelStore.mergeBaseAndOptionGeometries()
                this.populateMaterialOptions(this.props.modelStore!.optionModel.children)
            }
        } else {
            geometries = this.props.modelStore.geometries
            this.selectedOption = null
        }
        
        if (geometries != null) {
            this.viewerManager!.setMaterialOptions(this.materialOptions)
            postal.publish({ channel: 'renderer', topic: 'request.rerender', data: { geometries } })
        }
    }

    private populateMaterialOptions(nodes: IModel[]) {
        Object.values(MODEL_TYPES_TO_LOAD).forEach((modelType) => {
            switch (modelType) {
                case ModelType.WALL:
                    const wallMaterialOption = MaterialOptionsLib.createSimpleMaterialOption(MaterialOptionsLib.COLOR_HEX_BEIGE, 1, true)
                    this.createGeometryMaterialOptions(nodes, modelType, MaterialOptionsType.PBR, wallMaterialOption)
                    break
                default:
                    break
            }
        })
    }

    private createGeometryMaterialOptions(nodes: IModel[], type: ModelType, materialOptionsType: MaterialOptionsType, materialOption: MaterialOptions.BaseMaterialOptions) {
        if (this.props.modelStore!.model !== null && materialOption !== undefined) {
            const ids = ModelUtils.findElementIdsByType(nodes, type)
            const option: IGeometryMaterialOptions = {
                ids,
                options: materialOption
            }
            const options = this.materialOptions.get(materialOptionsType)

            if (options === undefined) {
                this.materialOptions.set(materialOptionsType, [option])
            } else {
                options.push(option)
            }
        }
    }
}

export { Preview }