import { action, autorun, computed, observable } from 'mobx'
import * as authService from '../../auth/authService'
import { User } from '../../auth/models/User'

export class Message {
	@observable public id: string
	@observable public createdDate: number
	@observable public modifiedDate: number
	@observable public createdBy: string
	@observable public comment: string
	@observable public fromUser: User

	constructor() {
		autorun(() => {
			if (this.createdBy) {
				this.getFrom()
			}
		})
	}

	@computed get formatedCreatedDate(): string {
		if (this.createdDate !== undefined) {
			const date = new Date(this.createdDate)
			return date.toLocaleDateString() + date.toLocaleTimeString()
		} else {
			return ''
		}
	}

	@computed get formatedModifiedDate(): string {
		if (this.modifiedDate !== undefined) {
			return new Date(this.modifiedDate).toLocaleDateString()
		} else {
			return ''
		}
	}

	@computed get from(): string {
		return this.fromUser ? this.fromUser.username : ''
	}

	@action public async getFrom() {
		if (this.createdBy) {
			authService.getUserById(this.createdBy)
				.then(userResponse => action(() => this.fromUser = Object.assign(new User(), userResponse.data)))
		}
	}
}
