import { styled } from '@paradigm/blueprints-common-frontend'

const EmptyMessage = styled.div`
  font-size: 18px;
  color: #9c9c9c;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: ${(props) => props.theme.font.family};
  width: max-content;
`

export default EmptyMessage
