import { computed, observable } from 'mobx'

export class RevisionFile {
  @observable public id: string
  @observable public createdDate: number
  @observable public name: string
  @observable public extension: string | undefined
  @observable public fileSize: number
  @observable public fileType: string
  @observable public lastModifiedDate: number
  @observable public data: File
  @observable public fileFunction: string
  @observable public url: string
  @observable public fileUrl: string

  @computed get formatedCreatedDate(): string {
      if (this.createdDate !== undefined) {
          return new Date(this.createdDate).toLocaleDateString()
      }
      return ''
  }

  @computed get formatedLastModifiedDate(): string {
      if (this.lastModifiedDate !== undefined) {
          return new Date(this.lastModifiedDate).toLocaleDateString()
      }
      return ''
  }
}
