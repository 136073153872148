import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { IStore } from '../../../App/AppStore'
import MappingToolViewer from '../../buildingModel/components/MappingToolViewer/MappingToolViewer'
import { BASE_URL } from '../../common/service/transport'
import ProjectHeader from '../components/ProjectHeader'
import { FileFunction } from '../enums/FileFunction'

// @ts-ignore
@withRouter
@inject('store')
@observer
export default class MappingToolContainer extends React.Component<IStore> {
    constructor(props: Readonly<IStore>) {
        super(props)
    }

    public componentDidMount() {
        const { currentProject } = this.props.store!.projectStore

        if (currentProject.id === undefined) {
            const { match }: any = this.props
            this.props.store!.projectStore.getProject(match.params.projectId, false)
        }
    }

    public blueprintImages(): any[] {
        const { currentRevision } = this.props.store!.projectStore
        let blueprintImages: any[] = []

        if (currentRevision && currentRevision.revisionFiles) {
            blueprintImages = currentRevision.revisionFiles
                .filter((file) => this.isBlueprintImage(file.fileFunction))
                .map((file) => ({ id: file.id, url: `${BASE_URL}/project/api${file.url}` }))
        }

        return blueprintImages
    }

    public render() {
        const { currentRevision } = this.props.store!.projectStore

        if (this.blueprintImages().length === 0) {
            return null
        }

        return (
            <div>
                <ProjectHeader />
                <MappingToolViewer modelId={currentRevision.buildingModelId} blueprintImages={this.blueprintImages()} />
            </div>
        )
    }

    private isBlueprintImage(fileFunction: string): boolean {
        return fileFunction === FileFunction.BLUEPRINT_IMAGES.toString()
    }
}
