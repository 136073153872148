import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeleteButton, SecondaryButton, styled } from '@paradigm/blueprints-common-frontend'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { IStore } from '../../../App/AppStore'
import { UserRole } from '../../auth/models/User'
import Panel, { PanelActions, PanelContent, PanelHeader, PanelTitle } from '../../common/components/Panel'
import { UploadField } from './UploadField'

const CardPanel = styled(Panel)`
    margin: 1rem 0;
`

interface IProjectForm extends IStore {
    history?: any
}

// @ts-ignore
@withRouter
@inject('store')
@observer
export class ProjectForm extends React.Component<IProjectForm> {
    constructor(props: Readonly<IStore>) {
        super(props)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    public onChange(event: any) {
        this.props.store!.projectStore.projectForm.onFieldChange(event.target.name, event.target.value)
    }

    public onSubmit = async (event: any) => {
        event.preventDefault()
        await this.props.store!.projectStore.projectForm.onSubmit()
    }

    public render() {
        const { currentProject } = this.props.store!.projectStore
        const { user } = this.props.store!
        const showDeleteAction = (currentProject.id !== undefined) && (user.role === UserRole.ADMIN || UserRole.TEAM_LEADER)

        return (
            <CardPanel>
                <PanelHeader>
                    <PanelTitle>
                        <FormattedMessage
                            id={'projectInformations'}
                            defaultMessage={'Project Informations'} />
                    </PanelTitle>
                </PanelHeader>
                <PanelActions>
                    <SecondaryButton type='submit' onClick={this.onSubmit}>
                        <FontAwesomeIcon icon={['far', 'save']} /> <FormattedMessage id={'save'} defaultMessage={'Save'} />
                    </SecondaryButton>
                    {showDeleteAction &&
                        <DeleteButton onClick={this.handleOnDeleteProject}>
                            <FontAwesomeIcon icon={['far', 'trash-alt']} /> <FormattedMessage id={'delete'} defaultMessage={'Delete'} />
                        </DeleteButton>
                    }
                </PanelActions>
                <PanelContent height={'100%'}>
                    <form>
                        {this.props.store!.projectStore.projectForm.form.fields.map((field) =>
                            <FormGroup
                                key={field.name}
                                controlId={field.name}
                                className={field.error ? 'has-error' : ''}>
                                <ControlLabel>{this.props.store!.formatMessage(field.label as string)}</ControlLabel>
                                <FormControl
                                    name={field.name}
                                    type={field.type}
                                    value={field.value}
                                    onChange={this.onChange}
                                />
                                <span className='help-block'>{field.error}</span>
                            </FormGroup>
                        )}
                        {this.props.store!.projectStore.isNewProject &&
                            <UploadField />
                        }
                    </form>
                </PanelContent>
            </CardPanel>
        )
    }

    private handleOnDeleteProject = async () => {
        const { currentProject } = this.props.store!.projectStore
        if (currentProject.id !== undefined) {
            const isDeleted = await this.props.store!.projectStore.deleteProject(currentProject.id)

            if (isDeleted) {
                const { history } = this.props as any
                if (history) {
                    history.push('/')
                }
            }
        }
    }
}
