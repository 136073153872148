import { JsonUtils } from '@paradigm/blueprints-common-frontend'
import { observable, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import ImageCarousel from '../../common/components/ImageCarousel/ImageCarousel'
import EmptyMessage from '../../common/components/Typography/EmptyMessage'
import { FILE_STORAGE_API, FILES_ENDPOINT } from '../../common/service/fileStorageApi'
import * as buildingModelService from '../buildingModelService'
import { IMappingData } from '../interfaces'

interface IBlueprintsCarouselProps {
    modelId: string
}

@inject('store')
@observer
export default class BlueprintsCarousel extends React.Component<IBlueprintsCarouselProps> {
    @observable private imageURLs: string[] = []
    @observable private isLoading: boolean = true
    @observable private hasError: boolean = false

    public async componentDidMount() {
        const imageURLs = await this.retrieveMappingDataImageURLs(this.props.modelId)
        runInAction(() => this.imageURLs = imageURLs)
    }

    public async retrieveMappingDataImageURLs(modelId: string): Promise<string[]> {
        const imageURLs: string[] = []

        try {
            const mappingDataResponse = await buildingModelService.fetchMappingData(modelId)
            const mappings: Map<string, IMappingData> = JsonUtils.buildMap(mappingDataResponse.data)
            if (mappings && mappings.size > 0) {
                mappings.forEach((mappingData: IMappingData) => {
                    if (mappingData.imageURL) {
                        imageURLs.push(FILE_STORAGE_API + '/' + FILES_ENDPOINT + mappingData.imageURL)
                    }
                })
            }
        } catch (error) {
            console.error(error)
            runInAction(() => this.hasError = true)
        } finally {
            runInAction(() => this.isLoading = false)
        }

        return imageURLs
    }

    public render() {
        if (this.isLoading) {
            return (<div>
                <EmptyMessage>
                    <FormattedMessage id='loadingModelMappingImages' />
                </EmptyMessage>
            </div>)
        }
        if (this.hasError) {
            return (<div>
                <EmptyMessage>
                    <FormattedMessage id='errorWhileLoadingMappingImages' />
                </EmptyMessage>
            </div>)
        }
        if (this.imageURLs.length === 0) {
            return (<div>
                <EmptyMessage>
                    <FormattedMessage id='currentModelHasNoMappingImages' />
                </EmptyMessage>
            </div>)
        }
        return (
            <ImageCarousel imageURLs={this.imageURLs}  />
        )
    }
}
