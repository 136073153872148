import { JsonProperty } from 'jsog-typescript'
import { observable } from 'mobx'
import { Project } from './Project'
import { Revision } from './Revision'
import { AssetBundle } from './AssetBundle'
import { RevitModelValidationStatus } from '../enums/FileFunction'

export class Alternative {
    public id: string
    public createdDate: number
    public createdBy: string
    public modifiedDate: number
    public deletedDate: number
    public version: number
    @observable public name: string = ''
    @observable public displayOrder: number = 1
    @observable public imageUrl: string = ''
    @JsonProperty(Project)
    @observable public project: Project
    @observable public revisions: Revision[] = []
    @observable public extSceneUrl: string | null
    @observable public extAssetUrl: string | null
    @observable public intSceneUrl: string | null
    @observable public intAssetUrl: string | null
    @observable public useCatalogFamilyMappings: boolean = true
    @JsonProperty(AssetBundle)
    @observable public assetBundles: AssetBundle[] = []
    @observable public withFloorPlanScene: boolean
    @observable public myHomeEnabled: boolean
    @observable public revitModelValidationStatus: RevitModelValidationStatus
    @observable public revitModelValidationFileUrl: string | null
    @observable public assetBundlePannellumConfig: string | null
    @observable public assetBundleDotToolData: string | null
}
