import { Spinner } from "."
import { styled } from '@paradigm/blueprints-common-frontend'

const PageSpinner = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`

export default PageSpinner
